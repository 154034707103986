import React from 'react';
import {
  PhoneTelephone,
  Info,
  Help,
  Attention,
  Play,
  AtSign,
  LinkOne,
  VerticalTidyUp,
  AdjacentItem,
  Time,
  PictureOne,
  HamburgerButton,
  Mail,
  SettingTwo,
  TextMessage,
  AddText,
  Topic,
  Comment,
  HandleX,
  Right,
  ClickToFold,
  Minus,
  Down,
  Left,
  Up,
  AddTextTwo,
  Comments,
  Redo,
  Undo,
  AddItem,
  Paperclip,
  CalendarThree,
  Plus,
  Add,
  OnlineMeeting,
  Close,
  MoreOne,
  AddOne,
  Copy,
  LinkTwo,
  Timer,
  Lightning,
  Search,
  SettingConfig,
  WeixinMarket,
  Analysis,
  ForkSpoon,
  BankCard,
  City,
  Hold,
  MoreTwo,
  Clue,
  ConnectionArrow,
  STurnLeft,
  CheckSmall,
  Sapling,
  BottleOne,
  BottleTwo,
  ClothesTurtleneck,
  HighHeeledShoes,
  Piano,
  BookOne,
  ChildrenPyramid,
  Pills,
  CheckOne,
  LayoutTwo,
  Logout,
  LinkRight,
  Outbound,
  Send,
  Bye,
  Order,
  TipsOne,
  Box,
  Five,
  User,
  PoundSign,
  Local,
  Checklist,
  RobotOne,
  Erase,
  AlignTextBoth,
  LoadingFour,
  FolderOpen,
  Avatar,
  ListTop,
  ListTwo,
  Code,
  CodeBrackets,
  Edit,
  FileAdditionOne,
  AlphabeticalSorting,
  AlphabeticalSortingTwo,
  SortAmountDown,
  SortAmountUp,
  TextBold,
  TextItalic,
  TextUnderline,
  OrderedList,
  AlignLeftOne,
  Back,
  Filter,
  Cycle,
  AllApplication,
  Check,
  Pencil,
  PreviewOpen,
  PreviewClose,
  UploadLogs,
  AddThree,
  Me,
  SendToBack,
  ListView,
  Lifebuoy,
  ExpandTextInput,
  Success,
  ChartHistogramTwo,
  InsertTable,
  PeoplePlus,
  Download,
  Lock,
  ShareThree,
  Share,
  Terminal,
  CodeOne,
} from '@icon-park/react';
import { IIconProps } from '@icon-park/react/lib/runtime';

import './index.less';

export type IconName =
  | 'info'
  | 'help'
  | 'attention'
  | 'play'
  | 'at-sign'
  | 'link-one'
  | 'vertical-tidy-up'
  | 'adjacent-item'
  | 'time'
  | 'picture-one'
  | 'hamburger-button'
  | 'mail'
  | 'setting-two'
  | 'text-message'
  | 'add-text'
  | 'topic'
  | 'comment'
  | 'handle-x'
  | 'right'
  | 'click-to-fold'
  | 'minus'
  | 'down'
  | 'left'
  | 'up'
  | 'phone-telephone'
  | 'add-text-two'
  | 'comments'
  | 'robot'
  | 'redo'
  | 'undo'
  | 'add-item'
  | 'paperclip'
  | 'calendar-three'
  | 'plus'
  | 'add'
  | 'online-meeting'
  | 'close'
  | 'more-one'
  | 'add-one'
  | 'copy'
  | 'link-two'
  | 'timer'
  | 'lightning'
  | 'search'
  | 'setting-config'
  | 'weixin-market'
  | 'analysis'
  | 'fork-spoon'
  | 'bank-card'
  | 'city'
  | 'hold'
  | 'more-two'
  | 'clue'
  | 'connection-arrow'
  | 's-turn-left'
  | 'check-small'
  | 'sapling'
  | 'bottle-one'
  | 'bottle-two'
  | 'clothes-turtleneck'
  | 'high-heeled-shoes'
  | 'piano'
  | 'book-one'
  | 'children-pyramid'
  | 'pills'
  | 'check-one'
  | 'layout-two'
  | 'logout'
  | 'link-right'
  | 'outbound'
  | 'send'
  | 'bye'
  | 'order'
  | 'tips-one'
  | 'box'
  | 'five'
  | 'user'
  | 'pound-sign'
  | 'local'
  | 'checklist'
  | 'erase'
  | 'align-text-both'
  | 'loading-four'
  | 'folder-open'
  | 'avatar'
  | 'list-top'
  | 'list-two'
  | 'code'
  | 'code-brackets'
  | 'edit'
  | 'file-addition-one'
  | 'alphabetical-sorting'
  | 'alphabetical-sorting-two'
  | 'sort-amount-down'
  | 'sort-amount-up'
  | 'text-bold'
  | 'text-italic'
  | 'text-underline'
  | 'ordered-list'
  | 'align-left-one'
  | 'back'
  | 'filter'
  | 'cycle'
  | 'all-application'
  | 'check'
  | 'pencil'
  | 'preview-open'
  | 'preview-close'
  | 'upload-logs'
  | 'add-three'
  | 'me'
  | 'send-to-back'
  | 'list-view'
  | 'lifebuoy'
  | 'expand-text-input'
  | 'success'
  | 'insert-table'
  | 'chart-histogram-two'
  | 'people-plus'
  | 'download'
  | 'share-three'
  | 'share'
  | 'lock'
  | 'terminal'
  | 'code-one';

interface SbIconProps extends IIconProps {
  iconName: IconName;
}

const SbIcon: React.FC<SbIconProps> = ({ iconName, ...iIconProps }) => {
  const size = iIconProps.size || 24;
  const theme = iIconProps.theme || 'outline';
  const style = { width: size, height: size, ...iIconProps.style };
  const className = `${iIconProps.className || ''} sb-icon`;

  const props = {
    ...iIconProps,
    size,
    theme,
    style,
    className,
  };

  switch (iconName) {
    case 'info':
      return <Info {...props} />;
    case 'help':
      return <Help {...props} />;
    case 'attention':
      return <Attention {...props} />;
    case 'play':
      return <Play {...props} />;
    case 'at-sign':
      return <AtSign {...props} />;
    case 'link-one':
      return <LinkOne {...props} />;
    case 'vertical-tidy-up':
      return <VerticalTidyUp {...props} />;
    case 'adjacent-item':
      return <AdjacentItem {...props} />;
    case 'time':
      return <Time {...props} />;
    case 'picture-one':
      return <PictureOne {...props} />;
    case 'hamburger-button':
      return <HamburgerButton {...props} />;
    case 'mail':
      return <Mail {...props} />;
    case 'setting-two':
      return <SettingTwo {...props} />;
    case 'text-message':
      return <TextMessage {...props} />;
    case 'add-text':
      return <AddText {...props} />;
    case 'topic':
      return <Topic {...props} />;
    case 'comment':
      return <Comment {...props} />;
    case 'handle-x':
      return <HandleX {...props} />;
    case 'right':
      return <Right {...props} />;
    case 'click-to-fold':
      return <ClickToFold {...props} />;
    case 'minus':
      return <Minus {...props} />;
    case 'down':
      return <Down {...props} />;
    case 'left':
      return <Left {...props} />;
    case 'up':
      return <Up {...props} />;
    case 'phone-telephone':
      return <PhoneTelephone {...props} />;
    case 'add-text-two':
      return <AddTextTwo {...props} />;
    case 'comments':
      return <Comments {...props} />;
    case 'robot':
      return <RobotOne {...props} />;
    case 'redo':
      return <Redo {...props} />;
    case 'undo':
      return <Undo {...props} />;
    case 'add-item':
      return <AddItem {...props} />;
    case 'paperclip':
      return <Paperclip {...props} />;
    case 'calendar-three':
      return <CalendarThree {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'add':
      return <Add {...props} />;
    case 'online-meeting':
      return <OnlineMeeting {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'more-one':
      return <MoreOne {...props} />;
    case 'add-one':
      return <AddOne {...props} />;
    case 'copy':
      return <Copy {...props} />;
    case 'link-two':
      return <LinkTwo {...props} />;
    case 'timer':
      return <Timer {...props} />;
    case 'lightning':
      return <Lightning {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'setting-config':
      return <SettingConfig {...props} />;
    case 'weixin-market':
      return <WeixinMarket {...props} />;
    case 'analysis':
      return <Analysis {...props} />;
    case 'fork-spoon':
      return <ForkSpoon {...props} />;
    case 'bank-card':
      return <BankCard {...props} />;
    case 'city':
      return <City {...props} />;
    case 'hold':
      return <Hold {...props} />;
    case 'more-two':
      return <MoreTwo {...props} />;
    case 'clue':
      return <Clue {...props} />;
    case 'connection-arrow':
      return <ConnectionArrow {...props} />;
    case 's-turn-left':
      return <STurnLeft {...props} />;
    case 'check-small':
      return <CheckSmall {...props} />;
    case 'sapling':
      return <Sapling {...props} />;
    case 'bottle-one':
      return <BottleOne {...props} />;
    case 'bottle-two':
      return <BottleTwo {...props} />;
    case 'clothes-turtleneck':
      return <ClothesTurtleneck {...props} />;
    case 'high-heeled-shoes':
      return <HighHeeledShoes {...props} />;
    case 'piano':
      return <Piano {...props} />;
    case 'book-one':
      return <BookOne {...props} />;
    case 'children-pyramid':
      return <ChildrenPyramid {...props} />;
    case 'pills':
      return <Pills {...props} />;
    case 'check-one':
      return <CheckOne {...props} />;
    case 'layout-two':
      return <LayoutTwo {...props} />;
    case 'logout':
      return <Logout {...props} />;
    case 'link-right':
      return <LinkRight {...props} />;
    case 'outbound':
      return <Outbound {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'bye':
      return <Bye {...props} />;
    case 'order':
      return <Order {...props} />;
    case 'tips-one':
      return <TipsOne {...props} />;
    case 'box':
      return <Box {...props} />;
    case 'five':
      return <Five {...props} />;
    case 'user':
      return <User {...props} />;
    case 'pound-sign':
      return <PoundSign {...props} />;
    case 'local':
      return <Local {...props} />;
    case 'checklist':
      return <Checklist {...props} />;
    case 'erase':
      return <Erase {...props} />;
    case 'align-text-both':
      return <AlignTextBoth {...props} />;
    case 'loading-four':
      return <LoadingFour {...props} />;
    case 'folder-open':
      return <FolderOpen {...props} />;
    case 'avatar':
      return <Avatar {...props} />;
    case 'list-top':
      return <ListTop {...props} />;
    case 'list-two':
      return <ListTwo {...props} />;
    case 'code':
      return <Code {...props} />;
    case 'code-brackets':
      return <CodeBrackets {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'file-addition-one':
      return <FileAdditionOne {...props} />;
    case 'alphabetical-sorting':
      return <AlphabeticalSorting {...props} />;
    case 'alphabetical-sorting-two':
      return <AlphabeticalSortingTwo {...props} />;
    case 'sort-amount-down':
      return <SortAmountDown {...props} />;
    case 'sort-amount-up':
      return <SortAmountUp {...props} />;
    case 'text-bold':
      return <TextBold {...props} />;
    case 'text-italic':
      return <TextItalic {...props} />;
    case 'text-underline':
      return <TextUnderline {...props} />;
    case 'ordered-list':
      return <OrderedList {...props} />;
    case 'align-left-one':
      return <AlignLeftOne {...props} />;
    case 'back':
      return <Back {...props} />;
    case 'filter':
      return <Filter {...props} />;
    case 'cycle':
      return <Cycle {...props} />;
    case 'all-application':
      return <AllApplication {...props} />;
    case 'check':
      return <Check {...props} />;
    case 'pencil':
      return <Pencil {...props} />;
    case 'preview-open':
      return <PreviewOpen {...props} />;
    case 'preview-close':
      return <PreviewClose {...props} />;
    case 'upload-logs':
      return <UploadLogs {...props} />;
    case 'add-three':
      return <AddThree {...props} />;
    case 'me':
      return <Me {...props} />;
    case 'send-to-back':
      return <SendToBack {...props} />;
    case 'list-view':
      return <ListView {...props} />;
    case 'lifebuoy':
      return <Lifebuoy {...props} />;
    case 'expand-text-input':
      return <ExpandTextInput {...props} />;
    case 'success':
      return <Success {...props} />;
    case 'insert-table':
      return <InsertTable {...props} />;
    case 'chart-histogram-two':
      return <ChartHistogramTwo {...props} />;
    case 'people-plus':
      return <PeoplePlus {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'share-three':
      return <ShareThree {...props} />;
    case 'share':
      return <Share {...props} />;
    case 'terminal':
      return <Terminal {...props} />;
    case 'code-one':
      return <CodeOne {...props} />;
    default:
      return null;
  }
};

export default SbIcon;
