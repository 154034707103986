import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Typography } from 'antd';

import './index.less';

import { renderActiveDialogCount, renderDialogCount } from '../../../../utils/stringUtil';
import { ConversationModel, ConversationStatus } from '../../../../../api';
import { agentApi, botApi, conversationApi } from '../../../../apis';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { DIALOGS_COUNT_TAG_COLOR } from '../../../const';
import { AlertTypes } from '../../../../constants';
import SbButton from '../../../components/common/SbButton';
import SbSpin from '../../../components/common/SbSpin';
import SbIcon from '../../../components/common/SbIcon';
import SbTag from '../../../components/common/SbTag';

import DialogList from './DialogList';
import DialogHistory from './DialogHistory';

const DialogsCard: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(true);
  const [agentStageId, setAgentStageId] = useState<string>();
  const [selectedConversation, setSelectedConversation] = useState<ConversationModel>();
  const [totalConversationCount, setTotalConversationCount] = useState(0);
  const [activeConversationCount, setActiveConversationCount] = useState(0);

  const loadDialogsCount = async (agentStageId?: string) => {
    try {
      const totalConversationCountResponse = await conversationApi.getConversationCount(undefined, agentStageId);
      setTotalConversationCount(totalConversationCountResponse.data);

      const conversationStatus = ConversationStatus.Closed;
      const closedConversationCountResponse = await conversationApi.getConversationCount(
        undefined,
        agentStageId,
        undefined,
        undefined,
        conversationStatus
      );
      setActiveConversationCount(totalConversationCountResponse.data - closedConversationCountResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке количества диалогов',
        error: e,
      });
    }
  };

  const loadAgentStageId = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      const agentResponse = await agentApi.getAgent(botResponse.data.originStage.agentId);
      setAgentStageId(agentResponse.data.productionAgent.id);
      return agentResponse.data.productionAgent.id;
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
  };

  const loadDataAsync = async () => {
    const agentStageId = await loadAgentStageId();
    await loadDialogsCount(agentStageId);
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [id]);

  const onBackButtonClick = () => push(`/simple-bots/${id}`);

  const onDialogSelect = (conversation?: ConversationModel) => setSelectedConversation(conversation);

  return (
    <div className="sb-dialogs-card">
      <div className="sb-dialogs-card__header">
        <div className="sb-dialogs-card__header__left">
          <SbButton icon={<SbIcon iconName="left" />} sbType="icon-secondary-32" onClick={onBackButtonClick} />
          <div className="sb-dialogs-card__header__left__title">
            <Typography.Title ellipsis level={3}>
              Диалоги
              {!loading && (
                <SbTag
                  color={DIALOGS_COUNT_TAG_COLOR}
                  sbSize="small"
                  text={renderActiveDialogCount(activeConversationCount)}
                />
              )}
            </Typography.Title>
            <Typography.Title ellipsis level={4}>
              {!loading && renderDialogCount(totalConversationCount)}
            </Typography.Title>
          </div>
        </div>
      </div>
      <div className="sb-dialogs-card__content">
        {loading ? (
          <SbSpin />
        ) : (
          <>
            <div className="sb-dialogs-card__content__list-container">
              <DialogList agentStageId={agentStageId} onSelect={onDialogSelect} />
            </div>
            <div className="sb-dialogs-card__content__panel-container">
              <DialogHistory conversation={selectedConversation} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DialogsCard;
