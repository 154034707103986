import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Flags } from 'react-feature-flags';

import { FeatureFlagNames } from './constants';
import { browserHistory } from './utils/historyUtil';
import BaseLayout from './layouts/BaseLayout';
import EmptyLayout from './layouts/EmptyLayout';
import AgentList from './pages/agents/AgentList';
import AgentAdd from './pages/agents/AgentAdd';
import AgentCard from './pages/agents/AgentCard';
import AgentEdit from './pages/agents/AgentEdit';
import AgentStageEdit from './pages/agents/stages/AgentStageEdit';
import ViberChannelAdd from './pages/agents/channels/ViberChannelAdd';
import TelegramChannelAdd from './pages/agents/channels/TelegramChannelAdd';
import ELMA365ChannelAdd from './pages/agents/channels/ELMA365ChannelAdd';
import ELMA365ChannelEdit from './pages/agents/channels/ELMA365ChannelEdit';
import CampaignList from './pages/campaigns/CampaignList';
import CampaignAdd from './pages/campaigns/CampaignAdd';
import CampaignCard from './pages/campaigns/CampaignCard';
import GroupList from './pages/groups/GroupList';
import GroupAdd from './pages/groups/GroupAdd';
import GroupEdit from './pages/groups/GroupEdit';
import GroupCard from './pages/groups/GroupCard';
import PersonList from './pages/persons/PersonList';
import PersonAdd from './pages/persons/PersonAdd';
import PersonCard from './pages/persons/PersonCard';
import PersonGroups from './pages/persons/PersonGroups';
import KnowledgeBaseList from './pages/knowledge-bases/KnowledgeBaseList';
import KnowledgeBaseAdd from './pages/knowledge-bases/KnowledgeBaseAdd';
import KnowledgeBaseEdit from './pages/knowledge-bases/KnowledgeBaseEdit';
import KnowledgeBaseCard from './pages/knowledge-bases/KnowledgeBaseCard';
import VersionAdd from './pages/knowledge-bases/VersionAdd';
import VersionCard from './pages/knowledge-bases/VersionCard';
import DraftAdd from './pages/knowledge-bases/DraftAdd';
import DraftCard from './pages/knowledge-bases/DraftCard';
import ConversationCard from './pages/conversations/ConversationCard';
import SimpleBotBaseLayout from './simple-bot/layouts/SimpleBotBaseLayout';
import SimpleBotEmptyLayout from './simple-bot/layouts/SimpleBotEmptyLayout';
import SimpleBotList from './simple-bot/pages/bots/SimpleBotList';
import SimpleBotCard from './simple-bot/pages/bots/SimpleBotCard';
import SimpleBotRouter from './simple-bot/pages/bots/SimpleBotRouter';
import ScenarioCard from './simple-bot/pages/scenarios/ScenarioCard';
import SimpleKnowledgeBaseCard from './simple-bot/pages/knowledge-bases/SimpleKnowledgeBaseCard';
import { Dispatcher, dispatcherState } from './recoil/scenarioStructure';
import createDispatcher from './recoil/scenarioStructure/dispatcher';
import LoggedInCard from './simple-bot/pages/employee/auth/LoggedInCard';
import ErrorCard from './simple-bot/pages/employee/auth/ErrorCard';
import SimpleBotEmployeeLayout from './simple-bot/layouts/SimpleBotEmployeeLayout';
import SimpleBotWebChat from './simple-bot/pages/SimpleBotWebChat';
import AnalyticsCard from './simple-bot/pages/analytics/AnalyticsCard';
import DialogsCard from './simple-bot/pages/dialogs/DialogsCard';

function App(): JSX.Element {
  const setDispatcher = useSetRecoilState(dispatcherState);

  // NOTE: useRef нужен для обеспечения наличия единственного экземпляра
  const dispatcherRef = React.useRef<Dispatcher>(createDispatcher());

  React.useEffect(() => {
    setDispatcher(dispatcherRef.current);
  }, [setDispatcher]);

  return (
    <Router history={browserHistory}>
      <Switch>
        <Route
          exact
          path="/agents"
          render={() => (
            <BaseLayout>
              <AgentList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/add"
          render={() => (
            <BaseLayout>
              <AgentAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/:id/edit"
          render={() => (
            <BaseLayout>
              <AgentEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/:id/addViber"
          render={() => (
            <BaseLayout>
              <ViberChannelAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/:id/addTelegram"
          render={() => (
            <BaseLayout>
              <TelegramChannelAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/:id/addELMA365"
          render={() => (
            <BaseLayout>
              <ELMA365ChannelAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/:id/editELMA365/:accountId"
          render={() => (
            <BaseLayout>
              <ELMA365ChannelEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/:id/:stageType?"
          render={() => (
            <BaseLayout>
              <AgentCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/agents/stages/:id/edit"
          render={() => (
            <BaseLayout>
              <AgentStageEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/campaigns"
          render={() => (
            <BaseLayout>
              <CampaignList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/campaigns/add"
          render={() => (
            <BaseLayout>
              <CampaignAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/campaigns/:id"
          render={() => (
            <BaseLayout>
              <CampaignCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/persons"
          render={() => (
            <BaseLayout>
              <PersonList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/persons/add"
          render={() => (
            <BaseLayout>
              <PersonAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/persons/:id"
          render={() => (
            <BaseLayout>
              <PersonCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/persons/:id/groups"
          render={() => (
            <BaseLayout>
              <PersonGroups />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/groups"
          render={() => (
            <BaseLayout>
              <GroupList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/groups/add"
          render={() => (
            <BaseLayout>
              <GroupAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/groups/edit/:id"
          render={() => (
            <BaseLayout>
              <GroupEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/groups/:id"
          render={() => (
            <BaseLayout>
              <GroupCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases"
          render={() => (
            <BaseLayout>
              <KnowledgeBaseList />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/add"
          render={() => (
            <BaseLayout>
              <KnowledgeBaseAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id"
          render={() => (
            <BaseLayout>
              <KnowledgeBaseCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/edit"
          render={() => (
            <BaseLayout>
              <KnowledgeBaseEdit />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/versions/add"
          render={() => (
            <BaseLayout>
              <VersionAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/versions/:versionId"
          render={() => (
            <BaseLayout>
              <VersionCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/drafts/add"
          render={() => (
            <BaseLayout>
              <DraftAdd />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/knowledge-bases/:id/drafts/:draftId"
          render={() => (
            <BaseLayout>
              <DraftCard />
            </BaseLayout>
          )}
        />
        <Route
          exact
          path="/conversations/:id"
          render={() => (
            <EmptyLayout>
              <ConversationCard />
            </EmptyLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots"
          render={() => (
            <SimpleBotBaseLayout>
              <SimpleBotList />
            </SimpleBotBaseLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots/router"
          render={() => (
            <SimpleBotBaseLayout>
              <SimpleBotRouter />
            </SimpleBotBaseLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id"
          render={() => (
            <SimpleBotBaseLayout>
              <SimpleBotCard />
            </SimpleBotBaseLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/scenario/:scenarioStructureId"
          render={() => (
            <SimpleBotEmptyLayout>
              <ScenarioCard />
            </SimpleBotEmptyLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/knowledge-base"
          render={() => (
            <SimpleBotEmptyLayout>
              <SimpleKnowledgeBaseCard />
            </SimpleBotEmptyLayout>
          )}
        />
        <Route
          exact
          path="/employee/auth/logged-in"
          render={() => (
            <SimpleBotEmployeeLayout>
              <LoggedInCard />
            </SimpleBotEmployeeLayout>
          )}
        />
        <Route
          exact
          path="/employee/auth/error"
          render={() => (
            <SimpleBotEmployeeLayout>
              <ErrorCard />
            </SimpleBotEmployeeLayout>
          )}
        />
        <Route
          exact
          path="/webchat"
          render={() => (
            <EmptyLayout>
              <SimpleBotWebChat />
            </EmptyLayout>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/analytics/:tabPaneKey?"
          render={() => (
            <Flags authorizedFlags={[FeatureFlagNames.ANALYTICS]}>
              <SimpleBotEmptyLayout>
                <AnalyticsCard />
              </SimpleBotEmptyLayout>
            </Flags>
          )}
        />
        <Route
          exact
          path="/simple-bots/:id/dialogs"
          render={() => (
            <SimpleBotEmptyLayout>
              <DialogsCard />
            </SimpleBotEmptyLayout>
          )}
        />
        <Route path="*">
          <Redirect to="/simple-bots" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
