import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Menu, List, Spin, ConfigProvider } from 'antd';
import { useSetRecoilState } from 'recoil';
import { MenuItem } from 'rc-menu';
import { PaginationConfig } from 'antd/lib/pagination';
import { useDebounce, useTimeout } from 'usehooks-ts';
import './index.less';

import { ListScenarioTemplateModel, ListBotTemplateModel } from '../../../../api';
import { scenarioTemplateApi, botTemplateApi } from '../../../apis';
import { AlertTypes } from '../../../constants';
import { tagColorPalette, FROM_SCRATCH_BOT_TEMPLATE_CODE } from '../../const';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import SbButton from '../common/SbButton';
import SbScroll from '../common/SbScroll';
import SbSearch from '../common/SbSearch';
import SbScenarioTemplateCard from '../SbScenarioTemplateCard';

export const SCENARIO_ADD_MODAL_WINDOW_WIDTH = 1200;

const SEARCH_DELAY = 200; //ms
const FIRST_LOAD_DELAY = 200; //ms

const PAGE_SIZE = 1000;

const FROM_SCRATCH_BOT_TEMPLATE_NAME_OVERRIDE = 'Базовый';

interface IScenarioAddModalProps {
  originBotTemplateCode?: string | null;
  onScenarioCreate: (scenarioTemplateContent: string, scenarioName: string) => Promise<void>;
}

const ScenarioAddModal: React.FC<IScenarioAddModalProps> = ({ originBotTemplateCode, onScenarioCreate }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [isLoadingBotTemplates, setIsLoadingBotTemplates] = useState(true);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [creatingScenario, setCreatingScenario] = useState(false);
  const [botTemplates, setBotTemplates] = useState<ListBotTemplateModel[]>([]);
  const [selectedBotTemplate, setSelectedBotTemplate] = useState<ListBotTemplateModel>();
  const [foundTemplates, setFoundTemplates] = useState<ListScenarioTemplateModel[]>();
  const [searchText, setSearchText] = useState('');

  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';

  const [isSearching, setIsSearching] = useState(false);

  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    onChange: (page) => {
      setPagination({
        ...pagination,
        current: page,
      });
    },
  });

  const onTemplateClick = async (templateId: string) => {
    // NOTE: отмены состояния нет, т.к. иначе после создания сценария перед закрытием диалогового окна
    // успевает мелькнуть состояние без спиннера
    setCreatingScenario(true);
    try {
      const response = await scenarioTemplateApi.getScenarioTemplate(templateId);
      await onScenarioCreate(response.data.content, response.data.name);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке шаблона сценария',
        description: (e as Error).toString(),
      });
    }
  };

  const loadBotTemplatesAsync = async () => {
    setIsLoadingBotTemplates(true);
    try {
      const response = await botTemplateApi.getAllBotTemplates();
      const baseTemplate = response.data.find((t) => t.code === FROM_SCRATCH_BOT_TEMPLATE_CODE);
      baseTemplate && (baseTemplate.name = FROM_SCRATCH_BOT_TEMPLATE_NAME_OVERRIDE);
      setBotTemplates(response.data);
      if (response.data.length) {
        const originBotTemplate = originBotTemplateCode
          ? response.data.find((t) => t.code === originBotTemplateCode)
          : null;
        setSelectedBotTemplate(originBotTemplate ?? response.data[0]);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке шаблонов ботов',
        description: (e as Error).toString(),
      });
    }
    setIsLoadingBotTemplates(false);
  };

  const loadBotTemplates = () => {
    loadBotTemplatesAsync().finally();
  };

  // HACK: иногда данные успевают загрузиться и отрендериться быстрее, чем длится анимация
  // появления модального окна. Из-за этого у скроллбаров успевают появиться лишние полосы прокрутки.
  // Для исправления перед первой загрузкой данных ждем окончания анимации.
  useTimeout(loadBotTemplates, FIRST_LOAD_DELAY);

  const loadScenarioTemplatesAsync = async () => {
    if (!deferredSearchText && !selectedBotTemplate) {
      return;
    }
    setIsLoadingTemplates(true);
    try {
      const response = await scenarioTemplateApi.searchScenarioTemplates(
        deferredSearchText ? undefined : selectedBotTemplate?.id,
        deferredSearchText,
        (pagination.current || 1) - 1,
        pagination.pageSize
      );
      setTotal(response.data.totalItemCount || 0);
      if (response.data?.items) {
        setFoundTemplates(response.data.items);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка шаблонов сценариев',
        description: (e as Error).toString(),
      });
    }
    setIsLoadingTemplates(false);
  };

  const loadScenarioTemplates = () => {
    loadScenarioTemplatesAsync().finally();
  };

  useEffect(loadScenarioTemplates, [selectedBotTemplate, deferredSearchText, pagination]);

  useEffect(() => {
    if (searchText) {
      setIsSearching(true);
    }
  }, [searchText]);

  useEffect(() => {
    if (!isLoadingTemplates) {
      setIsSearching(false);
    }
  }, [isLoadingTemplates]);

  const onSelectedBotTemplateChange = (value: string) => {
    const newSelectedBotTemplate = botTemplates.find((b) => b.code === value);
    setSelectedBotTemplate(newSelectedBotTemplate);
    setSearchText('');
  };

  const onSearchTextChanged = (newValue: string) => {
    setSearchText(newValue);
  };

  const isLoadingAnything = isLoadingTemplates || (!!searchText && searchText !== deferredSearchText);

  const visibleTemplates = isLoadingAnything ? [] : foundTemplates;

  const createScenarioFromScratch = async () => {
    // NOTE: отмены состояния нет, т.к. иначе после создания сценария перед закрытием диалогового окна
    // успевает мелькнуть состояние без спиннера
    setCreatingScenario(true);
    try {
      const emptyScenarioResponse = await scenarioTemplateApi.getEmptyScenarioTemplate();
      await onScenarioCreate(emptyScenarioResponse.data.content, emptyScenarioResponse.data.name);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке пустого шаблона сценария',
        description: (e as Error).toString(),
      });
    }
  };

  const getTagColorFromBotTemplateName = (botTemplateName: string) => {
    const botTemplateIndex = botTemplates.findIndex((b) => b.name === botTemplateName);
    return tagColorPalette[botTemplateIndex >= 0 ? botTemplateIndex % tagColorPalette.length : 0];
  };

  const renderEmptyDataMessage = () => {
    return (
      <div className="empty-results">
        <h4>Ничего не найдено</h4>
        <p>
          Попробуйте поискать в шаблонах ботов
          <br />
          или создайте с нуля.
        </p>
      </div>
    );
  };

  const renderMainContent = () => {
    return (
      <ConfigProvider renderEmpty={renderEmptyDataMessage}>
        <List<ListScenarioTemplateModel>
          className="sb-scenario-templates-list"
          dataSource={visibleTemplates}
          grid={{ gutter: 0, xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
          loading={isLoadingAnything}
          pagination={{ ...pagination, total, hideOnSinglePage: true }}
          renderItem={(item) => {
            return (
              <Fragment>
                <List.Item key={item.code}>
                  <SbScenarioTemplateCard
                    color={getTagColorFromBotTemplateName(item.botTemplateName || '')}
                    scenarioTemplate={item}
                    onCardClick={onTemplateClick}
                  />
                </List.Item>
              </Fragment>
            );
          }}
        />
      </ConfigProvider>
    );
  };

  if (creatingScenario) {
    return (
      <div className="creating-scenario">
        <div className="creating-scenario__spin">
          <Spin />
        </div>
        <p>Создаем новый сценарий...</p>
      </div>
    );
  }

  if (isLoadingBotTemplates) {
    return <Spin className="initial-loading-spin" />;
  }

  return (
    <div className="sb-add-scenario-modal-content">
      <Row className="sb-add-scenario-modal-content__main ant-row-no-wrap">
        <Col className="sb-add-scenario-modal-content__main__column sb-modal-menu-container" flex="314px">
          <h3>Выберите шаблон сценария</h3>
          <SbScroll>
            <div className="scroll-content">
              <h4>Шаблоны ботов</h4>
              <Menu selectedKeys={selectedBotTemplate && !deferredSearchText ? [selectedBotTemplate.code] : []}>
                {botTemplates.map((botTemplate) => {
                  return (
                    <MenuItem
                      key={botTemplate.code}
                      onClick={() => {
                        onSelectedBotTemplateChange(botTemplate.code);
                      }}
                    >
                      {botTemplate.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </SbScroll>
          <div className="sb-add-scenario-modal-content__main__column__footer">
            <SbButton sbType="primary" onClick={createScenarioFromScratch}>
              Создайте сценарий с нуля
            </SbButton>
          </div>
        </Col>
        <Col className="sb-add-scenario-modal-content__main__column" flex="auto">
          <Row className="sb-search-row">
            <SbSearch
              isLoading={isSearching}
              placeholder="Поиск"
              sbSize="big"
              value={searchText}
              onChange={(e) => onSearchTextChanged(e.target.value)}
              onClear={() => {
                setSearchText('');
              }}
            />
          </Row>
          <Row className="full-height sb-scenario-templates-list-container" style={{ minHeight: 0 }}>
            <SbScroll>{renderMainContent()}</SbScroll>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ScenarioAddModal;
