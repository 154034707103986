import React, { useState } from 'react';
import { Button, Form, PageHeader, Select, Space, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { useSetRecoilState } from 'recoil';

import './styles.css';
import {
  CampaignCreationRequest,
  PersonModel,
  GroupModel,
  AgentStageAccountModel,
  CampaignCreationResponse,
} from '../../../api';
import { AlertTypes, Colors } from '../../constants';
import { campaignApi, personApi, groupApi } from '../../apis';
import { getFio } from '../../utils/stringUtil';
import BotChannelsSwitch from '../../components/BotChannelsSwitch';
import ProactiveDialogSelect, { IProactiveDialogData } from '../../components/ProactiveDialogSelect';
import { alertsSelectorAdd } from '../../recoil/alerts';

const CampaignAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [runBtnDisabled, setRunBtnDisabled] = useState(false);
  const [personsSearching, setPersonsSearching] = useState(false);
  const [groupsSearching, setGroupsSearching] = useState(false);
  const [personRecipients, setPersonRecipients] = useState([] as PersonModel[]);
  const [groupRecipients, setGroupRecipients] = useState([] as GroupModel[]);
  const [agentStageAccounts, setAgentStageAccounts] = useState([] as AgentStageAccountModel[]);
  const [proactiveDialogData, setProactiveDialogData] = useState({} as IProactiveDialogData);

  const [showRecipientsErrorMessage, setShowRecipientsErrorMessage] = useState(false);
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const history = useHistory();

  const onFinish = async () => {
    if (!form.getFieldValue('personRecipients')?.length && !form.getFieldValue('groupRecipients')?.length) {
      setShowRecipientsErrorMessage(true);
      return;
    }
    setShowRecipientsErrorMessage(false);

    const newCampaign: CampaignCreationRequest = {
      name: `Рассылка: ${proactiveDialogData?.dialogName}`,
      personRecipients: form.getFieldValue('personRecipients')?.map((r: { value: string }) => r.value),
      groupRecipients: form.getFieldValue('groupRecipients')?.map((r: { value: string }) => r.value),
      agentId: proactiveDialogData?.agentId,
      agentStageId: proactiveDialogData?.agentStageId,
      agentStageAccounts: agentStageAccounts.map((a) => {
        return {
          channelId: a.channelId,
          accountId: a.id,
          displayName: a.displayName,
          externalName: a.externalName,
        };
      }),
      proactiveDialogId: proactiveDialogData?.dialogId,
      properties: proactiveDialogData?.properties,
    };

    setRunBtnDisabled(true);
    let createdCampaign: CampaignCreationResponse | undefined = undefined;
    try {
      createdCampaign = (await campaignApi.createCampaign(newCampaign)).data;
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлена новая рассылка',
        description: newCampaign.name,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении рассылки',
        error: e,
      });
    }

    if (!createdCampaign) {
      setRunBtnDisabled(false);
      return;
    }

    try {
      await campaignApi.runCampaign(createdCampaign.id);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Запущена новая рассылка',
        description: newCampaign.name,
      });
      history.push(`/campaigns/${createdCampaign.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске рассылки',
        error: e,
      });
      setRunBtnDisabled(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/campaigns');
  };

  const onPersonRecipientsSearch = debounce(async (value) => {
    if (!value) return;
    setPersonsSearching(true);
    try {
      const response = await personApi.searchPersons(0, 50, false, 0, 50, value);
      setPersonRecipients(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при зарузке списка работников',
        error: e,
      });
    }
    setPersonsSearching(false);
  }, 300);

  const onPersonRecipientsBlur = () => {
    setPersonRecipients([]);
  };

  const onGroupRecipientsSearch = debounce(async (value) => {
    if (!value) return;
    setGroupsSearching(true);
    try {
      const response = await groupApi.searchGroups(0, 50, false, 0, 50, value);
      setGroupRecipients(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при зарузке списка групп',
        error: e,
      });
    }
    setGroupsSearching(false);
  }, 300);

  const onGroupRecipientsBlur = () => {
    setGroupRecipients([]);
  };

  const onAgentStageAccountsChange = (value: AgentStageAccountModel[]) => {
    setAgentStageAccounts(value);
    // для автоматической валидации добавляем данные на форму
    form.setFieldsValue({
      agentStageAccounts: value,
    });
  };

  const onProactiveDialogDataChange = (value: IProactiveDialogData) => {
    setProactiveDialogData(value);
    // для автоматической валидации добавляем данные на форму
    form.setFieldsValue({
      dialogData: value.dialogId,
    });
  };

  return (
    <div>
      <PageHeader title="Новая рассылка" />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <h3 className="required-field">Шаблон рассылки</h3>
        <Form.Item name="dialogData" rules={[{ required: true, message: 'Выберите шаблон рассылки' }]}>
          <ProactiveDialogSelect onProactiveDialogDataChange={onProactiveDialogDataChange} />
        </Form.Item>
        <h3 className="required-field">Получатели</h3>
        <Form.Item
          label="Сотрудники"
          name="personRecipients"
          rules={[{ required: false, message: 'Добавьте получателей', type: 'array' }]}
        >
          <Select
            labelInValue
            filterOption={false}
            mode="multiple"
            notFoundContent={personsSearching ? <Spin size="small" /> : null}
            placeholder="Добавить сотрудника"
            value={form.getFieldValue('personRecipients')}
            onBlur={onPersonRecipientsBlur}
            onSearch={onPersonRecipientsSearch}
          >
            {personRecipients.map((r: PersonModel) => (
              <Select.Option key={r.id} value={r.id}>
                {getFio(r)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Группы"
          name="groupRecipients"
          rules={[{ required: false, message: 'Добавьте получателей', type: 'array' }]}
        >
          <Select
            labelInValue
            filterOption={false}
            mode="multiple"
            notFoundContent={groupsSearching ? <Spin size="small" /> : null}
            placeholder="Добавить группу"
            value={form.getFieldValue('groupRecipients')}
            onBlur={onGroupRecipientsBlur}
            onSearch={onGroupRecipientsSearch}
          >
            {groupRecipients.map((r: GroupModel) => (
              <Select.Option key={r.id} value={r.id}>
                {r.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <h3 className="required-field">Каналы</h3>
        <Form.Item name="channels" rules={[{ required: true, message: 'Выберите каналы для рассылки' }]}>
          {proactiveDialogData?.agentId ? (
            <BotChannelsSwitch
              agentStageId={proactiveDialogData.agentStageId}
              onAccountsChange={onAgentStageAccountsChange}
            />
          ) : (
            <p>Для отображения списка каналов выберите шаблон рассылки</p>
          )}
        </Form.Item>
        {showRecipientsErrorMessage && (
          <p style={{ color: Colors.ERROR }}>Выберите хотя бы одного сотрудника или группу</p>
        )}
        <Form.Item>
          <Space>
            <Button disabled={runBtnDisabled} htmlType="submit" type="primary">
              Запустить
            </Button>
            <Button onClick={onBackBtnClick}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CampaignAdd;
