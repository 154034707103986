import { DefaultValue, selector } from 'recoil';
import { v4 } from 'uuid';
import { AxiosError } from 'axios';

import { IAlert } from '../../types';
import { isReloading } from '../../utils/oidcUtil';

import { alertsState } from './atom';

const getErrorMessage = (error: Error) => {
  let message = '';

  const axiosError = error as AxiosError;
  if (axiosError.isAxiosError && axiosError.response) {
    message =
      axiosError.response.status <= 500
        ? axiosError.response.data.detail || 'Внутренняя ошибка сервера'
        : 'Внутренняя ошибка сервера';
  }

  return message || error.message || String(error);
};

export const alertsSelector = selector<IAlert[]>({
  key: 'alertsSelector',
  get: ({ get }) => get(alertsState),
});

export const alertsSelectorAdd = selector<IAlert>({
  key: 'alertsSelectorAdd',
  get: () => ({} as IAlert), // NOTE: get не используется
  set: ({ set, get }, newAlert) => {
    if (isReloading() || newAlert instanceof DefaultValue) {
      return;
    }
    if (!newAlert.description && newAlert.error) {
      newAlert.description = getErrorMessage(newAlert.error);
    }
    set(alertsState, [...get(alertsState), { id: v4(), ...newAlert }]);
  },
});

export const alertsSelectorRemove = selector<string>({
  key: 'alertsSelectorRemove',
  get: () => '', // NOTE: get не используется
  set: ({ set, get }, id) => {
    if (id instanceof DefaultValue) {
      return;
    }
    const filteredAlerts = get(alertsState).filter((item) => item.id !== id);
    set(alertsState, filteredAlerts);
  },
});
