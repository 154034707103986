import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';

import './index.less';

import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import { botApi, dataEntryKbApi, knowledgeBaseKbApi, stageKbApi, versionKbApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { SingleBotModel } from '../../../../../api';
import SbSpin from '../../../components/common/SbSpin';
import {
  DataEntryModel,
  KnowledgeSourceModel,
  PublishStatusState,
  SingleKnowledgeBaseModel,
} from '../../../../../kb-api';
import { renderRecordCount } from '../../../../utils/stringUtil';
import SbTag from '../../../components/common/SbTag';

import RollbackButton from './RollbackButton';
import SaveButton from './SaveButton';
import DiagnosticButton from './DiagnosticButton';
import DataEntryList from './DataEntryList';
import KnowledgeSourceImportProgressModal from './KnowledgeSourceImportProgressModal';
import KnowledgeSourceImportModal from './KnowledgeSourceImportModal';
import InstancePublishModal from './InstancePublishModal';
import DataEntryEditModal from './DataEntryEditModal';
import DataEntryDeleteConfirmModal from './DataEntryDeleteConfirmModal';
import TestingPanel from './TestingPanel';
import DiagnosticModal from './DiagnosticModal';

const ENABLED_TAG_COLOR = '#E6F7FF';
const DISABLED_TAG_COLOR = '#F5F5F5';

enum CurrentModal {
  NONE = 'NONE',
  IMPORT = 'IMPORT',
  IMPORT_PROGRESS = 'IMPORT_PROGRESS',
  PUBLISH = 'PUBLISH',
  EDIT = 'EDIT',
  DELETE_CONFIRM = 'DELETE_CONFIRM',
  DIAGNOSTIC = 'DIAGNOSTIC',
}

const SimpleKnowledgeBaseCard: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [dataUpdating, setDataUpdating] = useState(false);
  const [currentModal, setCurrentModal] = useState(CurrentModal.NONE);
  const [bot, setBot] = useState<SingleBotModel>();
  const [knowledgeBase, setKnowledgeBase] = useState<SingleKnowledgeBaseModel>();

  const [selectedDataEntry, setSelectedDataEntry] = useState<DataEntryModel>();
  const [lastImportedKnowledgeSource, setLastImportedKnowledgeSource] = useState<KnowledgeSourceModel>();

  const draftInstance = knowledgeBase?.draftInstance;
  const recordCount = knowledgeBase?.draftDataEntryCount || knowledgeBase?.originDataEntryCount || 0;
  const enabled = knowledgeBase?.originStage.recognizerSettings?.enabled ?? true;
  const diagnosticInstanceId = draftInstance?.id || knowledgeBase?.originInstance.id;

  const loadKnowledgeBaseData = async (bot?: SingleBotModel) => {
    if (!bot?.entry.knowledgeBaseId) return;

    try {
      const kbResponse = await knowledgeBaseKbApi.getKnowledgeBase(bot.entry.knowledgeBaseId);
      const knowledgeBase = kbResponse.data;
      setKnowledgeBase(knowledgeBase);

      const draftInstance = knowledgeBase.draftInstance;

      if (
        currentModal === CurrentModal.NONE &&
        (draftInstance?.status.state === PublishStatusState.Pending ||
          draftInstance?.status.state === PublishStatusState.Processing)
      ) {
        setCurrentModal(CurrentModal.PUBLISH);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных базы знаний',
        error: e,
      });
    }
  };

  const loadBotDataAsync = async (): Promise<SingleBotModel | undefined> => {
    try {
      const botResponse = await botApi.getBot(id);
      setBot(botResponse.data);
      return botResponse.data;
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке бота',
        error: e,
      });
    }
  };

  const loadDataAsync = async () => {
    setLoading(true);
    const bot = await loadBotDataAsync();
    await loadKnowledgeBaseData(bot);
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [id]);

  const reloadKnowledgeBaseData = async () => {
    await loadKnowledgeBaseData(bot);
  };

  const getOrCreateDraftStage = async () => {
    if (!knowledgeBase) return;

    setDataUpdating(true);

    if (knowledgeBase.draftStage) {
      return knowledgeBase.draftStage;
    }

    try {
      const response = await stageKbApi.createDraftStage(knowledgeBase.originStage.id);
      await reloadKnowledgeBaseData();
      return response.data;
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании черновой стадии базы знаний',
        error: e,
      });
    }
  };

  const getDataEntryIdByVersion = async (versionId: string, dataEntryId: string) => {
    if (knowledgeBase?.draftStage?.versionId === versionId) {
      return dataEntryId;
    }

    const versionResponse = await versionKbApi.getVersion(versionId);
    const draftVersion = versionResponse.data;

    const dataEntriesResponse = await dataEntryKbApi.searchDataEntries(
      undefined,
      draftVersion.editionId,
      undefined,
      undefined,
      undefined,
      undefined,
      dataEntryId
    );
    return (dataEntriesResponse.data.items || []).pop()?.entry.id;
  };

  const cancelModals = () => {
    setDataUpdating(false);
    setCurrentModal(CurrentModal.NONE);
    setLastImportedKnowledgeSource(undefined);
    setSelectedDataEntry(undefined);
  };

  const onBackButtonClick = () => push(`/simple-bots/${id}`);

  const onModalCancel = () => cancelModals();

  const onDataChanged = () => {
    cancelModals();
    reloadKnowledgeBaseData().finally();
  };

  const onImportKnowledgeSource = () => {
    if (dataUpdating) return;
    setCurrentModal(CurrentModal.IMPORT);
  };

  const onKnowledgeSourceUploaded = (knowledgeSource: KnowledgeSourceModel) => {
    setCurrentModal(CurrentModal.IMPORT_PROGRESS);
    setLastImportedKnowledgeSource(knowledgeSource);
    reloadKnowledgeBaseData().finally();
  };

  const onAddRecord = () => {
    if (dataUpdating) return;
    setCurrentModal(CurrentModal.EDIT);
  };

  const onEditRecord = (dataEntry: DataEntryModel) => {
    if (dataUpdating) return;
    setSelectedDataEntry(dataEntry);
    setCurrentModal(CurrentModal.EDIT);
  };

  const onDeleteRecord = (dataEntry: DataEntryModel) => {
    if (dataUpdating) return;
    setSelectedDataEntry(dataEntry);
    setCurrentModal(CurrentModal.DELETE_CONFIRM);
  };

  const onKnowledgeBaseSave = async () => {
    if (!knowledgeBase?.draftStage?.id) return;

    try {
      await stageKbApi.mergeStage(knowledgeBase.originStage.id, {
        sourceStageId: knowledgeBase.draftStage.id,
      });
      await reloadKnowledgeBaseData();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении базы знаний',
        error: e,
      });
    }
  };

  const onKnowledgeBaseRollback = async () => {
    if (!knowledgeBase?.draftStage?.id) return;

    try {
      await stageKbApi.deleteStage(knowledgeBase.draftStage.id);
      await reloadKnowledgeBaseData();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при отмене изменений в базе знаний',
        error: e,
      });
    }
  };

  const onRunDiagnostic = () => setCurrentModal(CurrentModal.DIAGNOSTIC);

  return (
    <div className="sb-knowledge-base-card">
      <div className="sb-knowledge-base-card__header">
        <div className="sb-knowledge-base-card__header__left">
          <SbButton
            icon={<SbIcon iconName="left" />}
            sbType="icon-secondary-32"
            style={{ marginRight: 10 }}
            onClick={onBackButtonClick}
          />
          <div className="sb-knowledge-base-card__header__left__title">
            <Typography.Title ellipsis level={3}>
              База знаний
              {enabled ? (
                <SbTag color={ENABLED_TAG_COLOR} sbSize="small" text="Включена" />
              ) : (
                <SbTag color={DISABLED_TAG_COLOR} sbSize="small" text="Отключена" />
              )}
            </Typography.Title>
            <Typography.Title ellipsis level={4}>
              {renderRecordCount(recordCount)}
            </Typography.Title>
          </div>
        </div>
        <div className="sb-knowledge-base-card__header__right">
          <div className="sb-knowledge-base-card__header__right__secondary-buttons">
            <DiagnosticButton dataUpdating={dataUpdating} onRunDiagnostic={onRunDiagnostic} />
            <RollbackButton
              dataUpdating={dataUpdating}
              knowledgeBase={knowledgeBase}
              onRollback={onKnowledgeBaseRollback}
            />
          </div>
          <SaveButton
            dataUpdating={dataUpdating}
            knowledgeBase={knowledgeBase}
            onRollback={onKnowledgeBaseRollback}
            onSave={onKnowledgeBaseSave}
          />
        </div>
      </div>
      <div className="sb-knowledge-base-card__content">
        {loading ? (
          <SbSpin />
        ) : (
          <>
            <Row className="sb-knowledge-base-card__content__row" wrap={false}>
              <Col className="sb-knowledge-base-card__content__row__entries" flex="auto">
                <DataEntryList
                  dataUpdating={dataUpdating}
                  getDataEntryIdByVersion={getDataEntryIdByVersion}
                  getDraftStage={getOrCreateDraftStage}
                  knowledgeBase={knowledgeBase}
                  onAddRecord={onAddRecord}
                  onDataChanged={onDataChanged}
                  onDeleteRecord={onDeleteRecord}
                  onEditRecord={onEditRecord}
                  onImportKnowledgeSource={onImportKnowledgeSource}
                />
              </Col>
              <Col className="sb-knowledge-base-card__content__row__testing-panel-container">
                <TestingPanel
                  dataUpdating={dataUpdating}
                  getDataEntryIdByVersion={getDataEntryIdByVersion}
                  getDraftStage={getOrCreateDraftStage}
                  knowledgeBase={knowledgeBase}
                  onAddRecord={onAddRecord}
                  onDataChanged={onDataChanged}
                  onEditRecord={onEditRecord}
                />
              </Col>
            </Row>
            {knowledgeBase && (
              <KnowledgeSourceImportModal
                getDraftStage={getOrCreateDraftStage}
                knowledgeBase={knowledgeBase}
                visible={currentModal === CurrentModal.IMPORT}
                onCancel={onModalCancel}
                onKnowledgeSourceUploaded={onKnowledgeSourceUploaded}
              />
            )}
            {lastImportedKnowledgeSource && draftInstance && (
              <KnowledgeSourceImportProgressModal
                instanceId={draftInstance.id}
                knowledgeSource={lastImportedKnowledgeSource}
                visible={currentModal === CurrentModal.IMPORT_PROGRESS}
                onDataChanged={onDataChanged}
              />
            )}
            {knowledgeBase && (
              <DataEntryEditModal
                dataEntry={selectedDataEntry}
                getDataEntryIdByVersion={getDataEntryIdByVersion}
                getDraftStage={getOrCreateDraftStage}
                knowledgeBase={knowledgeBase}
                visible={currentModal === CurrentModal.EDIT}
                onCancel={onModalCancel}
                onDataChanged={onDataChanged}
              />
            )}
            {knowledgeBase && (
              <DataEntryDeleteConfirmModal
                dataEntry={selectedDataEntry}
                getDataEntryIdByVersion={getDataEntryIdByVersion}
                getDraftStage={getOrCreateDraftStage}
                knowledgeBase={knowledgeBase}
                visible={currentModal === CurrentModal.DELETE_CONFIRM}
                onCancel={onModalCancel}
                onDataChanged={onDataChanged}
              />
            )}
            {diagnosticInstanceId && (
              <DiagnosticModal
                instanceId={diagnosticInstanceId}
                visible={currentModal === CurrentModal.DIAGNOSTIC}
                onCancel={onModalCancel}
                onDataChanged={onDataChanged}
              />
            )}
            {draftInstance && (
              <InstancePublishModal
                instanceId={draftInstance.id}
                visible={currentModal === CurrentModal.PUBLISH}
                onCancel={onModalCancel}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SimpleKnowledgeBaseCard;
