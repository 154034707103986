import numberToWordsRu from 'number-to-words-ru';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

import { PersonCreationRequest, PersonModel } from '../../api';
import { Channels, StageTypes } from '../constants';

const cyrillicToTranslit = new CyrillicToTranslit();

export const equalsIgnoreCase = (x: string, y: string): boolean => x.toLowerCase() === y.toLowerCase();

export const includesIgnoreCase = (value: string | null | undefined, query: string): boolean =>
  (value || '').toLowerCase().includes(query.toLowerCase());

export const indexOfIgnoreCase = (value: string | null | undefined, query: string, position?: number): number =>
  (value || '').toLowerCase().indexOf(query.toLowerCase(), position);

export const translitVariableName = (name?: string): string => {
  const translatedName = cyrillicToTranslit.transform(name || '', '_');
  return translatedName.replace(/[^0-9a-zA-Z_$]/g, '_').toLowerCase();
};

export const translitIntentName = (name?: string): string => {
  const translatedName = cyrillicToTranslit.transform(name || '', '_');
  return translatedName.replace(/[^0-9a-zA-Z_]/g, '_').toLowerCase();
};

export const round = (number: number | undefined, digits: number): number => parseFloat(number?.toFixed(digits) || '0');

export const getFio = (person?: PersonModel | PersonCreationRequest): string =>
  person ? `${person.lastName} ${person.firstName} ${person.middleName}` : '';

// todo: временная функция для названий каналов
export const getChannelName = (
  channelId?: string | null,
  externalName?: string | null,
  displayName?: string | null
): string => {
  const name = displayName || externalName;
  switch (channelId) {
    case Channels.VIBER:
      return 'Viber' + (name ? ` (${name})` : '');
    case Channels.TELEGRAM:
      return 'Telegram' + (name ? ` (${name})` : '');
    case Channels.ELMA365:
      return 'ELMA365' + (displayName ? ` (${displayName})` : '');
    case Channels.DIRECTLINE:
      return 'Веб-чат';
    case Channels.EMULATOR:
      return 'Эмулятор';
    default:
      return 'Неизв. канал';
  }
};

export const getStageNameByType = (stageType?: string | null): string => {
  switch (stageType) {
    case StageTypes.STAGING:
      return 'Тест';
    case StageTypes.PRODUCTION:
      return 'Релиз';
    default:
      return 'Неизв. тип';
  }
};

export const renderActiveDialogCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['активный', 'активных', 'активных'],
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderDialogCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['диалог', 'дилога', 'диалогов'],
      currencyNameDeclensions: {
        nominative: ['диалог', 'дилоги'],
        genitive: ['диалога', 'диалогов'],
        dative: ['диалогу', 'диалогам'],
        accusative: ['диалог', 'дилоги'],
        instrumental: ['диалогом', 'диалогами'],
        prepositional: ['диалоге', 'диалогах'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderUserCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['пользователь', 'пользователя', 'пользователей'],
      currencyNameDeclensions: {
        nominative: ['пользователь', 'пользователи'],
        genitive: ['пользователя', 'пользователей'],
        dative: ['пользователю', 'пользователям'],
        accusative: ['пользователя', 'пользователей'],
        instrumental: ['пользователем', 'пользователями'],
        prepositional: ['пользователе', 'пользователях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderMessageCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['сообщение', 'сообщения', 'сообщений'],
      currencyNameDeclensions: {
        nominative: ['сообщение', 'сообщения'],
        genitive: ['сообщения', 'сообщений'],
        dative: ['сообщению', 'сообщениям'],
        accusative: ['сообщение', 'сообщения'],
        instrumental: ['сообщением', 'сообщениями'],
        prepositional: ['сообщении', 'сообщениях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderRecordCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['запись', 'записи', 'записей'],
      currencyNameDeclensions: {
        nominative: ['запись', 'записи'],
        genitive: ['записи', 'записей'],
        dative: ['записи', 'записям'],
        accusative: ['запись', 'записи'],
        instrumental: ['записью', 'записями'],
        prepositional: ['записи', 'записях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderReportRowCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['совпадение', 'совпадения', 'совпадений'],
      currencyNameDeclensions: {
        nominative: ['совпадение', 'совпадения'],
        genitive: ['совпадения', 'совпадений'],
        dative: ['совпадению', 'совпадениям'],
        accusative: ['совпадение', 'совпадения'],
        instrumental: ['совпадением', 'совпадениями'],
        prepositional: ['совпадении', 'совпадениях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const getIgnoreCaseStringComparer = <T>(mapper: (v: T) => string) => (a: T, b: T): number => {
  const mappedA = mapper(a);
  const mappedB = mapper(b);

  if (mappedA.toLowerCase() < mappedB.toLowerCase()) return -1;
  if (mappedA.toLowerCase() > mappedB.toLowerCase()) return 1;
  return 0;
};

export const onlyUnique = (s: string, i: number, a: string[]): boolean => a.indexOf(s) === i;
