import { RcFile } from 'antd/es/upload';
import downloadFile from 'js-file-download';
import { AxiosResponse } from 'axios';

import { BinarySourceFormat } from '../../kb-api';
import { FileExtensions } from '../constants';

export const getBase64Content = (file: RcFile): Promise<string> =>
  new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        const base64Content = Buffer.from(e.target.result).toString('base64');
        res(base64Content);
      } else {
        rej('Невозможно загрузить файл.');
      }
    };
    reader.readAsArrayBuffer(file);
  });

export const base64ContentToFormFile = (file: RcFile, base64Content: string): File =>
  new File([new Blob([Buffer.from(base64Content, 'base64')])], file.name, {
    type: file.type || 'application/octet-stream',
  });

export const getFileExtension = (fileName: string): string => `.${fileName.split('.').pop()}`;

export const binarySourceFormatToFileExtension = (format?: BinarySourceFormat): FileExtensions | undefined => {
  switch (format) {
    case BinarySourceFormat.Csv:
      return FileExtensions.CSV;
    case BinarySourceFormat.Xls:
      return FileExtensions.XLS;
    case BinarySourceFormat.Xlsx:
      return FileExtensions.XLSX;
    case BinarySourceFormat.Doc:
      return FileExtensions.DOC;
    case BinarySourceFormat.Docx:
      return FileExtensions.DOCX;
    case BinarySourceFormat.Pdf:
      return FileExtensions.PDF;
    default:
      return undefined;
  }
};

export const fileExtensionToBinarySourceFormat = (fileExtension?: FileExtensions): BinarySourceFormat | undefined => {
  switch (fileExtension) {
    case FileExtensions.CSV:
      return BinarySourceFormat.Csv;
    case FileExtensions.XLS:
      return BinarySourceFormat.Xls;
    case FileExtensions.XLSX:
      return BinarySourceFormat.Xlsx;
    case FileExtensions.DOC:
      return BinarySourceFormat.Doc;
    case FileExtensions.DOCX:
      return BinarySourceFormat.Docx;
    case FileExtensions.PDF:
      return BinarySourceFormat.Pdf;
    default:
      return undefined;
  }
};

export const downloadNamedFile = (response: AxiosResponse): void => {
  const contentDisposition = response.headers['content-disposition'];

  const fileNameUnicodeMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
  if (fileNameUnicodeMatch && fileNameUnicodeMatch.length) {
    downloadFile(response.data, decodeURIComponent(fileNameUnicodeMatch[1]));
    return;
  }

  const fileNameMatch = contentDisposition.match(/filename=(.+?);/);
  if (fileNameMatch && fileNameMatch.length) {
    downloadFile(response.data, fileNameMatch[1]);
    return;
  }

  downloadFile(response.data, 'unknown.data');
};
