import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import './index.less';

import { Elma365BpmTemplate, Elma365IntegrationSettingsSchema } from '../../../../../../api';
import SbSpin from '../../../../../simple-bot/components/common/SbSpin';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import { instanceOfElma365IntegrationSettingsSchema } from '../../../utils';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { botSettingsSelector } from '../../../../../recoil/scenarioStructure';
import { elma365BpmTemplateApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';

interface IElma365BpmTemplateSelectorProps {
  bpmTemplateCode?: string | null;
  bpmTemplateNamespace?: string | null;
  onChange: (bpmTemplate?: Elma365BpmTemplate) => void;
  onSelectedBpmTemplateUpdated: (bpmTemplate?: Elma365BpmTemplate) => void;
}

const filterBpmTemplateList = (bpmTemplateList: Elma365BpmTemplate[]) =>
  bpmTemplateList.filter((t) => !t.draft && !t.readonly);

const Elma365BpmTemplateSelector: React.FC<IElma365BpmTemplateSelectorProps> = ({
  bpmTemplateCode,
  bpmTemplateNamespace,
  onChange,
  onSelectedBpmTemplateUpdated,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const botSettings = useRecoilValue(botSettingsSelector);

  const [bpmTemplateListLoading, setBpmTemplateListLoading] = useState(false);
  const [bpmTemplateListAll, setBpmTemplateListAll] = useState<Elma365BpmTemplate[]>([]);
  const [selectedBpmTemplate, setSelectedBpmTemplate] = useState<Elma365BpmTemplate>();

  const bpmTemplateList = filterBpmTemplateList(bpmTemplateListAll);

  const integrationSettings = botSettings?.integrations.find((i) =>
    instanceOfElma365IntegrationSettingsSchema(i)
  ) as Elma365IntegrationSettingsSchema;
  const integrationSettingsNotSet = !integrationSettings?.apiUrl || !integrationSettings?.xToken;

  const tryLoadBpmTemplateList = async (baseUrl?: string, xToken?: string) => {
    if (bpmTemplateListAll.length) return;

    if (!baseUrl || !xToken) {
      setBpmTemplateListAll([]);
      return;
    }

    setBpmTemplateListLoading(true);
    try {
      const response = await elma365BpmTemplateApi.getElma365BpmTemplateList(baseUrl, xToken);
      setBpmTemplateListAll(response.data);

      const selectedBpmTemplate = response.data.find(
        (t) => t.namespace === bpmTemplateNamespace && t.code === bpmTemplateCode
      );

      setSelectedBpmTemplate(selectedBpmTemplate);
      onSelectedBpmTemplateUpdated(selectedBpmTemplate);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка бизнес-процессов',
        error: e,
      });
    }
    setBpmTemplateListLoading(false);
  };

  const onInit = () => {
    tryLoadBpmTemplateList(integrationSettings?.apiUrl, integrationSettings?.xToken).finally();
  };
  useEffect(onInit, [integrationSettings]);

  const updateSelectedBpmTemplate = () => {
    if (!bpmTemplateList.length) return;

    const selectedBpmTemplate = bpmTemplateList.find(
      (t) => t.namespace === bpmTemplateNamespace && t.code === bpmTemplateCode
    );

    setSelectedBpmTemplate(selectedBpmTemplate);
    onSelectedBpmTemplateUpdated(selectedBpmTemplate);
  };

  useEffect(updateSelectedBpmTemplate, [bpmTemplateNamespace, bpmTemplateCode]);

  const onBpmTemplateIdChange = (value: SelectValue) => {
    const id = value as string;
    onChange(bpmTemplateList.find((t) => t.__id === id));
  };

  const classes = ['sb-elma365-bpm-template-selector'];
  bpmTemplateListLoading && classes.push('sb-elma365-bpm-template-selector_loading');

  if (integrationSettingsNotSet) {
    return (
      <SbPanel sbType="help" scrollable={false}>
        Настройте параметры интеграции ELMA365 на странице бота.
      </SbPanel>
    );
  }

  return (
    <div className={classes.join(' ')}>
      <h4>Бизнес-процесс:</h4>
      <SbSelect
        placeholder="Выберите бизнес-процесс"
        sbSize="big"
        sbType="light"
        value={selectedBpmTemplate?.__id as string | undefined}
        onChange={onBpmTemplateIdChange}
      >
        {bpmTemplateList.map((t) => (
          <Select.Option key={t.__id} value={t.__id}>
            {t.__name}
          </Select.Option>
        ))}
      </SbSelect>
      <SbSpin />
    </div>
  );
};

export default Elma365BpmTemplateSelector;
