import React from 'react';
import { Spin } from 'antd';
import ReactWebChat from 'botframework-webchat';

import { simpleBotWebChatStyleOptions } from '../../const';

interface ISbWebChatContentProps {
  initializing: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webChatProps: any;
}

const SbWebChatContent: React.FC<ISbWebChatContentProps> = ({ initializing, webChatProps }) => (
  <div className="sb-webchat__content">
    {initializing ? (
      <Spin className="sb-webchat__content__spinner" />
    ) : (
      <ReactWebChat
        {...{
          styleOptions: simpleBotWebChatStyleOptions,
          ...webChatProps,
        }}
      />
    )}
  </div>
);

export default SbWebChatContent;
