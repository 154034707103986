import React from 'react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { Avatar, Button, Typography } from 'antd';

import SbTooltip from '../../../../components/common/SbTooltip';
import SbButton from '../../../../components/common/SbButton';
import SbPanel from '../../../../components/common/SbPanel';
import SbIcon from '../../../../components/common/SbIcon';
import { DirectLineIcon, Elma365Icon, Logo, TelegramIcon, ViberIcon } from '../../../../../assets';
import { Channels } from '../../../../../constants';
import { renderMessageCount } from '../../../../../utils/stringUtil';
import { ConversationModel, ConversationStatus } from '../../../../../../api';
import { ChannelNames, Elma365ChannelIdentifiers } from '../../../../utils/dialogs';
import { conversationExportApi } from '../../../../../apis';
import { downloadNamedFile } from '../../../../../utils/fileUtil';

const MAIN_CLASS_NAME = 'sb-dialogs-block__content';
const SESSION_DATE_FORMAT = 'DD.MM.YYYY в HH:mm';
const DATE_FORMAT = 'DD MMMM YYYY в HH:mm';
const DIALOG_INFO_ICON = {
  size: 40,
  innerSize: 20,
};
const CHANNEL_ICON_SIZE = 28;
const CHANNEL_ICON_PROPERTIES = {
  height: CHANNEL_ICON_SIZE,
  width: CHANNEL_ICON_SIZE,
  style: { marginTop: 6 },
};

interface IDialogInfoProps {
  conversation: ConversationModel;
}

const DialogInfo: React.FC<IDialogInfoProps> = ({ conversation }) => {
  const onConversationExportDownloadButtonClick = async () => {
    const response = await conversationExportApi.conversationExport(conversation.id, { responseType: 'blob' });
    downloadNamedFile(response);
  };

  const onChatDeskOpenButtonClick = () => {
    const serviceUrl = new URL(conversation.serviceUrl);
    const sessionId = conversation.properties.channelData.elma365.session.id;
    const chatDeskUrl = `${serviceUrl.origin}/_lines/_sessions/detail/${sessionId}`;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(chatDeskUrl, '_blank');
  };

  const getChannelIcon = () => {
    switch (conversation.channelId) {
      case Channels.TELEGRAM:
        return <TelegramIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.DIRECTLINE:
        return <DirectLineIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.VIBER:
        return <ViberIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.ELMA365:
        return <Elma365Icon {...CHANNEL_ICON_PROPERTIES} />;
      default:
        return <Logo {...CHANNEL_ICON_PROPERTIES} />;
    }
  };

  const onCopySessionId = () => {
    copy(conversation.properties.channelData.elma365.session.id);
  };

  return (
    <>
      <Typography.Title ellipsis level={4}>
        Подробнее о беседе
      </Typography.Title>
      <div className={MAIN_CLASS_NAME}>
        {conversation.status === ConversationStatus.Closed && (
          <SbPanel>Беседа закрыта {moment(conversation.finishedOn).format(SESSION_DATE_FORMAT)}</SbPanel>
        )}
        <div className={`${MAIN_CLASS_NAME}__info`}>
          <Avatar icon={getChannelIcon()} size={DIALOG_INFO_ICON.size} />
          <div>
            <div className={`${MAIN_CLASS_NAME}__info__channel-name`}>
              <div className={`${MAIN_CLASS_NAME}__info__channel-name__title`}>
                {ChannelNames.find((c) => c.value === conversation.channelId)?.label}
              </div>
              {conversation.channelId === Channels.ELMA365 &&
                conversation.properties?.channelData?.originalChannelId &&
                conversation.properties?.channelData?.elma365?.session?.id && (
                  <>
                    <div className={`${MAIN_CLASS_NAME}__info__channel-name__title`}>
                      ({Elma365ChannelIdentifiers[conversation.properties.channelData.originalChannelId]})
                    </div>
                    <SbTooltip
                      title={
                        <>
                          <span>{conversation.properties.channelData.elma365.session.id}</span>
                          <Button size="small" type="link" onClick={onCopySessionId}>
                            <SbIcon iconName="link-one" size={14} />
                          </Button>
                        </>
                      }
                    >
                      <SbIcon iconName="info" size={14} />
                    </SbTooltip>
                  </>
                )}
            </div>
            <div className={`${MAIN_CLASS_NAME}__info__title-optional`}>
              Создан {moment(conversation.startedOn).format(DATE_FORMAT)}
            </div>
          </div>
        </div>
        <div className={`${MAIN_CLASS_NAME}__info`}>
          <Avatar icon={<SbIcon iconName="comment" size={DIALOG_INFO_ICON.innerSize} />} size={DIALOG_INFO_ICON.size} />
          <div>
            <div className={`${MAIN_CLASS_NAME}__info__title-main`}>
              {renderMessageCount(conversation.messagesCount ?? 0)}
            </div>
            {conversation.messagesCount != 0 && (
              <div className={`${MAIN_CLASS_NAME}__info__title-optional`}>
                Последнее {moment(conversation.latestMessageOn).format(DATE_FORMAT)}
              </div>
            )}
          </div>
        </div>
        <div className={`${MAIN_CLASS_NAME}__info`}>
          <Avatar icon={<SbIcon iconName="user" size={DIALOG_INFO_ICON.innerSize} />} size={DIALOG_INFO_ICON.size} />
          <div>
            <div className={`${MAIN_CLASS_NAME}__info__title-main`}>{conversation.userName}</div>
          </div>
        </div>
        <div className={`${MAIN_CLASS_NAME}__buttons`}>
          {conversation.channelId === Channels.ELMA365 && (
            <SbButton sbSize="big" sbType="secondary" onClick={onChatDeskOpenButtonClick}>
              Открыть сессию в ELMA365
            </SbButton>
          )}
          <SbButton sbSize="big" sbType="secondary" onClick={onConversationExportDownloadButtonClick}>
            Скачать транскрипт
          </SbButton>
        </div>
      </div>
    </>
  );
};

export default DialogInfo;
