import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { createDirectLine } from 'botframework-webchat';

import './index.less';

import SbButton from '../common/SbButton';
import SbIcon from '../common/SbIcon';
import { webChatMarkdownRenderer, WEB_CHAT_POLLING_INTERVAL, WebChatMode } from '../../../constants';
import { getProfile } from '../../../utils/oidcUtil';

import SbWebChatContent from './SbWebChatContent';

const webChatRenderDelay = 200; // мс
const botUriParamName = 'botUri';

interface ISbWebChatProps {
  title: string;
  subTitle?: string;
  webchatUrl: string;
  onRestart?: () => void | Promise<void>;
  onClose?: () => void;
  mode?: WebChatMode;
}

const SbWebChat: React.FC<ISbWebChatProps> = ({
  title,
  subTitle,
  webchatUrl,
  onRestart = () => {},
  onClose = () => {},
  mode,
}) => {
  const urlParams = new URL(webchatUrl);
  const agentStageId = urlParams.searchParams.get(botUriParamName);

  const [initializing, setInitializing] = useState(true);
  const [webChatProps, setWebChatProps] = useState({});
  const [webChatMode, setWebChatMode] = useState(mode || WebChatMode.Default);

  const initializeAsync = async () => {
    setInitializing(true);

    // NOTE: для красивого рендеринга
    await (() => new Promise((res) => setTimeout(res, webChatRenderDelay)))();

    const profile = getProfile();
    let domain = `/api/v1/directline/${agentStageId}`;
    if (webChatMode !== WebChatMode.Fullscreen) {
      domain = `${domain}/testing`;
    }

    setWebChatProps({
      directLine: createDirectLine({
        domain,
        token: profile.accessToken,
        webSocket: false,
        pollingInterval: WEB_CHAT_POLLING_INTERVAL,
      }),
      username: 'web-chat-user',
      renderMarkdown: webChatMarkdownRenderer,
    });

    setInitializing(false);
  };
  const initialize = () => {
    initializeAsync();
  };
  useEffect(initialize, [webchatUrl]);

  const onRestartButtonClick = async () => {
    await onRestart();
    await initializeAsync();
  };

  const onExpandButtonClick = () => {
    setWebChatMode(WebChatMode.Expanded);
  };

  const onFoldButtonClick = () => {
    setWebChatMode(WebChatMode.Default);
  };

  const classes = [];

  switch (webChatMode) {
    case WebChatMode.Fullscreen:
      classes.push('sb-webchat_fullscreen');
      break;
    case WebChatMode.Expanded:
      classes.push('sb-webchat_expanded');
      break;
    case WebChatMode.Default:
    default:
      break;
  }

  return (
    <div className={`sb-webchat ${classes.join(' ')}`}>
      <Row align="middle" className="sb-webchat__header" wrap={false}>
        <Col className="sb-webchat__header__title" flex="auto">
          <h1>{title}</h1>
          {!!subTitle && <p>{subTitle}</p>}
        </Col>
        <Col>
          <SbButton
            icon={<SbIcon iconName="redo" size={20} />}
            loading={initializing}
            sbType="link"
            onClick={onRestartButtonClick}
          />
        </Col>
        <Col>
          {webChatMode === WebChatMode.Expanded ? (
            <SbButton
              className="sb-webchat__header__fold-button"
              icon={<SbIcon iconName="click-to-fold" size={20} />}
              sbType="link"
              onClick={onFoldButtonClick}
            />
          ) : (
            <SbButton
              className="sb-webchat__header__expand-button"
              icon={<SbIcon iconName="expand-text-input" size={20} />}
              sbType="link"
              onClick={onExpandButtonClick}
            />
          )}
        </Col>
        <Col>
          <SbButton
            className="sb-webchat__header__close-button"
            icon={<SbIcon iconName="close" size={20} />}
            sbType="link"
            onClick={onClose}
          />
        </Col>
      </Row>
      <SbWebChatContent initializing={initializing} webChatProps={webChatProps} />
    </div>
  );
};

export default SbWebChat;
