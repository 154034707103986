import React from 'react';
import moment from 'moment/moment';
import ReactMarkdown from 'react-markdown';
import sanitizeHtml from 'sanitize-html';

import { ActivityDirection, ActivityModel } from '../../../../../../api';

import Attachments from './Attachments';
import SuggestedActions from './SuggestedActions';

const MAIN_CLASS_NAME = 'sb-dialogs-card__content__panel-container__messages';
const USER_SELECTOR_NAME = 'user';
const BOT_SELECTOR_NAME = 'bot';

interface IMessageBubbleProps {
  activity: ActivityModel;
  direction: ActivityDirection | undefined;
  isSelected: boolean;
  onSelect: (activity: ActivityModel) => void;
  getMenuItemSelected: (activity: ActivityModel) => ActivityModel | undefined;
}

const MessageBubble: React.FC<IMessageBubbleProps> = ({
  activity,
  direction,
  isSelected,
  onSelect,
  getMenuItemSelected,
}) => {
  const role = direction == ActivityDirection.NUMBER_0 ? USER_SELECTOR_NAME : BOT_SELECTOR_NAME;
  const messageContainerClass = `${MAIN_CLASS_NAME}__${role}-message-container`;

  const onMessageClick = () => onSelect(activity);

  const menuItemSelected = getMenuItemSelected(activity);

  const cleanActivityText = sanitizeHtml(activity.text || '(пустое сообщение)', { allowedTags: ['u'] });

  return (
    <>
      <div className={messageContainerClass}>
        <div
          className={`${messageContainerClass}__message ${
            isSelected ? `${messageContainerClass}__message_selected` : ''
          }`}
          onClick={onMessageClick}
        >
          <div className={`${messageContainerClass}__message__text`}>
            <ReactMarkdown allowDangerousHtml>{cleanActivityText}</ReactMarkdown>
          </div>
          <span className={`${messageContainerClass}__message__time`}>{moment(activity.date).format('LT')}</span>
        </div>
      </div>
      {!!activity.attachments?.length && <Attachments attachments={activity.attachments} role={role} />}
      {!!activity.suggestedActions?.length && (
        <SuggestedActions items={activity.suggestedActions} selectedItem={menuItemSelected?.text} />
      )}
    </>
  );
};

export default MessageBubble;
