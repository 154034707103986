export * from './notifications';
export * from './parameterNames';
export * from './specialCases';
export * from './webChat';

export const BASEPATH = window.location.origin;

export const DEFAULT_PAGE_SIZE = 10;

export enum ActionTypes {
  SET_DATA_LOADING = 'SET_DATA_LOADING',
  SET_ALERT = 'SET_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

export enum AlertTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum Colors {
  SUCCESS = '#52c41a',
  INFO = '#1890ff',
  WARNING = '#faad14',
  ERROR = '#ff4d4f',
}

export enum Channels {
  VIBER = 'viber',
  TELEGRAM = 'telegram',
  ELMA365 = 'elma365',
  DIRECTLINE = 'directline',
  EMULATOR = 'emulator',
}

export enum StageTypes {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum FileExtensions {
  CSV = '.csv',
  XLS = '.xls',
  XLSX = '.xlsx',
  DOC = '.doc',
  DOCX = '.docx',
  PDF = '.pdf',
  JSON = '.json',
  ZIP = '.zip',
}

export const ALLOWED_KNOWLEDGEBASE_FILE_TYPES = [
  FileExtensions.CSV,
  FileExtensions.XLS,
  FileExtensions.XLSX,
  FileExtensions.DOCX,
];

export const ALLOWED_IMPORT_BOT_FILE_TYPES = [FileExtensions.JSON, FileExtensions.ZIP];

export const ALLOWED_IMPORT_SCENARIO_FILE_TYPES = [FileExtensions.JSON];

export enum FeatureFlagNames {
  HIDDEN = 'hidden',
  ANALYTICS = 'Analytics',
  CONVERSATION_CLOSING = 'ConversationClosing',
  LOW_CONVERSATION_CLOSING_TIMEOUT_MIN_VALUE = 'LowConversationClosingTimeoutMinValue',
  TRAINING_MODEL_SELECTION = 'TrainingModelSelection',
}

export enum ReportRowRuleNames {
  SAME_ANSWER_FULL_MATCH = 'same-answer-full-match',
  SAME_ANSWER_CLOSE_MATCH = 'same-answer-close-match',
  OTHER_ANSWER_FULL_MATCH = 'other-answer-full-match',
  OTHER_ANSWER_CLOSE_MATCH = 'other-answer-close-match',
}
