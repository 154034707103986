import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useSetRecoilState } from 'recoil';
import { Typography } from 'antd';
import chroma from 'chroma-js';
import { createDirectLine } from 'botframework-webchat';

import SbCollapse, { SbCollapsePanel } from '../../../components/common/SbCollapse';
import {
  DataEntryModel,
  QnAData,
  RecognizedValueModel,
  SingleKnowledgeBaseModel,
  StageModel,
} from '../../../../../kb-api';
import SbScroll from '../../../components/common/SbScroll';
import { instanceKbApi, versionKbApi } from '../../../../apis';
import { AlertTypes, WEB_CHAT_POLLING_INTERVAL } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbSearch from '../../../components/common/SbSearch';
import SbIcon from '../../../components/common/SbIcon';
import SbMarkdownEditor from '../../../components/common/SbMarkdownEditor';
import SbButton from '../../../components/common/SbButton';
import SbSpin from '../../../components/common/SbSpin';
import SbPanel from '../../../components/common/SbPanel';
import { getProfile } from '../../../../utils/oidcUtil';
import { getConfig } from '../../../../utils/configUtil';
import SbWebChatContent from '../../../components/SbWebChat/SbWebChatContent';

import InstanceStatusProgress from './InstanceStatusProgress';

const SEARCH_DELAY = 200; //ms
const QUERY_LIMIT = 10;
const DEFAULT_THRESHOLD = 0.8;
const SCORE_DIFFERENCE = 0.02;

const scoreGradientFunction = chroma.scale(['#096DD9', '#1CBF4A']);

enum AccordionPanels {
  SEMANTIC = 'SEMANTIC',
  WEB_CHAT = 'WEB_CHAT',
}

interface ITestingPanelProps {
  knowledgeBase?: SingleKnowledgeBaseModel;
  dataUpdating: boolean;
  onAddRecord: () => void;
  getDraftStage: () => Promise<StageModel | undefined>;
  getDataEntryIdByVersion: (versionId: string, dataEntryId: string) => Promise<string | undefined>;
  onDataChanged: () => void;
  onEditRecord?: (dataEntry: DataEntryModel) => void;
}

const TestingPanel: React.FC<ITestingPanelProps> = ({
  knowledgeBase,
  dataUpdating,
  onAddRecord,
  getDraftStage,
  getDataEntryIdByVersion,
  onDataChanged,
  onEditRecord = () => {},
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [activePanel, setActivePanel] = useState(AccordionPanels.WEB_CHAT);

  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const [loading, setLoading] = useState(false);
  const [recognizedValues, setRecognizedValues] = useState<RecognizedValueModel[]>([]);
  const [searchText, setSearchText] = useState('');

  const [webChatInitializing, setWebChatInitializing] = useState(true);
  const [webChatProps, setWebChatProps] = useState({});
  const [showWebChatHelp, setShowWebChatHelp] = useState(true);

  const draftInstance = knowledgeBase?.draftInstance;
  const queryInstance = draftInstance || knowledgeBase?.originInstance;
  const threshold =
    knowledgeBase?.draftStage?.recognizerSettings?.threshold ||
    knowledgeBase?.originStage.recognizerSettings?.threshold ||
    DEFAULT_THRESHOLD;
  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';
  const controlsDisabled = saving || publishing;

  const initDirectLine = async () => {
    setShowWebChatHelp(true);

    if (!queryInstance?.id) {
      return;
    }

    setWebChatInitializing(true);

    const profile = getProfile();
    const config = await getConfig();
    const basePath = config?.knowledgeBaseService?.basePath;
    setWebChatProps({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      activityMiddleware: () => (next: any) => (...setupArgs: any[]) => {
        setShowWebChatHelp(false);
        return next(...setupArgs);
      },
      directLine: createDirectLine({
        domain: `${basePath}/api/v1/directline/${queryInstance.id}`,
        token: profile.accessToken,
        webSocket: false,
        pollingInterval: WEB_CHAT_POLLING_INTERVAL,
      }),
      userID: profile.userId,
      username: profile.userName,
    });

    setWebChatInitializing(false);
  };
  const onQueryInstanceChange = () => {
    initDirectLine().finally();
  };
  useEffect(onQueryInstanceChange, [queryInstance?.id]);

  const loadDataAsync = async () => {
    if (!queryInstance || !queryInstance.status.indexId) return;

    if (!deferredSearchText) {
      setRecognizedValues([]);
      return;
    }

    setLoading(true);
    try {
      const response = await instanceKbApi.queryInstance(queryInstance.id, deferredSearchText, QUERY_LIMIT);
      setRecognizedValues(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке результатов семантического поиска',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [knowledgeBase, deferredSearchText]);

  const onRestartWebChatIconClick: MouseEventHandler<HTMLSpanElement> = async (e) => {
    e.stopPropagation();
    await initDirectLine();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);

  const onSearchClear = () => setSearchText('');

  const addPhrase = async (versionId: string, dataEntry: DataEntryModel) => {
    try {
      const dataEntryId = await getDataEntryIdByVersion(versionId, dataEntry.id);

      if (!dataEntryId) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при обновлении записи в базе знаний',
        });
        return;
      }

      const dataEntryData = dataEntry.data as QnAData;
      dataEntryData.questions?.push(searchText);

      await versionKbApi.updateDataEntry(versionId, dataEntryId, {
        type: dataEntry.type,
        data: dataEntryData,
        childEntryIds: dataEntry.childEntryIds,
        isArchived: false,
      });
      setPublishing(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при обновлении записи в базе знаний',
        error: e,
      });
    }
  };

  const onAddPhrase: (dataEntry: DataEntryModel) => MouseEventHandler<HTMLDivElement> = (dataEntry) => async (e) => {
    e.stopPropagation();

    if (!searchText || controlsDisabled || dataUpdating) return;

    setSaving(true);

    const draftStage = await getDraftStage();
    if (!draftStage) {
      setSaving(false);
      return;
    }

    await addPhrase(draftStage.versionId, dataEntry);

    setSaving(false);
  };

  const onCardClick = (dataEntry: DataEntryModel) => () => onEditRecord(dataEntry);

  const onPublishComplete = () => {
    setPublishing(false);
    onDataChanged();
  };

  const onAccordionChange = (key: string | string[]) => {
    if (key) {
      setActivePanel(key as AccordionPanels);
    } else {
      setActivePanel(activePanel === AccordionPanels.SEMANTIC ? AccordionPanels.WEB_CHAT : AccordionPanels.SEMANTIC);
    }
  };

  const renderRecommendation = () => {
    if (!recognizedValues.length) return null;

    if (recognizedValues[0]?.score < threshold) {
      return (
        <SbPanel sbType="help">
          Запись не находится, рекомендуем:
          <ul>
            <li>добавить фразу в существующую запись из результатов поиска</li>
            <li>добавить новую запись</li>
          </ul>
        </SbPanel>
      );
    }

    if (
      recognizedValues[0]?.score >= threshold &&
      recognizedValues[1]?.score >= threshold &&
      Math.abs(recognizedValues[0]?.score - recognizedValues[1]?.score) < SCORE_DIFFERENCE &&
      recognizedValues[0]?.dataEntry.id !== recognizedValues[1]?.dataEntry.id
    ) {
      return (
        <SbPanel sbType="help">
          Найдены похожие фразы, рекомендуем:
          <ul>
            <li>добавить фразу в существующую запись из результатов поиска</li>
            <li>объединить записи из первых результатов поиска</li>
          </ul>
        </SbPanel>
      );
    }

    return null;
  };

  return (
    <SbCollapse activeKey={activePanel} onChange={onAccordionChange}>
      <SbCollapsePanel key={AccordionPanels.SEMANTIC} header="Семантический поиск">
        {publishing && draftInstance?.id && (
          <InstanceStatusProgress instanceId={draftInstance.id} visible={false} onPublishComplete={onPublishComplete} />
        )}
        <div className="sb-testing-panel__semantic">
          {controlsDisabled && (
            <div className="sb-testing-panel__semantic__updating">
              <SbSpin />
            </div>
          )}
          <div className="sb-testing-panel__semantic__title">
            <SbSearch
              isLoading={loading}
              placeholder="Поиск"
              sbSize="small"
              value={searchText}
              onChange={onSearchChange}
              onClear={onSearchClear}
            />
            {renderRecommendation()}
            {!!recognizedValues.length && (
              <SbButton sbType="secondary" onClick={onAddRecord}>
                Добавить новую запись
              </SbButton>
            )}
          </div>
          <div className="sb-testing-panel__semantic__content">
            {searchText ? (
              <SbScroll>
                <div>
                  {recognizedValues.map((recognizedValue, index) => {
                    const scorePercent = (recognizedValue.score || 0) * 100;
                    const scoreColor = scoreGradientFunction(recognizedValue.score).hex();
                    const { answer } = recognizedValue.dataEntry.data as QnAData;
                    return (
                      <div
                        key={index}
                        className="sb-testing-panel__semantic__content__card"
                        onClick={onCardClick(recognizedValue.dataEntry)}
                      >
                        <div className="sb-testing-panel__semantic__content__card__title">
                          <Typography.Text
                            ellipsis
                            className="sb-testing-panel__semantic__content__card__title__question"
                          >
                            {recognizedValue.question}
                          </Typography.Text>
                          <span
                            className="sb-testing-panel__semantic__content__card__title__score"
                            style={{ color: scoreColor }}
                          >
                            {recognizedValue.score?.toFixed(3)}
                          </span>
                        </div>
                        <div className="sb-testing-panel__semantic__content__card__progress">
                          <div
                            className="sb-testing-panel__semantic__content__card__progress__bar"
                            style={{ width: `${100 - scorePercent}%` }}
                          />
                        </div>
                        <SbMarkdownEditor readOnly value={answer || ''} />
                        {recognizedValue.question !== searchText ? (
                          <SbButton
                            icon={<SbIcon iconName={'add-one'} size={16} />}
                            sbSize="medium"
                            sbType="tertiary"
                            onClick={onAddPhrase(recognizedValue.dataEntry)}
                          >
                            Добавить фразу
                          </SbButton>
                        ) : (
                          <div className="sb-testing-panel__semantic__content__card__description">
                            <SbIcon iconName="check-small" size={16} />
                            <span>Фраза добавлена</span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </SbScroll>
            ) : (
              <div className="sb-testing-panel__semantic__content__help">
                <SbIcon iconName="search" size={64} />
                <div>
                  Начните вводить фразу, чтобы
                  <br /> начать поиск по совпадениям
                </div>
              </div>
            )}
          </div>
        </div>
      </SbCollapsePanel>
      <SbCollapsePanel
        key={AccordionPanels.WEB_CHAT}
        header={
          <div className="sb-testing-panel__web-chat-panel-header">
            Тестирование <SbIcon iconName="redo" onClick={onRestartWebChatIconClick} />
          </div>
        }
      >
        <div className="sb-testing-panel__web-chat">
          {showWebChatHelp && (
            <div className="sb-testing-panel__web-chat__help">
              <SbIcon iconName="comments" size={64} />
              <div>
                Начните писать сообщения,
                <br /> чтобы начать общение с ботом
              </div>
            </div>
          )}
          <SbWebChatContent initializing={webChatInitializing} webChatProps={webChatProps} />
        </div>
      </SbCollapsePanel>
    </SbCollapse>
  );
};

export default TestingPanel;
