import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Divider, Row, Space, Spin, Table, Tooltip } from 'antd';
import { useParams } from 'react-router';
import { TeamOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import AddPersonToGroupButton from '../../components/AddPersonToGroupButton';
import { GroupModel, PersonModel } from '../../../api';
import { groupApi, personApi } from '../../apis';
import { AlertTypes, PERSON_UPDATED } from '../../constants';
import { getFio } from '../../utils/stringUtil';
import { hubConnections } from '../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../recoil/alerts';

const GroupCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState({} as GroupModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await groupApi.getGroup(id);
      setGroup(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке карточки группы',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const onDeleteBtnClick = async (person: PersonModel) => {
    try {
      const fullPersonResponse = await personApi.getPerson(person.id);
      if (!fullPersonResponse.data) {
        return;
      }
      const fullPerson = fullPersonResponse.data;
      const remainingGroupIds = fullPerson.groupIds?.filter((groupId) => groupId !== group.id) || [];

      await personApi.updatePerson(fullPerson.id, {
        ...fullPerson,
        groupIds: remainingGroupIds,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении сотрудника из группы',
        error: e,
      });
    }
  };

  const personEventHandler = (args: { personId: string }) => {
    if (group?.persons?.some((p) => p.id === args?.personId)) {
      loadData();
    }
  };
  const subscribe = () => {
    if (!group?.persons || !conn) return;

    conn.on(PERSON_UPDATED, personEventHandler);

    return () => {
      conn.off(PERSON_UPDATED, personEventHandler);
    };
  };
  useEffect(subscribe, [conn, group]);

  const personColumns = [
    {
      title: 'ФИО',
      dataIndex: 'fio',
      render: (_text: unknown, person: PersonModel) => (
        <Link
          to={{
            pathname: `/persons/${person.id}`,
          }}
        >
          {getFio(person)}
        </Link>
      ),
    },
    {
      title: 'Телефон',
      dataIndex: 'tel',
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'E-mail',
      dataIndex: 'eMail',
      ellipsis: true,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: (_text: unknown, person: PersonModel) => (
        <Tooltip placement="top" title="Удалить сотрудника из группы">
          <Button icon={<DeleteOutlined />} type="primary" onClick={() => onDeleteBtnClick(person)} />
        </Tooltip>
      ),
      ellipsis: true,
      width: '15%',
    },
  ];

  if (loading)
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  return (
    <div>
      <Row align="top" justify="space-between">
        <Space>
          <Avatar icon={<TeamOutlined />} size={64} />
          <Space direction="vertical">
            <span>{group.name}</span>
            <span>Группа</span>
          </Space>
        </Space>
        <AddPersonToGroupButton group={group} />
      </Row>
      <Divider />
      <h3>Список сотрудников</h3>
      <Table
        columns={personColumns}
        dataSource={group.persons?.map((item, i) => ({ key: i, ...item })) ?? []}
        loading={loading}
      />
    </div>
  );
};

export default GroupCard;
