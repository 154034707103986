import React, { useState } from 'react';
import { Divider, Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { SelectValue } from 'antd/lib/select';

import { Elma365App, Elma365EnumInputSchema, InputActionSchema } from '../../../../../../../../api';
import SbScroll from '../../../../../../../simple-bot/components/common/SbScroll';
import SbSelect from '../../../../../../../simple-bot/components/common/SbSelect';
import { Elma365BpmTemplate, Elma365EnumSourceType } from '../../../../../../../../api';
import Elma365AppSelector from '../../../Elma365AppSelector';
import Elma365BpmTemplateSelector from '../../../Elma365BpmTemplateSelector';

interface IInputActionElma365EnumSettingsTabProps {
  action: Elma365EnumInputSchema;
  onChange: (action: InputActionSchema) => void;
}

const Elma365EnumSelectionSources = [
  { value: Elma365EnumSourceType.Application, label: 'В приложении' },
  { value: Elma365EnumSourceType.Process, label: 'В бизнес-процессе' },
];

const CONTEXT_ELEMENT_ENUM_TYPE = 'ENUM';

const InputActionElma365EnumSettingsTab: React.FC<IInputActionElma365EnumSettingsTabProps> = ({ action, onChange }) => {
  const [app, setApp] = useState<Elma365App>();
  const [selectedBpmTemplate, setSelectedBpmTemplate] = useState<Elma365BpmTemplate>();

  const onAppSelectorChange = (app?: Elma365App) => {
    setApp(app);

    const newAction = cloneDeep(action);
    newAction.appCode = app?.code;
    newAction.appNamespace = app?.namespace;
    newAction.appTitle = app?.name;

    const appEnums = app?.fields.filter((f) => f.type === CONTEXT_ELEMENT_ENUM_TYPE) ?? [];

    // NOTE: если поменялось выбранное приложение, сбрасывается выбранная категория и по возможности устанавливается первая из списка
    if (app?.code !== action.appCode || app?.namespace !== action.appNamespace) {
      newAction.enumCode = appEnums[0]?.code;
      newAction.enumName = appEnums[0]?.view.name;
    }

    onChange(newAction);
  };

  const onAppSelectorSelectedAppUpdated = (app?: Elma365App) => setApp(app);

  const onEnumSourceTypeChange = (value: SelectValue) => {
    const newAction = cloneDeep(action);
    newAction.enumSourceType = value as Elma365EnumSourceType;

    onChange(newAction);
  };

  const onAppEnumCodeChange = (value: SelectValue) => {
    const newAction = cloneDeep(action);
    newAction.enumCode = value as string;
    newAction.enumName = app?.fields
      .filter((f) => f.type === CONTEXT_ELEMENT_ENUM_TYPE)
      ?.find((e) => e.code === newAction.enumCode)?.view.name;

    onChange(newAction);
  };

  const onBpmTemplateEnumCodeChange = (value: SelectValue) => {
    const newAction = cloneDeep(action);
    newAction.enumCode = value as string;
    newAction.enumName = selectedBpmTemplate?.context.find((e) => e.code === newAction.enumCode)?.view.name;

    onChange(newAction);
  };

  const onBpmTemplateSelectorChange = (bpmTemplate?: Elma365BpmTemplate) => {
    const newAction = cloneDeep(action);
    newAction.bpmTemplateCode = bpmTemplate?.code;
    newAction.bpmTemplateNamespace = bpmTemplate?.namespace;

    const selectedBpmTemplateEnums = bpmTemplate?.context.filter((f) => f.type === CONTEXT_ELEMENT_ENUM_TYPE) ?? [];

    // NOTE: если поменялся выбранный бизнес-процесс, сбрасывается выбранная категория и по возможности устанавливается первая из списка
    if (bpmTemplate?.code !== action.bpmTemplateCode || bpmTemplate?.namespace !== action.bpmTemplateNamespace) {
      newAction.enumCode = selectedBpmTemplateEnums[0]?.code;
      newAction.enumName = selectedBpmTemplateEnums[0]?.view.name;
    }

    onChange(newAction);
  };

  const onSelectedBpmTemplateUpdated = (bpmTemplate?: Elma365BpmTemplate) => {
    setSelectedBpmTemplate(bpmTemplate);
  };

  const renderAppEnumSelection = () => {
    const appEnums = app?.fields.filter((f) => f.type === CONTEXT_ELEMENT_ENUM_TYPE);

    if (!appEnums) {
      return null;
    }

    const enumCode = action.enumSourceType === Elma365EnumSourceType.Application ? action.enumCode : undefined;

    return (
      <div className="field">
        <h4>Категория:</h4>
        <SbSelect
          placeholder="Выберите категорию"
          sbSize="big"
          sbType="light"
          value={enumCode as string | undefined}
          onChange={onAppEnumCodeChange}
        >
          {appEnums.map((f) => (
            <Select.Option key={f.code} value={f.code}>
              {f.view.name}
            </Select.Option>
          ))}
        </SbSelect>
      </div>
    );
  };

  const renderBpmTemplateEnumSelection = () => {
    if (!selectedBpmTemplate) {
      return null;
    }

    const bpmTemplateEnums = selectedBpmTemplate?.context.filter((f) => f.type === CONTEXT_ELEMENT_ENUM_TYPE) ?? [];
    const enumCode =
      action.enumSourceType === Elma365EnumSourceType.Process &&
      bpmTemplateEnums.some((e) => e.code === action.enumCode)
        ? action.enumCode
        : undefined;

    return (
      <div className="field">
        <h4>Категория:</h4>
        <SbSelect
          placeholder="Выберите категорию"
          sbSize="big"
          sbType="light"
          value={enumCode as string | undefined}
          onChange={onBpmTemplateEnumCodeChange}
        >
          {bpmTemplateEnums.map((e) => (
            <Select.Option key={e.code} value={e.code}>
              {e.view.name}
            </Select.Option>
          ))}
        </SbSelect>
      </div>
    );
  };

  const renderEnumSelection = () => {
    switch (action.enumSourceType) {
      case Elma365EnumSourceType.Application:
        return (
          <>
            <div className="field">
              <Elma365AppSelector
                appCode={action.appCode}
                appNamespace={action.appNamespace}
                onChange={onAppSelectorChange}
                onSelectedAppUpdated={onAppSelectorSelectedAppUpdated}
              />
            </div>
            {renderAppEnumSelection()}
          </>
        );
      case Elma365EnumSourceType.Process:
        return (
          <>
            <div className="field">
              <Elma365BpmTemplateSelector
                bpmTemplateCode={action.bpmTemplateCode}
                bpmTemplateNamespace={action.bpmTemplateNamespace}
                onChange={onBpmTemplateSelectorChange}
                onSelectedBpmTemplateUpdated={onSelectedBpmTemplateUpdated}
              />
            </div>
            {renderBpmTemplateEnumSelection()}
          </>
        );
      default:
        break;
    }
  };

  return (
    <SbScroll>
      <div>
        <div className="field">
          <h4>Где искать категорию</h4>
          <SbSelect
            placeholder="Выберите тип объекта"
            sbSize="big"
            sbType="light"
            value={action.enumSourceType}
            onChange={onEnumSourceTypeChange}
          >
            {Elma365EnumSelectionSources.map((t) => (
              <Select.Option key={t.value} value={t.value}>
                {t.label}
              </Select.Option>
            ))}
          </SbSelect>
        </div>
        {renderEnumSelection()}
        <Divider />
      </div>
    </SbScroll>
  );
};

export default InputActionElma365EnumSettingsTab;
