import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LabeledValue, SelectValue } from 'antd/es/select';

import './index.less';

import { Elma365App, Elma365IntegrationSettingsSchema } from '../../../../../../api';
import { elma365AppApi } from '../../../../../apis';
import { botSettingsSelector } from '../../../../../recoil/scenarioStructure';
import { instanceOfElma365IntegrationSettingsSchema } from '../../../utils';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import SbDebounceSelect from '../../../../../simple-bot/components/common/SbDebounceSelect';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';

interface IElma365AppSelectorProps {
  appCode?: string | null;
  appNamespace?: string | null;
  onChange: (app?: Elma365App) => void;
  onSelectedAppUpdated: (app?: Elma365App) => void;
}

const Elma365AppSelector: React.FC<IElma365AppSelectorProps> = ({
  appCode,
  appNamespace,
  onChange,
  onSelectedAppUpdated,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const botSettings = useRecoilValue(botSettingsSelector);

  const [appList, setAppList] = useState<Elma365App[]>([]);
  const [selectedApp, setSelectedApp] = useState<Elma365App>();

  const integrationSettings = botSettings?.integrations.find((i) =>
    instanceOfElma365IntegrationSettingsSchema(i)
  ) as Elma365IntegrationSettingsSchema;
  const integrationSettingsNotSet = !integrationSettings?.apiUrl || !integrationSettings?.xToken;

  const searchApps = async (search: string) => {
    if (!integrationSettings.apiUrl || !integrationSettings.xToken) {
      setAppList([]);
      return [];
    }

    try {
      const response = await elma365AppApi.searchElma365Apps(
        integrationSettings.apiUrl,
        integrationSettings.xToken,
        search
      );
      const appList = response.data;
      setAppList(appList);
      return appList.map((e) => ({ value: e.__id, key: e.__id, label: e.name } as SelectValue));
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка приложений',
        error: e,
      });
    }

    setAppList([]);
    return [];
  };

  const tryLoadApplication = async (
    appCode?: string | null,
    appNamespace?: string | null
  ): Promise<Elma365App | undefined> => {
    if (!integrationSettings.apiUrl || !integrationSettings.xToken || !appCode || !appNamespace) {
      setSelectedApp(undefined);
      return;
    }

    try {
      const { data: app } = await elma365AppApi.getElma365App(
        integrationSettings.apiUrl,
        integrationSettings.xToken,
        appNamespace,
        appCode
      );

      setSelectedApp(app);
      onSelectedAppUpdated(app);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке приложения',
        error: e,
      });
      setSelectedApp(undefined);
    }
  };

  const onPropsChange = () => {
    tryLoadApplication(appCode, appNamespace).finally();
  };
  useEffect(onPropsChange, [appCode, appNamespace]);

  const onAppSelectChange = (value: SelectValue) => {
    const labeledValue = value as LabeledValue;
    const app = appList.find((t) => t.__id === labeledValue.value);
    setSelectedApp(app);
    onChange(app);
  };

  if (integrationSettingsNotSet) {
    return (
      <SbPanel sbType="help" scrollable={false}>
        Настройте параметры интеграции ELMA365 на странице бота.
      </SbPanel>
    );
  }

  return (
    <div className="sb-elma365-app-selector">
      <h4>Приложение:</h4>
      <SbDebounceSelect
        showSearch
        fetchOptions={searchApps}
        sbType="light"
        value={
          { value: selectedApp?.__id || '-', label: selectedApp?.name || 'Выберите или начните писать' } as SelectValue
        }
        onChange={onAppSelectChange}
      />
    </div>
  );
};

export default Elma365AppSelector;
