import React, { useEffect, useState } from 'react';
import { Button, Modal, Table, TablePaginationConfig, Tooltip } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { AgentModel, PersonModel } from '../../../api';
import { getFio } from '../../utils/stringUtil';
import { agentApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

import AgentAccountList from './AgentAccountList';

export interface ISendInviteButtonProps {
  person: PersonModel;
  showButtonWithText?: boolean;
}

const SendInviteButton: React.FC<ISendInviteButtonProps> = ({ person, showButtonWithText = true }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [modalVisible, setModalVisible] = useState(false);
  const [agentsLoading, setAgentsLoading] = useState(false);
  const [listAgents, setListAgents] = useState([] as AgentModel[]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadAgentsAsync = async () => {
    setAgentsLoading(true);
    try {
      const response = await agentApi.searchAgents((pagination.current ?? 1) - 1, pagination.pageSize);
      setListAgents(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка доступных ботов',
        error: e,
      });
    }
    setAgentsLoading(false);
  };
  const loadAgents = () => {
    if (!modalVisible) return;
    loadAgentsAsync();
  };
  useEffect(loadAgents, [pagination.current, pagination.pageSize]);

  const onOpenModalBtnClick = () => {
    if (!person) return;

    setModalVisible(true);
    loadAgentsAsync();
  };

  const onModalCancelBtnClick = () => {
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      ellipsis: true,
      width: '60%',
    },
  ];

  const button = showButtonWithText ? (
    <Button icon={<MailOutlined />} type="primary" onClick={onOpenModalBtnClick}>
      Отправить приглашение
    </Button>
  ) : (
    <Tooltip placement="top" title="Отправить приглашение">
      <Button icon={<MailOutlined />} type="primary" onClick={onOpenModalBtnClick} />
    </Tooltip>
  );

  const renderAgentAccounts = (agent: AgentModel, _index: number, _indent: number, expanded: boolean) => {
    return <AgentAccountList agent={agent} person={person} visible={expanded} />;
  };

  return (
    <div>
      {button}
      <Modal
        destroyOnClose
        footer={[
          <Button key="back" onClick={onModalCancelBtnClick}>
            Отмена
          </Button>,
        ]}
        style={{ minWidth: '600px' }}
        title={getFio(person)}
        visible={modalVisible}
        onCancel={onModalCancel}
      >
        <h4>Выберите бота и канал для отправки приглашения</h4>
        <Table
          expandRowByClick
          columns={columns}
          dataSource={listAgents.map((item, i) => ({ key: i, ...item }))}
          expandedRowRender={renderAgentAccounts}
          loading={agentsLoading}
          pagination={pagination}
          onChange={onTableChange}
        />
      </Modal>
    </div>
  );
};

export default SendInviteButton;
