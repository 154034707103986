import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useTimeout } from 'usehooks-ts';

import { SingleScenarioTemplateModel, ListBotTemplateModel, BotCreationRequest, SchemaKind } from '../../../../../api';
import { scenarioTemplateApi, botApi } from '../../../../apis';
import { FROM_SCRATCH_BOT_TEMPLATE_CODE, LOCALE_NAMES } from '../../../const';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { generateId } from '../../../../components/ScenarioEditor/utils';
import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbSteps from '../../../components/common/SbSteps';

import BotTemplateSelectionStep from './BotTemplateSelectionStep';
import ScenariosSelectionStep from './ScenariosSelectionStep';

import './index.less';

const DEFAULT_SCRIPT_EXECUTION_TIMEOUT = 15;
const DEFAULT_SCRIPT_RETRY_COUNT = 0;
const DEFAULT_SCRIPT_RETRY_DELAY = 1;

const DEFAULT_SCRIPT_CONTENT = `// Примеры работы с событиями бота
// 1. Событие распознанного интента
// onIntentRecognized("pokupka_prodazha__chasy_raboty_magazinov", async (botEvent) => {
//   код интента
//   console.log(botEvent.intentKey);
//   результаты распознавания
//   console.log(botEvent.recognizerResult);
//   ввод пользователя
//   console.log(botEvent.userInputText);
//});

// 2. Событие начала работы элемента ввода данных
// onInputDialogBegin("store_name", async (botEvent) => {
//   тип распознавателя
//   console.log(botEvent.recognizerKind);
//   сообщение бота
//   context.output.result.promptText = "Введите название магазина";
//   сообщение при нераспознанном вводе
//   context.output.result.unrecognizedPromptText = "Не удалось распознать название магазина";
//   подсказки бота
//   context.output.result.suggestedActions = ["Мебель", "Одежда", "Обувь"];
// });

// 3. Событие прерывания ввода
// onInputDialogBeforeInterruption("store_name", async (botEvent) => {
//   ввод пользователя
//   console.log(botEvent.userInputText);
//   распознанное значение
//   console.log(botEvent.recognizedValue);
//   тип распознавателя
//   console.log(botEvent.recognizerKind);
//   нужно ли разрешать прерывать работу элемента
//   context.output.result.allowInterruptions = false;
// });

// 4. Событие распознавания ввода пользователя
// onInputDialogValueRecognized("store_name", async (botEvent) => {
//   ввод пользователя
//   console.log(botEvent.userInputText);
//   распознанное значение
//   console.log(botEvent.recognizedValue);
//   тип распознавателя
//   console.log(botEvent.recognizerKind);
//   объект диалоговой активности пользователя
//   console.log(botEvent.activity);
//   распознано ли значение
//   context.output.result.recognized = true;
//   распознанное значение
//   context.output.result.outputValue = "Магазин Мебель";
// });
`;

interface ISbCreateBotWizardProps {
  onClose: () => void;
}

const CreateBotWizard: React.FC<ISbCreateBotWizardProps> = ({ onClose }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [isCreatingBot, setIsCreatingBot] = useState(false);
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const [selectedBotTemplate, setSelectedBotTemplate] = useState<ListBotTemplateModel>();
  const [selectedScenariosIds, setSelectedScenariosIds] = useState<string[]>([]);
  const [botName, setBotName] = useState('');

  useEffect(() => {
    setSelectedScenariosIds([]);
    selectedBotTemplate &&
      setBotName(selectedBotTemplate.code === FROM_SCRATCH_BOT_TEMPLATE_CODE ? '' : selectedBotTemplate.name);
  }, [selectedBotTemplate]);

  const steps = [
    {
      title: 'Выберите шаблон',
      content: (
        <BotTemplateSelectionStep
          selectedBotTemplate={selectedBotTemplate}
          onSelectedBotTemplateChanged={(botTemplate) => {
            setSelectedBotTemplate(botTemplate);
          }}
        />
      ),
      pageTitle: 'Выберите шаблон бота',
      pageDescription: `Выберите одного из ботов в качестве шаблона либо создайте\nсвоего бота с нуля`,
    },
    {
      title: 'Выберите сценарии',
      content: selectedBotTemplate && (
        <ScenariosSelectionStep
          botName={botName}
          selectedBotTemplate={selectedBotTemplate}
          selectedScenariosIds={selectedScenariosIds}
          onBotNameChange={setBotName}
          onSelectedScenariosIdsChange={(value) => {
            setSelectedScenariosIds(value);
          }}
        />
      ),
      pageTitle: 'Назовите бота и выберите сценарии',
      pageDescription: 'От набора сценариев будет зависеть функциональность бота',
      disabled: !selectedBotTemplate,
    },
  ];

  // HACK: иногда данные успевают загрузиться и отрендериться быстрее, чем длится анимация
  // появления модального окна. Из-за этого у скроллбаров успевают появиться лишние полосы прокрутки.
  // Для исправления перед первой загрузкой данных ждем окончания анимации.
  useTimeout(() => {
    setLoading(false);
  }, 300);

  const onNextStepClick = () => {
    setSelectedStepIndex(selectedStepIndex + 1);
  };

  const onPreviousStepClick = () => {
    setSelectedStepIndex(selectedStepIndex - 1);
  };

  const getScenarios = async () => {
    const selectedScenarios: SingleScenarioTemplateModel[] = [];
    // TODO: сделать специальный метод API?
    await Promise.all(
      selectedScenariosIds.map(async (scenarioId) => {
        const scenarioResponse = await scenarioTemplateApi.getScenarioTemplate(scenarioId);
        selectedScenarios.push(scenarioResponse.data);
      })
    );
    return selectedScenarios.map((scenarioTemplate) => {
      const newScenario = JSON.parse(scenarioTemplate.content);
      newScenario.id = generateId('SCN');
      newScenario.name = scenarioTemplate.name;
      return newScenario;
    });
  };

  const onCreateClick = async () => {
    setIsCreatingBot(true);

    try {
      const scenarios = await getScenarios();
      const newSimpleBot: BotCreationRequest = {
        botName,
        botStructure: {
          id: generateId('BOT'),
          name: botName,
          scenarios: scenarios,
          settings: {
            id: generateId('SET'),
            $kind: SchemaKind.BotSettings,
            script: {
              content: DEFAULT_SCRIPT_CONTENT,
              executionTimeout: DEFAULT_SCRIPT_EXECUTION_TIMEOUT,
              retryCount: DEFAULT_SCRIPT_RETRY_COUNT,
              retryDelay: DEFAULT_SCRIPT_RETRY_DELAY,
            },
            integrations: [],
            localization: {
              locale: LOCALE_NAMES.default,
            },
            recognition: {
              nlp: {
                useCustomTrainingModel: false,
                customTrainingModel: undefined,
              },
            },
          },
        },
        botTemplateCode: selectedBotTemplate?.code,
      };
      const creationResponse = await botApi.createBot(newSimpleBot);
      history.push(`/simple-bots/${creationResponse.data.entry.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании бота',
        error: e,
      });
    }

    onClose();
  };

  if (isCreatingBot) {
    return (
      <div className="creating-scenario">
        <div className="creating-scenario__spin">
          <Spin />
        </div>
        <p>Создаем бота...</p>
      </div>
    );
  }

  return (
    <div className="sb-create-bot-wizard">
      <div className="sb-create-bot-wizard__header">
        <h1>{steps[selectedStepIndex].pageTitle}</h1>
        <p>{steps[selectedStepIndex].pageDescription}</p>
      </div>
      <div className="sb-create-bot-wizard__steps">
        <SbSteps
          current={selectedStepIndex}
          labelPlacement="vertical"
          steps={steps}
          onChange={setSelectedStepIndex}
        ></SbSteps>
      </div>
      <div className="sb-create-bot-wizard__content">{loading || steps[selectedStepIndex].content}</div>
      <div className="sb-create-bot-wizard__footer">
        {selectedStepIndex > 0 && (
          <SbButton sbSize="big" sbType="secondary" onClick={onPreviousStepClick}>
            <SbIcon iconName="left" />
            Назад
          </SbButton>
        )}
        {selectedStepIndex < steps.length - 1 && (
          <SbButton disabled={!selectedBotTemplate} sbSize="big" sbType="primary" onClick={onNextStepClick}>
            <SbIcon iconName="right" />
            Далее
          </SbButton>
        )}
        {selectedStepIndex === steps.length - 1 && (
          <SbButton disabled={!botName} sbSize="big" sbType="primary" onClick={onCreateClick}>
            Создать
          </SbButton>
        )}
      </div>
    </div>
  );
};

export default CreateBotWizard;
