import React from 'react';
import moment from 'moment';
import { Avatar } from 'antd';

import './index.less';

import { ConversationModel } from '../../../../../../api';
import SbTag from '../../../../components/common/SbTag';
import { renderActiveDialogCount, renderDialogCount, renderUserCount } from '../../../../../utils/stringUtil';
import { DIALOGS_COUNT_TAG_COLOR } from '../../../../const';
import SbIcon from '../../../../components/common/SbIcon';

const START_DATE_FORMAT = 'DD.MM.YYYY в HH:mm';

interface IDialogsBlockProps {
  latestConversation?: ConversationModel;
  totalCount: number;
  activeCount: number;
  userCount: number;
}

const DialogsBlock: React.FC<IDialogsBlockProps> = ({ latestConversation, totalCount, activeCount, userCount }) => {
  const renderHeader = () => (
    <>
      <h2>Диалоги</h2>
      <SbTag color={DIALOGS_COUNT_TAG_COLOR} sbSize="small" text={renderActiveDialogCount(activeCount)} />
    </>
  );

  const renderContent = () => (
    <>
      <div className="sb-dialogs-block__content__info">
        <Avatar icon={<SbIcon iconName="comment" size={20} />} size={40} />
        <div>
          <div className="sb-dialogs-block__content__info__title-main">{renderDialogCount(totalCount)}</div>
          {latestConversation && (
            <div className="sb-dialogs-block__content__info__title-optional">
              Последний {moment(latestConversation.startedOn).format(START_DATE_FORMAT)}
            </div>
          )}
        </div>
      </div>
      <div className="sb-dialogs-block__content__info">
        <Avatar icon={<SbIcon iconName="user" size={20} />} size={40} />
        <div className="sb-dialogs-block__content__info__title-main">{renderUserCount(userCount)}</div>
      </div>
    </>
  );

  return (
    <div className="sb-dialogs-block">
      <div className="sb-dialogs-block__header sb-bot-card__content__block__inner-wrapper__header">
        {renderHeader()}
      </div>
      <div className="sb-dialogs-block__content">{renderContent()}</div>
    </div>
  );
};

export default DialogsBlock;
