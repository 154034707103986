import React, { ChangeEventHandler, CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import { Button, Divider, Input } from 'antd';
import { EditorState, getVisibleSelectionRect, RichUtils } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { useRecoilValue } from 'recoil';

import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import {
  addLink,
  BlockTypes,
  InlineStyles,
  toggleBlockType,
  toggleInlineStyle,
} from '../../../../simple-bot/utils/markdownEditor';
import { zoomSelector } from '../../../../recoil/scenarioStructure';

import { MAIN_CLASS_NAME } from './constants';

const TOOLBAR_CLASS_NAME = `${MAIN_CLASS_NAME}__inline-toolbar`;
const TOOLBAR_BUTTON_CLASS_NAME = `${TOOLBAR_CLASS_NAME}__button`;
const TOOLBAR_BUTTON_SELECTED_CLASS_NAME = `${TOOLBAR_BUTTON_CLASS_NAME}_selected`;

enum ToolbarModes {
  MAIN = 'MAIN',
  SHOW_HEADERS = 'SHOW_HEADERS',
  SHOW_LINK = 'SHOW_LINK',
}

interface IInlineToolbarProps {
  editorRef: RefObject<Editor>;
  editorState: EditorState;
  useScenarioEditorZoom?: boolean;
  onChange: (editorState: EditorState) => void;
}

const InlineToolbar: React.FC<IInlineToolbarProps> = ({ editorRef, editorState, useScenarioEditorZoom, onChange }) => {
  const scenarioEditorZoom = useRecoilValue(zoomSelector);
  const zoom = useScenarioEditorZoom ? scenarioEditorZoom : 1;

  const [toolbarMode, setToolbarMode] = useState(ToolbarModes.MAIN);
  const [link, setLink] = useState('');
  const [toolbarStyle, setToolbarStyle] = useState<CSSProperties>();

  const toolbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const editorRoot = editorRef.current?.getEditorRef().editor;
    const editorRootRect = editorRoot?.getBoundingClientRect();
    const parentWindow = editorRoot?.ownerDocument && editorRoot.ownerDocument.defaultView;
    const selectionRect = getVisibleSelectionRect(parentWindow || window);
    const inlineToolbarRect = toolbarRef.current?.getBoundingClientRect();
    if (editorRoot && editorRootRect && selectionRect && inlineToolbarRect) {
      let left = (selectionRect.left - editorRootRect.left + selectionRect.width / 2) / zoom;
      if (!useScenarioEditorZoom) {
        left = Math.max(left, inlineToolbarRect.width / 2);
        left = Math.min(left, editorRootRect.width - inlineToolbarRect.width / 2);
      }
      left += editorRoot.offsetLeft;
      setToolbarStyle({
        top: editorRoot.offsetTop + (selectionRect.top - editorRootRect.top) / zoom,
        left,
      });
    }
  }, []);

  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const block = content.getBlockForKey(startKey);
  const blockType = block.getType();

  const boldButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (currentInlineStyle.has(InlineStyles.BOLD)) boldButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const italicButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (currentInlineStyle.has(InlineStyles.ITALIC)) italicButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const h1ButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H1) h1ButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const h2ButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H2) h2ButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const h3ButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H3) h3ButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const h4ButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H4) h4ButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const h5ButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H5) h5ButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const h6ButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H6) h6ButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const orderedListButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.ORDERED_LIST) orderedListButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const listButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.UNORDERED_LIST) listButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const linkButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (RichUtils.currentBlockContainsLink(editorState)) linkButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const blockquoteButtonClasses = [TOOLBAR_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.BLOCKQUOTE) blockquoteButtonClasses.push(TOOLBAR_BUTTON_SELECTED_CLASS_NAME);

  const tryAddLink = () => {
    if (link) {
      onChange(addLink(editorState, link));
      setLink('');
    }
  };

  const onAddLinkButtonClick = () => tryAddLink();
  const onLinkInputPressEnter = () => tryAddLink();
  const onLinkInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setLink(e.target.value);

  const onBold = () => onChange(toggleInlineStyle(editorState, InlineStyles.BOLD));
  const onItalic = () => onChange(toggleInlineStyle(editorState, InlineStyles.ITALIC));
  const onH1 = () => onChange(toggleBlockType(editorState, BlockTypes.H1));
  const onH2 = () => onChange(toggleBlockType(editorState, BlockTypes.H2));
  const onH3 = () => onChange(toggleBlockType(editorState, BlockTypes.H3));
  const onH4 = () => onChange(toggleBlockType(editorState, BlockTypes.H4));
  const onH5 = () => onChange(toggleBlockType(editorState, BlockTypes.H5));
  const onH6 = () => onChange(toggleBlockType(editorState, BlockTypes.H6));
  const onOrderedList = () => onChange(toggleBlockType(editorState, BlockTypes.ORDERED_LIST));
  const onUnorderedList = () => onChange(toggleBlockType(editorState, BlockTypes.UNORDERED_LIST));
  const onBlockquote = () => onChange(toggleBlockType(editorState, BlockTypes.BLOCKQUOTE));

  const onHeadersButtonClick = () => setToolbarMode(ToolbarModes.SHOW_HEADERS);
  const onLinkButtonClick = () => setToolbarMode(ToolbarModes.SHOW_LINK);
  const onBackButtonClick = () => setToolbarMode(ToolbarModes.MAIN);

  const renderMainButtons = () => (
    <>
      <Button className={boldButtonClasses.join(' ')} type="link" onClick={onBold}>
        <SbIcon iconName="text-bold" size={14} />
      </Button>
      <Button className={italicButtonClasses.join(' ')} type="link" onClick={onItalic}>
        <SbIcon iconName="text-italic" size={14} />
      </Button>
      <Divider type="vertical" />
      <Button className={TOOLBAR_BUTTON_CLASS_NAME} type="link" onClick={onHeadersButtonClick}>
        H1-6
      </Button>
      <Divider type="vertical" />
      <Button className={orderedListButtonClasses.join(' ')} type="link" onClick={onOrderedList}>
        <SbIcon iconName="ordered-list" size={14} />
      </Button>
      <Button className={listButtonClasses.join(' ')} type="link" onClick={onUnorderedList}>
        <SbIcon iconName="list-two" size={14} />
      </Button>
      <Divider type="vertical" />
      <Button className={linkButtonClasses.join(' ')} type="link" onClick={onLinkButtonClick}>
        <SbIcon iconName="link-one" size={14} />
      </Button>
      <Button className={blockquoteButtonClasses.join(' ')} type="link" onClick={onBlockquote}>
        <SbIcon iconName="align-left-one" size={14} />
      </Button>
    </>
  );

  const renderHeadersButtons = () => (
    <>
      <Button className={h1ButtonClasses.join(' ')} type="link" onClick={onH1}>
        H1
      </Button>
      <Button className={h2ButtonClasses.join(' ')} type="link" onClick={onH2}>
        H2
      </Button>
      <Button className={h3ButtonClasses.join(' ')} type="link" onClick={onH3}>
        H3
      </Button>
      <Button className={h4ButtonClasses.join(' ')} type="link" onClick={onH4}>
        H4
      </Button>
      <Button className={h5ButtonClasses.join(' ')} type="link" onClick={onH5}>
        H5
      </Button>
      <Button className={h6ButtonClasses.join(' ')} type="link" onClick={onH6}>
        H6
      </Button>
      <Divider type="vertical" />
      <Button className={TOOLBAR_BUTTON_CLASS_NAME} type="link" onClick={onBackButtonClick}>
        <SbIcon iconName="back" size={14} />
      </Button>
    </>
  );

  const renderLinkInput = () => (
    <>
      <Input.Group compact className={`${TOOLBAR_CLASS_NAME}__link-input`}>
        <Input
          autoFocus
          placeholder="Введите url"
          value={link}
          onChange={onLinkInputChange}
          onPressEnter={onLinkInputPressEnter}
        />
        <Button type="primary" onClick={onAddLinkButtonClick}>
          ОК
        </Button>
      </Input.Group>
      <Divider type="vertical" />
      <Button className={TOOLBAR_BUTTON_CLASS_NAME} type="link" onClick={onBackButtonClick}>
        <SbIcon iconName="back" size={14} />
      </Button>
    </>
  );

  return (
    <div ref={toolbarRef} className={TOOLBAR_CLASS_NAME} style={toolbarStyle}>
      {toolbarMode === ToolbarModes.MAIN && renderMainButtons()}
      {toolbarMode === ToolbarModes.SHOW_HEADERS && renderHeadersButtons()}
      {toolbarMode === ToolbarModes.SHOW_LINK && renderLinkInput()}
    </div>
  );
};

export default InlineToolbar;
