import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Space, Button, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountCreationRequest } from '../../../../api';
import { AlertTypes, Channels } from '../../../constants';
import { agentStageAccountApi } from '../../../apis';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Title } = Typography;

const TelegramChannelAdd: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, unknown>>();
  const [error, setError] = useState<string>();

  const onFinish = async () => {
    try {
      setLoading(true);
      const request: AgentStageAccountCreationRequest = {
        agentStageId: id,
        displayName: form.getFieldValue('displayName'),
        authToken: form.getFieldValue('token'),
        channelId: Channels.TELEGRAM,
      };

      await agentStageAccountApi.createAgentStageAccount(request);

      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлен новый канал',
        description: 'Telegram',
      });
      goBack();
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();
      setErrors(errors);
      setError(errorText);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item help={error} validateStatus={error ? 'error' : undefined}>
          <Title level={4}>Новый канал Telegram</Title>
        </Form.Item>
        <Form.Item label="Отображаемое наименование" name="displayName">
          <Input placeholder="Отображаемое наименование канала" />
        </Form.Item>
        <Form.Item
          help={errors?.AuthToken ? 'Некорректный токен' : undefined}
          label="Токен Telegram"
          name="token"
          rules={[{ required: true, message: 'Введите токен' }]}
          validateStatus={errors?.AuthToken ? 'error' : undefined}
        >
          <Input placeholder="Токен авторизации Telegram" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={goBack}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TelegramChannelAdd;
