import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ActivityTypes } from 'botframework-schema';
import { useSetRecoilState } from 'recoil';
import { Skeleton } from 'antd';

import SbScroll from '../../../../components/common/SbScroll';
import SbIcon from '../../../../components/common/SbIcon';
import SbSpin from '../../../../components/common/SbSpin';
import { ActivityDirection, ActivityModel, ConversationModel, ConversationStatus } from '../../../../../../api';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { AlertTypes } from '../../../../../constants';
import { activityApi } from '../../../../../apis';
import { TriggerNames, ActivityEventNames } from '../../../../utils/dialogs';

import TransferToOperatorInitiated from './TransferToOperatorInitiated';
import SigmaIntentRecognized from './SigmaIntentRecognized';
import ExternalEventTrigger from './ExternalEventTrigger';
import ScriptExecutionInfo from './ScriptExecutionInfo';
import TransitionTrigger from './TransitionTrigger';
import IntentRecognized from './IntentRecognized';
import MenuItemSelected from './MenuItemSelected';
import ScenarioStarted from './ScenarioStarted';
import TerminalTrigger from './TerminalTrigger';
import ScriptExecuted from './ScriptExecuted';
import PromptAccepted from './PromptAccepted';
import MessageBubble from './MessageBubble';
import UnknownIntent from './UnknownIntent';
import ErrorOccured from './ErrorOccured';
import StartTrigger from './StartTrigger';
import MessageInfo from './MessageInfo';
import SessionInfo from './SessionInfo';
import DialogInfo from './DialogInfo';
import ErrorInfo from './ErrorInfo';

const ACTIVITIES_PER_REQUEST = 50;
const DIALOG_MESSAGES_SCROLL_ID = 'sb-dialog-messages-scroll';
const DIALOG_MESSAGE_INFO_SCROLL_ID = 'sb-dialog-message-info-scroll';

interface IDialogHistoryProps {
  conversation?: ConversationModel;
}

const DialogHistory: React.FC<IDialogHistoryProps> = ({ conversation }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [activitiesPageIndex, setActivitiesPageIndex] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState<ActivityModel>();
  const [activities, setActivities] = useState([] as ActivityModel[]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);

  const loadActivities = async (loadMore = true) => {
    if (!conversation || loadingActivities) return;

    loadMore ? setLoadingActivities(true) : setLoading(true);

    try {
      const activitiesResponse = await activityApi.searchActivities(
        undefined,
        undefined,
        undefined,
        conversation.id,
        undefined,
        undefined,
        undefined,
        loadMore ? activitiesPageIndex : 0,
        ACTIVITIES_PER_REQUEST
      );

      if (loadMore) {
        setActivities([...activities, ...(activitiesResponse.data.items ?? [])]);
      } else {
        setActivities(activitiesResponse.data.items ?? []);
      }
      setActivitiesPageIndex((activitiesResponse.data.pageIndex ?? 0) + 1);
      setHasMore(activitiesResponse.data.hasMore ?? false);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка сообщений',
        error: e,
      });
    }
    setLoadingActivities(false);
    setLoading(false);
  };

  const onMessageSelect = (activity: ActivityModel) => {
    const newSelectedActivities = [selectedActivity].includes(activity) ? [] : [activity];
    const newActivity = newSelectedActivities.pop();
    setSelectedActivity(newActivity);
  };

  const getMenuItemSelected = (activity: ActivityModel) => {
    if (!activity.suggestedActions) return;

    const activityIndex = activities.indexOf(activity);
    const remainingActivities = activities.slice(activityIndex + 1);
    const selectedActivity = remainingActivities.find(
      (item) => item.suggestedActions || item.name == ActivityEventNames.MenuItemSelected
    );
    if (selectedActivity?.name == ActivityEventNames.MenuItemSelected) {
      return selectedActivity;
    }

    const buttonSelected = remainingActivities.find(
      (item) =>
        item.suggestedActions || (item.type == ActivityTypes.Message && item.direction == ActivityDirection.NUMBER_0)
    );
    return activity.suggestedActions?.some((i) => i == buttonSelected?.text) ? buttonSelected : undefined;
  };

  const getRecognizerResult = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    const remainingActivities = activities.slice(activityIndex + 1);
    return remainingActivities.find(
      (item) => item.name == ActivityEventNames.OmegaRecognizer && item.value.Result?.text == activity.text
    );
  };

  const getSigmaRecognizerResult = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    const remainingActivities = activities.slice(activityIndex + 1);
    return remainingActivities.find(
      (item) => item.name == ActivityEventNames.SigmaRecognizer && item.value.message?.text == activity.text
    );
  };

  const getScenarioInfo = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    if (activityIndex < 1) return;
    const previousActivities = activities.slice(0, activityIndex).reverse();
    const scenarioActivity = previousActivities.find(
      (item) =>
        item.name == ActivityEventNames.ScenarioStarted ||
        (item.type != ActivityTypes.Event && item.type != ActivityTypes.Trace)
    );
    if (scenarioActivity?.name == ActivityEventNames.ScenarioStarted) {
      return scenarioActivity;
    }
  };

  const getTriggerInfo = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    if (activityIndex < 1) return;
    const previousActivities = activities.slice(0, activityIndex).reverse();
    const triggerActivity = previousActivities.find(
      (item) =>
        item.name == ActivityEventNames.TriggerFired ||
        (item.type != ActivityTypes.Event && item.type != ActivityTypes.Trace)
    );
    if (triggerActivity?.name == ActivityEventNames.TriggerFired) {
      return triggerActivity;
    }
  };

  const renderTriggerEvent = (activity: ActivityModel) => (
    <>
      {activity.text == TriggerNames.Intent && <IntentRecognized name={activity.value.IntentName} />}
      {activity.text == TriggerNames.SigmaIntent && <SigmaIntentRecognized />}
      {activity.text == TriggerNames.MenuButton && <MenuItemSelected />}
      {activity.text == TriggerNames.UnknownIntent && <UnknownIntent />}
      {activity.text == TriggerNames.Transition && <TransitionTrigger />}
      {activity.text == TriggerNames.Terminal && <TerminalTrigger />}
      {activity.text == TriggerNames.ExternalEvent && <ExternalEventTrigger />}
      {activity.text == TriggerNames.Start && <StartTrigger />}
    </>
  );

  const renderActivity = (activity: ActivityModel) => (
    <div key={activity.id ?? ''}>
      {activity.type == ActivityTypes.Message && (
        <MessageBubble
          activity={activity}
          direction={activity.direction}
          getMenuItemSelected={getMenuItemSelected}
          isSelected={activity.id == selectedActivity?.id}
          onSelect={onMessageSelect}
        />
      )}
      {activity.name == ActivityEventNames.ScenarioStarted && <ScenarioStarted name={activity.text} />}
      {activity.name == ActivityEventNames.TriggerFired && renderTriggerEvent(activity)}
      {activity.name == ActivityEventNames.PromptAccepted && <PromptAccepted />}
      {activity.name == ActivityEventNames.TransferToOperatorInitiated && <TransferToOperatorInitiated />}
      {activity.name == ActivityEventNames.ScriptExecuted && (
        <ScriptExecuted activity={activity} onSelect={onMessageSelect} />
      )}
      {activity.name == ActivityEventNames.ErrorOccured && (
        <ErrorOccured activity={activity} onSelect={onMessageSelect} />
      )}
    </div>
  );

  const renderInfo = () => {
    if (selectedActivity?.name === ActivityEventNames.ErrorOccured) {
      return <ErrorInfo activity={selectedActivity} />;
    }

    if (selectedActivity?.name === ActivityEventNames.ScriptExecuted) {
      return <ScriptExecutionInfo activity={selectedActivity} />;
    }

    if (selectedActivity) {
      return (
        <MessageInfo
          activity={selectedActivity}
          getRecognizerResult={getRecognizerResult}
          getScenarioInfo={getScenarioInfo}
          getSigmaRecognizerResult={getSigmaRecognizerResult}
          getTriggerInfo={getTriggerInfo}
        />
      );
    }

    if (conversation) {
      return <DialogInfo conversation={conversation} />;
    }
  };

  const loadData = () => {
    setSelectedActivity(undefined);
    loadActivities(false).finally();
  };
  useEffect(loadData, [conversation]);

  if (!conversation) {
    return (
      <div className="sb-dialogs-card__content__panel-container_empty">
        <SbIcon iconName="comments" size={64} />
        <div>
          Выберите диалог, чтобы
          <br /> посмотреть его подробности
        </div>
      </div>
    );
  }

  if (loading) {
    return <SbSpin />;
  }

  return (
    <>
      <div className="sb-dialogs-card__content__panel-container__messages">
        <SbScroll id={DIALOG_MESSAGES_SCROLL_ID}>
          <InfiniteScroll
            dataLength={activities.length}
            hasMore={hasMore}
            loader={<Skeleton active />}
            next={loadActivities}
            scrollableTarget={DIALOG_MESSAGES_SCROLL_ID}
          >
            <>
              <SessionInfo timestamp={conversation.startedOn} />
              {activities.map((a) => renderActivity(a))}
              {conversation.status === ConversationStatus.Closed && (
                <SessionInfo isClosed timestamp={conversation.finishedOn} />
              )}
            </>
          </InfiniteScroll>
        </SbScroll>
      </div>
      <div className="sb-dialogs-card__content__panel-container__info">
        <SbScroll id={selectedActivity ? DIALOG_MESSAGE_INFO_SCROLL_ID : ''}>{renderInfo()}</SbScroll>
      </div>
    </>
  );
};

export default DialogHistory;
