/* tslint:disable */
/* eslint-disable */
/**
 * База знаний ELMA Bot
 * Управление базами знаний
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    name?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    description?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    version?: string | null;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    timestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    localTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    serviceUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    channelId?: string | null;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof Activity
     */
    from?: ChannelAccount;
    /**
     * 
     * @type {ConversationAccount}
     * @memberof Activity
     */
    conversation?: ConversationAccount;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof Activity
     */
    recipient?: ChannelAccount;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    textFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    attachmentLayout?: string | null;
    /**
     * 
     * @type {Array<ChannelAccount>}
     * @memberof Activity
     */
    membersAdded?: Array<ChannelAccount> | null;
    /**
     * 
     * @type {Array<ChannelAccount>}
     * @memberof Activity
     */
    membersRemoved?: Array<ChannelAccount> | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    topicName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Activity
     */
    historyDisclosed?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    speak?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    inputHint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    summary?: string | null;
    /**
     * 
     * @type {SuggestedActions}
     * @memberof Activity
     */
    suggestedActions?: SuggestedActions;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Activity
     */
    attachments?: Array<Attachment> | null;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof Activity
     */
    entities?: Array<Entity> | null;
    /**
     * 
     * @type {any}
     * @memberof Activity
     */
    channelData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    replyToId?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Activity
     */
    value?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    name?: string | null;
    /**
     * 
     * @type {ConversationReference}
     * @memberof Activity
     */
    relatesTo?: ConversationReference;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @interface ActivitySet
 */
export interface ActivitySet {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivitySet
     */
    activities?: Array<Activity> | null;
    /**
     * 
     * @type {string}
     * @memberof ActivitySet
     */
    watermark?: string | null;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    contentUrl?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Attachment
     */
    content?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    thumbnailUrl?: string | null;
}
/**
 * 
 * @export
 * @interface BinaryData
 */
export interface BinaryData {
    /**
     * 
     * @type {BinarySource}
     * @memberof BinaryData
     */
    binarySource?: BinarySource;
}
/**
 * 
 * @export
 * @interface BinaryKnowledgeData
 */
export interface BinaryKnowledgeData {
    /**
     * 
     * @type {BinarySourceFormat}
     * @memberof BinaryKnowledgeData
     */
    format?: BinarySourceFormat;
    /**
     * 
     * @type {string}
     * @memberof BinaryKnowledgeData
     */
    data?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BinaryKnowledgeData
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface BinarySource
 */
export interface BinarySource {
    /**
     * 
     * @type {BinarySourceFormat}
     * @memberof BinarySource
     */
    format?: BinarySourceFormat;
    /**
     * 
     * @type {string}
     * @memberof BinarySource
     */
    fileId?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BinarySourceFormat {
    Csv = 'Csv',
    Xls = 'Xls',
    Xlsx = 'Xlsx',
    Doc = 'Doc',
    Docx = 'Docx',
    Pdf = 'Pdf'
}

/**
 * 
 * @export
 * @interface CardAction
 */
export interface CardAction {
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    image?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CardAction
     */
    value?: any | null;
}
/**
 * 
 * @export
 * @interface ChannelAccount
 */
export interface ChannelAccount {
    /**
     * 
     * @type {string}
     * @memberof ChannelAccount
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelAccount
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    conversationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    token?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    expires_in?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    streamUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    referenceGrammarId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    eTag?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationAccount
 */
export interface ConversationAccount {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationAccount
     */
    isGroup?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccount
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccount
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationReference
 */
export interface ConversationReference {
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    activityId?: string | null;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof ConversationReference
     */
    user?: ChannelAccount;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof ConversationReference
     */
    bot?: ChannelAccount;
    /**
     * 
     * @type {ConversationAccount}
     * @memberof ConversationReference
     */
    conversation?: ConversationAccount;
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    channelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    serviceUrl?: string | null;
}
/**
 * 
 * @export
 * @interface DataEntryAddingRequest
 */
export interface DataEntryAddingRequest {
    /**
     * 
     * @type {DataType}
     * @memberof DataEntryAddingRequest
     */
    type: DataType;
    /**
     * Данные.
     * @type {any}
     * @memberof DataEntryAddingRequest
     */
    data: any | null;
    /**
     * ИД уточняющих вопросов.
     * @type {Array<string>}
     * @memberof DataEntryAddingRequest
     */
    childEntryIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface DataEntryDiagnosticsModel
 */
export interface DataEntryDiagnosticsModel {
    /**
     * Индекс вопроса.
     * @type {number}
     * @memberof DataEntryDiagnosticsModel
     */
    questionIndex: number;
    /**
     * 
     * @type {ReportRowModel}
     * @memberof DataEntryDiagnosticsModel
     */
    actualReportRow: ReportRowModel;
    /**
     * 
     * @type {DataEntryModel}
     * @memberof DataEntryDiagnosticsModel
     */
    relatedDataEntry: DataEntryModel;
}
/**
 * Модель записи в редакции.
 * @export
 * @interface DataEntryModel
 */
export interface DataEntryModel {
    /**
     * ИД записи.
     * @type {string}
     * @memberof DataEntryModel
     */
    id: string;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof DataEntryModel
     */
    knowledgeBaseId: string;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof DataEntryModel
     */
    editionId: string;
    /**
     * ИД источника.
     * @type {string}
     * @memberof DataEntryModel
     */
    knowledgeSourceId?: string | null;
    /**
     * 
     * @type {DataType}
     * @memberof DataEntryModel
     */
    type: DataType;
    /**
     * 
     * @type {object}
     * @memberof DataEntryModel
     */
    data: object;
    /**
     * ИД родительского элемента. Если null, то элемент корневой.
     * @type {string}
     * @memberof DataEntryModel
     */
    parentEntryId?: string | null;
    /**
     * ИД уточняющих вопросов.
     * @type {Array<string>}
     * @memberof DataEntryModel
     */
    childEntryIds?: Array<string> | null;
    /**
     * ИД записи, на основе которой была сделана копия.
     * @type {string}
     * @memberof DataEntryModel
     */
    sourceDataEntryId?: string | null;
    /**
     * Признак включения/отключения записи.
     * @type {boolean}
     * @memberof DataEntryModel
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DataEntrySortDirection {
    CreatedOnDescending = 'CreatedOnDescending',
    ModifiedOnDescending = 'ModifiedOnDescending',
    AnswerAscending = 'AnswerAscending',
    CreatedOnAscending = 'CreatedOnAscending',
    ModifiedOnAscending = 'ModifiedOnAscending',
    AnswerDescending = 'AnswerDescending'
}

/**
 * 
 * @export
 * @interface DataEntryUpdatingRequest
 */
export interface DataEntryUpdatingRequest {
    /**
     * 
     * @type {DataType}
     * @memberof DataEntryUpdatingRequest
     */
    type: DataType;
    /**
     * Данные.
     * @type {any}
     * @memberof DataEntryUpdatingRequest
     */
    data: any | null;
    /**
     * ИД уточняющих вопросов.
     * @type {Array<string>}
     * @memberof DataEntryUpdatingRequest
     */
    childEntryIds?: Array<string> | null;
    /**
     * Признак что запись необходимо удалить.
     * @type {boolean}
     * @memberof DataEntryUpdatingRequest
     */
    isArchived?: boolean;
    /**
     * Признак включения/отключения записи.
     * @type {boolean}
     * @memberof DataEntryUpdatingRequest
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DataType {
    QnA = 'QnA',
    Text = 'Text',
    Binary = 'Binary'
}

/**
 * Запрос на создание черновика.
 * @export
 * @interface DraftCreationRequest
 */
export interface DraftCreationRequest {
    /**
     * Название черновика.
     * @type {string}
     * @memberof DraftCreationRequest
     */
    name: string;
    /**
     * Описание черновика.
     * @type {string}
     * @memberof DraftCreationRequest
     */
    description?: string | null;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof DraftCreationRequest
     */
    knowledgeBaseId: string;
    /**
     * 
     * @type {KnowledgeData}
     * @memberof DraftCreationRequest
     */
    knowledgeData: KnowledgeData;
}
/**
 * Ответ на создание черновика.
 * @export
 * @interface DraftCreationResponse
 */
export interface DraftCreationResponse {
    /**
     * ИД черновика.
     * @type {string}
     * @memberof DraftCreationResponse
     */
    id?: string | null;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof DraftCreationResponse
     */
    editionId?: string | null;
}
/**
 * Модель черновика.
 * @export
 * @interface DraftModel
 */
export interface DraftModel {
    /**
     * ИД черновика.
     * @type {string}
     * @memberof DraftModel
     */
    id: string;
    /**
     * Название черновика.
     * @type {string}
     * @memberof DraftModel
     */
    name: string;
    /**
     * Описание черновика.
     * @type {string}
     * @memberof DraftModel
     */
    description?: string | null;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof DraftModel
     */
    knowledgeBaseId: string;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof DraftModel
     */
    editionId: string;
    /**
     * ИД экземпляра.
     * @type {string}
     * @memberof DraftModel
     */
    instanceId?: string | null;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof DraftModel
     */
    modifiedOn: string;
    /**
     * Дата создания.
     * @type {string}
     * @memberof DraftModel
     */
    createdOn: string;
}
/**
 * 
 * @export
 * @interface DraftModelPaginationResponse
 */
export interface DraftModelPaginationResponse {
    /**
     * 
     * @type {Array<DraftModel>}
     * @memberof DraftModelPaginationResponse
     */
    items?: Array<DraftModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DraftModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DraftModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DraftModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DraftModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof DraftModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Запрос на запуск тестирования черновика.
 * @export
 * @interface DraftTestRequest
 */
export interface DraftTestRequest {
    /**
     * 
     * @type {KnowledgeModel}
     * @memberof DraftTestRequest
     */
    model: KnowledgeModel;
}
/**
 * Запрос на обновление черновика.
 * @export
 * @interface DraftUpdatingRequest
 */
export interface DraftUpdatingRequest {
    /**
     * 
     * @type {KnowledgeData}
     * @memberof DraftUpdatingRequest
     */
    knowledgeData: KnowledgeData;
}
/**
 * Ответ на обновление черновика.
 * @export
 * @interface DraftUpdatingResponse
 */
export interface DraftUpdatingResponse {
    /**
     * ИД редакции.
     * @type {string}
     * @memberof DraftUpdatingResponse
     */
    editionId?: string | null;
}
/**
 * Модель редакции.
 * @export
 * @interface EditionModel
 */
export interface EditionModel {
    /**
     * ИД редакции.
     * @type {string}
     * @memberof EditionModel
     */
    id?: string | null;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof EditionModel
     */
    knowledgeBaseId?: string | null;
}
/**
 * 
 * @export
 * @interface EditionModelPaginationResponse
 */
export interface EditionModelPaginationResponse {
    /**
     * 
     * @type {Array<EditionModel>}
     * @memberof EditionModelPaginationResponse
     */
    items?: Array<EditionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditionModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EditionModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EditionModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EditionModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    type?: string | null;
}
/**
 * Модель события.
 * @export
 * @interface EventModel
 */
export interface EventModel {
    /**
     * ИД события.
     * @type {string}
     * @memberof EventModel
     */
    id?: string | null;
    /**
     * Тип события.
     * @type {string}
     * @memberof EventModel
     */
    type?: string | null;
    /**
     * Аргументы события.
     * @type {{ [key: string]: any; }}
     * @memberof EventModel
     */
    arguments?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface EventModelPaginationResponse
 */
export interface EventModelPaginationResponse {
    /**
     * 
     * @type {Array<EventModel>}
     * @memberof EventModelPaginationResponse
     */
    items?: Array<EventModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ExportFormat {
    Json = 'Json',
    Zip = 'Zip',
    Csv = 'Csv'
}

/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    status?: string | null;
}
/**
 * Модель экземпляра.
 * @export
 * @interface InstanceModel
 */
export interface InstanceModel {
    /**
     * ИД экземпляра.
     * @type {string}
     * @memberof InstanceModel
     */
    id: string;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof InstanceModel
     */
    knowledgeBaseId: string;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof InstanceModel
     */
    editionId: string;
    /**
     * 
     * @type {KnowledgeModel}
     * @memberof InstanceModel
     */
    model: KnowledgeModel;
    /**
     * 
     * @type {PublishStatus}
     * @memberof InstanceModel
     */
    status: PublishStatus;
    /**
     * Дата публикации.
     * @type {string}
     * @memberof InstanceModel
     */
    createdOn: string;
}
/**
 * 
 * @export
 * @interface InstanceModelPaginationResponse
 */
export interface InstanceModelPaginationResponse {
    /**
     * 
     * @type {Array<InstanceModel>}
     * @memberof InstanceModelPaginationResponse
     */
    items?: Array<InstanceModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstanceModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstanceModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstanceModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Запрос на создание базы знаний.
 * @export
 * @interface KnowledgeBaseCreationRequest
 */
export interface KnowledgeBaseCreationRequest {
    /**
     * Наименование базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseCreationRequest
     */
    name: string;
    /**
     * Описание базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseCreationRequest
     */
    description?: string | null;
    /**
     * 
     * @type {KnowledgeData}
     * @memberof KnowledgeBaseCreationRequest
     */
    knowledgeData?: KnowledgeData;
    /**
     * Настройка поиска когда нет контекста. True - только по корневым вопросам, False - по всем вопросам, включая уточняющие.
     * @type {boolean}
     * @memberof KnowledgeBaseCreationRequest
     */
    searchParentsOnlyIfNoContext?: boolean;
    /**
     * 
     * @type {KnowledgeBaseEntryType}
     * @memberof KnowledgeBaseCreationRequest
     */
    type?: KnowledgeBaseEntryType;
    /**
     * 
     * @type {TemplateInfo}
     * @memberof KnowledgeBaseCreationRequest
     */
    templateInfo?: TemplateInfo;
}
/**
 * Ответ на создание базы знаний.
 * @export
 * @interface KnowledgeBaseCreationResponse
 */
export interface KnowledgeBaseCreationResponse {
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseCreationResponse
     */
    id: string;
}
/**
 * Модель базы знаний.
 * @export
 * @interface KnowledgeBaseEntryModel
 */
export interface KnowledgeBaseEntryModel {
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseEntryModel
     */
    id: string;
    /**
     * Наименование базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseEntryModel
     */
    name: string;
    /**
     * Описание базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseEntryModel
     */
    description?: string | null;
    /**
     * 
     * @type {KnowledgeBaseEntryType}
     * @memberof KnowledgeBaseEntryModel
     */
    type: KnowledgeBaseEntryType;
    /**
     * 
     * @type {TemplateInfo}
     * @memberof KnowledgeBaseEntryModel
     */
    templateInfo?: TemplateInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum KnowledgeBaseEntryType {
    Default = 'Default',
    System = 'System',
    Private = 'Private'
}

/**
 * Запрос на обновление базы знаний.
 * @export
 * @interface KnowledgeBaseUpdatingRequest
 */
export interface KnowledgeBaseUpdatingRequest {
    /**
     * Наименование базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseUpdatingRequest
     */
    name: string;
    /**
     * Описание базы знаний.
     * @type {string}
     * @memberof KnowledgeBaseUpdatingRequest
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface KnowledgeData
 */
export interface KnowledgeData {
    /**
     * 
     * @type {KnowledgeDataType}
     * @memberof KnowledgeData
     */
    type?: KnowledgeDataType;
    /**
     * 
     * @type {BinaryKnowledgeData}
     * @memberof KnowledgeData
     */
    binaryData?: BinaryKnowledgeData;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeData
     */
    editionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeData
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum KnowledgeDataType {
    Binary = 'Binary',
    Reference = 'Reference',
    WebPageUrl = 'WebPageUrl',
    ResourceUrl = 'ResourceUrl',
    ElmaResourceUrl = 'ElmaResourceUrl',
    GoogleDocUrl = 'GoogleDocUrl',
    GoogleSpreadSheetUrl = 'GoogleSpreadSheetUrl'
}

/**
 * 
 * @export
 * @interface KnowledgeModel
 */
export interface KnowledgeModel {
    /**
     * 
     * @type {KnowledgeModelPipeline}
     * @memberof KnowledgeModel
     */
    pipeline?: KnowledgeModelPipeline;
    /**
     * 
     * @type {KnowledgeModelRanking}
     * @memberof KnowledgeModel
     */
    ranking?: KnowledgeModelRanking;
}
/**
 * 
 * @export
 * @interface KnowledgeModelPipeline
 */
export interface KnowledgeModelPipeline {
    /**
     * 
     * @type {string}
     * @memberof KnowledgeModelPipeline
     */
    modelType?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KnowledgeModelPipeline
     */
    parameters?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeModelPipeline
     */
    parametersHash?: string | null;
}
/**
 * 
 * @export
 * @interface KnowledgeModelRanking
 */
export interface KnowledgeModelRanking {
    /**
     * 
     * @type {string}
     * @memberof KnowledgeModelRanking
     */
    storageType?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof KnowledgeModelRanking
     */
    parameters?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeModelRanking
     */
    parametersHash?: string | null;
}
/**
 * 
 * @export
 * @interface KnowledgeSourceAddingRequest
 */
export interface KnowledgeSourceAddingRequest {
    /**
     * 
     * @type {KnowledgeData}
     * @memberof KnowledgeSourceAddingRequest
     */
    knowledgeData: KnowledgeData;
}
/**
 * Модель источника базы знаний.
 * @export
 * @interface KnowledgeSourceModel
 */
export interface KnowledgeSourceModel {
    /**
     * ИД записи.
     * @type {string}
     * @memberof KnowledgeSourceModel
     */
    id: string;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof KnowledgeSourceModel
     */
    knowledgeBaseId: string;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof KnowledgeSourceModel
     */
    editionId: string;
    /**
     * 
     * @type {ParsingStatus}
     * @memberof KnowledgeSourceModel
     */
    parsingStatus: ParsingStatus;
    /**
     * 
     * @type {KnowledgeSourceType}
     * @memberof KnowledgeSourceModel
     */
    type: KnowledgeSourceType;
    /**
     * 
     * @type {BinarySource}
     * @memberof KnowledgeSourceModel
     */
    binarySource?: BinarySource;
    /**
     * Url с исходными данными (web-страница, ссылка на файл)
     * @type {string}
     * @memberof KnowledgeSourceModel
     */
    url?: string | null;
    /**
     * Название.
     * @type {string}
     * @memberof KnowledgeSourceModel
     */
    name?: string | null;
    /**
     * ИД источника, на основе которой была сделана копия.
     * @type {string}
     * @memberof KnowledgeSourceModel
     */
    sourceKnowledgeSourceId?: string | null;
}
/**
 * 
 * @export
 * @interface KnowledgeSourceModelPaginationResponse
 */
export interface KnowledgeSourceModelPaginationResponse {
    /**
     * 
     * @type {Array<KnowledgeSourceModel>}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    items?: Array<KnowledgeSourceModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeSourceModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface KnowledgeSourceReplacingRequest
 */
export interface KnowledgeSourceReplacingRequest {
    /**
     * 
     * @type {KnowledgeData}
     * @memberof KnowledgeSourceReplacingRequest
     */
    knowledgeData: KnowledgeData;
    /**
     * Заменяемый источник базы знаний.
     * @type {string}
     * @memberof KnowledgeSourceReplacingRequest
     */
    replaceableKnowledgeSourceId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum KnowledgeSourceType {
    Binary = 'Binary',
    WebPageUrl = 'WebPageUrl',
    ResourceUrl = 'ResourceUrl',
    ElmaResourceUrl = 'ElmaResourceUrl',
    GoogleDocUrl = 'GoogleDocUrl',
    GoogleSpreadSheetUrl = 'GoogleSpreadSheetUrl'
}

/**
 * Запрос на валидацию источника базы знаний.
 * @export
 * @interface KnowledgeSourceValidationRequest
 */
export interface KnowledgeSourceValidationRequest {
    /**
     * 
     * @type {BinaryKnowledgeData}
     * @memberof KnowledgeSourceValidationRequest
     */
    binaryData?: BinaryKnowledgeData;
    /**
     * Ссылка на веб-страницу.
     * @type {string}
     * @memberof KnowledgeSourceValidationRequest
     */
    webPageUrl?: string | null;
    /**
     * Ссылка на файл elma.
     * @type {string}
     * @memberof KnowledgeSourceValidationRequest
     */
    elmaResourceUrl?: string | null;
    /**
     * Ссылка на файл.
     * @type {string}
     * @memberof KnowledgeSourceValidationRequest
     */
    resourceUrl?: string | null;
    /**
     * Ссылка на GoogleDoc.
     * @type {string}
     * @memberof KnowledgeSourceValidationRequest
     */
    googleDocUrl?: string | null;
    /**
     * Ссылка на GoogleSpreadSheet.
     * @type {string}
     * @memberof KnowledgeSourceValidationRequest
     */
    googleSpreadSheetUrl?: string | null;
}
/**
 * Результат валидации источника базы знаний.
 * @export
 * @interface KnowledgeSourceValidationResponse
 */
export interface KnowledgeSourceValidationResponse {
    /**
     * Признак валидности источника базы знаний.
     * @type {boolean}
     * @memberof KnowledgeSourceValidationResponse
     */
    isValid: boolean;
    /**
     * Текст ошибки валидации источника базы знаний.
     * @type {string}
     * @memberof KnowledgeSourceValidationResponse
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface ListDataEntryModel
 */
export interface ListDataEntryModel {
    /**
     * 
     * @type {DataEntryModel}
     * @memberof ListDataEntryModel
     */
    entry: DataEntryModel;
    /**
     * Данные диагностики.
     * @type {Array<DataEntryDiagnosticsModel>}
     * @memberof ListDataEntryModel
     */
    diagnostics: Array<DataEntryDiagnosticsModel>;
}
/**
 * 
 * @export
 * @interface ListDataEntryModelPaginationResponse
 */
export interface ListDataEntryModelPaginationResponse {
    /**
     * 
     * @type {Array<ListDataEntryModel>}
     * @memberof ListDataEntryModelPaginationResponse
     */
    items?: Array<ListDataEntryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListDataEntryModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListDataEntryModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListDataEntryModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ListDataEntryModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDataEntryModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListDataEntryModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ListDataEntryModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Упрощенная модель базы знаний.
 * @export
 * @interface ListKnowledgeBaseModel
 */
export interface ListKnowledgeBaseModel {
    /**
     * 
     * @type {KnowledgeBaseEntryModel}
     * @memberof ListKnowledgeBaseModel
     */
    entry: KnowledgeBaseEntryModel;
}
/**
 * 
 * @export
 * @interface ListKnowledgeBaseModelPaginationResponse
 */
export interface ListKnowledgeBaseModelPaginationResponse {
    /**
     * 
     * @type {Array<ListKnowledgeBaseModel>}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    items?: Array<ListKnowledgeBaseModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ListKnowledgeBaseModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface ParsingStatus
 */
export interface ParsingStatus {
    /**
     * 
     * @type {ParsingStatusState}
     * @memberof ParsingStatus
     */
    state?: ParsingStatusState;
    /**
     * 
     * @type {string}
     * @memberof ParsingStatus
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ParsingStatusState {
    Pending = 'Pending',
    Processing = 'Processing',
    Success = 'Success',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface PublishOperation
 */
export interface PublishOperation {
    /**
     * 
     * @type {string}
     * @memberof PublishOperation
     */
    operationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublishOperation
     */
    indexId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublishOperation
     */
    indexType?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublishOperation
     */
    batchIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PublishOperation
     */
    currentBatchId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublishOperation
     */
    currentBatchIndex?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PublishOperation
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublishOperation
     */
    currentProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof PublishOperation
     */
    maximumProgress?: number;
    /**
     * 
     * @type {string}
     * @memberof PublishOperation
     */
    problemDetail?: string | null;
}
/**
 * 
 * @export
 * @interface PublishOperationsState
 */
export interface PublishOperationsState {
    /**
     * 
     * @type {PublishOperation}
     * @memberof PublishOperationsState
     */
    indexCreationOperation?: PublishOperation;
    /**
     * 
     * @type {PublishOperation}
     * @memberof PublishOperationsState
     */
    qnaEntriesUpdatingOperation?: PublishOperation;
    /**
     * 
     * @type {PublishOperation}
     * @memberof PublishOperationsState
     */
    reportGenerationOperation?: PublishOperation;
}
/**
 * 
 * @export
 * @interface PublishStatus
 */
export interface PublishStatus {
    /**
     * 
     * @type {PublishStatusState}
     * @memberof PublishStatus
     */
    state?: PublishStatusState;
    /**
     * 
     * @type {string}
     * @memberof PublishStatus
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublishStatus
     */
    indexId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublishStatus
     */
    reportId?: string | null;
    /**
     * 
     * @type {PublishOperationsState}
     * @memberof PublishStatus
     */
    operations?: PublishOperationsState;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PublishStatusState {
    Pending = 'Pending',
    Processing = 'Processing',
    Success = 'Success',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface QnAData
 */
export interface QnAData {
    /**
     * 
     * @type {Array<string>}
     * @memberof QnAData
     */
    questions?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof QnAData
     */
    answer?: string | null;
}
/**
 * 
 * @export
 * @interface RecognizedValueModel
 */
export interface RecognizedValueModel {
    /**
     * 
     * @type {DataEntryModel}
     * @memberof RecognizedValueModel
     */
    dataEntry: DataEntryModel;
    /**
     * 
     * @type {string}
     * @memberof RecognizedValueModel
     */
    question?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecognizedValueModel
     */
    score: number;
    /**
     * 
     * @type {Array<RecognizedValueModel>}
     * @memberof RecognizedValueModel
     */
    children: Array<RecognizedValueModel>;
}
/**
 * 
 * @export
 * @interface RecognizerSettings
 */
export interface RecognizerSettings {
    /**
     * 
     * @type {boolean}
     * @memberof RecognizerSettings
     */
    enabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecognizerSettings
     */
    threshold?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RecognizerSettings
     */
    searchParentsOnlyIfNoContext?: boolean;
}
/**
 * Модель отчета.
 * @export
 * @interface ReportModel
 */
export interface ReportModel {
    /**
     * ИД отчета.
     * @type {string}
     * @memberof ReportModel
     */
    id?: string | null;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof ReportModel
     */
    knowledgeBaseId?: string | null;
    /**
     * ИД экземпляра.
     * @type {string}
     * @memberof ReportModel
     */
    instanceId?: string | null;
    /**
     * Тип метрики.
     * @type {string}
     * @memberof ReportModel
     */
    metricType?: string | null;
}
/**
 * 
 * @export
 * @interface ReportModelPaginationResponse
 */
export interface ReportModelPaginationResponse {
    /**
     * 
     * @type {Array<ReportModel>}
     * @memberof ReportModelPaginationResponse
     */
    items?: Array<ReportModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Модель записи из отчета.
 * @export
 * @interface ReportRowModel
 */
export interface ReportRowModel {
    /**
     * ИД записи из отчета.
     * @type {string}
     * @memberof ReportRowModel
     */
    id?: string | null;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof ReportRowModel
     */
    knowledgeBaseId?: string | null;
    /**
     * ИД отчета.
     * @type {string}
     * @memberof ReportRowModel
     */
    reportId?: string | null;
    /**
     * Правило.
     * @type {string}
     * @memberof ReportRowModel
     */
    rule?: string | null;
    /**
     * Сообщение.
     * @type {string}
     * @memberof ReportRowModel
     */
    message?: string | null;
    /**
     * ИД исходного ответа.
     * @type {string}
     * @memberof ReportRowModel
     */
    sourceAnswerId?: string | null;
    /**
     * Текст исходного ответа.
     * @type {string}
     * @memberof ReportRowModel
     */
    sourceAnswerText?: string | null;
    /**
     * ИД исходного вопроса.
     * @type {number}
     * @memberof ReportRowModel
     */
    sourceQuestionId?: number;
    /**
     * Текст исходного вопроса.
     * @type {string}
     * @memberof ReportRowModel
     */
    sourceQuestionText?: string | null;
    /**
     * Score.
     * @type {number}
     * @memberof ReportRowModel
     */
    score?: number;
    /**
     * ИД целевого ответа.
     * @type {string}
     * @memberof ReportRowModel
     */
    targetAnswerId?: string | null;
    /**
     * Текст целевого ответа.
     * @type {string}
     * @memberof ReportRowModel
     */
    targetAnswerText?: string | null;
    /**
     * ИД целевого вопроса.
     * @type {number}
     * @memberof ReportRowModel
     */
    targetQuestionId?: number;
    /**
     * Текст целевого вопроса.
     * @type {string}
     * @memberof ReportRowModel
     */
    targetQuestionText?: string | null;
}
/**
 * 
 * @export
 * @interface ReportRowModelPaginationResponse
 */
export interface ReportRowModelPaginationResponse {
    /**
     * 
     * @type {Array<ReportRowModel>}
     * @memberof ReportRowModelPaginationResponse
     */
    items?: Array<ReportRowModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportRowModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportRowModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRowModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRowModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportRowModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportRowModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportRowModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface ResourceResponse
 */
export interface ResourceResponse {
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    id?: string | null;
}
/**
 * Полная модель базы знаний.
 * @export
 * @interface SingleKnowledgeBaseModel
 */
export interface SingleKnowledgeBaseModel {
    /**
     * 
     * @type {KnowledgeBaseEntryModel}
     * @memberof SingleKnowledgeBaseModel
     */
    entry: KnowledgeBaseEntryModel;
    /**
     * 
     * @type {StageModel}
     * @memberof SingleKnowledgeBaseModel
     */
    originStage: StageModel;
    /**
     * 
     * @type {VersionModel}
     * @memberof SingleKnowledgeBaseModel
     */
    originVersion: VersionModel;
    /**
     * 
     * @type {InstanceModel}
     * @memberof SingleKnowledgeBaseModel
     */
    originInstance: InstanceModel;
    /**
     * Источники версии релизной стадии.
     * @type {Array<KnowledgeSourceModel>}
     * @memberof SingleKnowledgeBaseModel
     */
    originKnowledgeSources: Array<KnowledgeSourceModel>;
    /**
     * Количество записей в релизной стадии.
     * @type {number}
     * @memberof SingleKnowledgeBaseModel
     */
    originDataEntryCount?: number | null;
    /**
     * 
     * @type {StageModel}
     * @memberof SingleKnowledgeBaseModel
     */
    draftStage?: StageModel;
    /**
     * 
     * @type {VersionModel}
     * @memberof SingleKnowledgeBaseModel
     */
    draftVersion?: VersionModel;
    /**
     * 
     * @type {InstanceModel}
     * @memberof SingleKnowledgeBaseModel
     */
    draftInstance?: InstanceModel;
    /**
     * Источники версии черновой стадии.
     * @type {Array<KnowledgeSourceModel>}
     * @memberof SingleKnowledgeBaseModel
     */
    draftKnowledgeSources: Array<KnowledgeSourceModel>;
    /**
     * Количество записей в черновой стадии.
     * @type {number}
     * @memberof SingleKnowledgeBaseModel
     */
    draftDataEntryCount?: number | null;
}
/**
 * Полная модель отчета.
 * @export
 * @interface SingleReportModel
 */
export interface SingleReportModel {
    /**
     * 
     * @type {ReportModel}
     * @memberof SingleReportModel
     */
    reportEntry?: ReportModel;
    /**
     * Количество записей в отчете.
     * @type {number}
     * @memberof SingleReportModel
     */
    reportRowCount?: number | null;
}
/**
 * 
 * @export
 * @interface StageMergeRequest
 */
export interface StageMergeRequest {
    /**
     * Исходная стадия базы знаний.
     * @type {string}
     * @memberof StageMergeRequest
     */
    sourceStageId: string;
}
/**
 * Модель стадии.
 * @export
 * @interface StageModel
 */
export interface StageModel {
    /**
     * ИД стадии.
     * @type {string}
     * @memberof StageModel
     */
    id: string;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof StageModel
     */
    knowledgeBaseId: string;
    /**
     * ИД версии.
     * @type {string}
     * @memberof StageModel
     */
    versionId: string;
    /**
     * 
     * @type {StageType}
     * @memberof StageModel
     */
    type: StageType;
    /**
     * Имя стадии.
     * @type {string}
     * @memberof StageModel
     */
    name: string;
    /**
     * Имя базы знаний.
     * @type {string}
     * @memberof StageModel
     */
    knowledgeBaseName?: string | null;
    /**
     * 
     * @type {RecognizerSettings}
     * @memberof StageModel
     */
    recognizerSettings?: RecognizerSettings;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof StageModel
     */
    modifiedOn: string;
}
/**
 * Запрос на публикацию стадии.
 * @export
 * @interface StagePublishRequest
 */
export interface StagePublishRequest {
    /**
     * ИД версии.
     * @type {string}
     * @memberof StagePublishRequest
     */
    versionId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StageType {
    None = 'None',
    Draft = 'Draft',
    Origin = 'Origin'
}

/**
 * Запрос на обновление стадии базы знаний.
 * @export
 * @interface StageUpdatingRequest
 */
export interface StageUpdatingRequest {
    /**
     * 
     * @type {RecognizerSettings}
     * @memberof StageUpdatingRequest
     */
    recognizerSettings?: RecognizerSettings;
}
/**
 * 
 * @export
 * @interface SuggestedActions
 */
export interface SuggestedActions {
    /**
     * 
     * @type {Array<string>}
     * @memberof SuggestedActions
     */
    to?: Array<string> | null;
    /**
     * 
     * @type {Array<CardAction>}
     * @memberof SuggestedActions
     */
    actions?: Array<CardAction> | null;
}
/**
 * 
 * @export
 * @interface TemplateInfo
 */
export interface TemplateInfo {
    /**
     * 
     * @type {string}
     * @memberof TemplateInfo
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemplateInfo
     */
    version?: string | null;
}
/**
 * Модель шаблона базы знаний.
 * @export
 * @interface TemplateModel
 */
export interface TemplateModel {
    /**
     * Группа. (Пока только \"chitchat\")
     * @type {string}
     * @memberof TemplateModel
     */
    group: string;
    /**
     * Код шаблона.
     * @type {string}
     * @memberof TemplateModel
     */
    code: string;
    /**
     * Наименование файла.
     * @type {string}
     * @memberof TemplateModel
     */
    file: string;
    /**
     * Наименование шаблона.
     * @type {string}
     * @memberof TemplateModel
     */
    name: string;
    /**
     * Описание шаблона.
     * @type {string}
     * @memberof TemplateModel
     */
    description: string;
    /**
     * Версия шаблона.
     * @type {string}
     * @memberof TemplateModel
     */
    version: string;
}
/**
 * 
 * @export
 * @interface TextData
 */
export interface TextData {
    /**
     * 
     * @type {string}
     * @memberof TextData
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface TokenParameters
 */
export interface TokenParameters {
    /**
     * 
     * @type {ChannelAccount}
     * @memberof TokenParameters
     */
    user?: ChannelAccount;
    /**
     * 
     * @type {string}
     * @memberof TokenParameters
     */
    eTag?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    errors?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    instance?: string | null;
}
/**
 * Запрос на создание версии.
 * @export
 * @interface VersionCreationRequest
 */
export interface VersionCreationRequest {
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof VersionCreationRequest
     */
    knowledgeBaseId: string;
    /**
     * Наименование версии.
     * @type {string}
     * @memberof VersionCreationRequest
     */
    name: string;
    /**
     * Описание версии.
     * @type {string}
     * @memberof VersionCreationRequest
     */
    description?: string | null;
    /**
     * 
     * @type {KnowledgeData}
     * @memberof VersionCreationRequest
     */
    knowledgeData: KnowledgeData;
}
/**
 * Ответ на создание версии.
 * @export
 * @interface VersionCreationResponse
 */
export interface VersionCreationResponse {
    /**
     * ИД версии.
     * @type {string}
     * @memberof VersionCreationResponse
     */
    id?: string | null;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof VersionCreationResponse
     */
    editionId?: string | null;
}
/**
 * Модель версии.
 * @export
 * @interface VersionModel
 */
export interface VersionModel {
    /**
     * ИД версии.
     * @type {string}
     * @memberof VersionModel
     */
    id: string;
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof VersionModel
     */
    knowledgeBaseId: string;
    /**
     * ИД редакции.
     * @type {string}
     * @memberof VersionModel
     */
    editionId: string;
    /**
     * ИД экземпляра.
     * @type {string}
     * @memberof VersionModel
     */
    instanceId: string;
    /**
     * Наименование версии.
     * @type {string}
     * @memberof VersionModel
     */
    name: string;
    /**
     * Описание версии.
     * @type {string}
     * @memberof VersionModel
     */
    description?: string | null;
    /**
     * Дата создания версии.
     * @type {string}
     * @memberof VersionModel
     */
    createdOn: string;
}
/**
 * 
 * @export
 * @interface VersionModelPaginationResponse
 */
export interface VersionModelPaginationResponse {
    /**
     * 
     * @type {Array<VersionModel>}
     * @memberof VersionModelPaginationResponse
     */
    items?: Array<VersionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof VersionModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VersionModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VersionModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VersionModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModelPaginationResponse
     */
    toItemExclusive?: number;
}

/**
 * ApplicationKbApi - axios parameter creator
 * @export
 */
export const ApplicationKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationKbApi - functional programming interface
 * @export
 */
export const ApplicationKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAboutInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationKbApi - factory interface
 * @export
 */
export const ApplicationKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return localVarFp.getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return localVarFp.getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationKbApi - object-oriented interface
 * @export
 * @class ApplicationKbApi
 * @extends {BaseAPI}
 */
export class ApplicationKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationKbApi
     */
    public getAboutInfo(options?: any) {
        return ApplicationKbApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationKbApi
     */
    public getHealthInfo(options?: any) {
        return ApplicationKbApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationKbApi - axios parameter creator
 * @export
 */
export const ConfigurationKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationKbApi - functional programming interface
 * @export
 */
export const ConfigurationKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationKbApi - factory interface
 * @export
 */
export const ConfigurationKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return localVarFp.getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationKbApi - object-oriented interface
 * @export
 * @class ConfigurationKbApi
 * @extends {BaseAPI}
 */
export class ConfigurationKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationKbApi
     */
    public getClientConfig(options?: any) {
        return ConfigurationKbApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataEntryKbApi - axios parameter creator
 * @export
 */
export const DataEntryKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список дочерних записей.
         * @param {string} id ИД записи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChildren', 'id', id)
            const localVarPath = `/api/v1/entries/{id}/children`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить запись редакции.
         * @param {string} id ИД записи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataEntry: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDataEntry', 'id', id)
            const localVarPath = `/api/v1/entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск записей в редакции.
         * @param {string} [reportInstanceId] ИД экземпляра для данных диагностики.
         * @param {string} [editionId] ИД редакции.
         * @param {string} [knowledgeSourceId] ИД источника данных.
         * @param {DataType} [dataType] Тип данных.
         * @param {boolean} [onlyRootEntries] True - только корневые записи. False/Null - все.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [sourceDataEntryId] ИД записи, на основе которой была сделана копия.
         * @param {boolean} [enabled] Признак включения/отключения записи.
         * @param {DataEntrySortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDataEntries: async (reportInstanceId?: string, editionId?: string, knowledgeSourceId?: string, dataType?: DataType, onlyRootEntries?: boolean, searchText?: string, sourceDataEntryId?: string, enabled?: boolean, sorting?: DataEntrySortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (reportInstanceId !== undefined) {
                localVarQueryParameter['reportInstanceId'] = reportInstanceId;
            }

            if (editionId !== undefined) {
                localVarQueryParameter['EditionId'] = editionId;
            }

            if (knowledgeSourceId !== undefined) {
                localVarQueryParameter['KnowledgeSourceId'] = knowledgeSourceId;
            }

            if (dataType !== undefined) {
                localVarQueryParameter['DataType'] = dataType;
            }

            if (onlyRootEntries !== undefined) {
                localVarQueryParameter['OnlyRootEntries'] = onlyRootEntries;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (sourceDataEntryId !== undefined) {
                localVarQueryParameter['SourceDataEntryId'] = sourceDataEntryId;
            }

            if (enabled !== undefined) {
                localVarQueryParameter['Enabled'] = enabled;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataEntryKbApi - functional programming interface
 * @export
 */
export const DataEntryKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataEntryKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список дочерних записей.
         * @param {string} id ИД записи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildren(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DataEntryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChildren(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить запись редакции.
         * @param {string} id ИД записи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataEntry(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск записей в редакции.
         * @param {string} [reportInstanceId] ИД экземпляра для данных диагностики.
         * @param {string} [editionId] ИД редакции.
         * @param {string} [knowledgeSourceId] ИД источника данных.
         * @param {DataType} [dataType] Тип данных.
         * @param {boolean} [onlyRootEntries] True - только корневые записи. False/Null - все.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [sourceDataEntryId] ИД записи, на основе которой была сделана копия.
         * @param {boolean} [enabled] Признак включения/отключения записи.
         * @param {DataEntrySortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDataEntries(reportInstanceId?: string, editionId?: string, knowledgeSourceId?: string, dataType?: DataType, onlyRootEntries?: boolean, searchText?: string, sourceDataEntryId?: string, enabled?: boolean, sorting?: DataEntrySortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListDataEntryModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDataEntries(reportInstanceId, editionId, knowledgeSourceId, dataType, onlyRootEntries, searchText, sourceDataEntryId, enabled, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataEntryKbApi - factory interface
 * @export
 */
export const DataEntryKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataEntryKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список дочерних записей.
         * @param {string} id ИД записи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildren(id: string, options?: any): AxiosPromise<Array<DataEntryModel>> {
            return localVarFp.getChildren(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить запись редакции.
         * @param {string} id ИД записи.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataEntry(id: string, options?: any): AxiosPromise<DataEntryModel> {
            return localVarFp.getDataEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск записей в редакции.
         * @param {string} [reportInstanceId] ИД экземпляра для данных диагностики.
         * @param {string} [editionId] ИД редакции.
         * @param {string} [knowledgeSourceId] ИД источника данных.
         * @param {DataType} [dataType] Тип данных.
         * @param {boolean} [onlyRootEntries] True - только корневые записи. False/Null - все.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [sourceDataEntryId] ИД записи, на основе которой была сделана копия.
         * @param {boolean} [enabled] Признак включения/отключения записи.
         * @param {DataEntrySortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDataEntries(reportInstanceId?: string, editionId?: string, knowledgeSourceId?: string, dataType?: DataType, onlyRootEntries?: boolean, searchText?: string, sourceDataEntryId?: string, enabled?: boolean, sorting?: DataEntrySortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListDataEntryModelPaginationResponse> {
            return localVarFp.searchDataEntries(reportInstanceId, editionId, knowledgeSourceId, dataType, onlyRootEntries, searchText, sourceDataEntryId, enabled, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataEntryKbApi - object-oriented interface
 * @export
 * @class DataEntryKbApi
 * @extends {BaseAPI}
 */
export class DataEntryKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить список дочерних записей.
     * @param {string} id ИД записи.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataEntryKbApi
     */
    public getChildren(id: string, options?: any) {
        return DataEntryKbApiFp(this.configuration).getChildren(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить запись редакции.
     * @param {string} id ИД записи.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataEntryKbApi
     */
    public getDataEntry(id: string, options?: any) {
        return DataEntryKbApiFp(this.configuration).getDataEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск записей в редакции.
     * @param {string} [reportInstanceId] ИД экземпляра для данных диагностики.
     * @param {string} [editionId] ИД редакции.
     * @param {string} [knowledgeSourceId] ИД источника данных.
     * @param {DataType} [dataType] Тип данных.
     * @param {boolean} [onlyRootEntries] True - только корневые записи. False/Null - все.
     * @param {string} [searchText] Текст поиска.
     * @param {string} [sourceDataEntryId] ИД записи, на основе которой была сделана копия.
     * @param {boolean} [enabled] Признак включения/отключения записи.
     * @param {DataEntrySortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataEntryKbApi
     */
    public searchDataEntries(reportInstanceId?: string, editionId?: string, knowledgeSourceId?: string, dataType?: DataType, onlyRootEntries?: boolean, searchText?: string, sourceDataEntryId?: string, enabled?: boolean, sorting?: DataEntrySortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return DataEntryKbApiFp(this.configuration).searchDataEntries(reportInstanceId, editionId, knowledgeSourceId, dataType, onlyRootEntries, searchText, sourceDataEntryId, enabled, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectLineKbApi - axios parameter creator
 * @export
 */
export const DirectLineKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {TokenParameters} [tokenParameters] Запрос на создание беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation: async (instanceId: string, tokenParameters?: TokenParameters, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('createConversation', 'instanceId', instanceId)
            const localVarPath = `/api/v1/directline/{instanceId}/conversations`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} instanceId ИД экземпляра.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken: async (instanceId: string, tokenParameters?: TokenParameters, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('generateToken', 'instanceId', instanceId)
            const localVarPath = `/api/v1/directline/{instanceId}/tokens/generate`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (instanceId: string, conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getActivities', 'instanceId', instanceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getActivities', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{instanceId}/conversations/{conversationId}/activities`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (instanceId: string, conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getConversation', 'instanceId', instanceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{instanceId}/conversations/{conversationId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} instanceId ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (instanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('refreshToken', 'instanceId', instanceId)
            const localVarPath = `/api/v1/directline/{instanceId}/tokens/refresh`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivity: async (instanceId: string, conversationId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('sendActivity', 'instanceId', instanceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{instanceId}/conversations/{conversationId}/activities`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (instanceId: string, conversationId: string, file?: Array<any>, activity?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('upload', 'instanceId', instanceId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('upload', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{instanceId}/conversations/{conversationId}/upload`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (activity !== undefined) { 
                localVarFormParams.append('activity', activity as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineKbApi - functional programming interface
 * @export
 */
export const DirectLineKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {TokenParameters} [tokenParameters] Запрос на создание беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConversation(instanceId: string, tokenParameters?: TokenParameters, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConversation(instanceId, tokenParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} instanceId ИД экземпляра.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateToken(instanceId: string, tokenParameters?: TokenParameters, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateToken(instanceId, tokenParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(instanceId: string, conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(instanceId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(instanceId: string, conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(instanceId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} instanceId ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(instanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить действие в беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendActivity(instanceId: string, conversationId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendActivity(instanceId, conversationId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(instanceId: string, conversationId: string, file?: Array<any>, activity?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(instanceId, conversationId, file, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectLineKbApi - factory interface
 * @export
 */
export const DirectLineKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {TokenParameters} [tokenParameters] Запрос на создание беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConversation(instanceId: string, tokenParameters?: TokenParameters, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createConversation(instanceId, tokenParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} instanceId ИД экземпляра.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateToken(instanceId: string, tokenParameters?: TokenParameters, options?: any): AxiosPromise<Conversation> {
            return localVarFp.generateToken(instanceId, tokenParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(instanceId: string, conversationId: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getActivities(instanceId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(instanceId: string, conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getConversation(instanceId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} instanceId ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(instanceId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshToken(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivity(instanceId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.sendActivity(instanceId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} instanceId ИД экземпляра.
         * @param {string} conversationId ИД беседы.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(instanceId: string, conversationId: string, file?: Array<any>, activity?: any, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.upload(instanceId, conversationId, file, activity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineKbApi - object-oriented interface
 * @export
 * @class DirectLineKbApi
 * @extends {BaseAPI}
 */
export class DirectLineKbApi extends BaseAPI {
    /**
     * 
     * @summary Создать беседу.
     * @param {string} instanceId ИД экземпляра.
     * @param {TokenParameters} [tokenParameters] Запрос на создание беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public createConversation(instanceId: string, tokenParameters?: TokenParameters, options?: any) {
        return DirectLineKbApiFp(this.configuration).createConversation(instanceId, tokenParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сгенерировать токен.
     * @param {string} instanceId ИД экземпляра.
     * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public generateToken(instanceId: string, tokenParameters?: TokenParameters, options?: any) {
        return DirectLineKbApiFp(this.configuration).generateToken(instanceId, tokenParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} instanceId ИД экземпляра.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public getActivities(instanceId: string, conversationId: string, options?: any) {
        return DirectLineKbApiFp(this.configuration).getActivities(instanceId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} instanceId ИД экземпляра.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public getConversation(instanceId: string, conversationId: string, options?: any) {
        return DirectLineKbApiFp(this.configuration).getConversation(instanceId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} instanceId ИД экземпляра.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public refreshToken(instanceId: string, options?: any) {
        return DirectLineKbApiFp(this.configuration).refreshToken(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в беседу.
     * @param {string} instanceId ИД экземпляра.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на отправку действия в беседу.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public sendActivity(instanceId: string, conversationId: string, activity?: Activity, options?: any) {
        return DirectLineKbApiFp(this.configuration).sendActivity(instanceId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить файл в беседу.
     * @param {string} instanceId ИД экземпляра.
     * @param {string} conversationId ИД беседы.
     * @param {Array<any>} [file] 
     * @param {any} [activity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineKbApi
     */
    public upload(instanceId: string, conversationId: string, file?: Array<any>, activity?: any, options?: any) {
        return DirectLineKbApiFp(this.configuration).upload(instanceId, conversationId, file, activity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DraftKbApi - axios parameter creator
 * @export
 */
export const DraftKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать черновик.
         * @param {DraftCreationRequest} [draftCreationRequest] Запрос на создание черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraft: async (draftCreationRequest?: DraftCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(draftCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить черновик.
         * @param {string} id ИД черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDraft', 'id', id)
            const localVarPath = `/api/v1/drafts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактировать черновик.
         * @param {string} id ИД черновика.
         * @param {object} [body] Запрос на редактирование черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDraft: async (id: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editDraft', 'id', id)
            const localVarPath = `/api/v1/drafts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить черновик.
         * @param {string} id ИД черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraft: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDraft', 'id', id)
            const localVarPath = `/api/v1/drafts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск черновиков.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDrafts: async (knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запуск тестирования черновика.
         * @param {string} id ИД черновика.
         * @param {DraftTestRequest} [draftTestRequest] Запрос на запуск тестирования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testDraft: async (id: string, draftTestRequest?: DraftTestRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testDraft', 'id', id)
            const localVarPath = `/api/v1/drafts/{id}/test`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(draftTestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить черновик.
         * @param {string} id ИД черновика.
         * @param {DraftUpdatingRequest} [draftUpdatingRequest] Запрос на обновление черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDraft: async (id: string, draftUpdatingRequest?: DraftUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDraft', 'id', id)
            const localVarPath = `/api/v1/drafts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(draftUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DraftKbApi - functional programming interface
 * @export
 */
export const DraftKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DraftKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать черновик.
         * @param {DraftCreationRequest} [draftCreationRequest] Запрос на создание черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraft(draftCreationRequest?: DraftCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraft(draftCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить черновик.
         * @param {string} id ИД черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDraft(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDraft(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактировать черновик.
         * @param {string} id ИД черновика.
         * @param {object} [body] Запрос на редактирование черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editDraft(id: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editDraft(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить черновик.
         * @param {string} id ИД черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraft(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraft(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск черновиков.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDrafts(knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDrafts(knowledgeBaseId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запуск тестирования черновика.
         * @param {string} id ИД черновика.
         * @param {DraftTestRequest} [draftTestRequest] Запрос на запуск тестирования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testDraft(id: string, draftTestRequest?: DraftTestRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testDraft(id, draftTestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить черновик.
         * @param {string} id ИД черновика.
         * @param {DraftUpdatingRequest} [draftUpdatingRequest] Запрос на обновление черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDraft(id: string, draftUpdatingRequest?: DraftUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftUpdatingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDraft(id, draftUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DraftKbApi - factory interface
 * @export
 */
export const DraftKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DraftKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать черновик.
         * @param {DraftCreationRequest} [draftCreationRequest] Запрос на создание черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraft(draftCreationRequest?: DraftCreationRequest, options?: any): AxiosPromise<DraftCreationResponse> {
            return localVarFp.createDraft(draftCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить черновик.
         * @param {string} id ИД черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraft(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактировать черновик.
         * @param {string} id ИД черновика.
         * @param {object} [body] Запрос на редактирование черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDraft(id: string, body?: object, options?: any): AxiosPromise<object> {
            return localVarFp.editDraft(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить черновик.
         * @param {string} id ИД черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraft(id: string, options?: any): AxiosPromise<DraftModel> {
            return localVarFp.getDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск черновиков.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDrafts(knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<DraftModelPaginationResponse> {
            return localVarFp.searchDrafts(knowledgeBaseId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запуск тестирования черновика.
         * @param {string} id ИД черновика.
         * @param {DraftTestRequest} [draftTestRequest] Запрос на запуск тестирования.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testDraft(id: string, draftTestRequest?: DraftTestRequest, options?: any): AxiosPromise<PublishStatus> {
            return localVarFp.testDraft(id, draftTestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить черновик.
         * @param {string} id ИД черновика.
         * @param {DraftUpdatingRequest} [draftUpdatingRequest] Запрос на обновление черновика.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDraft(id: string, draftUpdatingRequest?: DraftUpdatingRequest, options?: any): AxiosPromise<DraftUpdatingResponse> {
            return localVarFp.updateDraft(id, draftUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DraftKbApi - object-oriented interface
 * @export
 * @class DraftKbApi
 * @extends {BaseAPI}
 */
export class DraftKbApi extends BaseAPI {
    /**
     * 
     * @summary Создать черновик.
     * @param {DraftCreationRequest} [draftCreationRequest] Запрос на создание черновика.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public createDraft(draftCreationRequest?: DraftCreationRequest, options?: any) {
        return DraftKbApiFp(this.configuration).createDraft(draftCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить черновик.
     * @param {string} id ИД черновика.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public deleteDraft(id: string, options?: any) {
        return DraftKbApiFp(this.configuration).deleteDraft(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактировать черновик.
     * @param {string} id ИД черновика.
     * @param {object} [body] Запрос на редактирование черновика.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public editDraft(id: string, body?: object, options?: any) {
        return DraftKbApiFp(this.configuration).editDraft(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить черновик.
     * @param {string} id ИД черновика.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public getDraft(id: string, options?: any) {
        return DraftKbApiFp(this.configuration).getDraft(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск черновиков.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public searchDrafts(knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return DraftKbApiFp(this.configuration).searchDrafts(knowledgeBaseId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запуск тестирования черновика.
     * @param {string} id ИД черновика.
     * @param {DraftTestRequest} [draftTestRequest] Запрос на запуск тестирования.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public testDraft(id: string, draftTestRequest?: DraftTestRequest, options?: any) {
        return DraftKbApiFp(this.configuration).testDraft(id, draftTestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить черновик.
     * @param {string} id ИД черновика.
     * @param {DraftUpdatingRequest} [draftUpdatingRequest] Запрос на обновление черновика.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftKbApi
     */
    public updateDraft(id: string, draftUpdatingRequest?: DraftUpdatingRequest, options?: any) {
        return DraftKbApiFp(this.configuration).updateDraft(id, draftUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EditionKbApi - axios parameter creator
 * @export
 */
export const EditionKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Экспорт редакции.
         * @param {string} id ИД редакции.
         * @param {ExportFormat} [format] Формат экспорта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEdition: async (id: string, format?: ExportFormat, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportEdition', 'id', id)
            const localVarPath = `/api/v1/editions/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить редакцию.
         * @param {string} id ИД редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdition: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEdition', 'id', id)
            const localVarPath = `/api/v1/editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск редакций.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEditions: async (knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/editions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EditionKbApi - functional programming interface
 * @export
 */
export const EditionKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EditionKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Экспорт редакции.
         * @param {string} id ИД редакции.
         * @param {ExportFormat} [format] Формат экспорта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportEdition(id: string, format?: ExportFormat, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportEdition(id, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить редакцию.
         * @param {string} id ИД редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEdition(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEdition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск редакций.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEditions(knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEditions(knowledgeBaseId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EditionKbApi - factory interface
 * @export
 */
export const EditionKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EditionKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Экспорт редакции.
         * @param {string} id ИД редакции.
         * @param {ExportFormat} [format] Формат экспорта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEdition(id: string, format?: ExportFormat, options?: any): AxiosPromise<any> {
            return localVarFp.exportEdition(id, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить редакцию.
         * @param {string} id ИД редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdition(id: string, options?: any): AxiosPromise<EditionModel> {
            return localVarFp.getEdition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск редакций.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEditions(knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<EditionModelPaginationResponse> {
            return localVarFp.searchEditions(knowledgeBaseId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EditionKbApi - object-oriented interface
 * @export
 * @class EditionKbApi
 * @extends {BaseAPI}
 */
export class EditionKbApi extends BaseAPI {
    /**
     * 
     * @summary Экспорт редакции.
     * @param {string} id ИД редакции.
     * @param {ExportFormat} [format] Формат экспорта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionKbApi
     */
    public exportEdition(id: string, format?: ExportFormat, options?: any) {
        return EditionKbApiFp(this.configuration).exportEdition(id, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить редакцию.
     * @param {string} id ИД редакции.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionKbApi
     */
    public getEdition(id: string, options?: any) {
        return EditionKbApiFp(this.configuration).getEdition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск редакций.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionKbApi
     */
    public searchEditions(knowledgeBaseId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return EditionKbApiFp(this.configuration).searchEditions(knowledgeBaseId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventKbApi - axios parameter creator
 * @export
 */
export const EventKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEvent', 'id', id)
            const localVarPath = `/api/v1/general/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents: async (type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventKbApi - functional programming interface
 * @export
 */
export const EventKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventKbApi - factory interface
 * @export
 */
export const EventKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: string, options?: any): AxiosPromise<EventModel> {
            return localVarFp.getEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<EventModelPaginationResponse> {
            return localVarFp.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventKbApi - object-oriented interface
 * @export
 * @class EventKbApi
 * @extends {BaseAPI}
 */
export class EventKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить событие.
     * @param {string} id ИД события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventKbApi
     */
    public getEvent(id: string, options?: any) {
        return EventKbApiFp(this.configuration).getEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск событий.
     * @param {string} [type] Тип события.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventKbApi
     */
    public searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return EventKbApiFp(this.configuration).searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstanceKbApi - axios parameter creator
 * @export
 */
export const InstanceKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить экземпляр.
         * @param {string} id ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstance: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInstance', 'id', id)
            const localVarPath = `/api/v1/instances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить публикацию экземпляра.
         * @param {string} id ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishInstance: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishInstance', 'id', id)
            const localVarPath = `/api/v1/instances/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос к базе знаний.
         * @param {string} id ИД экземпляра.
         * @param {string} utterance 
         * @param {number} [limit] 
         * @param {string} [parentDataEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInstance: async (id: string, utterance: string, limit?: number, parentDataEntryId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queryInstance', 'id', id)
            // verify required parameter 'utterance' is not null or undefined
            assertParamExists('queryInstance', 'utterance', utterance)
            const localVarPath = `/api/v1/instances/{id}/query`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (utterance !== undefined) {
                localVarQueryParameter['Utterance'] = utterance;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (parentDataEntryId !== undefined) {
                localVarQueryParameter['ParentDataEntryId'] = parentDataEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск экземпляров.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstances: async (knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (editionId !== undefined) {
                localVarQueryParameter['EditionId'] = editionId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstanceKbApi - functional programming interface
 * @export
 */
export const InstanceKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstanceKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить экземпляр.
         * @param {string} id ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstance(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запустить публикацию экземпляра.
         * @param {string} id ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishInstance(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishInstance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запрос к базе знаний.
         * @param {string} id ИД экземпляра.
         * @param {string} utterance 
         * @param {number} [limit] 
         * @param {string} [parentDataEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryInstance(id: string, utterance: string, limit?: number, parentDataEntryId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecognizedValueModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryInstance(id, utterance, limit, parentDataEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск экземпляров.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInstances(knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInstances(knowledgeBaseId, editionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstanceKbApi - factory interface
 * @export
 */
export const InstanceKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstanceKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить экземпляр.
         * @param {string} id ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstance(id: string, options?: any): AxiosPromise<InstanceModel> {
            return localVarFp.getInstance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить публикацию экземпляра.
         * @param {string} id ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishInstance(id: string, options?: any): AxiosPromise<InstanceModel> {
            return localVarFp.publishInstance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запрос к базе знаний.
         * @param {string} id ИД экземпляра.
         * @param {string} utterance 
         * @param {number} [limit] 
         * @param {string} [parentDataEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryInstance(id: string, utterance: string, limit?: number, parentDataEntryId?: string, options?: any): AxiosPromise<Array<RecognizedValueModel>> {
            return localVarFp.queryInstance(id, utterance, limit, parentDataEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск экземпляров.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInstances(knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<InstanceModelPaginationResponse> {
            return localVarFp.searchInstances(knowledgeBaseId, editionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstanceKbApi - object-oriented interface
 * @export
 * @class InstanceKbApi
 * @extends {BaseAPI}
 */
export class InstanceKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить экземпляр.
     * @param {string} id ИД экземпляра.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceKbApi
     */
    public getInstance(id: string, options?: any) {
        return InstanceKbApiFp(this.configuration).getInstance(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить публикацию экземпляра.
     * @param {string} id ИД экземпляра.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceKbApi
     */
    public publishInstance(id: string, options?: any) {
        return InstanceKbApiFp(this.configuration).publishInstance(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запрос к базе знаний.
     * @param {string} id ИД экземпляра.
     * @param {string} utterance 
     * @param {number} [limit] 
     * @param {string} [parentDataEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceKbApi
     */
    public queryInstance(id: string, utterance: string, limit?: number, parentDataEntryId?: string, options?: any) {
        return InstanceKbApiFp(this.configuration).queryInstance(id, utterance, limit, parentDataEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск экземпляров.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {string} [editionId] ИД редакции.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceKbApi
     */
    public searchInstances(knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return InstanceKbApiFp(this.configuration).searchInstances(knowledgeBaseId, editionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowledgeBaseKbApi - axios parameter creator
 * @export
 */
export const KnowledgeBaseKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать базу знаний.
         * @param {KnowledgeBaseCreationRequest} [knowledgeBaseCreationRequest] Запрос на создание базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKnowledgeBase: async (knowledgeBaseCreationRequest?: KnowledgeBaseCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/knowledge-bases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(knowledgeBaseCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKnowledgeBase: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteKnowledgeBase', 'id', id)
            const localVarPath = `/api/v1/knowledge-bases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnowledgeBase: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKnowledgeBase', 'id', id)
            const localVarPath = `/api/v1/knowledge-bases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск баз знаний.
         * @param {string} [name] Название базы знаний.
         * @param {KnowledgeBaseEntryType} [type] Тип базы знаний:  Default - значение по умоланию,  System - системная база знаний, т.е. созданная из шаблона,  Private - приватная база знаний, т.е. подключена только к одному боту.
         * @param {string} [templateCode] Код шаблона системной базы.
         * @param {string} [templateVersion] Версия шаблона системной базы.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKnowledgeBases: async (name?: string, type?: KnowledgeBaseEntryType, templateCode?: string, templateVersion?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/knowledge-bases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (templateCode !== undefined) {
                localVarQueryParameter['TemplateCode'] = templateCode;
            }

            if (templateVersion !== undefined) {
                localVarQueryParameter['TemplateVersion'] = templateVersion;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {KnowledgeBaseUpdatingRequest} [knowledgeBaseUpdatingRequest] Запрос на обновление базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKnowledgeBase: async (id: string, knowledgeBaseUpdatingRequest?: KnowledgeBaseUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateKnowledgeBase', 'id', id)
            const localVarPath = `/api/v1/knowledge-bases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(knowledgeBaseUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowledgeBaseKbApi - functional programming interface
 * @export
 */
export const KnowledgeBaseKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowledgeBaseKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать базу знаний.
         * @param {KnowledgeBaseCreationRequest} [knowledgeBaseCreationRequest] Запрос на создание базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKnowledgeBase(knowledgeBaseCreationRequest?: KnowledgeBaseCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeBaseCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createKnowledgeBase(knowledgeBaseCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteKnowledgeBase(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteKnowledgeBase(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKnowledgeBase(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleKnowledgeBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKnowledgeBase(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск баз знаний.
         * @param {string} [name] Название базы знаний.
         * @param {KnowledgeBaseEntryType} [type] Тип базы знаний:  Default - значение по умоланию,  System - системная база знаний, т.е. созданная из шаблона,  Private - приватная база знаний, т.е. подключена только к одному боту.
         * @param {string} [templateCode] Код шаблона системной базы.
         * @param {string} [templateVersion] Версия шаблона системной базы.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchKnowledgeBases(name?: string, type?: KnowledgeBaseEntryType, templateCode?: string, templateVersion?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListKnowledgeBaseModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchKnowledgeBases(name, type, templateCode, templateVersion, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {KnowledgeBaseUpdatingRequest} [knowledgeBaseUpdatingRequest] Запрос на обновление базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKnowledgeBase(id: string, knowledgeBaseUpdatingRequest?: KnowledgeBaseUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKnowledgeBase(id, knowledgeBaseUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowledgeBaseKbApi - factory interface
 * @export
 */
export const KnowledgeBaseKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowledgeBaseKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать базу знаний.
         * @param {KnowledgeBaseCreationRequest} [knowledgeBaseCreationRequest] Запрос на создание базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKnowledgeBase(knowledgeBaseCreationRequest?: KnowledgeBaseCreationRequest, options?: any): AxiosPromise<KnowledgeBaseCreationResponse> {
            return localVarFp.createKnowledgeBase(knowledgeBaseCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteKnowledgeBase(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteKnowledgeBase(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnowledgeBase(id: string, options?: any): AxiosPromise<SingleKnowledgeBaseModel> {
            return localVarFp.getKnowledgeBase(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск баз знаний.
         * @param {string} [name] Название базы знаний.
         * @param {KnowledgeBaseEntryType} [type] Тип базы знаний:  Default - значение по умоланию,  System - системная база знаний, т.е. созданная из шаблона,  Private - приватная база знаний, т.е. подключена только к одному боту.
         * @param {string} [templateCode] Код шаблона системной базы.
         * @param {string} [templateVersion] Версия шаблона системной базы.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKnowledgeBases(name?: string, type?: KnowledgeBaseEntryType, templateCode?: string, templateVersion?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListKnowledgeBaseModelPaginationResponse> {
            return localVarFp.searchKnowledgeBases(name, type, templateCode, templateVersion, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить базу знаний.
         * @param {string} id ИД базы знаний.
         * @param {KnowledgeBaseUpdatingRequest} [knowledgeBaseUpdatingRequest] Запрос на обновление базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKnowledgeBase(id: string, knowledgeBaseUpdatingRequest?: KnowledgeBaseUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateKnowledgeBase(id, knowledgeBaseUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowledgeBaseKbApi - object-oriented interface
 * @export
 * @class KnowledgeBaseKbApi
 * @extends {BaseAPI}
 */
export class KnowledgeBaseKbApi extends BaseAPI {
    /**
     * 
     * @summary Создать базу знаний.
     * @param {KnowledgeBaseCreationRequest} [knowledgeBaseCreationRequest] Запрос на создание базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseKbApi
     */
    public createKnowledgeBase(knowledgeBaseCreationRequest?: KnowledgeBaseCreationRequest, options?: any) {
        return KnowledgeBaseKbApiFp(this.configuration).createKnowledgeBase(knowledgeBaseCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить базу знаний.
     * @param {string} id ИД базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseKbApi
     */
    public deleteKnowledgeBase(id: string, options?: any) {
        return KnowledgeBaseKbApiFp(this.configuration).deleteKnowledgeBase(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить базу знаний.
     * @param {string} id ИД базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseKbApi
     */
    public getKnowledgeBase(id: string, options?: any) {
        return KnowledgeBaseKbApiFp(this.configuration).getKnowledgeBase(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск баз знаний.
     * @param {string} [name] Название базы знаний.
     * @param {KnowledgeBaseEntryType} [type] Тип базы знаний:  Default - значение по умоланию,  System - системная база знаний, т.е. созданная из шаблона,  Private - приватная база знаний, т.е. подключена только к одному боту.
     * @param {string} [templateCode] Код шаблона системной базы.
     * @param {string} [templateVersion] Версия шаблона системной базы.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseKbApi
     */
    public searchKnowledgeBases(name?: string, type?: KnowledgeBaseEntryType, templateCode?: string, templateVersion?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return KnowledgeBaseKbApiFp(this.configuration).searchKnowledgeBases(name, type, templateCode, templateVersion, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить базу знаний.
     * @param {string} id ИД базы знаний.
     * @param {KnowledgeBaseUpdatingRequest} [knowledgeBaseUpdatingRequest] Запрос на обновление базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseKbApi
     */
    public updateKnowledgeBase(id: string, knowledgeBaseUpdatingRequest?: KnowledgeBaseUpdatingRequest, options?: any) {
        return KnowledgeBaseKbApiFp(this.configuration).updateKnowledgeBase(id, knowledgeBaseUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowledgeSourceKbApi - axios parameter creator
 * @export
 */
export const KnowledgeSourceKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить файл источника базы знаний.
         * @param {string} knowledgeSourceId ИД источника базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnowledgeSourceFile: async (knowledgeSourceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'knowledgeSourceId' is not null or undefined
            assertParamExists('getKnowledgeSourceFile', 'knowledgeSourceId', knowledgeSourceId)
            const localVarPath = `/api/v1/knowledge-sources/{knowledgeSourceId}/file`
                .replace(`{${"knowledgeSourceId"}}`, encodeURIComponent(String(knowledgeSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск источников базы знаний.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {string} [sourceKnowledgeSourceId] ИД источника, на основе которой была сделана копия.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKnowledgeSources: async (knowledgeBaseId?: string, editionId?: string, sourceKnowledgeSourceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/knowledge-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (editionId !== undefined) {
                localVarQueryParameter['EditionId'] = editionId;
            }

            if (sourceKnowledgeSourceId !== undefined) {
                localVarQueryParameter['SourceKnowledgeSourceId'] = sourceKnowledgeSourceId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Валидация источника базы знаний.
         * @param {KnowledgeSourceValidationRequest} [knowledgeSourceValidationRequest] Запрос на валидацию источника базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateKnowledgeSource: async (knowledgeSourceValidationRequest?: KnowledgeSourceValidationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/knowledge-sources/validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(knowledgeSourceValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowledgeSourceKbApi - functional programming interface
 * @export
 */
export const KnowledgeSourceKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowledgeSourceKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить файл источника базы знаний.
         * @param {string} knowledgeSourceId ИД источника базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKnowledgeSourceFile(knowledgeSourceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKnowledgeSourceFile(knowledgeSourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск источников базы знаний.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {string} [sourceKnowledgeSourceId] ИД источника, на основе которой была сделана копия.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchKnowledgeSources(knowledgeBaseId?: string, editionId?: string, sourceKnowledgeSourceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeSourceModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchKnowledgeSources(knowledgeBaseId, editionId, sourceKnowledgeSourceId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Валидация источника базы знаний.
         * @param {KnowledgeSourceValidationRequest} [knowledgeSourceValidationRequest] Запрос на валидацию источника базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateKnowledgeSource(knowledgeSourceValidationRequest?: KnowledgeSourceValidationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeSourceValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateKnowledgeSource(knowledgeSourceValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowledgeSourceKbApi - factory interface
 * @export
 */
export const KnowledgeSourceKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowledgeSourceKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить файл источника базы знаний.
         * @param {string} knowledgeSourceId ИД источника базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKnowledgeSourceFile(knowledgeSourceId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getKnowledgeSourceFile(knowledgeSourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск источников базы знаний.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {string} [sourceKnowledgeSourceId] ИД источника, на основе которой была сделана копия.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchKnowledgeSources(knowledgeBaseId?: string, editionId?: string, sourceKnowledgeSourceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<KnowledgeSourceModelPaginationResponse> {
            return localVarFp.searchKnowledgeSources(knowledgeBaseId, editionId, sourceKnowledgeSourceId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Валидация источника базы знаний.
         * @param {KnowledgeSourceValidationRequest} [knowledgeSourceValidationRequest] Запрос на валидацию источника базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateKnowledgeSource(knowledgeSourceValidationRequest?: KnowledgeSourceValidationRequest, options?: any): AxiosPromise<KnowledgeSourceValidationResponse> {
            return localVarFp.validateKnowledgeSource(knowledgeSourceValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowledgeSourceKbApi - object-oriented interface
 * @export
 * @class KnowledgeSourceKbApi
 * @extends {BaseAPI}
 */
export class KnowledgeSourceKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить файл источника базы знаний.
     * @param {string} knowledgeSourceId ИД источника базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeSourceKbApi
     */
    public getKnowledgeSourceFile(knowledgeSourceId: string, options?: any) {
        return KnowledgeSourceKbApiFp(this.configuration).getKnowledgeSourceFile(knowledgeSourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск источников базы знаний.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {string} [editionId] ИД редакции.
     * @param {string} [sourceKnowledgeSourceId] ИД источника, на основе которой была сделана копия.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeSourceKbApi
     */
    public searchKnowledgeSources(knowledgeBaseId?: string, editionId?: string, sourceKnowledgeSourceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return KnowledgeSourceKbApiFp(this.configuration).searchKnowledgeSources(knowledgeBaseId, editionId, sourceKnowledgeSourceId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Валидация источника базы знаний.
     * @param {KnowledgeSourceValidationRequest} [knowledgeSourceValidationRequest] Запрос на валидацию источника базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeSourceKbApi
     */
    public validateKnowledgeSource(knowledgeSourceValidationRequest?: KnowledgeSourceValidationRequest, options?: any) {
        return KnowledgeSourceKbApiFp(this.configuration).validateKnowledgeSource(knowledgeSourceValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportKbApi - axios parameter creator
 * @export
 */
export const ReportKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить актуальный отчет.
         * @param {string} instanceId ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActualReport: async (instanceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getActualReport', 'instanceId', instanceId)
            const localVarPath = `/api/v1/reports/actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (instanceId !== undefined) {
                localVarQueryParameter['InstanceId'] = instanceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск отчетов.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [instanceId] ИД экземпляра.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReports: async (knowledgeBaseId?: string, instanceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (instanceId !== undefined) {
                localVarQueryParameter['InstanceId'] = instanceId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportKbApi - functional programming interface
 * @export
 */
export const ReportKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить актуальный отчет.
         * @param {string} instanceId ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActualReport(instanceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActualReport(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск отчетов.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [instanceId] ИД экземпляра.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchReports(knowledgeBaseId?: string, instanceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchReports(knowledgeBaseId, instanceId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportKbApi - factory interface
 * @export
 */
export const ReportKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить актуальный отчет.
         * @param {string} instanceId ИД экземпляра.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActualReport(instanceId: string, options?: any): AxiosPromise<SingleReportModel> {
            return localVarFp.getActualReport(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск отчетов.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [instanceId] ИД экземпляра.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReports(knowledgeBaseId?: string, instanceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ReportModelPaginationResponse> {
            return localVarFp.searchReports(knowledgeBaseId, instanceId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportKbApi - object-oriented interface
 * @export
 * @class ReportKbApi
 * @extends {BaseAPI}
 */
export class ReportKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить актуальный отчет.
     * @param {string} instanceId ИД экземпляра.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportKbApi
     */
    public getActualReport(instanceId: string, options?: any) {
        return ReportKbApiFp(this.configuration).getActualReport(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск отчетов.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {string} [instanceId] ИД экземпляра.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportKbApi
     */
    public searchReports(knowledgeBaseId?: string, instanceId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return ReportKbApiFp(this.configuration).searchReports(knowledgeBaseId, instanceId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportRowKbApi - axios parameter creator
 * @export
 */
export const ReportRowKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Выполнить поиск записей из отчетов.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [reportId] ИД отчета.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReportRows: async (knowledgeBaseId?: string, reportId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/report-rows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['ReportId'] = reportId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportRowKbApi - functional programming interface
 * @export
 */
export const ReportRowKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportRowKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Выполнить поиск записей из отчетов.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [reportId] ИД отчета.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchReportRows(knowledgeBaseId?: string, reportId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportRowModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchReportRows(knowledgeBaseId, reportId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportRowKbApi - factory interface
 * @export
 */
export const ReportRowKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportRowKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Выполнить поиск записей из отчетов.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [reportId] ИД отчета.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchReportRows(knowledgeBaseId?: string, reportId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ReportRowModelPaginationResponse> {
            return localVarFp.searchReportRows(knowledgeBaseId, reportId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportRowKbApi - object-oriented interface
 * @export
 * @class ReportRowKbApi
 * @extends {BaseAPI}
 */
export class ReportRowKbApi extends BaseAPI {
    /**
     * 
     * @summary Выполнить поиск записей из отчетов.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {string} [reportId] ИД отчета.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportRowKbApi
     */
    public searchReportRows(knowledgeBaseId?: string, reportId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return ReportRowKbApiFp(this.configuration).searchReportRows(knowledgeBaseId, reportId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StageKbApi - axios parameter creator
 * @export
 */
export const StageKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать черновую стадию.
         * @param {string} id ИД исходной стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDraftStage', 'id', id)
            const localVarPath = `/api/v1/stages/{id}/draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить стадию базы знаний.
         * @param {string} id ИД стадии базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStage', 'id', id)
            const localVarPath = `/api/v1/stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить стадию.
         * @param {string} id ИД стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStage', 'id', id)
            const localVarPath = `/api/v1/stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Объединить одну стадию базы знаний с другой.
         * @param {string} id ИД исходной стадии базы знаний.
         * @param {StageMergeRequest} [stageMergeRequest] Запрос на объединение с исходной стадией базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeStage: async (id: string, stageMergeRequest?: StageMergeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mergeStage', 'id', id)
            const localVarPath = `/api/v1/stages/{id}/merge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stageMergeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Публикация версии в стадию.
         * @param {string} id ИД стадии.
         * @param {StagePublishRequest} [stagePublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStage: async (id: string, stagePublishRequest?: StagePublishRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishStage', 'id', id)
            const localVarPath = `/api/v1/stages/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stagePublishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запрос к базе знаний.
         * @param {string} id ИД стадии.
         * @param {string} utterance 
         * @param {number} [limit] 
         * @param {string} [parentDataEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryStage: async (id: string, utterance: string, limit?: number, parentDataEntryId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queryStage', 'id', id)
            // verify required parameter 'utterance' is not null or undefined
            assertParamExists('queryStage', 'utterance', utterance)
            const localVarPath = `/api/v1/stages/{id}/query`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (utterance !== undefined) {
                localVarQueryParameter['Utterance'] = utterance;
            }

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (parentDataEntryId !== undefined) {
                localVarQueryParameter['ParentDataEntryId'] = parentDataEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить стадию.
         * @param {string} id ИД стадии.
         * @param {StageUpdatingRequest} [stageUpdatingRequest] Запрос на обновление стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStage: async (id: string, stageUpdatingRequest?: StageUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateStage', 'id', id)
            const localVarPath = `/api/v1/stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stageUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StageKbApi - functional programming interface
 * @export
 */
export const StageKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StageKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать черновую стадию.
         * @param {string} id ИД исходной стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить стадию базы знаний.
         * @param {string} id ИД стадии базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить стадию.
         * @param {string} id ИД стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Объединить одну стадию базы знаний с другой.
         * @param {string} id ИД исходной стадии базы знаний.
         * @param {StageMergeRequest} [stageMergeRequest] Запрос на объединение с исходной стадией базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeStage(id: string, stageMergeRequest?: StageMergeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeStage(id, stageMergeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Публикация версии в стадию.
         * @param {string} id ИД стадии.
         * @param {StagePublishRequest} [stagePublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishStage(id: string, stagePublishRequest?: StagePublishRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishStage(id, stagePublishRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запрос к базе знаний.
         * @param {string} id ИД стадии.
         * @param {string} utterance 
         * @param {number} [limit] 
         * @param {string} [parentDataEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryStage(id: string, utterance: string, limit?: number, parentDataEntryId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecognizedValueModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryStage(id, utterance, limit, parentDataEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить стадию.
         * @param {string} id ИД стадии.
         * @param {StageUpdatingRequest} [stageUpdatingRequest] Запрос на обновление стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStage(id: string, stageUpdatingRequest?: StageUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStage(id, stageUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StageKbApi - factory interface
 * @export
 */
export const StageKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StageKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать черновую стадию.
         * @param {string} id ИД исходной стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftStage(id: string, options?: any): AxiosPromise<StageModel> {
            return localVarFp.createDraftStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить стадию базы знаний.
         * @param {string} id ИД стадии базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStage(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить стадию.
         * @param {string} id ИД стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStage(id: string, options?: any): AxiosPromise<StageModel> {
            return localVarFp.getStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Объединить одну стадию базы знаний с другой.
         * @param {string} id ИД исходной стадии базы знаний.
         * @param {StageMergeRequest} [stageMergeRequest] Запрос на объединение с исходной стадией базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeStage(id: string, stageMergeRequest?: StageMergeRequest, options?: any): AxiosPromise<object> {
            return localVarFp.mergeStage(id, stageMergeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Публикация версии в стадию.
         * @param {string} id ИД стадии.
         * @param {StagePublishRequest} [stagePublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStage(id: string, stagePublishRequest?: StagePublishRequest, options?: any): AxiosPromise<void> {
            return localVarFp.publishStage(id, stagePublishRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запрос к базе знаний.
         * @param {string} id ИД стадии.
         * @param {string} utterance 
         * @param {number} [limit] 
         * @param {string} [parentDataEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryStage(id: string, utterance: string, limit?: number, parentDataEntryId?: string, options?: any): AxiosPromise<Array<RecognizedValueModel>> {
            return localVarFp.queryStage(id, utterance, limit, parentDataEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить стадию.
         * @param {string} id ИД стадии.
         * @param {StageUpdatingRequest} [stageUpdatingRequest] Запрос на обновление стадии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStage(id: string, stageUpdatingRequest?: StageUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateStage(id, stageUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StageKbApi - object-oriented interface
 * @export
 * @class StageKbApi
 * @extends {BaseAPI}
 */
export class StageKbApi extends BaseAPI {
    /**
     * 
     * @summary Создать черновую стадию.
     * @param {string} id ИД исходной стадии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public createDraftStage(id: string, options?: any) {
        return StageKbApiFp(this.configuration).createDraftStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить стадию базы знаний.
     * @param {string} id ИД стадии базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public deleteStage(id: string, options?: any) {
        return StageKbApiFp(this.configuration).deleteStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить стадию.
     * @param {string} id ИД стадии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public getStage(id: string, options?: any) {
        return StageKbApiFp(this.configuration).getStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Объединить одну стадию базы знаний с другой.
     * @param {string} id ИД исходной стадии базы знаний.
     * @param {StageMergeRequest} [stageMergeRequest] Запрос на объединение с исходной стадией базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public mergeStage(id: string, stageMergeRequest?: StageMergeRequest, options?: any) {
        return StageKbApiFp(this.configuration).mergeStage(id, stageMergeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Публикация версии в стадию.
     * @param {string} id ИД стадии.
     * @param {StagePublishRequest} [stagePublishRequest] Запрос на публикацию.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public publishStage(id: string, stagePublishRequest?: StagePublishRequest, options?: any) {
        return StageKbApiFp(this.configuration).publishStage(id, stagePublishRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запрос к базе знаний.
     * @param {string} id ИД стадии.
     * @param {string} utterance 
     * @param {number} [limit] 
     * @param {string} [parentDataEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public queryStage(id: string, utterance: string, limit?: number, parentDataEntryId?: string, options?: any) {
        return StageKbApiFp(this.configuration).queryStage(id, utterance, limit, parentDataEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить стадию.
     * @param {string} id ИД стадии.
     * @param {StageUpdatingRequest} [stageUpdatingRequest] Запрос на обновление стадии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StageKbApi
     */
    public updateStage(id: string, stageUpdatingRequest?: StageUpdatingRequest, options?: any) {
        return StageKbApiFp(this.configuration).updateStage(id, stageUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateKbApi - axios parameter creator
 * @export
 */
export const TemplateKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список шаблонов chitchat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChitchatTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates/chitchat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить шаблон базы знаний.
         * @param {string} templateCode Код шаблона базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (templateCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateCode' is not null or undefined
            assertParamExists('getTemplate', 'templateCode', templateCode)
            const localVarPath = `/api/v1/templates/{templateCode}`
                .replace(`{${"templateCode"}}`, encodeURIComponent(String(templateCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл шаблона базы знаний.
         * @param {string} templateCode Код шаблона базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateFile: async (templateCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateCode' is not null or undefined
            assertParamExists('getTemplateFile', 'templateCode', templateCode)
            const localVarPath = `/api/v1/templates/{templateCode}/file`
                .replace(`{${"templateCode"}}`, encodeURIComponent(String(templateCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateKbApi - functional programming interface
 * @export
 */
export const TemplateKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов chitchat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChitchatTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChitchatTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить шаблон базы знаний.
         * @param {string} templateCode Код шаблона базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(templateCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(templateCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить файл шаблона базы знаний.
         * @param {string} templateCode Код шаблона базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplateFile(templateCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplateFile(templateCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateKbApi - factory interface
 * @export
 */
export const TemplateKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов chitchat.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChitchatTemplates(options?: any): AxiosPromise<Array<TemplateModel>> {
            return localVarFp.getChitchatTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить шаблон базы знаний.
         * @param {string} templateCode Код шаблона базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateCode: string, options?: any): AxiosPromise<TemplateModel> {
            return localVarFp.getTemplate(templateCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл шаблона базы знаний.
         * @param {string} templateCode Код шаблона базы знаний.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateFile(templateCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.getTemplateFile(templateCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateKbApi - object-oriented interface
 * @export
 * @class TemplateKbApi
 * @extends {BaseAPI}
 */
export class TemplateKbApi extends BaseAPI {
    /**
     * 
     * @summary Получить список шаблонов chitchat.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateKbApi
     */
    public getChitchatTemplates(options?: any) {
        return TemplateKbApiFp(this.configuration).getChitchatTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить шаблон базы знаний.
     * @param {string} templateCode Код шаблона базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateKbApi
     */
    public getTemplate(templateCode: string, options?: any) {
        return TemplateKbApiFp(this.configuration).getTemplate(templateCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл шаблона базы знаний.
     * @param {string} templateCode Код шаблона базы знаний.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateKbApi
     */
    public getTemplateFile(templateCode: string, options?: any) {
        return TemplateKbApiFp(this.configuration).getTemplateFile(templateCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VersionKbApi - axios parameter creator
 * @export
 */
export const VersionKbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавить запись в версию.
         * @param {string} versionId ИД версии.
         * @param {DataEntryAddingRequest} [dataEntryAddingRequest] Запрос на добавление записи в версию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDataEntry: async (versionId: string, dataEntryAddingRequest?: DataEntryAddingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('addDataEntry', 'versionId', versionId)
            const localVarPath = `/api/v1/versions/{versionId}/data-entry`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataEntryAddingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавить источник в версию.
         * @param {string} versionId ИД версии.
         * @param {KnowledgeSourceAddingRequest} [knowledgeSourceAddingRequest] Запрос на добавление источника в версию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKnowledgeSource: async (versionId: string, knowledgeSourceAddingRequest?: KnowledgeSourceAddingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('addKnowledgeSource', 'versionId', versionId)
            const localVarPath = `/api/v1/versions/{versionId}/knowledge-source`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(knowledgeSourceAddingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать версию.
         * @param {VersionCreationRequest} [versionCreationRequest] Запрос на создание версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion: async (versionCreationRequest?: VersionCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(versionCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить версию.
         * @param {string} versionId ИД версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (versionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('getVersion', 'versionId', versionId)
            const localVarPath = `/api/v1/versions/{versionId}`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Заменить источник в версии.
         * @param {string} versionId ИД версии.
         * @param {KnowledgeSourceReplacingRequest} [knowledgeSourceReplacingRequest] Запрос на замену источника в версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceKnowledgeSource: async (versionId: string, knowledgeSourceReplacingRequest?: KnowledgeSourceReplacingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('replaceKnowledgeSource', 'versionId', versionId)
            const localVarPath = `/api/v1/versions/{versionId}/knowledge-source`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(knowledgeSourceReplacingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск версий.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions: async (knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (knowledgeBaseId !== undefined) {
                localVarQueryParameter['KnowledgeBaseId'] = knowledgeBaseId;
            }

            if (editionId !== undefined) {
                localVarQueryParameter['EditionId'] = editionId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить запись в версии.
         * @param {string} versionId ИД версии.
         * @param {string} dataEntryId ИД записи.
         * @param {DataEntryUpdatingRequest} [dataEntryUpdatingRequest] Запрос на обновление записи в версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataEntry: async (versionId: string, dataEntryId: string, dataEntryUpdatingRequest?: DataEntryUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'versionId' is not null or undefined
            assertParamExists('updateDataEntry', 'versionId', versionId)
            // verify required parameter 'dataEntryId' is not null or undefined
            assertParamExists('updateDataEntry', 'dataEntryId', dataEntryId)
            const localVarPath = `/api/v1/versions/{versionId}/data-entry/{dataEntryId}`
                .replace(`{${"versionId"}}`, encodeURIComponent(String(versionId)))
                .replace(`{${"dataEntryId"}}`, encodeURIComponent(String(dataEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataEntryUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionKbApi - functional programming interface
 * @export
 */
export const VersionKbApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionKbApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавить запись в версию.
         * @param {string} versionId ИД версии.
         * @param {DataEntryAddingRequest} [dataEntryAddingRequest] Запрос на добавление записи в версию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDataEntry(versionId: string, dataEntryAddingRequest?: DataEntryAddingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDataEntry(versionId, dataEntryAddingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавить источник в версию.
         * @param {string} versionId ИД версии.
         * @param {KnowledgeSourceAddingRequest} [knowledgeSourceAddingRequest] Запрос на добавление источника в версию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addKnowledgeSource(versionId: string, knowledgeSourceAddingRequest?: KnowledgeSourceAddingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeSourceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addKnowledgeSource(versionId, knowledgeSourceAddingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать версию.
         * @param {VersionCreationRequest} [versionCreationRequest] Запрос на создание версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVersion(versionCreationRequest?: VersionCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVersion(versionCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить версию.
         * @param {string} versionId ИД версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(versionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(versionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Заменить источник в версии.
         * @param {string} versionId ИД версии.
         * @param {KnowledgeSourceReplacingRequest} [knowledgeSourceReplacingRequest] Запрос на замену источника в версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceKnowledgeSource(versionId: string, knowledgeSourceReplacingRequest?: KnowledgeSourceReplacingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KnowledgeSourceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceKnowledgeSource(versionId, knowledgeSourceReplacingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск версий.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchVersions(knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchVersions(knowledgeBaseId, editionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить запись в версии.
         * @param {string} versionId ИД версии.
         * @param {string} dataEntryId ИД записи.
         * @param {DataEntryUpdatingRequest} [dataEntryUpdatingRequest] Запрос на обновление записи в версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataEntry(versionId: string, dataEntryId: string, dataEntryUpdatingRequest?: DataEntryUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataEntry(versionId, dataEntryId, dataEntryUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VersionKbApi - factory interface
 * @export
 */
export const VersionKbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionKbApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавить запись в версию.
         * @param {string} versionId ИД версии.
         * @param {DataEntryAddingRequest} [dataEntryAddingRequest] Запрос на добавление записи в версию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDataEntry(versionId: string, dataEntryAddingRequest?: DataEntryAddingRequest, options?: any): AxiosPromise<DataEntryModel> {
            return localVarFp.addDataEntry(versionId, dataEntryAddingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавить источник в версию.
         * @param {string} versionId ИД версии.
         * @param {KnowledgeSourceAddingRequest} [knowledgeSourceAddingRequest] Запрос на добавление источника в версию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addKnowledgeSource(versionId: string, knowledgeSourceAddingRequest?: KnowledgeSourceAddingRequest, options?: any): AxiosPromise<KnowledgeSourceModel> {
            return localVarFp.addKnowledgeSource(versionId, knowledgeSourceAddingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать версию.
         * @param {VersionCreationRequest} [versionCreationRequest] Запрос на создание версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVersion(versionCreationRequest?: VersionCreationRequest, options?: any): AxiosPromise<VersionCreationResponse> {
            return localVarFp.createVersion(versionCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить версию.
         * @param {string} versionId ИД версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(versionId: string, options?: any): AxiosPromise<VersionModel> {
            return localVarFp.getVersion(versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Заменить источник в версии.
         * @param {string} versionId ИД версии.
         * @param {KnowledgeSourceReplacingRequest} [knowledgeSourceReplacingRequest] Запрос на замену источника в версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceKnowledgeSource(versionId: string, knowledgeSourceReplacingRequest?: KnowledgeSourceReplacingRequest, options?: any): AxiosPromise<KnowledgeSourceModel> {
            return localVarFp.replaceKnowledgeSource(versionId, knowledgeSourceReplacingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск версий.
         * @param {string} [knowledgeBaseId] ИД базы знаний.
         * @param {string} [editionId] ИД редакции.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchVersions(knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<VersionModelPaginationResponse> {
            return localVarFp.searchVersions(knowledgeBaseId, editionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить запись в версии.
         * @param {string} versionId ИД версии.
         * @param {string} dataEntryId ИД записи.
         * @param {DataEntryUpdatingRequest} [dataEntryUpdatingRequest] Запрос на обновление записи в версии.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataEntry(versionId: string, dataEntryId: string, dataEntryUpdatingRequest?: DataEntryUpdatingRequest, options?: any): AxiosPromise<DataEntryModel> {
            return localVarFp.updateDataEntry(versionId, dataEntryId, dataEntryUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionKbApi - object-oriented interface
 * @export
 * @class VersionKbApi
 * @extends {BaseAPI}
 */
export class VersionKbApi extends BaseAPI {
    /**
     * 
     * @summary Добавить запись в версию.
     * @param {string} versionId ИД версии.
     * @param {DataEntryAddingRequest} [dataEntryAddingRequest] Запрос на добавление записи в версию.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public addDataEntry(versionId: string, dataEntryAddingRequest?: DataEntryAddingRequest, options?: any) {
        return VersionKbApiFp(this.configuration).addDataEntry(versionId, dataEntryAddingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавить источник в версию.
     * @param {string} versionId ИД версии.
     * @param {KnowledgeSourceAddingRequest} [knowledgeSourceAddingRequest] Запрос на добавление источника в версию.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public addKnowledgeSource(versionId: string, knowledgeSourceAddingRequest?: KnowledgeSourceAddingRequest, options?: any) {
        return VersionKbApiFp(this.configuration).addKnowledgeSource(versionId, knowledgeSourceAddingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать версию.
     * @param {VersionCreationRequest} [versionCreationRequest] Запрос на создание версии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public createVersion(versionCreationRequest?: VersionCreationRequest, options?: any) {
        return VersionKbApiFp(this.configuration).createVersion(versionCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить версию.
     * @param {string} versionId ИД версии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public getVersion(versionId: string, options?: any) {
        return VersionKbApiFp(this.configuration).getVersion(versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Заменить источник в версии.
     * @param {string} versionId ИД версии.
     * @param {KnowledgeSourceReplacingRequest} [knowledgeSourceReplacingRequest] Запрос на замену источника в версии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public replaceKnowledgeSource(versionId: string, knowledgeSourceReplacingRequest?: KnowledgeSourceReplacingRequest, options?: any) {
        return VersionKbApiFp(this.configuration).replaceKnowledgeSource(versionId, knowledgeSourceReplacingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск версий.
     * @param {string} [knowledgeBaseId] ИД базы знаний.
     * @param {string} [editionId] ИД редакции.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public searchVersions(knowledgeBaseId?: string, editionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return VersionKbApiFp(this.configuration).searchVersions(knowledgeBaseId, editionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить запись в версии.
     * @param {string} versionId ИД версии.
     * @param {string} dataEntryId ИД записи.
     * @param {DataEntryUpdatingRequest} [dataEntryUpdatingRequest] Запрос на обновление записи в версии.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionKbApi
     */
    public updateDataEntry(versionId: string, dataEntryId: string, dataEntryUpdatingRequest?: DataEntryUpdatingRequest, options?: any) {
        return VersionKbApiFp(this.configuration).updateDataEntry(versionId, dataEntryId, dataEntryUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


