import { FeatureFlag } from '../../api';
import { featureManagementApi } from '../apis';
import { FeatureFlagNames } from '../constants';

const clientFeatureFlags: FeatureFlag[] = [{ name: FeatureFlagNames.HIDDEN, isActive: false }];

export const getFeatureFlags = async (): Promise<FeatureFlag[]> => {
  try {
    const response = await featureManagementApi.getFeatureFlags();
    return clientFeatureFlags.concat(response.data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Ошибка при загрузке списка доступного функционала', e);
    throw e;
  }
};
