import {
  BinaryConditionOperator,
  DateTimeValueType,
  SchemaKind,
  UnaryConditionOperator,
  VariableScope,
  VariableType,
} from '../../../../api';

export const usualPadding = 16;

export const groupsMarginX = 50;
export const groupsMarginY = 70;
export const groupWidth = 324;
export const groupPlaceholderHeight = 146;
export const groupPlaceholderMargin = 50;

export const optionHeight = 32;

export const scenarioEditorContainerId = 'scenarioEditorContainer';

export const bindingArrowSize = { width: 8, height: 14 };
export const bindingOffsetX = {
  output: {
    button: 70,
    group: 40,
    trigger: 60,
  },
  input: {
    default: 20,
    alternate: 60,
  },
};

export const VariableTypes = [
  { value: VariableType.String, label: 'Текст' },
  { value: VariableType.Number, label: 'Число' },
  { value: VariableType.Boolean, label: 'Да/Нет' },
  { value: VariableType.DateTime, label: 'Дата/Время' },
  { value: VariableType.PersonName, label: 'ФИО' },
  { value: VariableType.ComplexObject, label: 'Объект' },
  { value: VariableType.SessionProfile, label: 'Пользователь' },
  { value: VariableType.File, label: 'Файл' },
  { value: VariableType.PersonContacts, label: 'Контакты' },
];

export const VariableScopes = [
  { value: VariableScope.Scenario, label: 'Сценарий' },
  { value: VariableScope.Bot, label: 'Бот' },
  { value: VariableScope.User, label: 'Пользователь' },
  { value: VariableScope.Session, label: 'Сеанс' },
];

export const VisibleVariableScopes = VariableScopes.filter((s) => s.value !== VariableScope.Session);
export const VisibleVariableTypes = VariableTypes.filter(
  (s) => s.value !== VariableType.SessionProfile && s.value !== VariableType.PersonContacts
);

export const UnaryConditionOperators = [
  { value: UnaryConditionOperator.Empty, label: 'Пустое' },
  { value: UnaryConditionOperator.NotEmpty, label: 'Не пустое' },
];

export const BinaryConditionOperators = [
  { value: BinaryConditionOperator.Equals, label: 'Равно' },
  { value: BinaryConditionOperator.NotEquals, label: 'Не равно' },
  { value: BinaryConditionOperator.Greater, label: 'Больше' },
  { value: BinaryConditionOperator.GreaterOrEquals, label: 'Больше или равно' },
  { value: BinaryConditionOperator.Less, label: 'Меньше' },
  { value: BinaryConditionOperator.LessOrEquals, label: 'Меньше или равно' },
];

export const ConditionOperators = [...UnaryConditionOperators, ...BinaryConditionOperators];

export const LengthBinaryConditionOperators = [
  { value: BinaryConditionOperator.Equals, label: 'Равно' },
  { value: BinaryConditionOperator.NotEquals, label: 'Не равно' },
  { value: BinaryConditionOperator.Greater, label: 'Длиннее' },
  { value: BinaryConditionOperator.GreaterOrEquals, label: 'Не короче' },
  { value: BinaryConditionOperator.Less, label: 'Короче' },
  { value: BinaryConditionOperator.LessOrEquals, label: 'Не длиннее' },
];

export const StringBinaryConditionOperators = BinaryConditionOperators;

export const NumberBinaryConditionOperators = BinaryConditionOperators;

export const DateTimeBinaryConditionOperators = [
  { value: BinaryConditionOperator.Equals, label: 'Равно' },
  { value: BinaryConditionOperator.NotEquals, label: 'Не равно' },
  { value: BinaryConditionOperator.Greater, label: 'Позже' },
  { value: BinaryConditionOperator.GreaterOrEquals, label: 'Не раньше' },
  { value: BinaryConditionOperator.Less, label: 'Раньше' },
  { value: BinaryConditionOperator.LessOrEquals, label: 'Не позже' },
];

export const DateTimeUnaryConditionOperators = [
  { value: DateTimeValueType.DateWithTime, label: 'Дата и время' },
  { value: DateTimeValueType.Date, label: 'Дата' },
  { value: DateTimeValueType.Time, label: 'Время' },
];

export const DateTimeConditionOperators = [...DateTimeBinaryConditionOperators, ...DateTimeUnaryConditionOperators];

export const BooleanItems = [
  { value: 'false', label: 'Нет' },
  { value: 'true', label: 'Да' },
];

export const ScenarioActionNames = {
  [SchemaKind.TextOutput as string]: 'Сообщение чат-бота',
  [SchemaKind.ChoiceTransit as string]: 'Вопрос. Текст + кнопки',
  [SchemaKind.TextInput as string]: 'Вопрос. Ввод текста',
  [SchemaKind.EndScenarioOutput as string]: 'Завершение сценария',
  [SchemaKind.MenuAction as string]: 'Показать меню',
  [SchemaKind.ScriptAction as string]: 'Скрипт',
  [SchemaKind.SwitchAction as string]: 'Условное ветвление',
  [SchemaKind.TransferToOperator as string]: 'Перевод на оператора',
  [SchemaKind.ExternalSignIn as string]: 'Вход через OAuth',
  [SchemaKind.SendEmail as string]: 'Отправить Email',
  [SchemaKind.DateTimeInput as string]: 'Запрос данных: Дата/Время',
  [SchemaKind.PhoneNumberInput as string]: 'Запрос данных: Телефон',
  [SchemaKind.EmailAddressInput as string]: 'Запрос данных: Email',
  [SchemaKind.NumberInput as string]: 'Запрос данных: Число',
  [SchemaKind.LinkInput as string]: 'Запрос данных: Ссылка',
  [SchemaKind.LocationInput as string]: 'Запрос данных: Местоположение',
  [SchemaKind.PersonNameInput as string]: 'Запрос данных: ФИО',
  [SchemaKind.ConfirmTransit as string]: 'Запрос данных: Вопрос. Да/Нет',
  [SchemaKind.FileInput as string]: 'Запрос данных: Файл',
  [SchemaKind.StartElma365Process as string]: 'ELMA 365: Запуск бизнес-процесса',
  [SchemaKind.CreateElma365AppElement as string]: 'ELMA 365: Создание элемента приложения',
  [SchemaKind.Elma365UserInput as string]: 'ELMA 365: Выбор пользователя',
  [SchemaKind.Elma365AppElementInput as string]: 'ELMA 365: Выбор элемента приложения',
  [SchemaKind.Elma365EnumInput as string]: 'ELMA 365: Выбор категории',
  [SchemaKind.ExternalEventTrigger as string]: 'Внешнее событие',
};

export const INTENT_CATEGORY_TAG_COLOR = '#E8E8E8';

export enum ValidationRuleCodes {
  IntentContentDuplicateInScenario = 'IntentContent.DuplicateInScenario',
  IntentContentDuplicateInBot = 'IntentContent.DuplicateInBot',
  Elma365FormPropertyVariableMatches = 'Elma365FormProperty.Variable.Matches',
  Elma365FormPropertyVariableRequired = 'Elma365FormProperty.Variable.Required',
}

export const DEFAULT_INPUT_MAX_TURN_COUNT = 3;
