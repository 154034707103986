import React, { MutableRefObject, useEffect, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import PerfectScrollbar from 'perfect-scrollbar';

import './index.less';

interface ISbScrollProps {
  id?: string;
  height?: number;
  containerRefElement?: MutableRefObject<HTMLElement | undefined>;
}

const MIN_SCROLLBAR_LENGTH = 24;
const UPDATE_TIMEOUT = 500;

const SbScroll: React.FC<ISbScrollProps> = ({ id, height, containerRefElement, children }) => {
  const [perfectScrollbar, setPerfectScrollbar] = useState<PerfectScrollbar>();

  const onSync = (ps: PerfectScrollbar) => setPerfectScrollbar(ps);

  useEffect(() => {
    setTimeout(() => perfectScrollbar?.update(), UPDATE_TIMEOUT);
  });

  return (
    <ScrollBar
      className="sb-scroll"
      containerRef={(el) => containerRefElement && (containerRefElement.current = el)}
      id={id}
      options={{ minScrollbarLength: MIN_SCROLLBAR_LENGTH }}
      style={{ height }}
      // NOTE: onSync не описан в definition файле, но есть в самом компоненте
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSync={onSync}
    >
      {children}
    </ScrollBar>
  );
};

export default SbScroll;
