import React from 'react';
import { Avatar, Badge } from 'antd';
import moment from 'moment/moment';
import ReactMarkdown from 'react-markdown';

import './index.less';

import { DirectLineIcon, Elma365Icon, Logo, TelegramIcon, ViberIcon } from '../../../../assets';
import { ActivityDirection, ConversationModel } from '../../../../../api';
import SbIcon from '../../../components/common/SbIcon';
import SbTag from '../../../components/common/SbTag';
import { DIALOGS_COUNT_TAG_COLOR } from '../../../const';
import { Channels } from '../../../../constants';

const DATE_FORMAT = 'DD MMM, YYYY в HH:mm';
const USER_AVATAR_BACKGROUND_COLOR = '#82B8F3';
const BOT_AVATAR_BACKGROUND_COLOR = '#9CE3E1';
const AVATAR_SIZE = 69;
const CHANNEL_ICON_SIZE = 20;
const CHANNEL_ICON_PROPERTIES = {
  height: CHANNEL_ICON_SIZE,
  width: CHANNEL_ICON_SIZE,
  style: { marginTop: 5 },
};

interface IDialogListItemProps {
  item: ConversationModel;
}

const DialogListItem: React.FC<IDialogListItemProps> = ({ item }) => {
  const getChannelIcon = () => {
    switch (item.channelId) {
      case Channels.TELEGRAM:
        return <TelegramIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.DIRECTLINE:
        return <DirectLineIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.VIBER:
        return <ViberIcon {...CHANNEL_ICON_PROPERTIES} />;
      case Channels.ELMA365:
        return <Elma365Icon {...CHANNEL_ICON_PROPERTIES} />;
      default:
        return <Logo {...CHANNEL_ICON_PROPERTIES} />;
    }
  };

  return (
    <div className="sb-dialogs-card__content__list-container__list__item">
      <Badge count={<Avatar icon={getChannelIcon()} size={30} />} offset={[-11, 58]}>
        {item.latestMessageDirection === ActivityDirection.NUMBER_0 ? (
          <Avatar
            icon={<SbIcon iconName="user" />}
            size={AVATAR_SIZE}
            style={{ background: USER_AVATAR_BACKGROUND_COLOR }}
          />
        ) : (
          <Avatar
            icon={<SbIcon iconName="robot" />}
            size={AVATAR_SIZE}
            style={{ background: BOT_AVATAR_BACKGROUND_COLOR }}
          />
        )}
      </Badge>
      <div className="sb-dialogs-card__content__list-container__list__item__text">
        <span className="sb-dialogs-card__content__list-container__list__item__text_main">
          {moment(item.latestMessageOn ?? item.startedOn).format(DATE_FORMAT)}
          <SbTag color={DIALOGS_COUNT_TAG_COLOR} sbSize="small" text={item.messagesCount} />
        </span>
        <span>
          <ReactMarkdown>{item.latestMessageText ?? ''}</ReactMarkdown>
        </span>
      </div>
    </div>
  );
};

export default DialogListItem;
