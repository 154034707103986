import React, { useEffect, useState } from 'react';
import { Button, Divider, Spin, Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import { Col, ColsWrapper, Row, RowsWrapper } from 'react-grid-resizable';
import Moment from 'react-moment';
import { useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { activityApi, agentApi, conversationApi, directLineTranscriptApi } from '../../apis';
import { getProfile } from '../../utils/oidcUtil';
import { AlertTypes, webChatMarkdownRenderer, webChatStyleOptions } from '../../constants';
import ConversationTranscriptLink from '../../components/ConversationTranscriptLink';
import { AgentModel, ConversationModel } from '../../../api';
import FormattedDateTime from '../../components/FormattedDateTime';
import { getChannelName } from '../../utils/stringUtil';
import { alertsSelectorAdd } from '../../recoil/alerts';

const webChatPollingInterval = 1000 * 60 * 60; // 1 час
const separatorThickness = 20;
const rootKey = 'root';
const colors = {
  GREEN: '#47B07F',
  BLUE: '#007ACC',
  RED: '#A1260D',
};
const debugConsoleFontSize = 12;
const BOT_ROLE_NAME = 'bot';

const styleOptions = {
  ...webChatStyleOptions,
  hideSendBox: true,
};

// TODO: заполнить поля типа
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Activity = any;

const ConversationCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [initializing, setInitializing] = useState(false);
  const [webChatProps, setWebChatProps] = useState<{ userID: string }>();
  const [agent, setAgent] = useState<AgentModel>();
  const [conversation, setConversation] = useState<ConversationModel>();
  const [activities, setActivities] = useState<Activity[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activityMiddleware = () => (next: any) => (...setupArgs: any[]) => {
    const [{ activity }] = setupArgs;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (...renderArgs: any[]) => {
      return <div onClick={() => setSelectedActivity(activity)}>{next(...setupArgs)(...renderArgs)}</div>;
    };
  };

  const initDirectLineAsync = async () => {
    setInitializing(true);

    let agentId;
    try {
      const response = await conversationApi.getConversation(id);
      agentId = response.data.agentId;
      setConversation(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных по беседе',
        error: e,
      });
      return;
    }

    try {
      const response = await agentApi.getAgent(agentId);
      setAgent(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }

    try {
      const response = await activityApi.exportActivities(
        undefined,
        undefined,
        undefined,
        id,
        undefined,
        undefined,
        undefined
      );
      setActivities(response.data as Activity[]);

      const { data: userId } = await directLineTranscriptApi.getDlExportUserId(id);
      const profile = getProfile();
      setWebChatProps({
        activityMiddleware,
        directLine: createDirectLine({
          domain: `/api/v1/runtime/dl-export/${id}`,
          token: profile.accessToken,
          webSocket: false,
          pollingInterval: webChatPollingInterval,
        }),
        userID: userId,
        disabled: true,
        styleOptions,
        renderMarkdown: webChatMarkdownRenderer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка сообщений',
        error: e,
      });
    }

    setInitializing(false);
  };
  const initDirectLine = () => {
    initDirectLineAsync();
  };
  useEffect(initDirectLine, []);

  const getTreeTitle = (key: string, value?: number | string | boolean | null | []) => {
    return (
      <span>
        <span style={{ color: colors.BLUE }}>{key}: </span>
        {typeof value === 'string' ? (
          <span style={{ color: colors.RED }}>&quot;{value}&quot;</span>
        ) : typeof value === 'number' || typeof value === 'boolean' ? (
          <span style={{ color: colors.GREEN }}>{value.toString()}</span>
        ) : Array.isArray(value) ? (
          <span>[ ]</span>
        ) : (
          <span>null</span>
        )}
      </span>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTreeData = (element: any, parentKey = rootKey): any => {
    if (!element) {
      return [];
    }

    const children = Object.keys(element).map((key) => {
      const elementKey = `${parentKey}.${key}`;
      if (element[key] === null || element[key] === undefined) {
        return {
          title: getTreeTitle(key, null),
          key: elementKey,
          selectable: false,
        };
      }

      if (['string', 'number', 'boolean'].includes(typeof element[key])) {
        return {
          title: getTreeTitle(key, element[key]),
          key: elementKey,
          selectable: false,
        };
      }

      if (Array.isArray(element[key]) && !element[key].length) {
        return {
          title: getTreeTitle(key, []),
          key: elementKey,
          selectable: false,
        };
      }

      return {
        title: key,
        key: elementKey,
        selectable: false,
        children: Object.keys(element[key]).length ? getTreeData(element[key], elementKey) : [],
      };
    });

    if (parentKey === rootKey) {
      return [
        {
          title: parentKey,
          key: parentKey,
          selectable: false,
          children,
        },
      ];
    }

    return children;
  };

  const treeData = getTreeData(selectedActivity);

  return (
    <Spin spinning={initializing}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh', background: '#fff' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
          }}
        >
          <span>
            <h3 style={{ display: 'inline', margin: 0, paddingLeft: 12 }}>
              <b>{agent?.name}</b>
            </h3>
            <Divider plain type="vertical" />
            <span>
              <b>ID беседы:</b> {id}
            </span>
            <Divider plain type="vertical" />
            <span>
              <b>Канал:</b> {getChannelName(conversation?.channelId)}
            </span>
            <Divider plain type="vertical" />
            <span>
              c <FormattedDateTime value={conversation?.startedOn} /> по{' '}
              <FormattedDateTime value={conversation?.finishedOn} />
            </span>
          </span>
          <ConversationTranscriptLink conversationId={id} />
        </div>
        <ColsWrapper
          separatorProps={{
            style: { height: '100%', width: separatorThickness },
            children: (
              <Divider
                style={{
                  height: '100%',
                  margin: 0,
                  marginLeft: separatorThickness / 2,
                  marginRight: separatorThickness / 2,
                }}
                type="vertical"
              />
            ),
          }}
        >
          <Col style={{ height: '100%' }}>{initializing ? undefined : <ReactWebChat {...webChatProps} />}</Col>
          <Col style={{ fontSize: debugConsoleFontSize }}>
            <RowsWrapper
              separatorProps={{
                style: { height: separatorThickness, paddingRight: separatorThickness / 2 },
                children: (
                  <Divider
                    style={{
                      margin: 0,
                      marginTop: separatorThickness / 2,
                      marginBottom: separatorThickness / 2,
                    }}
                    type="horizontal"
                  />
                ),
              }}
            >
              <Row style={{ display: 'flex', flexDirection: 'column' }}>
                {selectedActivity ? (
                  <div style={{ flexGrow: 1, overflow: 'auto' }}>
                    <Tree
                      defaultExpandedKeys={[rootKey]}
                      showLine={{ showLeafIcon: false }}
                      style={{ fontSize: debugConsoleFontSize }}
                      switcherIcon={<DownOutlined />}
                      treeData={treeData}
                    />
                  </div>
                ) : (
                  <div className="flex-center" style={{ fontSize: 16 }}>
                    Выберите сообщение для просмотра подробностей
                  </div>
                )}
              </Row>
              <Row style={{ overflow: 'auto' }}>
                {(activities || []).map((activity: Activity, index) => {
                  const time = <Moment date={activity.timestamp} format={'HH:mm:ss'} style={{ color: colors.GREEN }} />;

                  const direction = activity.from?.role === BOT_ROLE_NAME ? '<-' : '->';

                  const type = (
                    <Button
                      style={{ fontSize: debugConsoleFontSize, padding: 0 }}
                      type="link"
                      onClick={() => setSelectedActivity(activity)}
                    >
                      {activity.type}
                    </Button>
                  );

                  const description =
                    activity.type === 'message' ? activity.text : activity.type === 'trace' ? activity.name : null;

                  return (
                    <div key={index}>
                      [{time}] {direction} {type} {description}
                    </div>
                  );
                })}
              </Row>
            </RowsWrapper>
          </Col>
        </ColsWrapper>
      </div>
    </Spin>
  );
};

export default ConversationCard;
