import React, { useState, CSSProperties } from 'react';
import { Button, Divider, Modal, Spin } from 'antd';
import { ReloadOutlined, WechatOutlined } from '@ant-design/icons';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import throttle from 'lodash/throttle';

import { getProfile } from '../../utils/oidcUtil';
import { getConfig } from '../../utils/configUtil';
import { InstanceModel, PublishStatusState } from '../../../kb-api';
import { webChatStyleOptions, webChatMarkdownRenderer } from '../../constants';

import DetailsButton from './DetailsButton';

const webChatPollingInterval = 1000; // мс
const typingIndicatorThrottleTimeout = 500; // мс, должен быть меньше чем webChatPollingInterval

export interface IKBWebChatProps {
  instance: InstanceModel | undefined;
  title?: string | null | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const activityMiddleware = () => (next: any) => (...setupArgs: any[]) => {
  const [{ activity }] = setupArgs;

  if (activity.type === 'typing') return next(...setupArgs);

  const details =
    activity.from.role === 'bot' ? (
      <pre style={{ maxWidth: 480, maxHeight: 400 }}>{JSON.stringify(activity.value, null, 2)}</pre>
    ) : null;

  const wrapperStyle: CSSProperties =
    activity.from.role === 'bot' && !activity.value.isRecognized
      ? {
          borderLeftColor: 'red',
          borderLeftStyle: 'solid',
          borderLeftWidth: 5,
          marginLeft: 8,
        }
      : {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...renderArgs: any[]) => {
    return (
      <div style={wrapperStyle}>
        {next(...setupArgs)(...renderArgs)}
        {details && <DetailsButton content={details} />}
      </div>
    );
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTypingIndicator = throttle((next: any, setupArgs: any) => {
  return setupArgs.visible ? next(setupArgs) : null;
}, typingIndicatorThrottleTimeout);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typingIndicatorMiddleware = () => (next: any) => (setupArgs: any) => getTypingIndicator(next, setupArgs);

const styleOptions = {
  ...webChatStyleOptions,
  rootHeight: 'calc(100% - 40px)', // NOTE: уменьшаем высоту чата на размер кнопки с отступом
};

const KBWebChat: React.FC<IKBWebChatProps> = ({ instance, title }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [webChatProps, setWebChatProps] = useState({});

  const initDirectLine = async () => {
    if (instance?.status?.state !== PublishStatusState.Success) return;

    setInitializing(true);

    const profile = getProfile();
    const config = await getConfig();
    const basePath = config?.knowledgeBaseService?.basePath;
    setWebChatProps({
      activityMiddleware,
      typingIndicatorMiddleware,
      directLine: createDirectLine({
        domain: `${basePath}/api/v1/directline/${instance.id}`,
        token: profile.accessToken,
        webSocket: false,
        pollingInterval: webChatPollingInterval,
      }),
      userID: profile.userId,
      username: profile.userName,
      styleOptions,
      renderMarkdown: webChatMarkdownRenderer,
    });

    setInitializing(false);
  };

  const onOpenModalBtnClick = async () => {
    setModalVisible(true);
    await initDirectLine();
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const onRestartButtonClick = () => {
    initDirectLine();
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        disabled={instance?.status?.state !== PublishStatusState.Success}
        icon={<WechatOutlined />}
        type="primary"
        onClick={onOpenModalBtnClick}
      >
        Тест
      </Button>
      <Modal
        destroyOnClose
        bodyStyle={{ height: '600px', paddingTop: 8 }}
        footer={null}
        title={title}
        visible={modalVisible}
        width={750}
        onCancel={onModalCancel}
      >
        <Button
          icon={<ReloadOutlined />}
          loading={initializing}
          style={{ paddingLeft: 0 }}
          type="link"
          onClick={onRestartButtonClick}
        >
          Начать заново
        </Button>
        <Divider style={{ marginTop: 8, marginBottom: 0 }} />
        {initializing ? <Spin /> : <ReactWebChat {...webChatProps} />}
      </Modal>
    </div>
  );
};

export default KBWebChat;
