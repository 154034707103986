import React, { useEffect, useState } from 'react';
import { Button, Modal, Table, Row, TablePaginationConfig } from 'antd';
import { TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { GroupModel, PersonModel } from '../../../api';
import { getFio } from '../../utils/stringUtil';
import { groupApi, personApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

export interface IAddGroupToPersonButtonProps {
  person: PersonModel;
}

const AddGroupToPersonButton: React.FC<IAddGroupToPersonButtonProps> = ({ person }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [modalVisible, setModalVisible] = useState(false);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [listGroups, setListGroups] = useState([] as GroupModel[]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadGroupsAsync = async () => {
    setGroupsLoading(true);
    try {
      const response = await groupApi.listGroups((pagination.current ?? 1) - 1, pagination.pageSize);
      setListGroups(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка групп',
        error: e,
      });
    }
    setGroupsLoading(false);
  };
  const loadGroups = () => {
    if (!modalVisible) return;
    loadGroupsAsync();
  };
  useEffect(loadGroups, [pagination.current, pagination.pageSize]);

  const onOpenModalBtnClick = () => {
    if (!person) return;

    setModalVisible(true);
    loadGroupsAsync();
  };

  const onModalCancelBtnClick = () => {
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const addPersonToGroupAsync = async (group: GroupModel) => {
    setGroupsLoading(true);

    try {
      await personApi.updatePerson(person.id, {
        ...person,
        groupIds: [...(person.groupIds || []), group.id] as string[],
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении сотрудника в группу',
        error: e,
      });
    }

    setModalVisible(false);

    setGroupsLoading(false);
  };

  const onAddBtnClick = (group: GroupModel) => {
    addPersonToGroupAsync(group);
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const getAddBtnDisabled = (group: GroupModel) => {
    return !group || person.groupIds?.some((g) => g === group.id);
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: '40%',
    },
    {
      render: (_text: unknown, group: GroupModel) => (
        <Row align="middle" justify="end" style={{ width: '100%' }}>
          <Button
            disabled={getAddBtnDisabled(group)}
            icon={<UsergroupAddOutlined />}
            type="primary"
            onClick={() => onAddBtnClick(group)}
          >
            Добавить
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <div>
      <Button icon={<TeamOutlined />} type="primary" onClick={onOpenModalBtnClick}>
        Добавить группу
      </Button>
      <Modal
        destroyOnClose
        footer={[
          <Row key="buttons" align="middle" justify="end" style={{ width: '100%' }}>
            <Button key="back" onClick={onModalCancelBtnClick}>
              Отмена
            </Button>
          </Row>,
        ]}
        title={getFio(person)}
        visible={modalVisible}
        onCancel={onModalCancel}
      >
        <Table
          columns={columns}
          dataSource={listGroups.map((item, i) => ({ key: i, ...item }))}
          loading={groupsLoading}
          pagination={pagination}
          onChange={onTableChange}
        />
      </Modal>
    </div>
  );
};

export default AddGroupToPersonButton;
