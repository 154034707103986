/* tslint:disable */
/* eslint-disable */
/**
 * Менеджер ELMA Bot
 * Управление сотрудниками, ботами, каналами, кампаниями и т.д.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    name?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    description?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    version?: string | null;
}
/**
 * 
 * @export
 * @interface AccountContact
 */
export interface AccountContact {
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    channelId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    agentId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    agentStageId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    agentStageAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    personAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    externalBotId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    externalUserId?: string | null;
    /**
     * 
     * @type {AccountContactState}
     * @memberof AccountContact
     */
    state: AccountContactState;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContact
     */
    modifiedOn?: string;
}
/**
 * Модель контакта между аккаунтами.
 * @export
 * @interface AccountContactModel
 */
export interface AccountContactModel {
    /**
     * ИД контакта.
     * @type {string}
     * @memberof AccountContactModel
     */
    id?: string | null;
    /**
     * ИД канала.
     * @type {string}
     * @memberof AccountContactModel
     */
    channelId?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AccountContactModel
     */
    agentStageId?: string | null;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof AccountContactModel
     */
    personId?: string | null;
    /**
     * ИД аккаунта стадии бота.
     * @type {string}
     * @memberof AccountContactModel
     */
    agentStageAccountId?: string | null;
    /**
     * ИД аккаунта сотрудника.
     * @type {string}
     * @memberof AccountContactModel
     */
    personAccountId?: string | null;
    /**
     * 
     * @type {AccountContactState}
     * @memberof AccountContactModel
     */
    state?: AccountContactState;
}
/**
 * 
 * @export
 * @interface AccountContactModelPaginationResponse
 */
export interface AccountContactModelPaginationResponse {
    /**
     * 
     * @type {Array<AccountContactModel>}
     * @memberof AccountContactModelPaginationResponse
     */
    items?: Array<AccountContactModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface AccountContactPaginationResponse
 */
export interface AccountContactPaginationResponse {
    /**
     * 
     * @type {Array<AccountContact>}
     * @memberof AccountContactPaginationResponse
     */
    items?: Array<AccountContact> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccountContactPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountContactPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountContactPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountContactPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AccountContactState {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface ActionGroupSchema
 */
export interface ActionGroupSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof ActionGroupSchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof ActionGroupSchema
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ActionSchema
 */
export interface ActionSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof ActionSchema
     */
    $kind: string;
}
/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    timestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    localTimestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    serviceUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    channelId?: string | null;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof Activity
     */
    from?: ChannelAccount;
    /**
     * 
     * @type {ConversationAccount}
     * @memberof Activity
     */
    conversation?: ConversationAccount;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof Activity
     */
    recipient?: ChannelAccount;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    textFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    attachmentLayout?: string | null;
    /**
     * 
     * @type {Array<ChannelAccount>}
     * @memberof Activity
     */
    membersAdded?: Array<ChannelAccount> | null;
    /**
     * 
     * @type {Array<ChannelAccount>}
     * @memberof Activity
     */
    membersRemoved?: Array<ChannelAccount> | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    topicName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Activity
     */
    historyDisclosed?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    speak?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    inputHint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    summary?: string | null;
    /**
     * 
     * @type {SuggestedActions}
     * @memberof Activity
     */
    suggestedActions?: SuggestedActions;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Activity
     */
    attachments?: Array<Attachment> | null;
    /**
     * 
     * @type {Array<Entity>}
     * @memberof Activity
     */
    entities?: Array<Entity> | null;
    /**
     * 
     * @type {any}
     * @memberof Activity
     */
    channelData?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    replyToId?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Activity
     */
    value?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    name?: string | null;
    /**
     * 
     * @type {ConversationReference}
     * @memberof Activity
     */
    relatesTo?: ConversationReference;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityAttachment
 */
export interface ActivityAttachment {
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    contentUrl?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ActivityAttachment
     */
    content?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityAttachment
     */
    thumbnailUrl?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ActivityDirection {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * Модель активности.
 * @export
 * @interface ActivityModel
 */
export interface ActivityModel {
    /**
     * ИД активности.
     * @type {string}
     * @memberof ActivityModel
     */
    id?: string | null;
    /**
     * Внешний ИД.
     * @type {string}
     * @memberof ActivityModel
     */
    externalId?: string | null;
    /**
     * ИД канала.
     * @type {string}
     * @memberof ActivityModel
     */
    channelId?: string | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof ActivityModel
     */
    agentId?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof ActivityModel
     */
    agentStageId?: string | null;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof ActivityModel
     */
    personId?: string | null;
    /**
     * ИД беседы.
     * @type {string}
     * @memberof ActivityModel
     */
    conversationId?: string | null;
    /**
     * ИД корреляции.
     * @type {string}
     * @memberof ActivityModel
     */
    correlationId?: string | null;
    /**
     * 
     * @type {ActivityDirection}
     * @memberof ActivityModel
     */
    direction?: ActivityDirection;
    /**
     * Тип активности.
     * @type {string}
     * @memberof ActivityModel
     */
    type?: string | null;
    /**
     * Наименование активности.
     * @type {string}
     * @memberof ActivityModel
     */
    name?: string | null;
    /**
     * Текст активности.
     * @type {string}
     * @memberof ActivityModel
     */
    text?: string | null;
    /**
     * Дата.
     * @type {string}
     * @memberof ActivityModel
     */
    date?: string | null;
    /**
     * Value
     * @type {any}
     * @memberof ActivityModel
     */
    value?: any | null;
    /**
     * Наименования доступных кнопок.
     * @type {Array<string>}
     * @memberof ActivityModel
     */
    suggestedActions?: Array<string> | null;
    /**
     * Вложения.
     * @type {Array<ActivityAttachment>}
     * @memberof ActivityModel
     */
    attachments?: Array<ActivityAttachment> | null;
}
/**
 * 
 * @export
 * @interface ActivityModelPaginationResponse
 */
export interface ActivityModelPaginationResponse {
    /**
     * 
     * @type {Array<ActivityModel>}
     * @memberof ActivityModelPaginationResponse
     */
    items?: Array<ActivityModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface ActivitySet
 */
export interface ActivitySet {
    /**
     * 
     * @type {Array<Activity>}
     * @memberof ActivitySet
     */
    activities?: Array<Activity> | null;
    /**
     * 
     * @type {string}
     * @memberof ActivitySet
     */
    watermark?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalSettings
 */
export interface AdditionalSettings {
    /**
     * 
     * @type {string}
     * @memberof AdditionalSettings
     */
    greeting?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalSettings
     */
    transferToOperatorByUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalSettings
     */
    transferToOperatorInUnknownIntent?: boolean;
    /**
     * 
     * @type {Array<AgentStageKnowledgeBase>}
     * @memberof AdditionalSettings
     */
    knowledgeBases?: Array<AgentStageKnowledgeBase> | null;
}
/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {AgentType}
     * @memberof Agent
     */
    type?: AgentType;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    stagingAgentStageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    productionAgentStageId?: string | null;
    /**
     * 
     * @type {AgentCreationStatus}
     * @memberof Agent
     */
    creationStatus?: AgentCreationStatus;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    modifiedOn?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AgentCreationMode {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * Запрос на создание бота.
 * @export
 * @interface AgentCreationRequest
 */
export interface AgentCreationRequest {
    /**
     * Наименование бота.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    name: string;
    /**
     * 
     * @type {AgentCreationMode}
     * @memberof AgentCreationRequest
     */
    creationMode: AgentCreationMode;
    /**
     * Описание бота.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    description?: string | null;
    /**
     * Код готового шаблона бота.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    templateCode?: string | null;
    /**
     * Архив с ботом.
     * @type {string}
     * @memberof AgentCreationRequest
     */
    botZip?: string | null;
    /**
     * 
     * @type {AgentWizardConfig}
     * @memberof AgentCreationRequest
     */
    config?: AgentWizardConfig;
}
/**
 * Ответ на создание бота.
 * @export
 * @interface AgentCreationResponse
 */
export interface AgentCreationResponse {
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentCreationResponse
     */
    id?: string | null;
    /**
     * Дата/время создания бота.
     * @type {string}
     * @memberof AgentCreationResponse
     */
    createdOn?: string;
    /**
     * 
     * @type {AgentStageCreationResponse}
     * @memberof AgentCreationResponse
     */
    stagingAgent?: AgentStageCreationResponse;
    /**
     * 
     * @type {AgentStageCreationResponse}
     * @memberof AgentCreationResponse
     */
    productionAgent?: AgentStageCreationResponse;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AgentCreationState {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface AgentCreationStatus
 */
export interface AgentCreationStatus {
    /**
     * 
     * @type {AgentCreationState}
     * @memberof AgentCreationStatus
     */
    state?: AgentCreationState;
    /**
     * 
     * @type {string}
     * @memberof AgentCreationStatus
     */
    errorMessage?: string | null;
}
/**
 * Модель бота.
 * @export
 * @interface AgentModel
 */
export interface AgentModel {
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentModel
     */
    id: string;
    /**
     * Дата/время создания бота.
     * @type {string}
     * @memberof AgentModel
     */
    createdOn?: string;
    /**
     * Наименование бота.
     * @type {string}
     * @memberof AgentModel
     */
    name: string;
    /**
     * Описание бота.
     * @type {string}
     * @memberof AgentModel
     */
    description?: string | null;
    /**
     * 
     * @type {AgentStageModel}
     * @memberof AgentModel
     */
    stagingAgent: AgentStageModel;
    /**
     * 
     * @type {AgentStageModel}
     * @memberof AgentModel
     */
    productionAgent: AgentStageModel;
    /**
     * 
     * @type {AgentCreationStatus}
     * @memberof AgentModel
     */
    creationStatus: AgentCreationStatus;
}
/**
 * 
 * @export
 * @interface AgentModelPaginationResponse
 */
export interface AgentModelPaginationResponse {
    /**
     * 
     * @type {Array<AgentModel>}
     * @memberof AgentModelPaginationResponse
     */
    items?: Array<AgentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Модель проактивного диалога бота.
 * @export
 * @interface AgentProactiveDialogModel
 */
export interface AgentProactiveDialogModel {
    /**
     * Id проактивного диалога.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    id?: string | null;
    /**
     * Отображаемое название проактивного диалога.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    name?: string | null;
    /**
     * Id стадии агента, к которой относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    agentStageId?: string | null;
    /**
     * Стадия агента, к которой относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    agentStageType?: string | null;
    /**
     * Id агента, к которому относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    agentId?: string | null;
    /**
     * Название агента, к которому относится проактивный диалог.
     * @type {string}
     * @memberof AgentProactiveDialogModel
     */
    agentName?: string | null;
    /**
     * Параметры проактивного диалога.
     * @type {{ [key: string]: AgentProactiveDialogPropertyModel; }}
     * @memberof AgentProactiveDialogModel
     */
    properties?: { [key: string]: AgentProactiveDialogPropertyModel; } | null;
}
/**
 * Свойство запуска проактивного диалога.
 * @export
 * @interface AgentProactiveDialogPropertyModel
 */
export interface AgentProactiveDialogPropertyModel {
    /**
     * Тип свойства.
     * @type {string}
     * @memberof AgentProactiveDialogPropertyModel
     */
    type?: string | null;
    /**
     * Значение свойства по умолчанию.
     * @type {string}
     * @memberof AgentProactiveDialogPropertyModel
     */
    defaultValue?: string | null;
    /**
     * Отображаемое имя свойства.
     * @type {string}
     * @memberof AgentProactiveDialogPropertyModel
     */
    displayName?: string | null;
}
/**
 * 
 * @export
 * @interface AgentPublishRequest
 */
export interface AgentPublishRequest {
    /**
     * ИД версии бота из хранилища ботов.
     * @type {string}
     * @memberof AgentPublishRequest
     */
    versionId?: string | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentPublishRequest
     */
    agentId?: string | null;
    /**
     * 
     * @type {PublishStage}
     * @memberof AgentPublishRequest
     */
    publishStage?: PublishStage;
}
/**
 * 
 * @export
 * @interface AgentRetrainRequest
 */
export interface AgentRetrainRequest {
    /**
     * ИД бота.
     * @type {string}
     * @memberof AgentRetrainRequest
     */
    agentId?: string | null;
    /**
     * 
     * @type {PublishStage}
     * @memberof AgentRetrainRequest
     */
    publishStage?: PublishStage;
}
/**
 * Запрос на создание аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountCreationRequest
 */
export interface AgentStageAccountCreationRequest {
    /**
     * ИД канала.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    channelId: string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    agentStageId: string;
    /**
     * Отображаемое наименование.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    displayName?: string | null;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof AgentStageAccountCreationRequest
     */
    authToken: string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof AgentStageAccountCreationRequest
     */
    properties?: { [key: string]: any; } | null;
}
/**
 * Ответ на создание аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountCreationResponse
 */
export interface AgentStageAccountCreationResponse {
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    id?: string | null;
    /**
     * Дата/время создания аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    createdOn?: string;
    /**
     * URI аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    botUri?: string | null;
    /**
     * URL перенаправления на чат со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    redirectUrl: string;
    /**
     * URL обратного вызова для взаимодействия со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountCreationResponse
     */
    callbackUrl: string;
}
/**
 * Модель аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountModel
 */
export interface AgentStageAccountModel {
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    id: string;
    /**
     * Дата/время создания аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    createdOn?: string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    channelId: string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    agentStageId: string;
    /**
     * Отображаемое наименование.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    displayName?: string | null;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    authToken: string;
    /**
     * URI аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    botUri: string;
    /**
     * Внешнее наименование.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    externalName: string;
    /**
     * URL перенаправления на чат со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    redirectUrl: string;
    /**
     * URL обратного вызова для взаимодействия со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountModel
     */
    callbackUrl: string;
    /**
     * 
     * @type {AgentStageAccountSetupResult}
     * @memberof AgentStageAccountModel
     */
    setupResult: AgentStageAccountSetupResult;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof AgentStageAccountModel
     */
    properties?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface AgentStageAccountModelPaginationResponse
 */
export interface AgentStageAccountModelPaginationResponse {
    /**
     * 
     * @type {Array<AgentStageAccountModel>}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    items?: Array<AgentStageAccountModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentStageAccountModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface AgentStageAccountSetupResult
 */
export interface AgentStageAccountSetupResult {
    /**
     * 
     * @type {SetupStatus}
     * @memberof AgentStageAccountSetupResult
     */
    status?: SetupStatus;
    /**
     * 
     * @type {string}
     * @memberof AgentStageAccountSetupResult
     */
    errorMessage?: string | null;
}
/**
 * Запрос на обновление аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountUpdatingRequest
 */
export interface AgentStageAccountUpdatingRequest {
    /**
     * ИД канала.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    channelId: string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    agentStageId: string;
    /**
     * Отображаемое наименование.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    displayName?: string | null;
    /**
     * Токен аутентификации канала.
     * @type {string}
     * @memberof AgentStageAccountUpdatingRequest
     */
    authToken: string;
    /**
     * Набор дополнительных свойств.
     * @type {{ [key: string]: any; }}
     * @memberof AgentStageAccountUpdatingRequest
     */
    properties?: { [key: string]: any; } | null;
}
/**
 * Ответ на обновление аккаунта стадии бота в канале.
 * @export
 * @interface AgentStageAccountUpdatingResponse
 */
export interface AgentStageAccountUpdatingResponse {
    /**
     * URI аккаунта стадии бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingResponse
     */
    botUri?: string | null;
    /**
     * URL перенаправления на чат со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingResponse
     */
    redirectUrl: string;
    /**
     * URL обратного вызова для взаимодействия со стадией бота.
     * @type {string}
     * @memberof AgentStageAccountUpdatingResponse
     */
    callbackUrl: string;
}
/**
 * Ответ на создание стадии бота.
 * @export
 * @interface AgentStageCreationResponse
 */
export interface AgentStageCreationResponse {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageCreationResponse
     */
    id?: string | null;
    /**
     * Дата/время создания стадии бота.
     * @type {string}
     * @memberof AgentStageCreationResponse
     */
    createdOn?: string;
}
/**
 * 
 * @export
 * @interface AgentStageKnowledgeBase
 */
export interface AgentStageKnowledgeBase {
    /**
     * 
     * @type {string}
     * @memberof AgentStageKnowledgeBase
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentStageKnowledgeBase
     */
    enabled?: boolean;
}
/**
 * Модель стадии бота.
 * @export
 * @interface AgentStageModel
 */
export interface AgentStageModel {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof AgentStageModel
     */
    id: string;
    /**
     * Дата/время создания стадии бота.
     * @type {string}
     * @memberof AgentStageModel
     */
    createdOn?: string;
    /**
     * Включено или нет версионирование диалогов.
     * @type {boolean}
     * @memberof AgentStageModel
     */
    dialogsVersioningEnabled: boolean;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof AgentStageModel
     */
    emulatorUrl: string;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof AgentStageModel
     */
    webChatUrl: string;
    /**
     * 
     * @type {TrainResult}
     * @memberof AgentStageModel
     */
    trainResult: TrainResult;
    /**
     * 
     * @type {AdditionalSettings}
     * @memberof AgentStageModel
     */
    settings?: AdditionalSettings;
    /**
     * 
     * @type {ConversationSettings}
     * @memberof AgentStageModel
     */
    conversationSettings?: ConversationSettings;
}
/**
 * Запрос на обновление стадии бота.
 * @export
 * @interface AgentStageUpdatingRequest
 */
export interface AgentStageUpdatingRequest {
    /**
     * Включено или нет версионирование диалогов.
     * @type {boolean}
     * @memberof AgentStageUpdatingRequest
     */
    dialogsVersioningEnabled?: boolean;
}
/**
 * Тестовый бот.
 * @export
 * @interface AgentTestInstanceResponse
 */
export interface AgentTestInstanceResponse {
    /**
     * ИД стадии тестового бота.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    agentStageId: string;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    emulatorUrl: string;
    /**
     * 
     * @type {TrainResult}
     * @memberof AgentTestInstanceResponse
     */
    trainResult: TrainResult;
    /**
     * Дата создания.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    createdOn?: string;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    modifiedOn?: string;
    /**
     * ИД версии бота.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    botVersionId: string;
    /**
     * Название версии бота.
     * @type {string}
     * @memberof AgentTestInstanceResponse
     */
    botVersionName: string;
    /**
     * Версия бота является тестовой.
     * @type {boolean}
     * @memberof AgentTestInstanceResponse
     */
    botVersionIsStaging?: boolean;
    /**
     * Версия бота является релизной.
     * @type {boolean}
     * @memberof AgentTestInstanceResponse
     */
    botVersionIsProduction?: boolean;
}
/**
 * 
 * @export
 * @interface AgentTestInstanceResponsePaginationResponse
 */
export interface AgentTestInstanceResponsePaginationResponse {
    /**
     * 
     * @type {Array<AgentTestInstanceResponse>}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    items?: Array<AgentTestInstanceResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentTestInstanceResponsePaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AgentType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * Запрос на обновление бота.
 * @export
 * @interface AgentUpdatingRequest
 */
export interface AgentUpdatingRequest {
    /**
     * Наименование бота.
     * @type {string}
     * @memberof AgentUpdatingRequest
     */
    name: string;
    /**
     * Описание бота.
     * @type {string}
     * @memberof AgentUpdatingRequest
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface AgentWizardConfig
 */
export interface AgentWizardConfig {
    /**
     * 
     * @type {string}
     * @memberof AgentWizardConfig
     */
    greeting?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWizardConfig
     */
    transferToOperatorByUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWizardConfig
     */
    transferToOperatorInUnknownIntent?: boolean;
    /**
     * 
     * @type {BinaryKnowledgeData}
     * @memberof AgentWizardConfig
     */
    knowledgeData?: BinaryKnowledgeData;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWizardConfig
     */
    chitchatEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentWizardConfig
     */
    chitchatCode?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum AnalyticsEventMode {
    Testing = 'Testing',
    Release = 'Release'
}

/**
 * Общие данные по аналитике.
 * @export
 * @interface AnalyticsReportsGeneralDataModel
 */
export interface AnalyticsReportsGeneralDataModel {
    /**
     * Общее количество бесед.
     * @type {number}
     * @memberof AnalyticsReportsGeneralDataModel
     */
    conversationCount?: number;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    contentUrl?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Attachment
     */
    content?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    thumbnailUrl?: string | null;
}
/**
 * 
 * @export
 * @interface AttachmentSchema
 */
export interface AttachmentSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof AttachmentSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    fileId: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentSchema
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BinaryConditionOperator {
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    Greater = 'Greater',
    GreaterOrEquals = 'GreaterOrEquals',
    Less = 'Less',
    LessOrEquals = 'LessOrEquals'
}

/**
 * 
 * @export
 * @interface BinaryConditionSchema
 */
export interface BinaryConditionSchema extends ConditionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof BinaryConditionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {BinaryConditionOperator}
     * @memberof BinaryConditionSchema
     */
    operator: BinaryConditionOperator;
    /**
     * 
     * @type {OperandSchema}
     * @memberof BinaryConditionSchema
     */
    leftOperand: OperandSchema;
    /**
     * 
     * @type {OperandSchema}
     * @memberof BinaryConditionSchema
     */
    rightOperand: OperandSchema;
}
/**
 * 
 * @export
 * @interface BinaryKnowledgeData
 */
export interface BinaryKnowledgeData {
    /**
     * 
     * @type {BinarySourceFormat}
     * @memberof BinaryKnowledgeData
     */
    format?: BinarySourceFormat;
    /**
     * 
     * @type {string}
     * @memberof BinaryKnowledgeData
     */
    data?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BinaryKnowledgeData
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BinarySourceFormat {
    Csv = 'Csv',
    Xls = 'Xls',
    Xlsx = 'Xlsx',
    Doc = 'Doc',
    Docx = 'Docx',
    Pdf = 'Pdf'
}

/**
 * 
 * @export
 * @interface BindingSchema
 */
export interface BindingSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof BindingSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof BindingSchema
     */
    sourceEntityId: string;
    /**
     * 
     * @type {string}
     * @memberof BindingSchema
     */
    targetEntityId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BotContentFormat {
    Json = 'Json',
    Zip = 'Zip'
}

/**
 * 
 * @export
 * @interface BotCreationRequest
 */
export interface BotCreationRequest {
    /**
     * Название бота.
     * @type {string}
     * @memberof BotCreationRequest
     */
    botName?: string | null;
    /**
     * Структура бота.
     * @type {any}
     * @memberof BotCreationRequest
     */
    botStructure?: any | null;
    /**
     * 
     * @type {FileContentData}
     * @memberof BotCreationRequest
     */
    botFile?: FileContentData;
    /**
     * Код шаблона, на основе которого создается бот.
     * @type {string}
     * @memberof BotCreationRequest
     */
    botTemplateCode?: string | null;
}
/**
 * 
 * @export
 * @interface BotCreationResponse
 */
export interface BotCreationResponse {
    /**
     * 
     * @type {BotEntryCreationResponse}
     * @memberof BotCreationResponse
     */
    entry: BotEntryCreationResponse;
    /**
     * 
     * @type {BotStageCreationResponse}
     * @memberof BotCreationResponse
     */
    originStage: BotStageCreationResponse;
    /**
     * 
     * @type {BotEditionCreationResponse}
     * @memberof BotCreationResponse
     */
    originSourceEdition: BotEditionCreationResponse;
    /**
     * 
     * @type {BotEditionCreationResponse}
     * @memberof BotCreationResponse
     */
    originCurrentEdition: BotEditionCreationResponse;
}
/**
 * 
 * @export
 * @interface BotEditionAddingRequest
 */
export interface BotEditionAddingRequest {
    /**
     * 
     * @type {string}
     * @memberof BotEditionAddingRequest
     */
    botEditionId: string;
}
/**
 * 
 * @export
 * @interface BotEditionCreationResponse
 */
export interface BotEditionCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    agentVersionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    botEntryId: string;
    /**
     * 
     * @type {string}
     * @memberof BotEditionCreationResponse
     */
    description: string;
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof BotEditionCreationResponse
     */
    validation: StructureValidationResult;
}
/**
 * 
 * @export
 * @interface BotEditionModel
 */
export interface BotEditionModel {
    /**
     * ИД редакции бота.
     * @type {string}
     * @memberof BotEditionModel
     */
    id: string;
    /**
     * ИД версии сложного бота.
     * @type {string}
     * @memberof BotEditionModel
     */
    agentVersionId?: string | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotEditionModel
     */
    botEntryId: string;
    /**
     * Описание.
     * @type {string}
     * @memberof BotEditionModel
     */
    description: string;
    /**
     * 
     * @type {BotSchema}
     * @memberof BotEditionModel
     */
    structure: BotSchema;
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof BotEditionModel
     */
    validation: StructureValidationResult;
}
/**
 * 
 * @export
 * @interface BotEditionReferenceModel
 */
export interface BotEditionReferenceModel {
    /**
     * ИД редакции бота.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    botEditionId: string;
    /**
     * ИД структуры бота.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    botStructureId: string;
    /**
     * Дата создания редакции бота.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    createdOn: string;
    /**
     * 
     * @type {UserReferenceModel}
     * @memberof BotEditionReferenceModel
     */
    createdBy: UserReferenceModel;
    /**
     * Описание.
     * @type {string}
     * @memberof BotEditionReferenceModel
     */
    description: string;
    /**
     * Список сценариев.
     * @type {Array<ScenarioEditionReferenceModel>}
     * @memberof BotEditionReferenceModel
     */
    scenarios: Array<ScenarioEditionReferenceModel>;
}
/**
 * 
 * @export
 * @interface BotEntryCreationResponse
 */
export interface BotEntryCreationResponse {
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    id: string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    agentId: string;
    /**
     * Название бота.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    name: string;
    /**
     * Код шаблона, на основе которого создан бот.
     * @type {string}
     * @memberof BotEntryCreationResponse
     */
    botTemplateCode?: string | null;
}
/**
 * 
 * @export
 * @interface BotEntryModel
 */
export interface BotEntryModel {
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotEntryModel
     */
    id: string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotEntryModel
     */
    agentId: string;
    /**
     * Название бота.
     * @type {string}
     * @memberof BotEntryModel
     */
    name: string;
    /**
     * Код шаблона, на основе которого создан бот.
     * @type {string}
     * @memberof BotEntryModel
     */
    botTemplateCode?: string | null;
    /**
     * ИД подключенной базы знаний.
     * @type {string}
     * @memberof BotEntryModel
     */
    knowledgeBaseId?: string | null;
}
/**
 * 
 * @export
 * @interface BotExternalEventTrigger
 */
export interface BotExternalEventTrigger {
    /**
     * 
     * @type {string}
     * @memberof BotExternalEventTrigger
     */
    externalEventId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotExternalEventTrigger
     */
    externalEventName?: string | null;
    /**
     * 
     * @type {{ [key: string]: VariableContaner; }}
     * @memberof BotExternalEventTrigger
     */
    parameters?: { [key: string]: VariableContaner; } | null;
}
/**
 * 
 * @export
 * @interface BotImportRequest
 */
export interface BotImportRequest {
    /**
     * 
     * @type {FileContentData}
     * @memberof BotImportRequest
     */
    botFile: FileContentData;
}
/**
 * 
 * @export
 * @interface BotSchema
 */
export interface BotSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof BotSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof BotSchema
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof BotSchema
     */
    name: string;
    /**
     * 
     * @type {Array<ScenarioSchema>}
     * @memberof BotSchema
     */
    scenarios: Array<ScenarioSchema>;
    /**
     * 
     * @type {Array<VariableSchema>}
     * @memberof BotSchema
     */
    variables: Array<VariableSchema>;
    /**
     * 
     * @type {BotSettingsSchema}
     * @memberof BotSchema
     */
    settings: BotSettingsSchema;
}
/**
 * 
 * @export
 * @interface BotSettingsSchema
 */
export interface BotSettingsSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof BotSettingsSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {ScriptSettingsSchema}
     * @memberof BotSettingsSchema
     */
    script: ScriptSettingsSchema;
    /**
     * 
     * @type {Array<IntegrationSettingsSchema>}
     * @memberof BotSettingsSchema
     */
    integrations: Array<IntegrationSettingsSchema>;
    /**
     * 
     * @type {LocalizationSettingsSchema}
     * @memberof BotSettingsSchema
     */
    localization: LocalizationSettingsSchema;
    /**
     * 
     * @type {SmtpSettingsSchema}
     * @memberof BotSettingsSchema
     */
    smtp: SmtpSettingsSchema;
    /**
     * 
     * @type {RecognitionSettingsSchema}
     * @memberof BotSettingsSchema
     */
    recognition: RecognitionSettingsSchema;
    /**
     * 
     * @type {ConversationClosingSettingsSchema}
     * @memberof BotSettingsSchema
     */
    conversationClosing: ConversationClosingSettingsSchema;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BotSortDirection {
    StageModifiedOnDescending = 'StageModifiedOnDescending',
    EntryNameAscending = 'EntryNameAscending'
}

/**
 * 
 * @export
 * @interface BotStageCreationResponse
 */
export interface BotStageCreationResponse {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    id: string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    agentId: string;
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    botEntryId: string;
    /**
     * 
     * @type {BotStageType}
     * @memberof BotStageCreationResponse
     */
    type: BotStageType;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof BotStageCreationResponse
     */
    modifiedOn: string;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageCreationResponse
     */
    sourceEdition: BotEditionReferenceModel;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageCreationResponse
     */
    currentEdition: BotEditionReferenceModel;
    /**
     * История изменений.
     * @type {Array<BotEditionReferenceModel>}
     * @memberof BotStageCreationResponse
     */
    editionHistory: Array<BotEditionReferenceModel>;
}
/**
 * 
 * @export
 * @interface BotStageMergeRequest
 */
export interface BotStageMergeRequest {
    /**
     * Исходная стадия бота.
     * @type {string}
     * @memberof BotStageMergeRequest
     */
    sourceBotStageId: string;
    /**
     * Краткое описание изменений.
     * @type {string}
     * @memberof BotStageMergeRequest
     */
    description: string;
    /**
     * Признак, что необходимо перезаписать предыдущие изменения.
     * @type {boolean}
     * @memberof BotStageMergeRequest
     */
    force: boolean;
}
/**
 * 
 * @export
 * @interface BotStageMergeResponse
 */
export interface BotStageMergeResponse {
    /**
     * 
     * @type {MergeBotStageStatus}
     * @memberof BotStageMergeResponse
     */
    status: MergeBotStageStatus;
}
/**
 * 
 * @export
 * @interface BotStageModel
 */
export interface BotStageModel {
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof BotStageModel
     */
    id: string;
    /**
     * ИД сложного бота.
     * @type {string}
     * @memberof BotStageModel
     */
    agentId: string;
    /**
     * ИД бота.
     * @type {string}
     * @memberof BotStageModel
     */
    botEntryId: string;
    /**
     * 
     * @type {BotStageType}
     * @memberof BotStageModel
     */
    type: BotStageType;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof BotStageModel
     */
    modifiedOn: string;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageModel
     */
    sourceEdition: BotEditionReferenceModel;
    /**
     * 
     * @type {BotEditionReferenceModel}
     * @memberof BotStageModel
     */
    currentEdition: BotEditionReferenceModel;
    /**
     * История изменений.
     * @type {Array<BotEditionReferenceModel>}
     * @memberof BotStageModel
     */
    editionHistory: Array<BotEditionReferenceModel>;
}
/**
 * 
 * @export
 * @interface BotStageTestingResponse
 */
export interface BotStageTestingResponse {
    /**
     * 
     * @type {AgentStageModel}
     * @memberof BotStageTestingResponse
     */
    agentStage: AgentStageModel;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof BotStageTestingResponse
     */
    emulatorUrl: string;
    /**
     * URL обратного вызова.
     * @type {string}
     * @memberof BotStageTestingResponse
     */
    callbackUrl: string;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof BotStageTestingResponse
     */
    webchatUrl: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BotStageType {
    Origin = 'Origin',
    Draft = 'Draft'
}

/**
 * Шаблон для создания бота.
 * @export
 * @interface BotTemplateModel
 */
export interface BotTemplateModel {
    /**
     * Код шаблона.
     * @type {string}
     * @memberof BotTemplateModel
     */
    code: string;
    /**
     * Отображаемое название шаблона.
     * @type {string}
     * @memberof BotTemplateModel
     */
    name?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof BotTemplateModel
     */
    description?: string | null;
    /**
     * Признак того, что шаблон является шаблоном по-умолчанию.
     * @type {boolean}
     * @memberof BotTemplateModel
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface BotTrainingModelModel
 */
export interface BotTrainingModelModel {
    /**
     * Код модели.
     * @type {string}
     * @memberof BotTrainingModelModel
     */
    code: string;
    /**
     * Отображаемое название.
     * @type {string}
     * @memberof BotTrainingModelModel
     */
    displayName: string;
    /**
     * Описание.
     * @type {string}
     * @memberof BotTrainingModelModel
     */
    description: string;
}
/**
 * 
 * @export
 * @interface BotUpdatingRequest
 */
export interface BotUpdatingRequest {
    /**
     * ИД базы знаний.
     * @type {string}
     * @memberof BotUpdatingRequest
     */
    knowledgeBaseId?: string | null;
}
/**
 * 
 * @export
 * @interface BotVersionCreationRequest
 */
export interface BotVersionCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    agentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationRequest
     */
    file?: string | null;
}
/**
 * 
 * @export
 * @interface BotVersionCreationResponse
 */
export interface BotVersionCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationResponse
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BotVersionCreationResponse
     */
    agentId?: string | null;
}
/**
 * 
 * @export
 * @interface BotVersionModel
 */
export interface BotVersionModel {
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    agentId: string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BotVersionModel
     */
    isStaging: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BotVersionModel
     */
    isProduction: boolean;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    editUrl: string;
    /**
     * 
     * @type {string}
     * @memberof BotVersionModel
     */
    hash: string;
}
/**
 * 
 * @export
 * @interface BotVersionModelPaginationResponse
 */
export interface BotVersionModelPaginationResponse {
    /**
     * 
     * @type {Array<BotVersionModel>}
     * @memberof BotVersionModelPaginationResponse
     */
    items?: Array<BotVersionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BotVersionModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof BotVersionModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface ButtonSchema
 */
export interface ButtonSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ButtonSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof ButtonSchema
     */
    value: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ButtonSchema
     */
    synonyms: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ButtonSchema
     */
    outputBindingId?: string | null;
    /**
     * 
     * @type {ButtonType}
     * @memberof ButtonSchema
     */
    type: ButtonType;
    /**
     * 
     * @type {string}
     * @memberof ButtonSchema
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ButtonType {
    Transit = 'Transit',
    Url = 'Url'
}

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    personRecipients?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    groupRecipients?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    agentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    agentStageId?: string | null;
    /**
     * 
     * @type {Array<CampaignAccount>}
     * @memberof Campaign
     */
    agentStageAccounts?: Array<CampaignAccount> | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    proactiveDialogId?: string | null;
    /**
     * 
     * @type {Schedule}
     * @memberof Campaign
     */
    schedule?: Schedule;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Campaign
     */
    properties?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    modifiedOn?: string;
}
/**
 * 
 * @export
 * @interface CampaignAccount
 */
export interface CampaignAccount {
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    channelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    accountId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignAccount
     */
    externalName?: string | null;
}
/**
 * Модель аккаунта рассылки.
 * @export
 * @interface CampaignAccountModel
 */
export interface CampaignAccountModel {
    /**
     * ИД канала.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    channelId: string;
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    accountId: string;
    /**
     * Отображаемое имя аккаунта.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    displayName?: string | null;
    /**
     * Внешнее имя аккаунта.
     * @type {string}
     * @memberof CampaignAccountModel
     */
    externalName: string;
}
/**
 * Запрос на создание рассылки.
 * @export
 * @interface CampaignCreationRequest
 */
export interface CampaignCreationRequest {
    /**
     * Название рассылки.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    name: string;
    /**
     * Список ИД сотрудников.
     * @type {Array<string>}
     * @memberof CampaignCreationRequest
     */
    personRecipients: Array<string>;
    /**
     * Список ИД групп.
     * @type {Array<string>}
     * @memberof CampaignCreationRequest
     */
    groupRecipients?: Array<string> | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    agentId?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    agentStageId?: string | null;
    /**
     * Аккаунты стадии бота.
     * @type {Array<CampaignAccountModel>}
     * @memberof CampaignCreationRequest
     */
    agentStageAccounts: Array<CampaignAccountModel>;
    /**
     * ИД проактивного диалога.
     * @type {string}
     * @memberof CampaignCreationRequest
     */
    proactiveDialogId?: string | null;
    /**
     * 
     * @type {Schedule}
     * @memberof CampaignCreationRequest
     */
    schedule?: Schedule;
    /**
     * Свойства запуска проактивного диалога.
     * @type {{ [key: string]: any; }}
     * @memberof CampaignCreationRequest
     */
    properties?: { [key: string]: any; } | null;
}
/**
 * Ответ на создание рассылки.
 * @export
 * @interface CampaignCreationResponse
 */
export interface CampaignCreationResponse {
    /**
     * ИД рассылки.
     * @type {string}
     * @memberof CampaignCreationResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CampaignJobDescription
 */
export interface CampaignJobDescription {
    /**
     * 
     * @type {string}
     * @memberof CampaignJobDescription
     */
    id: string;
    /**
     * 
     * @type {Campaign}
     * @memberof CampaignJobDescription
     */
    campaign?: Campaign;
    /**
     * 
     * @type {{ [key: string]: Array<DialogProgressState>; }}
     * @memberof CampaignJobDescription
     */
    states?: { [key: string]: Array<DialogProgressState>; } | null;
}
/**
 * Запуск рассылки.
 * @export
 * @interface CampaignJobModel
 */
export interface CampaignJobModel {
    /**
     * ИД запуска рассылки.
     * @type {string}
     * @memberof CampaignJobModel
     */
    id: string;
    /**
     * ИД рассылки.
     * @type {string}
     * @memberof CampaignJobModel
     */
    campaignId: string;
    /**
     * Отменена.
     * @type {boolean}
     * @memberof CampaignJobModel
     */
    canceled: boolean;
    /**
     * Завершена.
     * @type {boolean}
     * @memberof CampaignJobModel
     */
    completed: boolean;
    /**
     * Список конечных получателей, материализованный из групп перед запуском рассылки
     * @type {Array<Person>}
     * @memberof CampaignJobModel
     */
    personRecipients: Array<Person>;
    /**
     * Результаты рассылок.
     * @type {Array<PersonalCampaignResult>}
     * @memberof CampaignJobModel
     */
    results: Array<PersonalCampaignResult>;
}
/**
 * 
 * @export
 * @interface CampaignJobModelPaginationResponse
 */
export interface CampaignJobModelPaginationResponse {
    /**
     * 
     * @type {Array<CampaignJobModel>}
     * @memberof CampaignJobModelPaginationResponse
     */
    items?: Array<CampaignJobModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignJobModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignJobModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface CampaignJobState
 */
export interface CampaignJobState {
    /**
     * 
     * @type {string}
     * @memberof CampaignJobState
     */
    id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignJobState
     */
    finished?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignJobState
     */
    hasErrors?: boolean;
    /**
     * 
     * @type {Array<PersonalCampaignResult>}
     * @memberof CampaignJobState
     */
    recipientState?: Array<PersonalCampaignResult> | null;
}
/**
 * Модель рассылки.
 * @export
 * @interface CampaignModel
 */
export interface CampaignModel {
    /**
     * ИД рассылки.
     * @type {string}
     * @memberof CampaignModel
     */
    id?: string | null;
    /**
     * Название рассылки.
     * @type {string}
     * @memberof CampaignModel
     */
    name: string;
    /**
     * Список ИД сотрудников.
     * @type {Array<string>}
     * @memberof CampaignModel
     */
    personRecipients: Array<string>;
    /**
     * Список ИД групп.
     * @type {Array<string>}
     * @memberof CampaignModel
     */
    groupRecipients?: Array<string> | null;
    /**
     * ИД бота.
     * @type {string}
     * @memberof CampaignModel
     */
    agentId?: string | null;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof CampaignModel
     */
    agentStageId?: string | null;
    /**
     * Аккаунты стадии бота.
     * @type {Array<CampaignAccountModel>}
     * @memberof CampaignModel
     */
    agentStageAccounts: Array<CampaignAccountModel>;
    /**
     * ИД проактивного диалога.
     * @type {string}
     * @memberof CampaignModel
     */
    proactiveDialogId?: string | null;
    /**
     * 
     * @type {Schedule}
     * @memberof CampaignModel
     */
    schedule?: Schedule;
    /**
     * Дата создания.
     * @type {string}
     * @memberof CampaignModel
     */
    createdOn?: string;
    /**
     * Данные по сотрудникам.
     * @type {Array<Person>}
     * @memberof CampaignModel
     */
    persons?: Array<Person> | null;
    /**
     * 
     * @type {Agent}
     * @memberof CampaignModel
     */
    agent?: Agent;
}
/**
 * 
 * @export
 * @interface CampaignModelPaginationResponse
 */
export interface CampaignModelPaginationResponse {
    /**
     * 
     * @type {Array<CampaignModel>}
     * @memberof CampaignModelPaginationResponse
     */
    items?: Array<CampaignModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface CardAction
 */
export interface CardAction {
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardAction
     */
    image?: string | null;
    /**
     * 
     * @type {any}
     * @memberof CardAction
     */
    value?: any | null;
}
/**
 * 
 * @export
 * @interface CaseActionSchema
 */
export interface CaseActionSchema extends ActionSchema {
    /**
     * 
     * @type {string}
     * @memberof CaseActionSchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof CaseActionSchema
     */
    outputBindingId?: string | null;
}
/**
 * 
 * @export
 * @interface ChannelAccount
 */
export interface ChannelAccount {
    /**
     * 
     * @type {string}
     * @memberof ChannelAccount
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelAccount
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface ChoiceTransitSchema
 */
export interface ChoiceTransitSchema extends TransitActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ChoiceTransitSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface CloseConversationSchema
 */
export interface CloseConversationSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof CloseConversationSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof CloseConversationSchema
     */
    closingMessage: string;
}
/**
 * 
 * @export
 * @interface ComparisonValidatorSchema
 */
export interface ComparisonValidatorSchema extends ValidatorSchema {
    /**
     * 
     * @type {string}
     * @memberof ComparisonValidatorSchema
     */
    $kind: string;
    /**
     * 
     * @type {BinaryConditionOperator}
     * @memberof ComparisonValidatorSchema
     */
    operator: BinaryConditionOperator;
}
/**
 * 
 * @export
 * @interface ConditionSchema
 */
export interface ConditionSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof ConditionSchema
     */
    $kind: string;
}
/**
 * 
 * @export
 * @interface ConfirmTransitSchema
 */
export interface ConfirmTransitSchema extends TransitActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ConfirmTransitSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface ConstantOperandSchema
 */
export interface ConstantOperandSchema extends OperandSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ConstantOperandSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {any}
     * @memberof ConstantOperandSchema
     */
    value?: any | null;
}
/**
 * Элемент контекста.
 * @export
 * @interface ContextElement
 */
export interface ContextElement {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ContextElement
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ContextElement
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    searchable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    indexed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    deleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    array: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    single: boolean;
    /**
     * 
     * @type {any}
     * @memberof ContextElement
     */
    defaultValue?: any | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElement
     */
    calcByFormula: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContextElement
     */
    formula: string;
    /**
     * 
     * @type {ContextElementData}
     * @memberof ContextElement
     */
    data: ContextElementData;
    /**
     * 
     * @type {ContextElementView}
     * @memberof ContextElement
     */
    view: ContextElementView;
}
/**
 * Данные элемента контекста.
 * @export
 * @interface ContextElementData
 */
export interface ContextElementData {
    [key: string]: any | any;

    /**
     * 
     * @type {Array<EnumItem>}
     * @memberof ContextElementData
     */
    enumItems?: Array<EnumItem> | null;
}
/**
 * Настройки отображения элемента контекста.
 * @export
 * @interface ContextElementView
 */
export interface ContextElementView {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ContextElementView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContextElementView
     */
    tooltip?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElementView
     */
    system?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContextElementView
     */
    hidden?: boolean | null;
    /**
     * 
     * @type {ContextElementViewData}
     * @memberof ContextElementView
     */
    data?: ContextElementViewData;
}
/**
 * Данные для настройки отображения элемента контекста.
 * @export
 * @interface ContextElementViewData
 */
export interface ContextElementViewData {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ContextElementViewData
     */
    additionalType?: string | null;
    /**
     * Валюта.
     * @type {string}
     * @memberof ContextElementViewData
     */
    currency?: string | null;
    /**
     * Дополнительное уточнение типа параметра.
     * @type {any}
     * @memberof ContextElementViewData
     */
    type?: any | null;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    conversationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    token?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    expires_in?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    streamUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    referenceGrammarId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    eTag?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationAccount
 */
export interface ConversationAccount {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationAccount
     */
    isGroup?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccount
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationAccount
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ConversationClosingReason {
    TimedOut = 'TimedOut',
    ClosedByScenarioAction = 'ClosedByScenarioAction',
    ClosedByOperator = 'ClosedByOperator',
    ConversationRestarted = 'ConversationRestarted'
}

/**
 * 
 * @export
 * @interface ConversationClosingSettingsSchema
 */
export interface ConversationClosingSettingsSchema {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationClosingSettingsSchema
     */
    closingEnabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConversationClosingSettingsSchema
     */
    closingTimeoutSeconds?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationClosingSettingsSchema
     */
    closingMessage?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ConversationClosingStatus {
    NotInitiated = 'NotInitiated',
    Initiated = 'Initiated',
    ClosingMessageSent = 'ClosingMessageSent',
    DialogResetEventSent = 'DialogResetEventSent',
    SessionClosed = 'SessionClosed'
}

/**
 * 
 * @export
 * @interface ConversationExportFilterParams
 */
export interface ConversationExportFilterParams {
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    agentStageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    startFromDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    startToDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    latestMessageFromDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    latestMessageToDate?: string | null;
    /**
     * 
     * @type {ConversationStatus}
     * @memberof ConversationExportFilterParams
     */
    status?: ConversationStatus;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportFilterParams
     */
    channelId?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ConversationExportStatus {
    Processing = 'Processing',
    Success = 'Success',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface ConversationExportStatusResultModel
 */
export interface ConversationExportStatusResultModel {
    /**
     * 
     * @type {ConversationExportStatus}
     * @memberof ConversationExportStatusResultModel
     */
    status?: ConversationExportStatus;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportStatusResultModel
     */
    fileUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationExportStatusResultModel
     */
    errorMessage?: string | null;
}
/**
 * Модель беседы.
 * @export
 * @interface ConversationModel
 */
export interface ConversationModel {
    /**
     * ИД беседы.
     * @type {string}
     * @memberof ConversationModel
     */
    id: string;
    /**
     * Внешний ИД беседы.
     * @type {string}
     * @memberof ConversationModel
     */
    externalId: string;
    /**
     * ИД канала.
     * @type {string}
     * @memberof ConversationModel
     */
    channelId: string;
    /**
     * ИД бота.
     * @type {string}
     * @memberof ConversationModel
     */
    agentId: string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof ConversationModel
     */
    agentStageId: string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof ConversationModel
     */
    personId: string;
    /**
     * Дата и время когда беседа была начата.
     * @type {string}
     * @memberof ConversationModel
     */
    startedOn: string;
    /**
     * Дата и время когда беседа завершилась.
     * @type {string}
     * @memberof ConversationModel
     */
    finishedOn: string;
    /**
     * Service Url беседы.
     * @type {string}
     * @memberof ConversationModel
     */
    serviceUrl: string;
    /**
     * Общее количество сообщений в беседе (activity с типом \"message\").
     * @type {number}
     * @memberof ConversationModel
     */
    messagesCount: number;
    /**
     * Свойства беседы.
     * @type {any}
     * @memberof ConversationModel
     */
    properties?: any | null;
    /**
     * Текст последнего сообщения или null, если сообщений нет.
     * @type {string}
     * @memberof ConversationModel
     */
    latestMessageText?: string | null;
    /**
     * 
     * @type {ActivityDirection}
     * @memberof ConversationModel
     */
    latestMessageDirection?: ActivityDirection;
    /**
     * Дата и время последнего сообщения в беседе.
     * @type {string}
     * @memberof ConversationModel
     */
    latestMessageOn?: string | null;
    /**
     * 
     * @type {ConversationStatus}
     * @memberof ConversationModel
     */
    status?: ConversationStatus;
    /**
     * 
     * @type {ConversationClosingStatus}
     * @memberof ConversationModel
     */
    closingStatus?: ConversationClosingStatus;
    /**
     * 
     * @type {ConversationClosingReason}
     * @memberof ConversationModel
     */
    closingReason?: ConversationClosingReason;
    /**
     * Имя участника беседы
     * @type {string}
     * @memberof ConversationModel
     */
    userName?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationModelPaginationResponse
 */
export interface ConversationModelPaginationResponse {
    /**
     * 
     * @type {Array<ConversationModel>}
     * @memberof ConversationModelPaginationResponse
     */
    items?: Array<ConversationModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface ConversationReference
 */
export interface ConversationReference {
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    activityId?: string | null;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof ConversationReference
     */
    user?: ChannelAccount;
    /**
     * 
     * @type {ChannelAccount}
     * @memberof ConversationReference
     */
    bot?: ChannelAccount;
    /**
     * 
     * @type {ConversationAccount}
     * @memberof ConversationReference
     */
    conversation?: ConversationAccount;
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    channelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationReference
     */
    serviceUrl?: string | null;
}
/**
 * 
 * @export
 * @interface ConversationSettings
 */
export interface ConversationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof ConversationSettings
     */
    timeoutEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationSettings
     */
    closingTimeout?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConversationSettings
     */
    closingMessage?: string | null;
    /**
     * 
     * @type {Elma365IntegrationSettings}
     * @memberof ConversationSettings
     */
    elma365IntegrationSettings?: Elma365IntegrationSettings;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ConversationSortDirection {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ConversationStatus {
    Active = 'Active',
    Closed = 'Closed',
    Operator = 'Operator'
}

/**
 * Аналитика по закрытию бесед в день.
 * @export
 * @interface ConversationsClosedPerDayModel
 */
export interface ConversationsClosedPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof ConversationsClosedPerDayModel
     */
    date: string;
    /**
     * Количество закрытых бесед оператором.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    byOperatorCount: number;
    /**
     * Количество закрытых бесед элементом сценария.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    byScenarioActionCount: number;
    /**
     * Количество закрытых бесед по таймауту.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    timedOutCount: number;
    /**
     * Количество закрытых бесед по событию рестарта беседы.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    conversationRestartedCount: number;
    /**
     * Общее количество закрытых бесед.
     * @type {number}
     * @memberof ConversationsClosedPerDayModel
     */
    totalCount: number;
}
/**
 * Аналитика по закрытым беседам в разрезе сценария.
 * @export
 * @interface ConversationsClosedPerScenarioModel
 */
export interface ConversationsClosedPerScenarioModel {
    /**
     * Название сценария.
     * @type {string}
     * @memberof ConversationsClosedPerScenarioModel
     */
    scenarioName: string;
    /**
     * ИД сценария.
     * @type {string}
     * @memberof ConversationsClosedPerScenarioModel
     */
    scenarioStructureId: string;
    /**
     * Количество закрытых бесед оператором.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    byOperatorCount: number;
    /**
     * Количество закрытых бесед элементом сценария.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    byScenarioActionCount: number;
    /**
     * Количество закрытых бесед по таймауту.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    timedOutCount: number;
    /**
     * Количество закрытых бесед по событию рестарта беседы.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    conversationRestartedCount: number;
    /**
     * Общее количество закрытых бесед.
     * @type {number}
     * @memberof ConversationsClosedPerScenarioModel
     */
    totalCount: number;
}
/**
 * Аналитика по беседам в день.
 * @export
 * @interface ConversationsPerDayModel
 */
export interface ConversationsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof ConversationsPerDayModel
     */
    date: string;
    /**
     * Общее количество бесед.
     * @type {number}
     * @memberof ConversationsPerDayModel
     */
    totalCount: number;
    /**
     * Количество бесед по каждому каналу.
     * @type {{ [key: string]: number; }}
     * @memberof ConversationsPerDayModel
     */
    channelCount: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface CreateElma365AppElementActionSchema
 */
export interface CreateElma365AppElementActionSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof CreateElma365AppElementActionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    appCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    appNamespace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    appUpdatedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    appTitle?: string | null;
    /**
     * 
     * @type {Array<Elma365FormPropertySchema>}
     * @memberof CreateElma365AppElementActionSchema
     */
    formProperties: Array<Elma365FormPropertySchema>;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    variableId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateElma365AppElementActionSchema
     */
    outputBindingId?: string | null;
    /**
     * 
     * @type {Elma365SessionType}
     * @memberof CreateElma365AppElementActionSchema
     */
    sessionType?: Elma365SessionType;
}
/**
 * 
 * @export
 * @interface CurrentDateTimeComparisonValidatorSchema
 */
export interface CurrentDateTimeComparisonValidatorSchema extends ComparisonValidatorSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof CurrentDateTimeComparisonValidatorSchema
     */
    $kind: SchemaKind;
}
/**
 * Аналитика по пользовательским событиям в день.
 * @export
 * @interface CustomEventsPerDayModel
 */
export interface CustomEventsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof CustomEventsPerDayModel
     */
    date: string;
    /**
     * Общее количество пользовательских событий в день.
     * @type {number}
     * @memberof CustomEventsPerDayModel
     */
    totalCount: number;
    /**
     * Топ пользовательских событий.
     * @type {{ [key: string]: number; }}
     * @memberof CustomEventsPerDayModel
     */
    topEvents: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface DateTimeInputSchema
 */
export interface DateTimeInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof DateTimeInputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface DateTimeTypeValidatorSchema
 */
export interface DateTimeTypeValidatorSchema extends ValidatorSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof DateTimeTypeValidatorSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {DateTimeValueType}
     * @memberof DateTimeTypeValidatorSchema
     */
    expectedType: DateTimeValueType;
}
/**
 * 
 * @export
 * @interface DateTimeValueComparisonValidatorSchema
 */
export interface DateTimeValueComparisonValidatorSchema extends ValueComparisonValidatorSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof DateTimeValueComparisonValidatorSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof DateTimeValueComparisonValidatorSchema
     */
    value: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DateTimeValueType {
    DateWithTime = 'DateWithTime',
    Date = 'Date',
    Time = 'Time'
}

/**
 * 
 * @export
 * @interface DefaultActionGroupSchema
 */
export interface DefaultActionGroupSchema extends ActionGroupSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof DefaultActionGroupSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Array<ActionSchema>}
     * @memberof DefaultActionGroupSchema
     */
    actions: Array<ActionSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefaultActionGroupSchema
     */
    inputBindingIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DefaultActionGroupSchema
     */
    outputBindingId?: string | null;
}
/**
 * 
 * @export
 * @interface DefaultScenarioSchema
 */
export interface DefaultScenarioSchema extends ScenarioSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof DefaultScenarioSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {TriggerGroupSchema}
     * @memberof DefaultScenarioSchema
     */
    triggerGroup: TriggerGroupSchema;
    /**
     * 
     * @type {Array<ActionGroupSchema>}
     * @memberof DefaultScenarioSchema
     */
    actionGroups: Array<ActionGroupSchema>;
    /**
     * 
     * @type {Array<IntentContentSchema>}
     * @memberof DefaultScenarioSchema
     */
    intents: Array<IntentContentSchema>;
    /**
     * 
     * @type {Array<BindingSchema>}
     * @memberof DefaultScenarioSchema
     */
    bindings: Array<BindingSchema>;
}
/**
 * 
 * @export
 * @interface DefaultTriggerGroupSchema
 */
export interface DefaultTriggerGroupSchema extends TriggerGroupSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof DefaultTriggerGroupSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Array<TriggerSchema>}
     * @memberof DefaultTriggerGroupSchema
     */
    triggers: Array<TriggerSchema>;
}
/**
 * 
 * @export
 * @interface DesignerSchema
 */
export interface DesignerSchema {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof DesignerSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DesignerSchema
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DesignerSchema
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DesignerSchema
     */
    positionX?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DesignerSchema
     */
    positionY?: number | null;
}
/**
 * 
 * @export
 * @interface DialogProgressState
 */
export interface DialogProgressState {
    /**
     * 
     * @type {number}
     * @memberof DialogProgressState
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof DialogProgressState
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof DialogProgressState
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof DialogProgressState
     */
    isFinal: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DialogProgressState
     */
    isError: boolean;
}
/**
 * Запрос на получение внешнего события.
 * @export
 * @interface DispatchExternalEventRequest
 */
export interface DispatchExternalEventRequest {
    /**
     * ИД беседы.
     * @type {string}
     * @memberof DispatchExternalEventRequest
     */
    'conversationId'?: string | null;
    /**
     * Внешний ИД беседы.
     * @type {string}
     * @memberof DispatchExternalEventRequest
     */
    'externalConversationId'?: string | null;
    /**
     * ИД внешнего события.
     * @type {string}
     * @memberof DispatchExternalEventRequest
     */
    externalEventId: string;
    /**
     * Коллекция значений переменных, которые будут переданы в сценарий в момент запуска события.
     * @type {{ [key: string]: any; }}
     * @memberof DispatchExternalEventRequest
     */
    externalEventPayload?: { [key: string]: any; } | null;
}
/**
 * Приложение ELMA365.
 * @export
 * @interface Elma365App
 */
export interface Elma365App {
    [key: string]: any | any;

    /**
     * ИД приложения.
     * @type {string}
     * @memberof Elma365App
     */
    __id: string;
    /**
     * Название приложения.
     * @type {string}
     * @memberof Elma365App
     */
    name: string;
    /**
     * Название элемента приложения.
     * @type {string}
     * @memberof Elma365App
     */
    elementName: string;
    /**
     * Код раздела.
     * @type {string}
     * @memberof Elma365App
     */
    namespace: string;
    /**
     * Код.
     * @type {string}
     * @memberof Elma365App
     */
    code: string;
    /**
     * Тип.
     * @type {string}
     * @memberof Elma365App
     */
    type: string;
    /**
     * Поля.
     * @type {Array<ContextElement>}
     * @memberof Elma365App
     */
    fields: Array<ContextElement>;
    /**
     * Фильтры.
     * @type {any}
     * @memberof Elma365App
     */
    filters?: any | null;
    /**
     * Настройки.
     * @type {any}
     * @memberof Elma365App
     */
    settings: any | null;
    /**
     * Дата изменения.
     * @type {string}
     * @memberof Elma365App
     */
    __updatedAt: string;
}
/**
 * 
 * @export
 * @interface Elma365AppElementInputSchema
 */
export interface Elma365AppElementInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365AppElementInputSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma365AppElementInputSchema
     */
    appCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365AppElementInputSchema
     */
    appNamespace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365AppElementInputSchema
     */
    appTitle?: string | null;
}
/**
 * Шаблон бизнес-процесса ELMA365.
 * @export
 * @interface Elma365BpmTemplate
 */
export interface Elma365BpmTemplate {
    [key: string]: any | any;

    /**
     * ИД шаблона.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    __id: string;
    /**
     * Код раздела.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    namespace: string;
    /**
     * Код.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    code: string;
    /**
     * Категория.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    category: string;
    /**
     * Название шаблона.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    __name: string;
    /**
     * Тип.
     * @type {string}
     * @memberof Elma365BpmTemplate
     */
    type: string;
    /**
     * Признак черновика.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    draft: boolean;
    /**
     * Версия.
     * @type {number}
     * @memberof Elma365BpmTemplate
     */
    version: number;
    /**
     * 
     * @type {Process}
     * @memberof Elma365BpmTemplate
     */
    process: Process;
    /**
     * Кoнтекст.
     * @type {Array<ContextElement>}
     * @memberof Elma365BpmTemplate
     */
    context: Array<ContextElement>;
    /**
     * Возможность ручного запуска.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    manualRun: boolean;
    /**
     * Скрыть в списке.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    hideInList: boolean;
    /**
     * Только для чтения.
     * @type {boolean}
     * @memberof Elma365BpmTemplate
     */
    readonly: boolean;
}
/**
 * 
 * @export
 * @interface Elma365EnumInputSchema
 */
export interface Elma365EnumInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365EnumInputSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Elma365EnumSourceType}
     * @memberof Elma365EnumInputSchema
     */
    enumSourceType: Elma365EnumSourceType;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    appCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    appNamespace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    bpmTemplateNamespace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    bpmTemplateCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    enumCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365EnumInputSchema
     */
    enumName?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Elma365EnumSourceType {
    Application = 'Application',
    Process = 'Process'
}

/**
 * 
 * @export
 * @interface Elma365FormPropertySchema
 */
export interface Elma365FormPropertySchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365FormPropertySchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof Elma365FormPropertySchema
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    dataType: string;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    additionalDataType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365FormPropertySchema
     */
    variableId?: string | null;
}
/**
 * 
 * @export
 * @interface Elma365IntegrationSettings
 */
export interface Elma365IntegrationSettings {
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    apiUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    integrationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    organizationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    xToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettings
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @interface Elma365IntegrationSettingsSchema
 */
export interface Elma365IntegrationSettingsSchema extends IntegrationSettingsSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365IntegrationSettingsSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    apiUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    integrationId: string;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Elma365IntegrationSettingsSchema
     */
    xToken: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Elma365ProcessStartType {
    ByStartEvent = 'ByStartEvent',
    ByContext = 'ByContext'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum Elma365SessionType {
    None = 'None',
    RegisterMessage = 'RegisterMessage',
    BindAccount = 'BindAccount'
}

/**
 * 
 * @export
 * @interface Elma365UserInputSchema
 */
export interface Elma365UserInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma365UserInputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface Elma4IntegrationSettings
 */
export interface Elma4IntegrationSettings {
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettings
     */
    apiUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettings
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettings
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @interface Elma4IntegrationSettingsSchema
 */
export interface Elma4IntegrationSettingsSchema extends IntegrationSettingsSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof Elma4IntegrationSettingsSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof Elma4IntegrationSettingsSchema
     */
    apiUrl: string;
}
/**
 * 
 * @export
 * @interface ElseCaseActionSchema
 */
export interface ElseCaseActionSchema extends CaseActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ElseCaseActionSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface EmailAddressInputSchema
 */
export interface EmailAddressInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof EmailAddressInputSchema
     */
    $kind: SchemaKind;
}
/**
 * Завершение сценария.  TODO: убрать возможность генерации сообщений и переименовать схему, т.к. в UI нет настройки сообщений.
 * @export
 * @interface EndScenarioOutputSchema
 */
export interface EndScenarioOutputSchema extends OutputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof EndScenarioOutputSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof EndScenarioOutputSchema
     */
    transitionScenarioId?: string | null;
}
/**
 * 
 * @export
 * @interface Entity
 */
export interface Entity {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof Entity
     */
    type?: string | null;
}
/**
 * 
 * @export
 * @interface EntitySchema
 */
export interface EntitySchema {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof EntitySchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof EntitySchema
     */
    id: string;
    /**
     * 
     * @type {DesignerSchema}
     * @memberof EntitySchema
     */
    $designer?: DesignerSchema;
}
/**
 * 
 * @export
 * @interface EntityValidationResult
 */
export interface EntityValidationResult {
    /**
     * 
     * @type {string}
     * @memberof EntityValidationResult
     */
    entityId: string;
    /**
     * 
     * @type {Array<FieldValidationResult>}
     * @memberof EntityValidationResult
     */
    fieldResults: Array<FieldValidationResult>;
}
/**
 * Элемент категории.
 * @export
 * @interface EnumItem
 */
export interface EnumItem {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof EnumItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof EnumItem
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnumItem
     */
    checked: boolean;
}
/**
 * Аналитика по переводам на оператора в день.
 * @export
 * @interface EscalationsPerDayModel
 */
export interface EscalationsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof EscalationsPerDayModel
     */
    date: string;
    /**
     * Количество уникальных бесед, переведенных на оператора.
     * @type {number}
     * @memberof EscalationsPerDayModel
     */
    uniqueEscalationCount: number;
    /**
     * Общее количество переводов на оператора.
     * @type {number}
     * @memberof EscalationsPerDayModel
     */
    totalEscalationCount: number;
    /**
     * Количество бесед.
     * @type {number}
     * @memberof EscalationsPerDayModel
     */
    conversationCount: number;
    /**
     * Количество переводов на оператора по сценариям.
     * @type {Array<ScenarioEventCountModel>}
     * @memberof EscalationsPerDayModel
     */
    topScenarioEscalations: Array<ScenarioEventCountModel>;
}
/**
 * Модель события.
 * @export
 * @interface EventModel
 */
export interface EventModel {
    /**
     * ИД события.
     * @type {string}
     * @memberof EventModel
     */
    id?: string | null;
    /**
     * Тип события.
     * @type {string}
     * @memberof EventModel
     */
    type?: string | null;
    /**
     * Аргументы события.
     * @type {{ [key: string]: any; }}
     * @memberof EventModel
     */
    arguments?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface EventModelPaginationResponse
 */
export interface EventModelPaginationResponse {
    /**
     * 
     * @type {Array<EventModel>}
     * @memberof EventModelPaginationResponse
     */
    items?: Array<EventModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EventModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface ExternalEventTriggerSchema
 */
export interface ExternalEventTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ExternalEventTriggerSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof ExternalEventTriggerSchema
     */
    externalEventName: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEventTriggerSchema
     */
    externalEventId: string;
    /**
     * 
     * @type {Array<VariableSchema>}
     * @memberof ExternalEventTriggerSchema
     */
    variables?: Array<VariableSchema> | null;
}
/**
 * 
 * @export
 * @interface ExternalSignInSchema
 */
export interface ExternalSignInSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ExternalSignInSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {number}
     * @memberof ExternalSignInSchema
     */
    maxTurnCount: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalSignInSchema
     */
    prompt: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalSignInSchema
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalSignInSchema
     */
    title: string;
    /**
     * 
     * @type {LoginProtocolSchema}
     * @memberof ExternalSignInSchema
     */
    protocol: LoginProtocolSchema;
}
/**
 * 
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlag
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlag
     */
    isActive?: boolean;
}
/**
 * 
 * @export
 * @interface FieldValidationResult
 */
export interface FieldValidationResult {
    /**
     * 
     * @type {string}
     * @memberof FieldValidationResult
     */
    fieldPath: string;
    /**
     * 
     * @type {Array<ValidationRuleResult>}
     * @memberof FieldValidationResult
     */
    ruleResults: Array<ValidationRuleResult>;
}
/**
 * 
 * @export
 * @interface FileContentData
 */
export interface FileContentData {
    /**
     * 
     * @type {string}
     * @memberof FileContentData
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof FileContentData
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof FileContentData
     */
    content: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FileInputMode {
    Single = 'single',
    Multiple = 'multiple',
    MultipleWithConfirm = 'multipleWithConfirm'
}

/**
 * 
 * @export
 * @interface FileInputSchema
 */
export interface FileInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof FileInputSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {FileInputMode}
     * @memberof FileInputSchema
     */
    fileInputMode: FileInputMode;
}
/**
 * 
 * @export
 * @interface FileUploadingResponse
 */
export interface FileUploadingResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadingResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FileUploadingResponse
     */
    url: string;
}
/**
 * Поле формы.
 * @export
 * @interface FormField
 */
export interface FormField {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    display?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormField
     */
    tooltip: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    readonly: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormField
     */
    hideEmpty?: boolean | null;
}
/**
 * 
 * @export
 * @interface GeneratedDataItem
 */
export interface GeneratedDataItem {
    /**
     * 
     * @type {string}
     * @memberof GeneratedDataItem
     */
    luFileName?: string | null;
    /**
     * 
     * @type {OmegaGenerated}
     * @memberof GeneratedDataItem
     */
    omegaGenerated?: OmegaGenerated;
}
/**
 * Запрос на создание группы сотрудников.
 * @export
 * @interface GroupCreationRequest
 */
export interface GroupCreationRequest {
    /**
     * Имя группы.
     * @type {string}
     * @memberof GroupCreationRequest
     */
    name: string;
}
/**
 * Ответ на создание группы сотрудников.
 * @export
 * @interface GroupCreationResponse
 */
export interface GroupCreationResponse {
    /**
     * ИД группы.
     * @type {string}
     * @memberof GroupCreationResponse
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface GroupEditionModel
 */
export interface GroupEditionModel {
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    groupEntryId: string;
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GroupEditionModel
     */
    semVer: string;
}
/**
 * 
 * @export
 * @interface GroupEditionModelPaginationResponse
 */
export interface GroupEditionModelPaginationResponse {
    /**
     * 
     * @type {Array<GroupEditionModel>}
     * @memberof GroupEditionModelPaginationResponse
     */
    items?: Array<GroupEditionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupEditionModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEditionModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface GroupEntryModel
 */
export interface GroupEntryModel {
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupEntryModel
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GroupEntryModel
     */
    currentSemVer: string;
}
/**
 * 
 * @export
 * @interface GroupEntryModelPaginationResponse
 */
export interface GroupEntryModelPaginationResponse {
    /**
     * 
     * @type {Array<GroupEntryModel>}
     * @memberof GroupEntryModelPaginationResponse
     */
    items?: Array<GroupEntryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupEntryModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupEntryModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Модель группы сотрудников.
 * @export
 * @interface GroupModel
 */
export interface GroupModel {
    /**
     * ИД группы.
     * @type {string}
     * @memberof GroupModel
     */
    id: string;
    /**
     * Имя группы.
     * @type {string}
     * @memberof GroupModel
     */
    name: string;
    /**
     * Список сотрудников, входящих в группу.
     * @type {Array<PersonModel>}
     * @memberof GroupModel
     */
    persons?: Array<PersonModel> | null;
}
/**
 * 
 * @export
 * @interface GroupModelPaginationResponse
 */
export interface GroupModelPaginationResponse {
    /**
     * 
     * @type {Array<GroupModel>}
     * @memberof GroupModelPaginationResponse
     */
    items?: Array<GroupModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GroupModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Запрос на изменение группы сотрудников.
 * @export
 * @interface GroupUpdatingRequest
 */
export interface GroupUpdatingRequest {
    /**
     * Имя группы.
     * @type {string}
     * @memberof GroupUpdatingRequest
     */
    name: string;
}
/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    status?: string | null;
}
/**
 * 
 * @export
 * @interface InputActionSchema
 */
export interface InputActionSchema extends ActionSchema {
    /**
     * 
     * @type {string}
     * @memberof InputActionSchema
     */
    $kind: string;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    messages: Array<MessageSchema>;
    /**
     * 
     * @type {string}
     * @memberof InputActionSchema
     */
    variableId?: string | null;
    /**
     * 
     * @type {Array<ValidatorSchema>}
     * @memberof InputActionSchema
     */
    validators: Array<ValidatorSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    required: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    cancellable: boolean;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    unrecognizedPromptMessages: Array<MessageSchema>;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    defaultValueResponseMessages: Array<MessageSchema>;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    customValidationErrorMessages: Array<MessageSchema>;
    /**
     * 
     * @type {number}
     * @memberof InputActionSchema
     */
    maxTurnCount: number;
    /**
     * 
     * @type {string}
     * @memberof InputActionSchema
     */
    defaultValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    canShowHelp: boolean;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof InputActionSchema
     */
    helpMessages: Array<MessageSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    canTransferToOperator: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InputActionSchema
     */
    alwaysPrompt: boolean;
}
/**
 * 
 * @export
 * @interface IntegrationSettings
 */
export interface IntegrationSettings {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettings
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettings
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationSettingsSchema
 */
export interface IntegrationSettingsSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingsSchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingsSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationSettingsSchema
     */
    code: string;
}
/**
 * 
 * @export
 * @interface IntentContentSchema
 */
export interface IntentContentSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof IntentContentSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof IntentContentSchema
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntentContentSchema
     */
    utterances: Array<string>;
}
/**
 * 
 * @export
 * @interface IntentEditionModel
 */
export interface IntentEditionModel {
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    groupEntryId: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    groupEditionId: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    intentEntryId: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    groupCode: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEditionModel
     */
    semVer: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntentEditionModel
     */
    utterances: Array<string>;
}
/**
 * 
 * @export
 * @interface IntentEditionModelPaginationResponse
 */
export interface IntentEditionModelPaginationResponse {
    /**
     * 
     * @type {Array<IntentEditionModel>}
     * @memberof IntentEditionModelPaginationResponse
     */
    items?: Array<IntentEditionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof IntentEditionModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEditionModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IntentEditionStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface IntentEntryModel
 */
export interface IntentEntryModel {
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    groupEntryId: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    groupCode: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntentEntryModel
     */
    tags?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof IntentEntryModel
     */
    currentSemVer: string;
}
/**
 * 
 * @export
 * @interface IntentEntryModelPaginationResponse
 */
export interface IntentEntryModelPaginationResponse {
    /**
     * 
     * @type {Array<IntentEntryModel>}
     * @memberof IntentEntryModelPaginationResponse
     */
    items?: Array<IntentEntryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof IntentEntryModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof IntentEntryModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface IntentReferenceSchema
 */
export interface IntentReferenceSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof IntentReferenceSchema
     */
    $kind: string;
}
/**
 * 
 * @export
 * @interface IntentTriggerSchema
 */
export interface IntentTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof IntentTriggerSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {IntentReferenceSchema}
     * @memberof IntentTriggerSchema
     */
    intentReference: IntentReferenceSchema;
    /**
     * 
     * @type {number}
     * @memberof IntentTriggerSchema
     */
    intentThreshold?: number | null;
}
/**
 * Аналитика по распознаваниям в день.
 * @export
 * @interface IntentsPerDayModel
 */
export interface IntentsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof IntentsPerDayModel
     */
    date: string;
    /**
     * Количество неизвестных интентов.
     * @type {number}
     * @memberof IntentsPerDayModel
     */
    unknownIntentCount: number;
    /**
     * Количество распознанных интентов.
     * @type {number}
     * @memberof IntentsPerDayModel
     */
    omegaIntentCount: number;
    /**
     * Количество распознанных вопросов БЗ.
     * @type {number}
     * @memberof IntentsPerDayModel
     */
    sigmaAnswerCount: number;
    /**
     * Топ интентов.
     * @type {Array<OmegaIntentCountModel>}
     * @memberof IntentsPerDayModel
     */
    topOmegaIntents: Array<OmegaIntentCountModel>;
    /**
     * Топ вопросов БЗ.
     * @type {Array<SigmaAnswerCountModel>}
     * @memberof IntentsPerDayModel
     */
    topSigmaAnswers: Array<SigmaAnswerCountModel>;
}
/**
 * Модель приглашения сотрудника в канал стадии бота.
 * @export
 * @interface InvitationCommandModel
 */
export interface InvitationCommandModel {
    /**
     * ИД канала.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    channelId: string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    agentStageAccountId: string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    personId: string;
    /**
     * Адрес Email.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    emailAddress: string;
    /**
     * ИД корреляции.
     * @type {string}
     * @memberof InvitationCommandModel
     */
    correlationId: string;
}
/**
 * Модель вызова проактивного действия.
 * @export
 * @interface InvocationCommandModel
 */
export interface InvocationCommandModel {
    /**
     * ИД канала.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    channelId: string;
    /**
     * ИД стадии бота.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    agentStageAccountId: string;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    personId: string;
    /**
     * ИД корреляции.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    correlationId: string;
    /**
     * ИД диалога.
     * @type {string}
     * @memberof InvocationCommandModel
     */
    dialogId: string;
    /**
     * Аргументы диалога.
     * @type {{ [key: string]: any; }}
     * @memberof InvocationCommandModel
     */
    dialogArguments?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface KbSettingsSchema
 */
export interface KbSettingsSchema {
    /**
     * 
     * @type {boolean}
     * @memberof KbSettingsSchema
     */
    enabled?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof KbSettingsSchema
     */
    threshold?: number;
    /**
     * 
     * @type {boolean}
     * @memberof KbSettingsSchema
     */
    searchParentsOnlyIfNoContext?: boolean | null;
}
/**
 * 
 * @export
 * @interface LengthComparisonValidatorSchema
 */
export interface LengthComparisonValidatorSchema extends ComparisonValidatorSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof LengthComparisonValidatorSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {number}
     * @memberof LengthComparisonValidatorSchema
     */
    length: number;
}
/**
 * 
 * @export
 * @interface LinkInputSchema
 */
export interface LinkInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof LinkInputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface ListBotModel
 */
export interface ListBotModel {
    /**
     * 
     * @type {BotEntryModel}
     * @memberof ListBotModel
     */
    entry: BotEntryModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof ListBotModel
     */
    originStage: BotStageModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof ListBotModel
     */
    draftStage?: BotStageModel;
}
/**
 * 
 * @export
 * @interface ListBotModelPaginationResponse
 */
export interface ListBotModelPaginationResponse {
    /**
     * 
     * @type {Array<ListBotModel>}
     * @memberof ListBotModelPaginationResponse
     */
    items?: Array<ListBotModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListBotModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ListBotModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Шаблон простого бота.
 * @export
 * @interface ListBotTemplateModel
 */
export interface ListBotTemplateModel {
    /**
     * Идентификатор шаблона бота.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    id: string;
    /**
     * Код шаблона бота.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    code: string;
    /**
     * Отображаемое название шаблона бота.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    name: string;
    /**
     * Описание.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    description?: string | null;
    /**
     * Название иконпака.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    iconPack?: string | null;
    /**
     * Код иконки.
     * @type {string}
     * @memberof ListBotTemplateModel
     */
    iconCode?: string | null;
}
/**
 * 
 * @export
 * @interface ListIntentModel
 */
export interface ListIntentModel {
    /**
     * 
     * @type {IntentEntryModel}
     * @memberof ListIntentModel
     */
    intentEntry: IntentEntryModel;
    /**
     * 
     * @type {IntentEditionModel}
     * @memberof ListIntentModel
     */
    intentEdition: IntentEditionModel;
}
/**
 * 
 * @export
 * @interface ListIntentModelPaginationResponse
 */
export interface ListIntentModelPaginationResponse {
    /**
     * 
     * @type {Array<ListIntentModel>}
     * @memberof ListIntentModelPaginationResponse
     */
    items?: Array<ListIntentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListIntentModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ListIntentModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * Описание шаблона для создания сценария простого бота.
 * @export
 * @interface ListScenarioTemplateModel
 */
export interface ListScenarioTemplateModel {
    /**
     * Идентификатор шаблона сценария.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    id: string;
    /**
     * Код шаблона сценария.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    code: string;
    /**
     * Отображаемое название шаблона сценария.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    name: string;
    /**
     * Описание.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    description: string;
    /**
     * Идентификатор шаблона бота.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    botTemplateId?: string | null;
    /**
     * Название шаблона бота.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    botTemplateName?: string | null;
    /**
     * Название иконпака.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    iconPack: string;
    /**
     * Код иконки.
     * @type {string}
     * @memberof ListScenarioTemplateModel
     */
    iconCode: string;
    /**
     * Признак того, что шаблон сценария выбран по умолчанию.
     * @type {boolean}
     * @memberof ListScenarioTemplateModel
     */
    isSelectedByDefault?: boolean;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ListScenarioTemplateModel
     */
    interruption: ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ListScenarioTemplateModel
     */
    cancellation: ScenarioCancellationMode;
}
/**
 * 
 * @export
 * @interface ListScenarioTemplateModelPaginationResponse
 */
export interface ListScenarioTemplateModelPaginationResponse {
    /**
     * 
     * @type {Array<ListScenarioTemplateModel>}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    items?: Array<ListScenarioTemplateModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof ListScenarioTemplateModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface LocalizationSettingsSchema
 */
export interface LocalizationSettingsSchema {
    /**
     * Локаль бота, например \"ru-ru\" или \"en-us\".
     * @type {string}
     * @memberof LocalizationSettingsSchema
     */
    locale?: string | null;
}
/**
 * 
 * @export
 * @interface LocationInputSchema
 */
export interface LocationInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof LocationInputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface LoginProtocolSchema
 */
export interface LoginProtocolSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof LoginProtocolSchema
     */
    $kind: string;
}
/**
 * 
 * @export
 * @interface MainCaseActionSchema
 */
export interface MainCaseActionSchema extends CaseActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof MainCaseActionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Array<ConditionSchema>}
     * @memberof MainCaseActionSchema
     */
    conditions: Array<ConditionSchema>;
}
/**
 * 
 * @export
 * @interface MenuActionSchema
 */
export interface MenuActionSchema extends OutputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof MenuActionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {MenuOptionsFillingMode}
     * @memberof MenuActionSchema
     */
    optionsFillingMode: MenuOptionsFillingMode;
    /**
     * 
     * @type {string}
     * @memberof MenuActionSchema
     */
    category?: string | null;
    /**
     * 
     * @type {Array<MenuButtonSchema>}
     * @memberof MenuActionSchema
     */
    buttons: Array<MenuButtonSchema>;
}
/**
 * 
 * @export
 * @interface MenuButtonSchema
 */
export interface MenuButtonSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof MenuButtonSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonSchema
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonSchema
     */
    outputBindingId?: string | null;
}
/**
 * 
 * @export
 * @interface MenuButtonTriggerSchema
 */
export interface MenuButtonTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof MenuButtonTriggerSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonTriggerSchema
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof MenuButtonTriggerSchema
     */
    category?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MenuButtonTriggerSchema
     */
    position: number;
}
/**
 * 
 * @export
 * @interface MenuItemSelectionCountModel
 */
export interface MenuItemSelectionCountModel {
    /**
     * Название кнопки меню.
     * @type {string}
     * @memberof MenuItemSelectionCountModel
     */
    name: string;
    /**
     * Количество.
     * @type {number}
     * @memberof MenuItemSelectionCountModel
     */
    count: number;
    /**
     * Режим меню.
     * @type {string}
     * @memberof MenuItemSelectionCountModel
     */
    mode: string;
}
/**
 * Аналитика по нажатиям кнопок меню в день.
 * @export
 * @interface MenuItemSelectionsPerDayModel
 */
export interface MenuItemSelectionsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof MenuItemSelectionsPerDayModel
     */
    date: string;
    /**
     * Общее количество нажатий кнопок меню.
     * @type {number}
     * @memberof MenuItemSelectionsPerDayModel
     */
    totalCount: number;
    /**
     * Топ кнопок меню.
     * @type {Array<MenuItemSelectionCountModel>}
     * @memberof MenuItemSelectionsPerDayModel
     */
    topMenuItems: Array<MenuItemSelectionCountModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MenuOptionsFillingMode {
    Auto = 'auto',
    AutoByCategory = 'autoByCategory',
    Manual = 'manual'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum MergeBotStageStatus {
    Merged = 'Merged',
    NothingToMerge = 'NothingToMerge',
    HasChanges = 'HasChanges'
}

/**
 * 
 * @export
 * @interface MessageSchema
 */
export interface MessageSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof MessageSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof MessageSchema
     */
    content: string;
    /**
     * 
     * @type {Array<AttachmentSchema>}
     * @memberof MessageSchema
     */
    attachments: Array<AttachmentSchema>;
}
/**
 * 
 * @export
 * @interface NlpSettingsSchema
 */
export interface NlpSettingsSchema {
    /**
     * Использовать указанную в настройках бота модель обучения.
     * @type {boolean}
     * @memberof NlpSettingsSchema
     */
    useCustomTrainingModel: boolean;
    /**
     * Модель обучения бота.
     * @type {string}
     * @memberof NlpSettingsSchema
     */
    customTrainingModel?: string | null;
}
/**
 * 
 * @export
 * @interface NumberInputSchema
 */
export interface NumberInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof NumberInputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface NumberValueComparisonValidatorSchema
 */
export interface NumberValueComparisonValidatorSchema extends ValueComparisonValidatorSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof NumberValueComparisonValidatorSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {number}
     * @memberof NumberValueComparisonValidatorSchema
     */
    value: number;
}
/**
 * 
 * @export
 * @interface OAuth2LoginProtocolSchema
 */
export interface OAuth2LoginProtocolSchema extends LoginProtocolSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof OAuth2LoginProtocolSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    authorizeEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    tokenEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    jwksEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof OAuth2LoginProtocolSchema
     */
    scopes: string;
}
/**
 * 
 * @export
 * @interface OmegaGenerated
 */
export interface OmegaGenerated {
    /**
     * 
     * @type {string}
     * @memberof OmegaGenerated
     */
    $kind?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaGenerated
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaGenerated
     */
    endpoint?: string | null;
}
/**
 * 
 * @export
 * @interface OmegaIntentCountModel
 */
export interface OmegaIntentCountModel {
    /**
     * Название интента.
     * @type {string}
     * @memberof OmegaIntentCountModel
     */
    intentName: string;
    /**
     * Количество.
     * @type {number}
     * @memberof OmegaIntentCountModel
     */
    count: number;
    /**
     * ИД интента.
     * @type {string}
     * @memberof OmegaIntentCountModel
     */
    intentId: string;
}
/**
 * 
 * @export
 * @interface OmegaScoreQuery
 */
export interface OmegaScoreQuery {
    /**
     * 
     * @type {string}
     * @memberof OmegaScoreQuery
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaScoreQuery
     */
    query?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OmegaScoreQuery
     */
    intents?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OmegaScoreQuery
     */
    error?: string | null;
}
/**
 * 
 * @export
 * @interface OperandSchema
 */
export interface OperandSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof OperandSchema
     */
    $kind: string;
}
/**
 * 
 * @export
 * @interface OutputActionSchema
 */
export interface OutputActionSchema extends ActionSchema {
    /**
     * 
     * @type {string}
     * @memberof OutputActionSchema
     */
    $kind: string;
    /**
     * 
     * @type {Array<MessageSchema>}
     * @memberof OutputActionSchema
     */
    messages: Array<MessageSchema>;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    eMail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    tel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    additionalInfo?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Person
     */
    groupIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    modifiedOn?: string;
}
/**
 * 
 * @export
 * @interface PersonAccount
 */
export interface PersonAccount {
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    channelId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    externalName?: string | null;
    /**
     * 
     * @type {PersonAccountState}
     * @memberof PersonAccount
     */
    state: PersonAccountState;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonAccount
     */
    modifiedOn?: string;
}
/**
 * Модель аккаунта сотрудника в канале.
 * @export
 * @interface PersonAccountModel
 */
export interface PersonAccountModel {
    /**
     * ИД аккаунта.
     * @type {string}
     * @memberof PersonAccountModel
     */
    id?: string | null;
    /**
     * ИД канала.
     * @type {string}
     * @memberof PersonAccountModel
     */
    channelId?: string | null;
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof PersonAccountModel
     */
    personId?: string | null;
    /**
     * 
     * @type {PersonAccountState}
     * @memberof PersonAccountModel
     */
    state?: PersonAccountState;
}
/**
 * 
 * @export
 * @interface PersonAccountModelPaginationResponse
 */
export interface PersonAccountModelPaginationResponse {
    /**
     * 
     * @type {Array<PersonAccountModel>}
     * @memberof PersonAccountModelPaginationResponse
     */
    items?: Array<PersonAccountModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonAccountModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonAccountModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PersonAccountState {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * Запрос на создание сотрудника.
 * @export
 * @interface PersonCreationRequest
 */
export interface PersonCreationRequest {
    /**
     * Имя сотрудника.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    firstName: string;
    /**
     * Фамилия сотрудника.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    lastName: string;
    /**
     * Отчество сотрудника.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    middleName?: string | null;
    /**
     * Email сотрудника.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    eMail: string;
    /**
     * Номер телефона сотрудника.
     * @type {string}
     * @memberof PersonCreationRequest
     */
    tel: string;
    /**
     * Дополнительная информация
     * @type {string}
     * @memberof PersonCreationRequest
     */
    additionalInfo?: string | null;
}
/**
 * Ответ на создание сотрудника.
 * @export
 * @interface PersonCreationResponse
 */
export interface PersonCreationResponse {
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof PersonCreationResponse
     */
    id?: string | null;
}
/**
 * Модель сотрудника.
 * @export
 * @interface PersonModel
 */
export interface PersonModel {
    /**
     * ИД сотрудника.
     * @type {string}
     * @memberof PersonModel
     */
    id: string;
    /**
     * Имя сотрудника.
     * @type {string}
     * @memberof PersonModel
     */
    firstName: string;
    /**
     * Фамилия сотрудника.
     * @type {string}
     * @memberof PersonModel
     */
    lastName: string;
    /**
     * Отчество сотрудника.
     * @type {string}
     * @memberof PersonModel
     */
    middleName?: string | null;
    /**
     * Email сотрудника.
     * @type {string}
     * @memberof PersonModel
     */
    eMail: string;
    /**
     * Номер телефона сотрудника.
     * @type {string}
     * @memberof PersonModel
     */
    tel: string;
    /**
     * Дополнительная информация
     * @type {string}
     * @memberof PersonModel
     */
    additionalInfo?: string | null;
    /**
     * Список каналов сотрудника
     * @type {Array<PersonAccount>}
     * @memberof PersonModel
     */
    personAccounts?: Array<PersonAccount> | null;
    /**
     * 
     * @type {AccountContactPaginationResponse}
     * @memberof PersonModel
     */
    accountContacts?: AccountContactPaginationResponse;
    /**
     * Данные по ботам
     * @type {Array<Agent>}
     * @memberof PersonModel
     */
    agents?: Array<Agent> | null;
    /**
     * Группы
     * @type {Array<GroupModel>}
     * @memberof PersonModel
     */
    groups?: Array<GroupModel> | null;
    /**
     * Список идентификаторов групп
     * @type {Array<string>}
     * @memberof PersonModel
     */
    groupIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PersonModelPaginationResponse
 */
export interface PersonModelPaginationResponse {
    /**
     * 
     * @type {Array<PersonModel>}
     * @memberof PersonModelPaginationResponse
     */
    items?: Array<PersonModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PersonModelPaginationResponse
     */
    hasMore?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    pageIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    totalItemCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    totalPageCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    fromItemInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof PersonModelPaginationResponse
     */
    toItemExclusive?: number;
}
/**
 * 
 * @export
 * @interface PersonNameInputSchema
 */
export interface PersonNameInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof PersonNameInputSchema
     */
    $kind: SchemaKind;
}
/**
 * Запрос на изменение сотрудника.
 * @export
 * @interface PersonUpdatingRequest
 */
export interface PersonUpdatingRequest {
    /**
     * Имя сотрудника.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    firstName: string;
    /**
     * Фамилия сотрудника.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    lastName: string;
    /**
     * Отчество сотрудника.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    middleName?: string | null;
    /**
     * Email сотрудника.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    eMail: string;
    /**
     * Номер телефона сотрудника.
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    tel: string;
    /**
     * Дополнительная информация
     * @type {string}
     * @memberof PersonUpdatingRequest
     */
    additionalInfo?: string | null;
    /**
     * Список идентификаторов групп.
     * @type {Array<string>}
     * @memberof PersonUpdatingRequest
     */
    groupIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PersonalCampaignResult
 */
export interface PersonalCampaignResult {
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    channelId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    agentStageAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    personId: string;
    /**
     * 
     * @type {number}
     * @memberof PersonalCampaignResult
     */
    progress: number;
    /**
     * 
     * @type {string}
     * @memberof PersonalCampaignResult
     */
    proactiveDialogId: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PersonalCampaignResult
     */
    properties: { [key: string]: any; };
    /**
     * 
     * @type {Array<DialogProgressState>}
     * @memberof PersonalCampaignResult
     */
    progressStates: Array<DialogProgressState>;
}
/**
 * 
 * @export
 * @interface PhoneNumberInputSchema
 */
export interface PhoneNumberInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof PhoneNumberInputSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {boolean}
     * @memberof PhoneNumberInputSchema
     */
    contactRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberInputSchema
     */
    contactButtonText?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * Бизнес-процесс.
 * @export
 * @interface Process
 */
export interface Process {
    [key: string]: any | any;

    /**
     * 
     * @type {{ [key: string]: ProcessItem; }}
     * @memberof Process
     */
    items: { [key: string]: ProcessItem; };
}
/**
 * Элемент бизнес-процесса.
 * @export
 * @interface ProcessItem
 */
export interface ProcessItem {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProcessItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessItem
     */
    type: string;
    /**
     * 
     * @type {ProcessItemSettings}
     * @memberof ProcessItem
     */
    settings: ProcessItemSettings;
}
/**
 * Настройки элемента бизнес-процесса.
 * @export
 * @interface ProcessItemSettings
 */
export interface ProcessItemSettings {
    [key: string]: any | any;

    /**
     * 
     * @type {Array<FormField>}
     * @memberof ProcessItemSettings
     */
    formFields: Array<FormField>;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    instruction: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    notifyMessage: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    titleTemplate: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessItemSettings
     */
    applicationForm: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessItemSettings
     */
    titleGenerateMethod: string;
}
/**
 * Аналитика по событиям суфлера в день.
 * @export
 * @interface PrompterModeEventsPerDayModel
 */
export interface PrompterModeEventsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof PrompterModeEventsPerDayModel
     */
    date: string;
    /**
     * Количество принятых ответов бота.
     * @type {number}
     * @memberof PrompterModeEventsPerDayModel
     */
    acceptedCount: number;
    /**
     * Количество отклоненных ответов бота.
     * @type {number}
     * @memberof PrompterModeEventsPerDayModel
     */
    rejectedCount: number;
    /**
     * Количество ответов бота.
     * @type {number}
     * @memberof PrompterModeEventsPerDayModel
     */
    botAnswerCount: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PublishStage {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface RecognitionSettingsSchema
 */
export interface RecognitionSettingsSchema {
    /**
     * Порог уверенности распознавания
     * @type {number}
     * @memberof RecognitionSettingsSchema
     */
    intentThreshold?: number;
    /**
     * 
     * @type {NlpSettingsSchema}
     * @memberof RecognitionSettingsSchema
     */
    nlp: NlpSettingsSchema;
    /**
     * 
     * @type {KbSettingsSchema}
     * @memberof RecognitionSettingsSchema
     */
    kb: KbSettingsSchema;
    /**
     * 
     * @type {ResultSelectionPriorityType}
     * @memberof RecognitionSettingsSchema
     */
    resultSelectionPriority: ResultSelectionPriorityType;
}
/**
 * 
 * @export
 * @interface ResourceResponse
 */
export interface ResourceResponse {
    /**
     * 
     * @type {string}
     * @memberof ResourceResponse
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ResultSelectionPriorityType {
    Score = 'Score',
    SigmaAnswer = 'SigmaAnswer',
    OmegaIntent = 'OmegaIntent'
}

/**
 * 
 * @export
 * @interface RunConversationExportResultModel
 */
export interface RunConversationExportResultModel {
    /**
     * ИД запроса.
     * @type {string}
     * @memberof RunConversationExportResultModel
     */
    requestId: string;
}
/**
 * Результат запуска экспорта отчетов аналитики.
 * @export
 * @interface RunExportAnalyticsReportResultModel
 */
export interface RunExportAnalyticsReportResultModel {
    /**
     * ИД запроса.
     * @type {string}
     * @memberof RunExportAnalyticsReportResultModel
     */
    requestId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ScenarioCancellationMode {
    Disabled = 'Disabled',
    CancelAll = 'CancelAll'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ScenarioContentFormat {
    Json = 'Json'
}

/**
 * 
 * @export
 * @interface ScenarioCreationRequest
 */
export interface ScenarioCreationRequest {
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioCreationRequest
     */
    scenarioName?: string | null;
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioCreationRequest
     */
    scenarioStructure?: any | null;
    /**
     * 
     * @type {FileContentData}
     * @memberof ScenarioCreationRequest
     */
    scenarioFile?: FileContentData;
}
/**
 * 
 * @export
 * @interface ScenarioDuplicationRequest
 */
export interface ScenarioDuplicationRequest {
    /**
     * ИД структуры сценария.
     * @type {string}
     * @memberof ScenarioDuplicationRequest
     */
    scenarioStructureId: string;
}
/**
 * 
 * @export
 * @interface ScenarioEditionModel
 */
export interface ScenarioEditionModel {
    /**
     * ИД редакции сценария.
     * @type {string}
     * @memberof ScenarioEditionModel
     */
    id: string;
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioEditionModel
     */
    structure: any | null;
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof ScenarioEditionModel
     */
    validation: StructureValidationResult;
}
/**
 * 
 * @export
 * @interface ScenarioEditionReferenceModel
 */
export interface ScenarioEditionReferenceModel {
    /**
     * ИД редакции сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    scenarioEditionId: string;
    /**
     * ИД структуры сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    scenarioStructureId: string;
    /**
     * Признак включения/выключения сценария
     * @type {boolean}
     * @memberof ScenarioEditionReferenceModel
     */
    enabled: boolean;
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    name: string;
    /**
     * Дата создания сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    createdOn: string;
    /**
     * Дата изменения сценария.
     * @type {string}
     * @memberof ScenarioEditionReferenceModel
     */
    modifiedOn: string;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ScenarioEditionReferenceModel
     */
    interruption: ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ScenarioEditionReferenceModel
     */
    cancellation: ScenarioCancellationMode;
}
/**
 * 
 * @export
 * @interface ScenarioEventCountModel
 */
export interface ScenarioEventCountModel {
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioEventCountModel
     */
    scenarioName: string;
    /**
     * Количество событий.
     * @type {number}
     * @memberof ScenarioEventCountModel
     */
    eventCount: number;
    /**
     * ИД сценария.
     * @type {string}
     * @memberof ScenarioEventCountModel
     */
    scenarioStructureId: string;
}
/**
 * 
 * @export
 * @interface ScenarioGetUsagesResponse
 */
export interface ScenarioGetUsagesResponse {
    /**
     * Список сценариев.
     * @type {Array<ScenarioEditionReferenceModel>}
     * @memberof ScenarioGetUsagesResponse
     */
    scenarios: Array<ScenarioEditionReferenceModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ScenarioInterruptionMode {
    Disabled = 'Disabled',
    InterruptActive = 'InterruptActive'
}

/**
 * 
 * @export
 * @interface ScenarioSchema
 */
export interface ScenarioSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof ScenarioSchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSchema
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSchema
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScenarioSchema
     */
    enabled: boolean;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ScenarioSchema
     */
    interruption: ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ScenarioSchema
     */
    cancellation: ScenarioCancellationMode;
    /**
     * 
     * @type {Array<VariableSchema>}
     * @memberof ScenarioSchema
     */
    variables: Array<VariableSchema>;
}
/**
 * Аналитика по стартам сценариев в день.
 * @export
 * @interface ScenarioStartsPerDayModel
 */
export interface ScenarioStartsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof ScenarioStartsPerDayModel
     */
    date: string;
    /**
     * Общее количество стартов сценариев.
     * @type {number}
     * @memberof ScenarioStartsPerDayModel
     */
    totalScenarioStartCount: number;
    /**
     * Топ сценариев.
     * @type {Array<ScenarioEventCountModel>}
     * @memberof ScenarioStartsPerDayModel
     */
    topScenarioStarts: Array<ScenarioEventCountModel>;
}
/**
 * 
 * @export
 * @interface ScenarioTestingRequest
 */
export interface ScenarioTestingRequest {
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioTestingRequest
     */
    scenarioStructure: any | null;
}
/**
 * 
 * @export
 * @interface ScenarioTestingResponse
 */
export interface ScenarioTestingResponse {
    /**
     * 
     * @type {AgentStageModel}
     * @memberof ScenarioTestingResponse
     */
    agentStage: AgentStageModel;
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof ScenarioTestingResponse
     */
    emulatorUrl: string;
    /**
     * URL обратного вызова.
     * @type {string}
     * @memberof ScenarioTestingResponse
     */
    callbackUrl: string;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof ScenarioTestingResponse
     */
    webchatUrl: string;
}
/**
 * 
 * @export
 * @interface ScenarioUpdatingRequest
 */
export interface ScenarioUpdatingRequest {
    /**
     * ИД структуры сценария.
     * @type {string}
     * @memberof ScenarioUpdatingRequest
     */
    scenarioStructureId: string;
    /**
     * 
     * @type {UpdateScenarioOperationType}
     * @memberof ScenarioUpdatingRequest
     */
    operationType: UpdateScenarioOperationType;
    /**
     * Название сценария.
     * @type {string}
     * @memberof ScenarioUpdatingRequest
     */
    name?: string | null;
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioUpdatingRequest
     */
    scenarioStructure?: any | null;
    /**
     * 
     * @type {FileContentData}
     * @memberof ScenarioUpdatingRequest
     */
    scenarioFile?: FileContentData;
    /**
     * Признак включен/выключен
     * @type {boolean}
     * @memberof ScenarioUpdatingRequest
     */
    enabled?: boolean;
    /**
     * 
     * @type {ScenarioInterruptionMode}
     * @memberof ScenarioUpdatingRequest
     */
    interruption?: ScenarioInterruptionMode;
    /**
     * 
     * @type {ScenarioCancellationMode}
     * @memberof ScenarioUpdatingRequest
     */
    cancellation?: ScenarioCancellationMode;
}
/**
 * 
 * @export
 * @interface ScenarioValidationRequest
 */
export interface ScenarioValidationRequest {
    /**
     * Структура сценария.
     * @type {any}
     * @memberof ScenarioValidationRequest
     */
    scenarioStructure?: any | null;
}
/**
 * 
 * @export
 * @interface ScenarioValidationResponse
 */
export interface ScenarioValidationResponse {
    /**
     * 
     * @type {StructureValidationResult}
     * @memberof ScenarioValidationResponse
     */
    result: StructureValidationResult;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {ScheduleType}
     * @memberof Schedule
     */
    type?: ScheduleType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ScheduleType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SchemaKind {
    Variable = 'ELMA.Assistant.Variable',
    Message = 'ELMA.Assistant.Message',
    Suggestion = 'ELMA.Assistant.Suggestion',
    Button = 'ELMA.Assistant.Button',
    TextValueComparisonValidator = 'ELMA.Assistant.TextValueComparisonValidator',
    NumberValueComparisonValidator = 'ELMA.Assistant.NumberValueComparisonValidator',
    DateTimeValueComparisonValidator = 'ELMA.Assistant.DateTimeValueComparisonValidator',
    DateTimeTypeValidator = 'ELMA.Assistant.DateTimeTypeValidator',
    VariableComparisonValidator = 'ELMA.Assistant.VariableComparisonValidator',
    CurrentDateTimeComparisonValidator = 'ELMA.Assistant.CurrentDateTimeComparisonValidator',
    LengthComparisonValidator = 'ELMA.Assistant.LengthComparisonValidator',
    IntentContent = 'ELMA.Assistant.IntentContent',
    SystemIntentReference = 'ELMA.Assistant.SystemIntentReference',
    UserIntentReference = 'ELMA.Assistant.UserIntentReference',
    IntentTrigger = 'ELMA.Assistant.IntentTrigger',
    TimerTrigger = 'ELMA.Assistant.TimerTrigger',
    StartTrigger = 'ELMA.Assistant.StartTrigger',
    TransitionTrigger = 'ELMA.Assistant.TransitionTrigger',
    UnknownIntentTrigger = 'ELMA.Assistant.UnknownIntentTrigger',
    TerminalTrigger = 'ELMA.Assistant.TerminalTrigger',
    MenuButtonTrigger = 'ELMA.Assistant.MenuButtonTrigger',
    ExternalEventTrigger = 'ELMA.Assistant.ExternalEventTrigger',
    TextInput = 'ELMA.Assistant.TextInput',
    NumberInput = 'ELMA.Assistant.NumberInput',
    DateTimeInput = 'ELMA.Assistant.DateTimeInput',
    EmailAddressInput = 'ELMA.Assistant.EmailAddressInput',
    PhoneNumberInput = 'ELMA.Assistant.PhoneNumberInput',
    LinkInput = 'ELMA.Assistant.LinkInput',
    LocationInput = 'ELMA.Assistant.LocationInput',
    PersonNameInput = 'ELMA.Assistant.PersonNameInput',
    SuggestionInput = 'ELMA.Assistant.SuggestionInput',
    FileInput = 'ELMA.Assistant.FileInput',
    TextOutput = 'ELMA.Assistant.TextOutput',
    EndScenarioOutput = 'ELMA.Assistant.EndScenarioOutput',
    ConfirmTransit = 'ELMA.Assistant.ConfirmTransit',
    ChoiceTransit = 'ELMA.Assistant.ChoiceTransit',
    DefaultTriggerGroup = 'ELMA.Assistant.DefaultTriggerGroup',
    DefaultActionGroup = 'ELMA.Assistant.DefaultActionGroup',
    Binding = 'ELMA.Assistant.Binding',
    DefaultScenario = 'ELMA.Assistant.DefaultScenario',
    Bot = 'ELMA.Assistant.Bot',
    TransferToOperator = 'ELMA.Assistant.TransferToOperator',
    MenuAction = 'ELMA.Assistant.MenuAction',
    MenuButton = 'ELMA.Assistant.MenuButton',
    ScriptAction = 'ELMA.Assistant.ScriptAction',
    Elma365UserInput = 'ELMA.Assistant.Elma365UserInput',
    Elma365AppElementInput = 'ELMA.Assistant.Elma365AppElementInput',
    Elma365EnumInput = 'ELMA.Assistant.Elma365EnumInput',
    SwitchAction = 'ELMA.Assistant.SwitchAction',
    MainCaseAction = 'ELMA.Assistant.MainCaseAction',
    ElseCaseAction = 'ELMA.Assistant.ElseCaseAction',
    UnaryCondition = 'ELMA.Assistant.UnaryCondition',
    BinaryCondition = 'ELMA.Assistant.BinaryCondition',
    ConstantOperand = 'ELMA.Assistant.ConstantOperand',
    VariableOperand = 'ELMA.Assistant.VariableOperand',
    BotSettings = 'ELMA.Assistant.BotSettings',
    Elma4IntegrationSettings = 'ELMA.Assistant.ELMA4IntegrationSettings',
    Elma365IntegrationSettings = 'ELMA.Assistant.ELMA365IntegrationSettings',
    StartElma365Process = 'ELMA.Assistant.StartElma365Process',
    Elma365FormProperty = 'ELMA.Assistant.Elma365FormProperty',
    CreateElma365AppElement = 'ELMA.Assistant.CreateElma365AppElement',
    Attachment = 'ELMA.Assistant.Attachment',
    ExternalSignIn = 'ELMA.Assistant.ExternalSignIn',
    OAuth2LoginProtocol = 'ELMA.Assistant.OAuth2LoginProtocol',
    SendEmail = 'ELMA.Assistant.SendEmail',
    SendSuccessfullServiceEvent = 'ELMA.Assistant.SendSuccessfullServiceEvent',
    CloseConversation = 'ELMA.Assistant.CloseConversation',
    SendCustomEvent = 'ELMA.Assistant.SendCustomEvent'
}

/**
 * 
 * @export
 * @interface ScriptActionSchema
 */
export interface ScriptActionSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof ScriptActionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof ScriptActionSchema
     */
    content: string;
    /**
     * Таймаут выполнения в секундах.
     * @type {number}
     * @memberof ScriptActionSchema
     */
    executionTimeout: number;
    /**
     * Количество повторных попыток.
     * @type {number}
     * @memberof ScriptActionSchema
     */
    retryCount: number;
    /**
     * Задержка между повторными попытками в секундах.
     * @type {number}
     * @memberof ScriptActionSchema
     */
    retryDelay: number;
    /**
     * 
     * @type {string}
     * @memberof ScriptActionSchema
     */
    outputBindingId?: string | null;
}
/**
 * 
 * @export
 * @interface ScriptSettingsSchema
 */
export interface ScriptSettingsSchema {
    /**
     * Текст скрипта.
     * @type {string}
     * @memberof ScriptSettingsSchema
     */
    content?: string | null;
    /**
     * Таймаут выполнения скрипта в секундах.
     * @type {number}
     * @memberof ScriptSettingsSchema
     */
    executionTimeout: number;
    /**
     * Количество повторных попыток выполнения скрипта.
     * @type {number}
     * @memberof ScriptSettingsSchema
     */
    retryCount: number;
    /**
     * Задержка между повторными попытками выполнения скрипта в секундах.
     * @type {number}
     * @memberof ScriptSettingsSchema
     */
    retryDelay: number;
}
/**
 * 
 * @export
 * @interface SendCustomEventSchema
 */
export interface SendCustomEventSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SendCustomEventSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof SendCustomEventSchema
     */
    eventName: string;
}
/**
 * 
 * @export
 * @interface SendEmailSchema
 */
export interface SendEmailSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SendEmailSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    recipients: Array<OperandSchema>;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    ccRecipients: Array<OperandSchema>;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    bccRecipients: Array<OperandSchema>;
    /**
     * 
     * @type {OperandSchema}
     * @memberof SendEmailSchema
     */
    subject: OperandSchema;
    /**
     * 
     * @type {string}
     * @memberof SendEmailSchema
     */
    body: string;
    /**
     * 
     * @type {Array<OperandSchema>}
     * @memberof SendEmailSchema
     */
    attachments: Array<OperandSchema>;
}
/**
 * 
 * @export
 * @interface SendSuccessfullServiceEventSchema
 */
export interface SendSuccessfullServiceEventSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SendSuccessfullServiceEventSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {SuccessfullServiceEventName}
     * @memberof SendSuccessfullServiceEventSchema
     */
    eventName: SuccessfullServiceEventName;
}
/**
 * 
 * @export
 * @interface SettingsUpdatingRequest
 */
export interface SettingsUpdatingRequest {
    /**
     * 
     * @type {BotSettingsSchema}
     * @memberof SettingsUpdatingRequest
     */
    settings: BotSettingsSchema;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SetupStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface SigmaAnswerCountModel
 */
export interface SigmaAnswerCountModel {
    /**
     * Вопрос.
     * @type {string}
     * @memberof SigmaAnswerCountModel
     */
    question: string;
    /**
     * Количество.
     * @type {number}
     * @memberof SigmaAnswerCountModel
     */
    count: number;
    /**
     * ИД ответа базы знаний.
     * @type {string}
     * @memberof SigmaAnswerCountModel
     */
    answerId: string;
}
/**
 * 
 * @export
 * @interface SingleBotModel
 */
export interface SingleBotModel {
    /**
     * 
     * @type {BotEntryModel}
     * @memberof SingleBotModel
     */
    entry: BotEntryModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof SingleBotModel
     */
    originStage: BotStageModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    originSourceEdition: BotEditionModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    originCurrentEdition: BotEditionModel;
    /**
     * 
     * @type {BotStageModel}
     * @memberof SingleBotModel
     */
    draftStage?: BotStageModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    draftSourceEdition?: BotEditionModel;
    /**
     * 
     * @type {BotEditionModel}
     * @memberof SingleBotModel
     */
    draftCurrentEdition?: BotEditionModel;
}
/**
 * 
 * @export
 * @interface SingleIntentModel
 */
export interface SingleIntentModel {
    /**
     * 
     * @type {GroupEntryModel}
     * @memberof SingleIntentModel
     */
    groupEntry: GroupEntryModel;
    /**
     * 
     * @type {GroupEditionModel}
     * @memberof SingleIntentModel
     */
    groupEdition: GroupEditionModel;
    /**
     * 
     * @type {IntentEntryModel}
     * @memberof SingleIntentModel
     */
    intentEntry: IntentEntryModel;
    /**
     * 
     * @type {IntentEditionModel}
     * @memberof SingleIntentModel
     */
    intentEdition: IntentEditionModel;
}
/**
 * Шаблон для создания сценария простого бота.
 * @export
 * @interface SingleScenarioTemplateModel
 */
export interface SingleScenarioTemplateModel {
    /**
     * Идентификатор шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    id: string;
    /**
     * Код шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    code: string;
    /**
     * Отображаемое название шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    name: string;
    /**
     * Описание.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    description: string;
    /**
     * Идентификатор шаблона бота.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    botTemplateId?: string | null;
    /**
     * Название шаблона бота.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    botTemplateName?: string | null;
    /**
     * Название иконпака.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    iconPack?: string | null;
    /**
     * Код иконки.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    iconCode?: string | null;
    /**
     * Содержимое шаблона.
     * @type {string}
     * @memberof SingleScenarioTemplateModel
     */
    content: string;
}
/**
 * 
 * @export
 * @interface SmtpSettingsSchema
 */
export interface SmtpSettingsSchema {
    /**
     * Адрес сервера
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    host?: string | null;
    /**
     * Имя отправителя
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    name?: string | null;
    /**
     * Имя пользователя
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    username?: string | null;
    /**
     * Пароль
     * @type {string}
     * @memberof SmtpSettingsSchema
     */
    password?: string | null;
    /**
     * Порт сервера
     * @type {number}
     * @memberof SmtpSettingsSchema
     */
    port?: number;
    /**
     * Использовать SSL
     * @type {boolean}
     * @memberof SmtpSettingsSchema
     */
    useSsl: boolean;
}
/**
 * 
 * @export
 * @interface StartElma365ProcessActionSchema
 */
export interface StartElma365ProcessActionSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof StartElma365ProcessActionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    bpmTemplateCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    bpmTemplateNamespace?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StartElma365ProcessActionSchema
     */
    bpmTemplateVersion?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    processTitle?: string | null;
    /**
     * 
     * @type {Elma365ProcessStartType}
     * @memberof StartElma365ProcessActionSchema
     */
    startBy: Elma365ProcessStartType;
    /**
     * 
     * @type {Array<Elma365FormPropertySchema>}
     * @memberof StartElma365ProcessActionSchema
     */
    formProperties: Array<Elma365FormPropertySchema>;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    relatedAppElementVariableId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    variableId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartElma365ProcessActionSchema
     */
    outputBindingId?: string | null;
}
/**
 * 
 * @export
 * @interface StartTriggerSchema
 */
export interface StartTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof StartTriggerSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StructureType {
    Bot = 'Bot',
    Scenario = 'Scenario'
}

/**
 * 
 * @export
 * @interface StructureValidationResult
 */
export interface StructureValidationResult {
    /**
     * 
     * @type {StructureType}
     * @memberof StructureValidationResult
     */
    structureType: StructureType;
    /**
     * 
     * @type {Array<EntityValidationResult>}
     * @memberof StructureValidationResult
     */
    entityResults: Array<EntityValidationResult>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SuccessfullServiceEventName {
    UserAnsweredYes = 'userAnsweredYes',
    UserAnsweredNo = 'userAnsweredNo'
}

/**
 * Аналитика по успешным обслуживаниям в день.
 * @export
 * @interface SuccessfullServiceEventsPerDayModel
 */
export interface SuccessfullServiceEventsPerDayModel {
    /**
     * Дата.
     * @type {string}
     * @memberof SuccessfullServiceEventsPerDayModel
     */
    date: string;
    /**
     * Количество успешных обслуживаний с ответом Да от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerDayModel
     */
    userAnsweredYesCount: number;
    /**
     * Количество успешных обслуживаний с ответом Нет от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerDayModel
     */
    userAnsweredNoCount: number;
}
/**
 * Аналитика по успешным обслуживаниям в разрезе сценария.
 * @export
 * @interface SuccessfullServiceEventsPerScenarioModel
 */
export interface SuccessfullServiceEventsPerScenarioModel {
    /**
     * Название сценария.
     * @type {string}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    scenarioName: string;
    /**
     * ИД сценария.
     * @type {string}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    scenarioStructureId: string;
    /**
     * Количество успешных обслуживаний с ответом Да от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    userAnsweredYesCount: number;
    /**
     * Количество успешных обслуживаний с ответом Нет от пользователя.
     * @type {number}
     * @memberof SuccessfullServiceEventsPerScenarioModel
     */
    userAnsweredNoCount: number;
}
/**
 * 
 * @export
 * @interface SuggestedActions
 */
export interface SuggestedActions {
    /**
     * 
     * @type {Array<string>}
     * @memberof SuggestedActions
     */
    to?: Array<string> | null;
    /**
     * 
     * @type {Array<CardAction>}
     * @memberof SuggestedActions
     */
    actions?: Array<CardAction> | null;
}
/**
 * 
 * @export
 * @interface SuggestionInputSchema
 */
export interface SuggestionInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SuggestionInputSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Array<SuggestionSchema>}
     * @memberof SuggestionInputSchema
     */
    suggestions: Array<SuggestionSchema>;
}
/**
 * 
 * @export
 * @interface SuggestionSchema
 */
export interface SuggestionSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SuggestionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof SuggestionSchema
     */
    caption: string;
}
/**
 * 
 * @export
 * @interface SwitchActionSchema
 */
export interface SwitchActionSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SwitchActionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {Array<MainCaseActionSchema>}
     * @memberof SwitchActionSchema
     */
    mainCases: Array<MainCaseActionSchema>;
    /**
     * 
     * @type {ElseCaseActionSchema}
     * @memberof SwitchActionSchema
     */
    elseCase: ElseCaseActionSchema;
}
/**
 * 
 * @export
 * @interface SystemIntentReferenceSchema
 */
export interface SystemIntentReferenceSchema extends IntentReferenceSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof SystemIntentReferenceSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof SystemIntentReferenceSchema
     */
    groupCode: string;
    /**
     * 
     * @type {string}
     * @memberof SystemIntentReferenceSchema
     */
    intentCode: string;
    /**
     * 
     * @type {string}
     * @memberof SystemIntentReferenceSchema
     */
    semVer: string;
}
/**
 * 
 * @export
 * @interface TenantModel
 */
export interface TenantModel {
    /**
     * 
     * @type {string}
     * @memberof TenantModel
     */
    id?: string | null;
}
/**
 * 
 * @export
 * @interface TerminalTriggerSchema
 */
export interface TerminalTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TerminalTriggerSchema
     */
    $kind: SchemaKind;
}
/**
 * Запрос на получение score для фраз в интенте.
 * @export
 * @interface TestInstanceIntentScoresRequest
 */
export interface TestInstanceIntentScoresRequest {
    /**
     * ИД агента.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    agentId: string;
    /**
     * ИД версии бота.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    botVersionId: string;
    /**
     * ИД диалога внутри бота.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    dialogId: string;
    /**
     * Название интента.
     * @type {string}
     * @memberof TestInstanceIntentScoresRequest
     */
    intentName: string;
    /**
     * Список фраз для получения score.
     * @type {Array<OmegaScoreQuery>}
     * @memberof TestInstanceIntentScoresRequest
     */
    queries?: Array<OmegaScoreQuery> | null;
}
/**
 * Результат расчета score для фраз в интенте
 * @export
 * @interface TestInstanceIntentScoresResponse
 */
export interface TestInstanceIntentScoresResponse {
    /**
     * Список фраз со score
     * @type {Array<OmegaScoreQuery>}
     * @memberof TestInstanceIntentScoresResponse
     */
    queries?: Array<OmegaScoreQuery> | null;
}
/**
 * Состояние тестового бота.
 * @export
 * @interface TestInstanceResponse
 */
export interface TestInstanceResponse {
    /**
     * Ссылка для тестирования в эмуляторе.
     * @type {string}
     * @memberof TestInstanceResponse
     */
    emulatorUrl?: string | null;
    /**
     * Ссылка для тестирования в веб-чате.
     * @type {string}
     * @memberof TestInstanceResponse
     */
    callbackUrl?: string | null;
    /**
     * 
     * @type {TrainResult}
     * @memberof TestInstanceResponse
     */
    trainResult?: TrainResult;
}
/**
 * Запрос на запуск тестового бота.
 * @export
 * @interface TestInstanceRunningRequest
 */
export interface TestInstanceRunningRequest {
    /**
     * ИД агента.
     * @type {string}
     * @memberof TestInstanceRunningRequest
     */
    agentId: string;
    /**
     * ИД версии бота.
     * @type {string}
     * @memberof TestInstanceRunningRequest
     */
    botVersionId: string;
    /**
     * Архив с ботом.
     * @type {string}
     * @memberof TestInstanceRunningRequest
     */
    botZip: string;
}
/**
 * 
 * @export
 * @interface TextInputSchema
 */
export interface TextInputSchema extends InputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TextInputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface TextOutputSchema
 */
export interface TextOutputSchema extends OutputActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TextOutputSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface TextValueComparisonValidatorSchema
 */
export interface TextValueComparisonValidatorSchema extends ValueComparisonValidatorSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TextValueComparisonValidatorSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof TextValueComparisonValidatorSchema
     */
    value: string;
}
/**
 * 
 * @export
 * @interface TimerTriggerSchema
 */
export interface TimerTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TimerTriggerSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof TimerTriggerSchema
     */
    cron: string;
}
/**
 * 
 * @export
 * @interface TokenParameters
 */
export interface TokenParameters {
    /**
     * 
     * @type {ChannelAccount}
     * @memberof TokenParameters
     */
    user?: ChannelAccount;
    /**
     * 
     * @type {string}
     * @memberof TokenParameters
     */
    eTag?: string | null;
}
/**
 * Топ распознаваний.
 * @export
 * @interface TopIntentsModel
 */
export interface TopIntentsModel {
    /**
     * Топ интентов.
     * @type {Array<OmegaIntentCountModel>}
     * @memberof TopIntentsModel
     */
    omegaIntents: Array<OmegaIntentCountModel>;
}
/**
 * 
 * @export
 * @interface TrainResult
 */
export interface TrainResult {
    /**
     * 
     * @type {TrainStatus}
     * @memberof TrainResult
     */
    status?: TrainStatus;
    /**
     * 
     * @type {string}
     * @memberof TrainResult
     */
    errorMessage?: string | null;
    /**
     * 
     * @type {Array<GeneratedDataItem>}
     * @memberof TrainResult
     */
    generatedData?: Array<GeneratedDataItem> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum TrainStatus {
    Pending = 'Pending',
    Success = 'Success',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface TransferToOperatorSchema
 */
export interface TransferToOperatorSchema extends ActionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TransferToOperatorSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface TransitActionSchema
 */
export interface TransitActionSchema extends InputActionSchema {
    /**
     * 
     * @type {string}
     * @memberof TransitActionSchema
     */
    $kind: string;
    /**
     * 
     * @type {Array<ButtonSchema>}
     * @memberof TransitActionSchema
     */
    buttons: Array<ButtonSchema>;
}
/**
 * 
 * @export
 * @interface TransitionTriggerSchema
 */
export interface TransitionTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof TransitionTriggerSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @interface TriggerGroupSchema
 */
export interface TriggerGroupSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof TriggerGroupSchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof TriggerGroupSchema
     */
    name: string;
}
/**
 * 
 * @export
 * @interface TriggerSchema
 */
export interface TriggerSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof TriggerSchema
     */
    $kind: string;
    /**
     * 
     * @type {string}
     * @memberof TriggerSchema
     */
    outputBindingId?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UnaryConditionOperator {
    Empty = 'Empty',
    NotEmpty = 'NotEmpty'
}

/**
 * 
 * @export
 * @interface UnaryConditionSchema
 */
export interface UnaryConditionSchema extends ConditionSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof UnaryConditionSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {UnaryConditionOperator}
     * @memberof UnaryConditionSchema
     */
    operator: UnaryConditionOperator;
    /**
     * 
     * @type {OperandSchema}
     * @memberof UnaryConditionSchema
     */
    operand: OperandSchema;
}
/**
 * 
 * @export
 * @interface UnknownIntentTriggerSchema
 */
export interface UnknownIntentTriggerSchema extends TriggerSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof UnknownIntentTriggerSchema
     */
    $kind: SchemaKind;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum UpdateScenarioOperationType {
    Rename = 'Rename',
    Edit = 'Edit',
    Import = 'Import',
    EnableDisable = 'EnableDisable',
    ChangeSettings = 'ChangeSettings'
}

/**
 * 
 * @export
 * @interface UserIntentReferenceSchema
 */
export interface UserIntentReferenceSchema extends IntentReferenceSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof UserIntentReferenceSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof UserIntentReferenceSchema
     */
    intentId: string;
}
/**
 * 
 * @export
 * @interface UserReferenceModel
 */
export interface UserReferenceModel {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserReferenceModel
     */
    userId: string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserReferenceModel
     */
    fullName: string;
}
/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    errors?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface ValidationRuleResult
 */
export interface ValidationRuleResult {
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleResult
     */
    code: string;
    /**
     * 
     * @type {ValidationRuleStatus}
     * @memberof ValidationRuleResult
     */
    status: ValidationRuleStatus;
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleResult
     */
    message: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ValidationRuleStatus {
    Validating = 'Validating',
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface ValidatorSchema
 */
export interface ValidatorSchema extends EntitySchema {
    /**
     * 
     * @type {string}
     * @memberof ValidatorSchema
     */
    $kind: string;
}
/**
 * 
 * @export
 * @interface ValueComparisonValidatorSchema
 */
export interface ValueComparisonValidatorSchema extends ComparisonValidatorSchema {
    /**
     * 
     * @type {string}
     * @memberof ValueComparisonValidatorSchema
     */
    $kind: string;
    /**
     * 
     * @type {any}
     * @memberof ValueComparisonValidatorSchema
     */
    value: any | null;
}
/**
 * 
 * @export
 * @interface VariableContaner
 */
export interface VariableContaner {
    /**
     * 
     * @type {string}
     * @memberof VariableContaner
     */
    name?: string | null;
    /**
     * 
     * @type {VariableType}
     * @memberof VariableContaner
     */
    type?: VariableType;
}
/**
 * 
 * @export
 * @interface VariableOperandSchema
 */
export interface VariableOperandSchema extends OperandSchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof VariableOperandSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof VariableOperandSchema
     */
    variableId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VariableOperandSchema
     */
    propertyPath?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VariableOwner {
    User = 'User',
    System = 'System'
}

/**
 * 
 * @export
 * @interface VariableSchema
 */
export interface VariableSchema extends EntitySchema {
    /**
     * 
     * @type {SchemaKind}
     * @memberof VariableSchema
     */
    $kind: SchemaKind;
    /**
     * 
     * @type {string}
     * @memberof VariableSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VariableSchema
     */
    code: string;
    /**
     * 
     * @type {VariableType}
     * @memberof VariableSchema
     */
    type: VariableType;
    /**
     * 
     * @type {VariableScope}
     * @memberof VariableSchema
     */
    scope: VariableScope;
    /**
     * 
     * @type {VariableState}
     * @memberof VariableSchema
     */
    state: VariableState;
    /**
     * 
     * @type {VariableOwner}
     * @memberof VariableSchema
     */
    owner: VariableOwner;
    /**
     * 
     * @type {Array<ValidatorSchema>}
     * @memberof VariableSchema
     */
    validators: Array<ValidatorSchema>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum VariableScope {
    Scenario = 'Scenario',
    Bot = 'Bot',
    User = 'User',
    Session = 'Session'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum VariableState {
    Exists = 'Exists',
    Deleted = 'Deleted'
}


/**
 * 
 * @export
 * @enum {string}
 */

export enum VariableType {
    String = 'String',
    Number = 'Number',
    Boolean = 'Boolean',
    DateTime = 'DateTime',
    PersonName = 'PersonName',
    ComplexObject = 'ComplexObject',
    SessionProfile = 'SessionProfile',
    File = 'File',
    PersonContacts = 'PersonContacts'
}


/**
 * AccountContactApi - axios parameter creator
 * @export
 */
export const AccountContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить контакт аккаунта.
         * @param {string} id ИД контакта аккаунта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContact: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccountContact', 'id', id)
            const localVarPath = `/api/v1/accounts/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск контактов аккаунтов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personAccountId] ИД аккаунта сотрудника.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAccountContacts: async (channelId?: string, agentStageId?: string, personId?: string, agentStageAccountId?: string, personAccountId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personAccountId !== undefined) {
                localVarQueryParameter['PersonAccountId'] = personAccountId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountContactApi - functional programming interface
 * @export
 */
export const AccountContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить контакт аккаунта.
         * @param {string} id ИД контакта аккаунта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountContact(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск контактов аккаунтов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personAccountId] ИД аккаунта сотрудника.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAccountContacts(channelId?: string, agentStageId?: string, personId?: string, agentStageAccountId?: string, personAccountId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountContactModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAccountContacts(channelId, agentStageId, personId, agentStageAccountId, personAccountId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountContactApi - factory interface
 * @export
 */
export const AccountContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить контакт аккаунта.
         * @param {string} id ИД контакта аккаунта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountContact(id: string, options?: any): AxiosPromise<AccountContactModel> {
            return localVarFp.getAccountContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск контактов аккаунтов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personAccountId] ИД аккаунта сотрудника.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAccountContacts(channelId?: string, agentStageId?: string, personId?: string, agentStageAccountId?: string, personAccountId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AccountContactModelPaginationResponse> {
            return localVarFp.searchAccountContacts(channelId, agentStageId, personId, agentStageAccountId, personAccountId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountContactApi - object-oriented interface
 * @export
 * @class AccountContactApi
 * @extends {BaseAPI}
 */
export class AccountContactApi extends BaseAPI {
    /**
     * 
     * @summary Получить контакт аккаунта.
     * @param {string} id ИД контакта аккаунта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountContactApi
     */
    public getAccountContact(id: string, options?: any) {
        return AccountContactApiFp(this.configuration).getAccountContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск контактов аккаунтов.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personAccountId] ИД аккаунта сотрудника.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountContactApi
     */
    public searchAccountContacts(channelId?: string, agentStageId?: string, personId?: string, agentStageAccountId?: string, personAccountId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return AccountContactApiFp(this.configuration).searchAccountContacts(channelId, agentStageId, personId, agentStageAccountId, personAccountId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Экспортировать активности.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {string} [type] Тип активности.
         * @param {string} [name] Наименование активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivities: async (channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/activities/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['ConversationId'] = conversationId;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить активность.
         * @param {string} id ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivity', 'id', id)
            const localVarPath = `/api/v1/runtime/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {string} [type] Тип активности.
         * @param {string} [name] Наименование активности.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivities: async (channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (conversationId !== undefined) {
                localVarQueryParameter['ConversationId'] = conversationId;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Экспортировать активности.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {string} [type] Тип активности.
         * @param {string} [name] Наименование активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportActivities(channelId, agentStageId, personId, conversationId, direction, type, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить активность.
         * @param {string} id ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {string} [type] Тип активности.
         * @param {string} [name] Наименование активности.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActivities(channelId, agentStageId, personId, conversationId, direction, type, name, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Экспортировать активности.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {string} [type] Тип активности.
         * @param {string} [name] Наименование активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, options?: any): AxiosPromise<any> {
            return localVarFp.exportActivities(channelId, agentStageId, personId, conversationId, direction, type, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить активность.
         * @param {string} id ИД активности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(id: string, options?: any): AxiosPromise<ActivityModel> {
            return localVarFp.getActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск активностей.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {string} [conversationId] ИД беседы.
         * @param {ActivityDirection} [direction] Направление активности.
         * @param {string} [type] Тип активности.
         * @param {string} [name] Наименование активности.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ActivityModelPaginationResponse> {
            return localVarFp.searchActivities(channelId, agentStageId, personId, conversationId, direction, type, name, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @summary Экспортировать активности.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {string} [conversationId] ИД беседы.
     * @param {ActivityDirection} [direction] Направление активности.
     * @param {string} [type] Тип активности.
     * @param {string} [name] Наименование активности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public exportActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, options?: any) {
        return ActivityApiFp(this.configuration).exportActivities(channelId, agentStageId, personId, conversationId, direction, type, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить активность.
     * @param {string} id ИД активности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivity(id: string, options?: any) {
        return ActivityApiFp(this.configuration).getActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск активностей.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {string} [conversationId] ИД беседы.
     * @param {ActivityDirection} [direction] Направление активности.
     * @param {string} [type] Тип активности.
     * @param {string} [name] Наименование активности.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public searchActivities(channelId?: string, agentStageId?: string, personId?: string, conversationId?: string, direction?: ActivityDirection, type?: string, name?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return ActivityApiFp(this.configuration).searchActivities(channelId, agentStageId, personId, conversationId, direction, type, name, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent: async (agentCreationRequest?: AgentCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgent', 'id', id)
            const localVarPath = `/api/v1/runtime/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgent', 'id', id)
            const localVarPath = `/api/v1/runtime/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Публикация версии бота.
         * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishBotFromVersion: async (agentPublishRequest?: AgentPublishRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents/publish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentPublishRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Переобучение стадии бота.
         * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrain: async (agentRetrainRequest?: AgentRetrainRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents/retrain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentRetrainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск проактивных диалогов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProactiveDialogs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/agents/proactive-dialogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent: async (id: string, agentUpdatingRequest?: AgentUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgent', 'id', id)
            const localVarPath = `/api/v1/runtime/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgent(agentCreationRequest?: AgentCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgent(agentCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgent(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgent(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Публикация версии бота.
         * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishBotFromVersion(agentPublishRequest?: AgentPublishRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishBotFromVersion(agentPublishRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Переобучение стадии бота.
         * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrain(agentRetrainRequest?: AgentRetrainRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrain(agentRetrainRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgents(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgents(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск проактивных диалогов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProactiveDialogs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentProactiveDialogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProactiveDialogs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgent(id: string, agentUpdatingRequest?: AgentUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgent(id, agentUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgent(agentCreationRequest?: AgentCreationRequest, options?: any): AxiosPromise<AgentCreationResponse> {
            return localVarFp.createAgent(agentCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgent(id: string, options?: any): AxiosPromise<AgentModel> {
            return localVarFp.getAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Публикация версии бота.
         * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishBotFromVersion(agentPublishRequest?: AgentPublishRequest, options?: any): AxiosPromise<void> {
            return localVarFp.publishBotFromVersion(agentPublishRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Переобучение стадии бота.
         * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrain(agentRetrainRequest?: AgentRetrainRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retrain(agentRetrainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AgentModelPaginationResponse> {
            return localVarFp.searchAgents(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск проактивных диалогов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProactiveDialogs(options?: any): AxiosPromise<Array<AgentProactiveDialogModel>> {
            return localVarFp.searchProactiveDialogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgent(id: string, agentUpdatingRequest?: AgentUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateAgent(id, agentUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
    /**
     * 
     * @summary Создать бота.
     * @param {AgentCreationRequest} [agentCreationRequest] Запрос на создание бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public createAgent(agentCreationRequest?: AgentCreationRequest, options?: any) {
        return AgentApiFp(this.configuration).createAgent(agentCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить бота.
     * @param {string} id ИД бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public deleteAgent(id: string, options?: any) {
        return AgentApiFp(this.configuration).deleteAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить бота.
     * @param {string} id ИД бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public getAgent(id: string, options?: any) {
        return AgentApiFp(this.configuration).getAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Публикация версии бота.
     * @param {AgentPublishRequest} [agentPublishRequest] Запрос на публикацию.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public publishBotFromVersion(agentPublishRequest?: AgentPublishRequest, options?: any) {
        return AgentApiFp(this.configuration).publishBotFromVersion(agentPublishRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Переобучение стадии бота.
     * @param {AgentRetrainRequest} [agentRetrainRequest] Запрос на переобучение.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public retrain(agentRetrainRequest?: AgentRetrainRequest, options?: any) {
        return AgentApiFp(this.configuration).retrain(agentRetrainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск ботов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public searchAgents(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return AgentApiFp(this.configuration).searchAgents(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск проактивных диалогов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public searchProactiveDialogs(options?: any) {
        return AgentApiFp(this.configuration).searchProactiveDialogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить бота.
     * @param {string} id ИД бота.
     * @param {AgentUpdatingRequest} [agentUpdatingRequest] Запрос на обновление бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public updateAgent(id: string, agentUpdatingRequest?: AgentUpdatingRequest, options?: any) {
        return AgentApiFp(this.configuration).updateAgent(id, agentUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentStageApi - axios parameter creator
 * @export
 */
export const AgentStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentStage', 'id', id)
            const localVarPath = `/api/v1/runtime/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить стадию бота.
         * @param {string} id ИД бота.
         * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStage: async (id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentStage', 'id', id)
            const localVarPath = `/api/v1/runtime/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentStageUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentStageApi - functional programming interface
 * @export
 */
export const AgentStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentStageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить стадию бота.
         * @param {string} id ИД бота.
         * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentStage(id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentStage(id, agentStageUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentStageApi - factory interface
 * @export
 */
export const AgentStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentStageApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStage(id: string, options?: any): AxiosPromise<AgentStageModel> {
            return localVarFp.getAgentStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить стадию бота.
         * @param {string} id ИД бота.
         * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStage(id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateAgentStage(id, agentStageUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentStageApi - object-oriented interface
 * @export
 * @class AgentStageApi
 * @extends {BaseAPI}
 */
export class AgentStageApi extends BaseAPI {
    /**
     * 
     * @summary Получить стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageApi
     */
    public getAgentStage(id: string, options?: any) {
        return AgentStageApiFp(this.configuration).getAgentStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить стадию бота.
     * @param {string} id ИД бота.
     * @param {AgentStageUpdatingRequest} [agentStageUpdatingRequest] Запрос на обновление стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageApi
     */
    public updateAgentStage(id: string, agentStageUpdatingRequest?: AgentStageUpdatingRequest, options?: any) {
        return AgentStageApiFp(this.configuration).updateAgentStage(id, agentStageUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentStageAccountApi - axios parameter creator
 * @export
 */
export const AgentStageAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать аккаунт бота.
         * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentStageAccount: async (agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/agentstages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentStageAccountCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить аккаунт бота.
         * @param {string} id ИД аккаунт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentStageAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgentStageAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStageAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentStageAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов ботов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentStageAccounts: async (channelId?: string, agentStageId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/agentstages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStageAccount: async (id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentStageAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/agentstages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentStageAccountUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentStageAccountApi - functional programming interface
 * @export
 */
export const AgentStageAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentStageAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать аккаунт бота.
         * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgentStageAccount(agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgentStageAccount(agentStageAccountCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить аккаунт бота.
         * @param {string} id ИД аккаунт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgentStageAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgentStageAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentStageAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentStageAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов ботов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgentStageAccounts(channelId?: string, agentStageId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentStageAccounts(channelId, agentStageId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentStageAccount(id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentStageAccountUpdatingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentStageAccount(id, agentStageAccountUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentStageAccountApi - factory interface
 * @export
 */
export const AgentStageAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentStageAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать аккаунт бота.
         * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentStageAccount(agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options?: any): AxiosPromise<AgentStageAccountCreationResponse> {
            return localVarFp.createAgentStageAccount(agentStageAccountCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить аккаунт бота.
         * @param {string} id ИД аккаунт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentStageAccount(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAgentStageAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentStageAccount(id: string, options?: any): AxiosPromise<AgentStageAccountModel> {
            return localVarFp.getAgentStageAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов ботов.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentStageAccounts(channelId?: string, agentStageId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AgentStageAccountModelPaginationResponse> {
            return localVarFp.searchAgentStageAccounts(channelId, agentStageId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить аккаунт бота.
         * @param {string} id ИД аккаунта бота.
         * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentStageAccount(id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options?: any): AxiosPromise<AgentStageAccountUpdatingResponse> {
            return localVarFp.updateAgentStageAccount(id, agentStageAccountUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentStageAccountApi - object-oriented interface
 * @export
 * @class AgentStageAccountApi
 * @extends {BaseAPI}
 */
export class AgentStageAccountApi extends BaseAPI {
    /**
     * 
     * @summary Создать аккаунт бота.
     * @param {AgentStageAccountCreationRequest} [agentStageAccountCreationRequest] Запрос на создание аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public createAgentStageAccount(agentStageAccountCreationRequest?: AgentStageAccountCreationRequest, options?: any) {
        return AgentStageAccountApiFp(this.configuration).createAgentStageAccount(agentStageAccountCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить аккаунт бота.
     * @param {string} id ИД аккаунт бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public deleteAgentStageAccount(id: string, options?: any) {
        return AgentStageAccountApiFp(this.configuration).deleteAgentStageAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аккаунт бота.
     * @param {string} id ИД аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public getAgentStageAccount(id: string, options?: any) {
        return AgentStageAccountApiFp(this.configuration).getAgentStageAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск аккаунтов ботов.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public searchAgentStageAccounts(channelId?: string, agentStageId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return AgentStageAccountApiFp(this.configuration).searchAgentStageAccounts(channelId, agentStageId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить аккаунт бота.
     * @param {string} id ИД аккаунта бота.
     * @param {AgentStageAccountUpdatingRequest} [agentStageAccountUpdatingRequest] Запрос на обновление аккаунта бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentStageAccountApi
     */
    public updateAgentStageAccount(id: string, agentStageAccountUpdatingRequest?: AgentStageAccountUpdatingRequest, options?: any) {
        return AgentStageAccountApiFp(this.configuration).updateAgentStageAccount(id, agentStageAccountUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsReportsApi - axios parameter creator
 * @export
 */
export const AnalyticsReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Аналитика по закрытию бесед в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getConversationsClosedPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/conversations/closed/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по закрытию бесед в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerScenarioList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getConversationsClosedPerScenarioList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/conversations/closed/grouping/scenario`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аналитику по беседам.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getConversationsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/conversations/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по пользовательским событиям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomEventsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getCustomEventsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/custom-events/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по переводам на оператора.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsalationsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getEsalationsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/escalations/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить общую аналитику.
         * @param {string} agentStageId ИД стадии агента.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralAnalytics: async (agentStageId: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getGeneralAnalytics', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/general`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аналитику по распознаваниям.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getIntentsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/intents/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по нажатиям кнопок меню.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuItemSelectionsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getMenuItemSelectionsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/menu/items/selections/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по событиям суфлера в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompterModeEventsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getPrompterModeEventsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/prompter-mode/events/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по стартам сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioStartsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getScenarioStartsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/scenarios/starts/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerDayList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getSuccessfullServiceEventsPerDayList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/successfull-service/events/grouping/day`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerScenarioList: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getSuccessfullServiceEventsPerScenarioList', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/successfull-service/events/grouping/scenario`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить аналитику по топу распознаваний.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopIntents: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getTopIntents', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/intents/top`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить экспорт отчета.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runExportAnalyticsReport: async (agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('runExportAnalyticsReport', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/analytics/reports/{agentStageId}/export`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['FromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['ToDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (eventMode !== undefined) {
                localVarQueryParameter['EventMode'] = eventMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsReportsApi - functional programming interface
 * @export
 */
export const AnalyticsReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Аналитика по закрытию бесед в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsClosedPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationsClosedPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsClosedPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по закрытию бесед в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsClosedPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationsClosedPerScenarioModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsClosedPerScenarioList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить аналитику по беседам.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по пользовательским событиям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomEventsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по переводам на оператора.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEsalationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EscalationsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEsalationsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить общую аналитику.
         * @param {string} agentStageId ИД стадии агента.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralAnalytics(agentStageId: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnalyticsReportsGeneralDataModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralAnalytics(agentStageId, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить аналитику по распознаваниям.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntentsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по нажатиям кнопок меню.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMenuItemSelectionsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MenuItemSelectionsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuItemSelectionsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по событиям суфлера в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrompterModeEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrompterModeEventsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrompterModeEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по стартам сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioStartsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScenarioStartsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioStartsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuccessfullServiceEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessfullServiceEventsPerDayModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuccessfullServiceEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuccessfullServiceEventsPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuccessfullServiceEventsPerScenarioModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuccessfullServiceEventsPerScenarioList(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить аналитику по топу распознаваний.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTopIntents(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopIntentsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTopIntents(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запустить экспорт отчета.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runExportAnalyticsReport(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunExportAnalyticsReportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runExportAnalyticsReport(agentStageId, fromDate, toDate, eventMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsReportsApi - factory interface
 * @export
 */
export const AnalyticsReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Аналитика по закрытию бесед в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ConversationsClosedPerDayModel>> {
            return localVarFp.getConversationsClosedPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по закрытию бесед в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsClosedPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ConversationsClosedPerScenarioModel>> {
            return localVarFp.getConversationsClosedPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аналитику по беседам.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ConversationsPerDayModel>> {
            return localVarFp.getConversationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по пользовательским событиям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<CustomEventsPerDayModel>> {
            return localVarFp.getCustomEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по переводам на оператора.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEsalationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<EscalationsPerDayModel>> {
            return localVarFp.getEsalationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить общую аналитику.
         * @param {string} agentStageId ИД стадии агента.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralAnalytics(agentStageId: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<AnalyticsReportsGeneralDataModel> {
            return localVarFp.getGeneralAnalytics(agentStageId, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аналитику по распознаваниям.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<IntentsPerDayModel>> {
            return localVarFp.getIntentsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по нажатиям кнопок меню.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMenuItemSelectionsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<MenuItemSelectionsPerDayModel>> {
            return localVarFp.getMenuItemSelectionsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по событиям суфлера в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrompterModeEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<PrompterModeEventsPerDayModel>> {
            return localVarFp.getPrompterModeEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по стартам сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioStartsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<ScenarioStartsPerDayModel>> {
            return localVarFp.getScenarioStartsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в день.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<SuccessfullServiceEventsPerDayModel>> {
            return localVarFp.getSuccessfullServiceEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuccessfullServiceEventsPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<Array<SuccessfullServiceEventsPerScenarioModel>> {
            return localVarFp.getSuccessfullServiceEventsPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить аналитику по топу распознаваний.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTopIntents(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<TopIntentsModel> {
            return localVarFp.getTopIntents(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить экспорт отчета.
         * @param {string} agentStageId ИД стадии агента.
         * @param {string} [fromDate] Начальная дата.
         * @param {string} [toDate] Конечная дата.
         * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runExportAnalyticsReport(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any): AxiosPromise<RunExportAnalyticsReportResultModel> {
            return localVarFp.runExportAnalyticsReport(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsReportsApi - object-oriented interface
 * @export
 * @class AnalyticsReportsApi
 * @extends {BaseAPI}
 */
export class AnalyticsReportsApi extends BaseAPI {
    /**
     * 
     * @summary Аналитика по закрытию бесед в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getConversationsClosedPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getConversationsClosedPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по закрытию бесед в разрезе сценариев.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getConversationsClosedPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getConversationsClosedPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аналитику по беседам.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getConversationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getConversationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по пользовательским событиям в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getCustomEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getCustomEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по переводам на оператора.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getEsalationsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getEsalationsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить общую аналитику.
     * @param {string} agentStageId ИД стадии агента.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getGeneralAnalytics(agentStageId: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getGeneralAnalytics(agentStageId, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аналитику по распознаваниям.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getIntentsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getIntentsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по нажатиям кнопок меню.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getMenuItemSelectionsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getMenuItemSelectionsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по событиям суфлера в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getPrompterModeEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getPrompterModeEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по стартам сценариев.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getScenarioStartsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getScenarioStartsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по успешным обслуживаниям в день.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getSuccessfullServiceEventsPerDayList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getSuccessfullServiceEventsPerDayList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Аналитика по успешным обслуживаниям в разрезе сценариев.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getSuccessfullServiceEventsPerScenarioList(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getSuccessfullServiceEventsPerScenarioList(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить аналитику по топу распознаваний.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public getTopIntents(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).getTopIntents(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить экспорт отчета.
     * @param {string} agentStageId ИД стадии агента.
     * @param {string} [fromDate] Начальная дата.
     * @param {string} [toDate] Конечная дата.
     * @param {AnalyticsEventMode} [eventMode] Признак тестового события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsReportsApi
     */
    public runExportAnalyticsReport(agentStageId: string, fromDate?: string, toDate?: string, eventMode?: AnalyticsEventMode, options?: any) {
        return AnalyticsReportsApiFp(this.configuration).runExportAnalyticsReport(agentStageId, fromDate, toDate, eventMode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAboutInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return localVarFp.getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return localVarFp.getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAboutInfo(options?: any) {
        return ApplicationApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getHealthInfo(options?: any) {
        return ApplicationApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BotApi - axios parameter creator
 * @export
 */
export const BotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBot: async (botCreationRequest?: BotCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/bots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД записи бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBot: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить бота по id сложного бота.
         * @param {string} agentId ИД записи сложного бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotByAgentId: async (agentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getBotByAgentId', 'agentId', agentId)
            const localVarPath = `/api/v1/runtime/simple/bots/get-by-agent-id/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список доступных моделей обучения бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTrainingModels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/bots/nlp-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {BotSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBots: async (sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/bots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBot: async (id: string, botUpdatingRequest?: BotUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bots/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotApi - functional programming interface
 * @export
 */
export const BotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBot(botCreationRequest?: BotCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBot(botCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД записи бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBot(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleBotModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBot(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить бота по id сложного бота.
         * @param {string} agentId ИД записи сложного бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotByAgentId(agentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleBotModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotByAgentId(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список доступных моделей обучения бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTrainingModels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotTrainingModelModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTrainingModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {BotSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBots(sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBotModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBots(sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBot(id: string, botUpdatingRequest?: BotUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBot(id, botUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BotApi - factory interface
 * @export
 */
export const BotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать бота.
         * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBot(botCreationRequest?: BotCreationRequest, options?: any): AxiosPromise<BotCreationResponse> {
            return localVarFp.createBot(botCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить бота.
         * @param {string} id ИД записи бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBot(id: string, options?: any): AxiosPromise<SingleBotModel> {
            return localVarFp.getBot(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить бота по id сложного бота.
         * @param {string} agentId ИД записи сложного бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotByAgentId(agentId: string, options?: any): AxiosPromise<SingleBotModel> {
            return localVarFp.getBotByAgentId(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список доступных моделей обучения бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTrainingModels(options?: any): AxiosPromise<Array<BotTrainingModelModel>> {
            return localVarFp.getBotTrainingModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск ботов.
         * @param {BotSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBots(sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListBotModelPaginationResponse> {
            return localVarFp.searchBots(sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить бота.
         * @param {string} id ИД бота.
         * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBot(id: string, botUpdatingRequest?: BotUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateBot(id, botUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotApi - object-oriented interface
 * @export
 * @class BotApi
 * @extends {BaseAPI}
 */
export class BotApi extends BaseAPI {
    /**
     * 
     * @summary Создать бота.
     * @param {BotCreationRequest} [botCreationRequest] Запрос на создание бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public createBot(botCreationRequest?: BotCreationRequest, options?: any) {
        return BotApiFp(this.configuration).createBot(botCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить бота.
     * @param {string} id ИД записи бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBot(id: string, options?: any) {
        return BotApiFp(this.configuration).getBot(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить бота по id сложного бота.
     * @param {string} agentId ИД записи сложного бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBotByAgentId(agentId: string, options?: any) {
        return BotApiFp(this.configuration).getBotByAgentId(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список доступных моделей обучения бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public getBotTrainingModels(options?: any) {
        return BotApiFp(this.configuration).getBotTrainingModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск ботов.
     * @param {BotSortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public searchBots(sorting?: BotSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return BotApiFp(this.configuration).searchBots(sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить бота.
     * @param {string} id ИД бота.
     * @param {BotUpdatingRequest} [botUpdatingRequest] Запрос на обновление бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotApi
     */
    public updateBot(id: string, botUpdatingRequest?: BotUpdatingRequest, options?: any) {
        return BotApiFp(this.configuration).updateBot(id, botUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BotEditionApi - axios parameter creator
 * @export
 */
export const BotEditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Экспортировать редакцию бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBotEdition: async (id: string, contentFormat: BotContentFormat, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportBotEdition', 'id', id)
            // verify required parameter 'contentFormat' is not null or undefined
            assertParamExists('exportBotEdition', 'contentFormat', contentFormat)
            const localVarPath = `/api/v1/runtime/simple/bot-editions/{id}/export`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (contentFormat !== undefined) {
                localVarQueryParameter['ContentFormat'] = contentFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotEditionApi - functional programming interface
 * @export
 */
export const BotEditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotEditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Экспортировать редакцию бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportBotEdition(id: string, contentFormat: BotContentFormat, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportBotEdition(id, contentFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BotEditionApi - factory interface
 * @export
 */
export const BotEditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotEditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Экспортировать редакцию бота.
         * @param {string} id ИД редакции бота.
         * @param {BotContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportBotEdition(id: string, contentFormat: BotContentFormat, options?: any): AxiosPromise<any> {
            return localVarFp.exportBotEdition(id, contentFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotEditionApi - object-oriented interface
 * @export
 * @class BotEditionApi
 * @extends {BaseAPI}
 */
export class BotEditionApi extends BaseAPI {
    /**
     * 
     * @summary Экспортировать редакцию бота.
     * @param {string} id ИД редакции бота.
     * @param {BotContentFormat} contentFormat Формат содержимого файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotEditionApi
     */
    public exportBotEdition(id: string, contentFormat: BotContentFormat, options?: any) {
        return BotEditionApiFp(this.configuration).exportBotEdition(id, contentFormat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BotStageApi - axios parameter creator
 * @export
 */
export const BotStageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавить существующую редакцию в стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBotEdition: async (id: string, botEditionAddingRequest?: BotEditionAddingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addBotEdition', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/add-bot-edition`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botEditionAddingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать черновую стадию бота.
         * @param {string} id ИД исходной стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftBotStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createDraftBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/create-draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario: async (id: string, scenarioCreationRequest?: ScenarioCreationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/create-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBotStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария для удаления.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario: async (id: string, scenarioStructureId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteScenario', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('deleteScenario', 'scenarioStructureId', scenarioStructureId)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/delete-scenario/{scenarioStructureId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"scenarioStructureId"}}`, encodeURIComponent(String(scenarioStructureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Дублировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateScenario: async (id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/duplicate-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioDuplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Экспортировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScenario: async (id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('exportScenario', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('exportScenario', 'scenarioStructureId', scenarioStructureId)
            // verify required parameter 'contentFormat' is not null or undefined
            assertParamExists('exportScenario', 'contentFormat', contentFormat)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/export-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }

            if (contentFormat !== undefined) {
                localVarQueryParameter['ContentFormat'] = contentFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить данные для тестирования стадии бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStageTestingState: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotStageTestingState', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-bot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario: async (id: string, scenarioStructureId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenario', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('getScenario', 'scenarioStructureId', scenarioStructureId)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/get-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить данные для тестирования сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId Структура сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTestingState: async (id: string, scenarioStructureId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenarioTestingState', 'id', id)
            // verify required parameter 'scenarioStructureId' is not null or undefined
            assertParamExists('getScenarioTestingState', 'scenarioStructureId', scenarioStructureId)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} [scenarioStructureId] ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioUsages: async (id: string, scenarioStructureId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenarioUsages', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/scenario-usages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (scenarioStructureId !== undefined) {
                localVarQueryParameter['ScenarioStructureId'] = scenarioStructureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Импортировать бота.
         * @param {string} id ИД стадии бота.
         * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBot: async (id: string, botImportRequest?: BotImportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('importBot', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/import-bot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Объединить одну стадию бота с другой.
         * @param {string} id ИД исходной стадии бота.
         * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeBotStage: async (id: string, botStageMergeRequest?: BotStageMergeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mergeBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/merge`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botStageMergeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Тестировать стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBotStage: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testBotStage', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-bot`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Тестировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testScenario: async (id: string, scenarioTestingRequest?: ScenarioTestingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/test-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioTestingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenario: async (id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/update-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить настройки бота.
         * @param {string} id ИД стадии бота.
         * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSettings', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/update-settings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingsUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить валидацию сценария.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateScenario: async (id: string, scenarioValidationRequest?: ScenarioValidationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('validateScenario', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/bot-stages/{id}/validate-scenario`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scenarioValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotStageApi - functional programming interface
 * @export
 */
export const BotStageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotStageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавить существующую редакцию в стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBotEdition(id: string, botEditionAddingRequest?: BotEditionAddingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBotEdition(id, botEditionAddingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать черновую стадию бота.
         * @param {string} id ИД исходной стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraftBotStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraftBotStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScenario(id: string, scenarioCreationRequest?: ScenarioCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScenario(id, scenarioCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBotStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBotStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария для удаления.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScenario(id: string, scenarioStructureId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScenario(id, scenarioStructureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Дублировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateScenario(id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateScenario(id, scenarioDuplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Экспортировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportScenario(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportScenario(id, scenarioStructureId, contentFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить данные для тестирования стадии бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotStageTestingState(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotStageTestingState(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenario(id: string, scenarioStructureId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioEditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenario(id, scenarioStructureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить данные для тестирования сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId Структура сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioTestingState(id: string, scenarioStructureId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioTestingState(id, scenarioStructureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} [scenarioStructureId] ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioUsages(id: string, scenarioStructureId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioGetUsagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioUsages(id, scenarioStructureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Импортировать бота.
         * @param {string} id ИД стадии бота.
         * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importBot(id: string, botImportRequest?: BotImportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importBot(id, botImportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Объединить одну стадию бота с другой.
         * @param {string} id ИД исходной стадии бота.
         * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeBotStage(id: string, botStageMergeRequest?: BotStageMergeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageMergeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mergeBotStage(id, botStageMergeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Тестировать стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBotStage(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBotStage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Тестировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testScenario(id: string, scenarioTestingRequest?: ScenarioTestingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioTestingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testScenario(id, scenarioTestingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateScenario(id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateScenario(id, scenarioUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить настройки бота.
         * @param {string} id ИД стадии бота.
         * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotStageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(id, settingsUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить валидацию сценария.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateScenario(id: string, scenarioValidationRequest?: ScenarioValidationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScenarioValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateScenario(id, scenarioValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BotStageApi - factory interface
 * @export
 */
export const BotStageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotStageApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавить существующую редакцию в стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBotEdition(id: string, botEditionAddingRequest?: BotEditionAddingRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.addBotEdition(id, botEditionAddingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать черновую стадию бота.
         * @param {string} id ИД исходной стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraftBotStage(id: string, options?: any): AxiosPromise<BotStageCreationResponse> {
            return localVarFp.createDraftBotStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScenario(id: string, scenarioCreationRequest?: ScenarioCreationRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.createScenario(id, scenarioCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBotStage(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteBotStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария для удаления.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScenario(id: string, scenarioStructureId: string, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.deleteScenario(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Дублировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateScenario(id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.duplicateScenario(id, scenarioDuplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Экспортировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScenario(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: any): AxiosPromise<any> {
            return localVarFp.exportScenario(id, scenarioStructureId, contentFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить данные для тестирования стадии бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStageTestingState(id: string, options?: any): AxiosPromise<BotStageTestingResponse> {
            return localVarFp.getBotStageTestingState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenario(id: string, scenarioStructureId: string, options?: any): AxiosPromise<ScenarioEditionModel> {
            return localVarFp.getScenario(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить данные для тестирования сценария.
         * @param {string} id ИД стадии бота.
         * @param {string} scenarioStructureId Структура сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTestingState(id: string, scenarioStructureId: string, options?: any): AxiosPromise<ScenarioTestingResponse> {
            return localVarFp.getScenarioTestingState(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
         * @param {string} id ИД стадии бота.
         * @param {string} [scenarioStructureId] ИД структуры сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioUsages(id: string, scenarioStructureId?: string, options?: any): AxiosPromise<ScenarioGetUsagesResponse> {
            return localVarFp.getScenarioUsages(id, scenarioStructureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Импортировать бота.
         * @param {string} id ИД стадии бота.
         * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importBot(id: string, botImportRequest?: BotImportRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.importBot(id, botImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Объединить одну стадию бота с другой.
         * @param {string} id ИД исходной стадии бота.
         * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeBotStage(id: string, botStageMergeRequest?: BotStageMergeRequest, options?: any): AxiosPromise<BotStageMergeResponse> {
            return localVarFp.mergeBotStage(id, botStageMergeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Тестировать стадию бота.
         * @param {string} id ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBotStage(id: string, options?: any): AxiosPromise<BotStageTestingResponse> {
            return localVarFp.testBotStage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Тестировать сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testScenario(id: string, scenarioTestingRequest?: ScenarioTestingRequest, options?: any): AxiosPromise<ScenarioTestingResponse> {
            return localVarFp.testScenario(id, scenarioTestingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить сценарий.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScenario(id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.updateScenario(id, scenarioUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить настройки бота.
         * @param {string} id ИД стадии бота.
         * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options?: any): AxiosPromise<BotStageModel> {
            return localVarFp.updateSettings(id, settingsUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить валидацию сценария.
         * @param {string} id ИД стадии бота.
         * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateScenario(id: string, scenarioValidationRequest?: ScenarioValidationRequest, options?: any): AxiosPromise<ScenarioValidationResponse> {
            return localVarFp.validateScenario(id, scenarioValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotStageApi - object-oriented interface
 * @export
 * @class BotStageApi
 * @extends {BaseAPI}
 */
export class BotStageApi extends BaseAPI {
    /**
     * 
     * @summary Добавить существующую редакцию в стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {BotEditionAddingRequest} [botEditionAddingRequest] Запрос на добавление существующей редакции.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public addBotEdition(id: string, botEditionAddingRequest?: BotEditionAddingRequest, options?: any) {
        return BotStageApiFp(this.configuration).addBotEdition(id, botEditionAddingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать черновую стадию бота.
     * @param {string} id ИД исходной стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public createDraftBotStage(id: string, options?: any) {
        return BotStageApiFp(this.configuration).createDraftBotStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioCreationRequest} [scenarioCreationRequest] Запрос на создание сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public createScenario(id: string, scenarioCreationRequest?: ScenarioCreationRequest, options?: any) {
        return BotStageApiFp(this.configuration).createScenario(id, scenarioCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public deleteBotStage(id: string, options?: any) {
        return BotStageApiFp(this.configuration).deleteBotStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария для удаления.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public deleteScenario(id: string, scenarioStructureId: string, options?: any) {
        return BotStageApiFp(this.configuration).deleteScenario(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Дублировать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioDuplicationRequest} [scenarioDuplicationRequest] Запрос на дублирование сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public duplicateScenario(id: string, scenarioDuplicationRequest?: ScenarioDuplicationRequest, options?: any) {
        return BotStageApiFp(this.configuration).duplicateScenario(id, scenarioDuplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Экспортировать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария.
     * @param {ScenarioContentFormat} contentFormat Формат содержимого файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public exportScenario(id: string, scenarioStructureId: string, contentFormat: ScenarioContentFormat, options?: any) {
        return BotStageApiFp(this.configuration).exportScenario(id, scenarioStructureId, contentFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить данные для тестирования стадии бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getBotStageTestingState(id: string, options?: any) {
        return BotStageApiFp(this.configuration).getBotStageTestingState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId ИД структуры сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getScenario(id: string, scenarioStructureId: string, options?: any) {
        return BotStageApiFp(this.configuration).getScenario(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить данные для тестирования сценария.
     * @param {string} id ИД стадии бота.
     * @param {string} scenarioStructureId Структура сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getScenarioTestingState(id: string, scenarioStructureId: string, options?: any) {
        return BotStageApiFp(this.configuration).getScenarioTestingState(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск сценариев, в которых используется указанный сценарий.
     * @param {string} id ИД стадии бота.
     * @param {string} [scenarioStructureId] ИД структуры сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public getScenarioUsages(id: string, scenarioStructureId?: string, options?: any) {
        return BotStageApiFp(this.configuration).getScenarioUsages(id, scenarioStructureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Импортировать бота.
     * @param {string} id ИД стадии бота.
     * @param {BotImportRequest} [botImportRequest] Запрос на импорт бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public importBot(id: string, botImportRequest?: BotImportRequest, options?: any) {
        return BotStageApiFp(this.configuration).importBot(id, botImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Объединить одну стадию бота с другой.
     * @param {string} id ИД исходной стадии бота.
     * @param {BotStageMergeRequest} [botStageMergeRequest] Запрос на объединение с исходной стадией бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public mergeBotStage(id: string, botStageMergeRequest?: BotStageMergeRequest, options?: any) {
        return BotStageApiFp(this.configuration).mergeBotStage(id, botStageMergeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Тестировать стадию бота.
     * @param {string} id ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public testBotStage(id: string, options?: any) {
        return BotStageApiFp(this.configuration).testBotStage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Тестировать сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioTestingRequest} [scenarioTestingRequest] Запрос на тестирование сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public testScenario(id: string, scenarioTestingRequest?: ScenarioTestingRequest, options?: any) {
        return BotStageApiFp(this.configuration).testScenario(id, scenarioTestingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить сценарий.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioUpdatingRequest} [scenarioUpdatingRequest] Запрос на сохранение сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public updateScenario(id: string, scenarioUpdatingRequest?: ScenarioUpdatingRequest, options?: any) {
        return BotStageApiFp(this.configuration).updateScenario(id, scenarioUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить настройки бота.
     * @param {string} id ИД стадии бота.
     * @param {SettingsUpdatingRequest} [settingsUpdatingRequest] Запрос на обновление настроек бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public updateSettings(id: string, settingsUpdatingRequest?: SettingsUpdatingRequest, options?: any) {
        return BotStageApiFp(this.configuration).updateSettings(id, settingsUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить валидацию сценария.
     * @param {string} id ИД стадии бота.
     * @param {ScenarioValidationRequest} [scenarioValidationRequest] Запрос на валидацию сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotStageApi
     */
    public validateScenario(id: string, scenarioValidationRequest?: ScenarioValidationRequest, options?: any) {
        return BotStageApiFp(this.configuration).validateScenario(id, scenarioValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BotTemplateApi - axios parameter creator
 * @export
 */
export const BotTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBotTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/templates/bot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotTemplateApi - functional programming interface
 * @export
 */
export const BotTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBotTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListBotTemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBotTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BotTemplateApi - factory interface
 * @export
 */
export const BotTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBotTemplates(options?: any): AxiosPromise<Array<ListBotTemplateModel>> {
            return localVarFp.getAllBotTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotTemplateApi - object-oriented interface
 * @export
 * @class BotTemplateApi
 * @extends {BaseAPI}
 */
export class BotTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Получить список шаблонов ботов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotTemplateApi
     */
    public getAllBotTemplates(options?: any) {
        return BotTemplateApiFp(this.configuration).getAllBotTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BotTemplatesApi - axios parameter creator
 * @export
 */
export const BotTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список готовых шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemplates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotTemplatesApi - functional programming interface
 * @export
 */
export const BotTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список готовых шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTemplates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotTemplateModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BotTemplatesApi - factory interface
 * @export
 */
export const BotTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список готовых шаблонов ботов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemplates(options?: any): AxiosPromise<Array<BotTemplateModel>> {
            return localVarFp.getBotTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotTemplatesApi - object-oriented interface
 * @export
 * @class BotTemplatesApi
 * @extends {BaseAPI}
 */
export class BotTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Получить список готовых шаблонов ботов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotTemplatesApi
     */
    public getBotTemplates(options?: any) {
        return BotTemplatesApiFp(this.configuration).getBotTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BotVersionApi - axios parameter creator
 * @export
 */
export const BotVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Добавление новой версии бота.
         * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBotVersion: async (botVersionCreationRequest?: BotVersionCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(botVersionCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить ссылку на редактирование последней версии бота.
         * @param {string} [agentId] ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotEditUrl: async (agentId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions/get-bot-edit-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файлы версии бота.
         * @param {string} [versionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotFiles: async (versionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions/get-bot-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (versionId !== undefined) {
                localVarQueryParameter['versionId'] = versionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить версию бота.
         * @param {string} id ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotVersion: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotVersion', 'id', id)
            const localVarPath = `/api/v1/botstorage/versions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск версий бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [hash] Хэш.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBotVersions: async (agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/botstorage/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['AgentId'] = agentId;
            }

            if (hash !== undefined) {
                localVarQueryParameter['Hash'] = hash;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BotVersionApi - functional programming interface
 * @export
 */
export const BotVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BotVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Добавление новой версии бота.
         * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBotVersion(botVersionCreationRequest?: BotVersionCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotVersionCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBotVersion(botVersionCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить ссылку на редактирование последней версии бота.
         * @param {string} [agentId] ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotEditUrl(agentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotEditUrl(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить файлы версии бота.
         * @param {string} [versionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotFiles(versionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotFiles(versionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить версию бота.
         * @param {string} id ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotVersion(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotVersionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotVersion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск версий бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [hash] Хэш.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBotVersions(agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BotVersionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBotVersions(agentId, hash, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BotVersionApi - factory interface
 * @export
 */
export const BotVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BotVersionApiFp(configuration)
    return {
        /**
         * 
         * @summary Добавление новой версии бота.
         * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBotVersion(botVersionCreationRequest?: BotVersionCreationRequest, options?: any): AxiosPromise<BotVersionCreationResponse> {
            return localVarFp.createBotVersion(botVersionCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить ссылку на редактирование последней версии бота.
         * @param {string} [agentId] ИД бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotEditUrl(agentId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.getBotEditUrl(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файлы версии бота.
         * @param {string} [versionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotFiles(versionId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBotFiles(versionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить версию бота.
         * @param {string} id ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotVersion(id: string, options?: any): AxiosPromise<BotVersionModel> {
            return localVarFp.getBotVersion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск версий бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [hash] Хэш.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBotVersions(agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<BotVersionModelPaginationResponse> {
            return localVarFp.searchBotVersions(agentId, hash, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BotVersionApi - object-oriented interface
 * @export
 * @class BotVersionApi
 * @extends {BaseAPI}
 */
export class BotVersionApi extends BaseAPI {
    /**
     * 
     * @summary Добавление новой версии бота.
     * @param {BotVersionCreationRequest} [botVersionCreationRequest] Запрос на добавление версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public createBotVersion(botVersionCreationRequest?: BotVersionCreationRequest, options?: any) {
        return BotVersionApiFp(this.configuration).createBotVersion(botVersionCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить ссылку на редактирование последней версии бота.
     * @param {string} [agentId] ИД бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public getBotEditUrl(agentId?: string, options?: any) {
        return BotVersionApiFp(this.configuration).getBotEditUrl(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файлы версии бота.
     * @param {string} [versionId] ИД версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public getBotFiles(versionId?: string, options?: any) {
        return BotVersionApiFp(this.configuration).getBotFiles(versionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить версию бота.
     * @param {string} id ИД версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public getBotVersion(id: string, options?: any) {
        return BotVersionApiFp(this.configuration).getBotVersion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск версий бота.
     * @param {string} [agentId] ИД агента.
     * @param {string} [hash] Хэш.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BotVersionApi
     */
    public searchBotVersions(agentId?: string, hash?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return BotVersionApiFp(this.configuration).searchBotVersions(agentId, hash, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать рассылку.
         * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (campaignCreationRequest?: CampaignCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/campaigns/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список рассылок.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/campaigns/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCampaign: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('runCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}/run`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить рассылку.
         * @param {string} id 
         * @param {CampaignModel} [campaignModel] Модель рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (id: string, campaignModel?: CampaignModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCampaign', 'id', id)
            const localVarPath = `/api/v1/campaigns/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать рассылку.
         * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(campaignCreationRequest?: CampaignCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(campaignCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить список рассылок.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaigns(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaigns(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runCampaign(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить рассылку.
         * @param {string} id 
         * @param {CampaignModel} [campaignModel] Модель рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(id: string, campaignModel?: CampaignModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaign(id, campaignModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать рассылку.
         * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(campaignCreationRequest?: CampaignCreationRequest, options?: any): AxiosPromise<CampaignCreationResponse> {
            return localVarFp.createCampaign(campaignCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить рассылку.
         * @param {string} id ИД рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(id: string, options?: any): AxiosPromise<CampaignModel> {
            return localVarFp.getCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список рассылок.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<CampaignModelPaginationResponse> {
            return localVarFp.listCampaigns(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runCampaign(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.runCampaign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить рассылку.
         * @param {string} id 
         * @param {CampaignModel} [campaignModel] Модель рассылки.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(id: string, campaignModel?: CampaignModel, options?: any): AxiosPromise<void> {
            return localVarFp.updateCampaign(id, campaignModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Создать рассылку.
     * @param {CampaignCreationRequest} [campaignCreationRequest] Запрос на создание рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public createCampaign(campaignCreationRequest?: CampaignCreationRequest, options?: any) {
        return CampaignApiFp(this.configuration).createCampaign(campaignCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить рассылку.
     * @param {string} id ИД рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public deleteCampaign(id: string, options?: any) {
        return CampaignApiFp(this.configuration).deleteCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить рассылку.
     * @param {string} id ИД рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(id: string, options?: any) {
        return CampaignApiFp(this.configuration).getCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список рассылок.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public listCampaigns(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return CampaignApiFp(this.configuration).listCampaigns(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public runCampaign(id: string, options?: any) {
        return CampaignApiFp(this.configuration).runCampaign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить рассылку.
     * @param {string} id 
     * @param {CampaignModel} [campaignModel] Модель рассылки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public updateCampaign(id: string, campaignModel?: CampaignModel, options?: any) {
        return CampaignApiFp(this.configuration).updateCampaign(id, campaignModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignJobApi - axios parameter creator
 * @export
 */
export const CampaignJobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJob: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignJob', 'id', id)
            const localVarPath = `/api/v1/campaigns/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobDescription: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignJobDescription', 'id', id)
            const localVarPath = `/api/v1/campaigns/jobs/{id}/description`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobState: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignJobState', 'id', id)
            const localVarPath = `/api/v1/campaigns/jobs/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список запусков рассылки.
         * @param {string} [campaignId] ИД рассылки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaignJobs: async (campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/campaigns/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['CampaignId'] = campaignId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignJobApi - functional programming interface
 * @export
 */
export const CampaignJobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignJobApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignJob(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignJob(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignJobDescription(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignJobDescription(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignJobState(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignJobState(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить список запусков рассылки.
         * @param {string} [campaignId] ИД рассылки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaignJobs(campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignJobModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaignJobs(campaignId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignJobApi - factory interface
 * @export
 */
export const CampaignJobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignJobApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJob(id: string, options?: any): AxiosPromise<CampaignJobModel> {
            return localVarFp.getCampaignJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobDescription(id: string, options?: any): AxiosPromise<CampaignJobDescription> {
            return localVarFp.getCampaignJobDescription(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignJobState(id: string, options?: any): AxiosPromise<CampaignJobState> {
            return localVarFp.getCampaignJobState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список запусков рассылки.
         * @param {string} [campaignId] ИД рассылки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaignJobs(campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<CampaignJobModelPaginationResponse> {
            return localVarFp.listCampaignJobs(campaignId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignJobApi - object-oriented interface
 * @export
 * @class CampaignJobApi
 * @extends {BaseAPI}
 */
export class CampaignJobApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public getCampaignJob(id: string, options?: any) {
        return CampaignJobApiFp(this.configuration).getCampaignJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public getCampaignJobDescription(id: string, options?: any) {
        return CampaignJobApiFp(this.configuration).getCampaignJobDescription(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public getCampaignJobState(id: string, options?: any) {
        return CampaignJobApiFp(this.configuration).getCampaignJobState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список запусков рассылки.
     * @param {string} [campaignId] ИД рассылки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignJobApi
     */
    public listCampaignJobs(campaignId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return CampaignJobApiFp(this.configuration).listCampaignJobs(campaignId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChannelApi - axios parameter creator
 * @export
 */
export const ChannelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet: async (channelId: string, botUri: string, signature: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet', 'channelId', channelId)
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet', 'botUri', botUri)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet', 'signature', signature)
            const localVarPath = `/api/v1/channels/{channelId}/bots/{botUri}/callback/{signature}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"signature"}}`, encodeURIComponent(String(signature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost: async (channelId: string, botUri: string, signature: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost', 'channelId', channelId)
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost', 'botUri', botUri)
            // verify required parameter 'signature' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost', 'signature', signature)
            const localVarPath = `/api/v1/channels/{channelId}/bots/{botUri}/callback/{signature}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)))
                .replace(`{${"signature"}}`, encodeURIComponent(String(signature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить перенаправление на канал.
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriRedirectGet: async (channelId: string, botUri: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriRedirectGet', 'channelId', channelId)
            // verify required parameter 'botUri' is not null or undefined
            assertParamExists('apiV1ChannelsChannelIdBotsBotUriRedirectGet', 'botUri', botUri)
            const localVarPath = `/api/v1/channels/{channelId}/bots/{botUri}/redirect`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"botUri"}}`, encodeURIComponent(String(botUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelApi - functional programming interface
 * @export
 */
export const ChannelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChannelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId: string, botUri: string, signature: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId, botUri, signature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId: string, botUri: string, signature: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId, botUri, signature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить перенаправление на канал.
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId: string, botUri: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId, botUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChannelApi - factory interface
 * @export
 */
export const ChannelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChannelApiFp(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId: string, botUri: string, signature: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId, botUri, signature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать обратный вызов от канала
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {string} signature Подпись.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId: string, botUri: string, signature: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId, botUri, signature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить перенаправление на канал.
         * @param {string} channelId ИД канала.
         * @param {string} botUri URI бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId: string, botUri: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId, botUri, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelApi - object-oriented interface
 * @export
 * @class ChannelApi
 * @extends {BaseAPI}
 */
export class ChannelApi extends BaseAPI {
    /**
     * 
     * @summary Обработать обратный вызов от канала
     * @param {string} channelId ИД канала.
     * @param {string} botUri URI бота.
     * @param {string} signature Подпись.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId: string, botUri: string, signature: string, options?: any) {
        return ChannelApiFp(this.configuration).apiV1ChannelsChannelIdBotsBotUriCallbackSignatureGet(channelId, botUri, signature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать обратный вызов от канала
     * @param {string} channelId ИД канала.
     * @param {string} botUri URI бота.
     * @param {string} signature Подпись.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId: string, botUri: string, signature: string, options?: any) {
        return ChannelApiFp(this.configuration).apiV1ChannelsChannelIdBotsBotUriCallbackSignaturePost(channelId, botUri, signature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить перенаправление на канал.
     * @param {string} channelId ИД канала.
     * @param {string} botUri URI бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelApi
     */
    public apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId: string, botUri: string, options?: any) {
        return ChannelApiFp(this.configuration).apiV1ChannelsChannelIdBotsBotUriRedirectGet(channelId, botUri, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommandApi - axios parameter creator
 * @export
 */
export const CommandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Пригласить сотрудника в канал бота.
         * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitePerson: async (invitationCommandModel?: InvitationCommandModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/commands/invitations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invitationCommandModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Вызвать проактивное действие.
         * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeAction: async (invocationCommandModel?: InvocationCommandModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/commands/invocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invocationCommandModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommandApi - functional programming interface
 * @export
 */
export const CommandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Пригласить сотрудника в канал бота.
         * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitePerson(invitationCommandModel?: InvitationCommandModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitePerson(invitationCommandModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Вызвать проактивное действие.
         * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invokeAction(invocationCommandModel?: InvocationCommandModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invokeAction(invocationCommandModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommandApi - factory interface
 * @export
 */
export const CommandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommandApiFp(configuration)
    return {
        /**
         * 
         * @summary Пригласить сотрудника в канал бота.
         * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitePerson(invitationCommandModel?: InvitationCommandModel, options?: any): AxiosPromise<void> {
            return localVarFp.invitePerson(invitationCommandModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Вызвать проактивное действие.
         * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invokeAction(invocationCommandModel?: InvocationCommandModel, options?: any): AxiosPromise<void> {
            return localVarFp.invokeAction(invocationCommandModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommandApi - object-oriented interface
 * @export
 * @class CommandApi
 * @extends {BaseAPI}
 */
export class CommandApi extends BaseAPI {
    /**
     * 
     * @summary Пригласить сотрудника в канал бота.
     * @param {InvitationCommandModel} [invitationCommandModel] Модель приглашения сотрудника в канал бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public invitePerson(invitationCommandModel?: InvitationCommandModel, options?: any) {
        return CommandApiFp(this.configuration).invitePerson(invitationCommandModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Вызвать проактивное действие.
     * @param {InvocationCommandModel} [invocationCommandModel] Модель вызова проактивного действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommandApi
     */
    public invokeAction(invocationCommandModel?: InvocationCommandModel, options?: any) {
        return CommandApiFp(this.configuration).invokeAction(invocationCommandModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return localVarFp.getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getClientConfig(options?: any) {
        return ConfigurationApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConversationApi - axios parameter creator
 * @export
 */
export const ConversationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить беседу.
         * @param {string} id ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversation', 'id', id)
            const localVarPath = `/api/v1/channels/conversations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить количество бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCount: async (channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels/conversations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (startFromDate !== undefined) {
                localVarQueryParameter['StartFromDate'] = (startFromDate as any instanceof Date) ?
                    (startFromDate as any).toISOString() :
                    startFromDate;
            }

            if (startToDate !== undefined) {
                localVarQueryParameter['StartToDate'] = (startToDate as any instanceof Date) ?
                    (startToDate as any).toISOString() :
                    startToDate;
            }

            if (lastMessageFromDate !== undefined) {
                localVarQueryParameter['LastMessageFromDate'] = (lastMessageFromDate as any instanceof Date) ?
                    (lastMessageFromDate as any).toISOString() :
                    lastMessageFromDate;
            }

            if (lastMessageToDate !== undefined) {
                localVarQueryParameter['LastMessageToDate'] = (lastMessageToDate as any instanceof Date) ?
                    (lastMessageToDate as any).toISOString() :
                    lastMessageToDate;
            }

            if (latestMessageText !== undefined) {
                localVarQueryParameter['LatestMessageText'] = latestMessageText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить количество уникальных пользователей бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsUniqueUserCount: async (channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels/conversations/unique-user-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (startFromDate !== undefined) {
                localVarQueryParameter['StartFromDate'] = (startFromDate as any instanceof Date) ?
                    (startFromDate as any).toISOString() :
                    startFromDate;
            }

            if (startToDate !== undefined) {
                localVarQueryParameter['StartToDate'] = (startToDate as any instanceof Date) ?
                    (startToDate as any).toISOString() :
                    startToDate;
            }

            if (lastMessageFromDate !== undefined) {
                localVarQueryParameter['LastMessageFromDate'] = (lastMessageFromDate as any instanceof Date) ?
                    (lastMessageFromDate as any).toISOString() :
                    lastMessageFromDate;
            }

            if (lastMessageToDate !== undefined) {
                localVarQueryParameter['LastMessageToDate'] = (lastMessageToDate as any instanceof Date) ?
                    (lastMessageToDate as any).toISOString() :
                    lastMessageToDate;
            }

            if (latestMessageText !== undefined) {
                localVarQueryParameter['LatestMessageText'] = latestMessageText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {ConversationSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConversations: async (channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (agentStageId !== undefined) {
                localVarQueryParameter['AgentStageId'] = agentStageId;
            }

            if (agentStageAccountId !== undefined) {
                localVarQueryParameter['AgentStageAccountId'] = agentStageAccountId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (startFromDate !== undefined) {
                localVarQueryParameter['StartFromDate'] = (startFromDate as any instanceof Date) ?
                    (startFromDate as any).toISOString() :
                    startFromDate;
            }

            if (startToDate !== undefined) {
                localVarQueryParameter['StartToDate'] = (startToDate as any instanceof Date) ?
                    (startToDate as any).toISOString() :
                    startToDate;
            }

            if (lastMessageFromDate !== undefined) {
                localVarQueryParameter['LastMessageFromDate'] = (lastMessageFromDate as any instanceof Date) ?
                    (lastMessageFromDate as any).toISOString() :
                    lastMessageFromDate;
            }

            if (lastMessageToDate !== undefined) {
                localVarQueryParameter['LastMessageToDate'] = (lastMessageToDate as any instanceof Date) ?
                    (lastMessageToDate as any).toISOString() :
                    lastMessageToDate;
            }

            if (latestMessageText !== undefined) {
                localVarQueryParameter['LatestMessageText'] = latestMessageText;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationApi - functional programming interface
 * @export
 */
export const ConversationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить беседу.
         * @param {string} id ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversation(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить количество бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить количество уникальных пользователей бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationsUniqueUserCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationsUniqueUserCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {ConversationSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConversations(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchConversations(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationApi - factory interface
 * @export
 */
export const ConversationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить беседу.
         * @param {string} id ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversation(id: string, options?: any): AxiosPromise<ConversationModel> {
            return localVarFp.getConversation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить количество бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getConversationCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить количество уникальных пользователей бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationsUniqueUserCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getConversationsUniqueUserCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск бесед.
         * @param {string} [channelId] ИД канала.
         * @param {string} [agentStageId] ИД стадии бота.
         * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
         * @param {string} [personId] ИД сотрудника.
         * @param {ConversationStatus} [status] Статус беседы.
         * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
         * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
         * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
         * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
         * @param {string} [latestMessageText] Текст последнего сообщения
         * @param {ConversationSortDirection} [sorting] Параметры сортировки.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConversations(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ConversationModelPaginationResponse> {
            return localVarFp.searchConversations(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationApi - object-oriented interface
 * @export
 * @class ConversationApi
 * @extends {BaseAPI}
 */
export class ConversationApi extends BaseAPI {
    /**
     * 
     * @summary Получить беседу.
     * @param {string} id ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversation(id: string, options?: any) {
        return ConversationApiFp(this.configuration).getConversation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить количество бесед.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {ConversationStatus} [status] Статус беседы.
     * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
     * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
     * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
     * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
     * @param {string} [latestMessageText] Текст последнего сообщения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversationCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any) {
        return ConversationApiFp(this.configuration).getConversationCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить количество уникальных пользователей бесед.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {ConversationStatus} [status] Статус беседы.
     * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
     * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
     * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
     * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
     * @param {string} [latestMessageText] Текст последнего сообщения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public getConversationsUniqueUserCount(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, options?: any) {
        return ConversationApiFp(this.configuration).getConversationsUniqueUserCount(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск бесед.
     * @param {string} [channelId] ИД канала.
     * @param {string} [agentStageId] ИД стадии бота.
     * @param {string} [agentStageAccountId] ИД аккаунта стадии бота.
     * @param {string} [personId] ИД сотрудника.
     * @param {ConversationStatus} [status] Статус беседы.
     * @param {string} [startFromDate] Дата начала беседы (начало промежутка)
     * @param {string} [startToDate] Дата начала беседы (окончание промежутка)
     * @param {string} [lastMessageFromDate] Дата последнего сообщения (начало промежутка)
     * @param {string} [lastMessageToDate] Дата последнего сообщения (окончание промежутка)
     * @param {string} [latestMessageText] Текст последнего сообщения
     * @param {ConversationSortDirection} [sorting] Параметры сортировки.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationApi
     */
    public searchConversations(channelId?: string, agentStageId?: string, agentStageAccountId?: string, personId?: string, status?: ConversationStatus, startFromDate?: string, startToDate?: string, lastMessageFromDate?: string, lastMessageToDate?: string, latestMessageText?: string, sorting?: ConversationSortDirection, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return ConversationApiFp(this.configuration).searchConversations(channelId, agentStageId, agentStageAccountId, personId, status, startFromDate, startToDate, lastMessageFromDate, lastMessageToDate, latestMessageText, sorting, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConversationExportApi - axios parameter creator
 * @export
 */
export const ConversationExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить файл экспорта истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExport: async (conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('conversationExport', 'conversationId', conversationId)
            const localVarPath = `/api/v1/dialogs/export/file/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Проверить статус экспорта истории диалога.
         * @param {string} requestId ИД запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExportStatus: async (requestId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('conversationExportStatus', 'requestId', requestId)
            const localVarPath = `/api/v1/dialogs/export/status/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить экспорт истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExport: async (conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('runConversationExport', 'conversationId', conversationId)
            const localVarPath = `/api/v1/dialogs/export/{conversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
         * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExportByFilter: async (conversationExportFilterParams?: ConversationExportFilterParams, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dialogs/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conversationExportFilterParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationExportApi - functional programming interface
 * @export
 */
export const ConversationExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConversationExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить файл экспорта истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationExport(conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationExport(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Проверить статус экспорта истории диалога.
         * @param {string} requestId ИД запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationExportStatus(requestId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationExportStatusResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationExportStatus(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запустить экспорт истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runConversationExport(conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunConversationExportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runConversationExport(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
         * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runConversationExportByFilter(conversationExportFilterParams?: ConversationExportFilterParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunConversationExportResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runConversationExportByFilter(conversationExportFilterParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConversationExportApi - factory interface
 * @export
 */
export const ConversationExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConversationExportApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить файл экспорта истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExport(conversationId: string, options?: any): AxiosPromise<any> {
            return localVarFp.conversationExport(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Проверить статус экспорта истории диалога.
         * @param {string} requestId ИД запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationExportStatus(requestId: string, options?: any): AxiosPromise<ConversationExportStatusResultModel> {
            return localVarFp.conversationExportStatus(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить экспорт истории диалога.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExport(conversationId: string, options?: any): AxiosPromise<RunConversationExportResultModel> {
            return localVarFp.runConversationExport(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
         * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runConversationExportByFilter(conversationExportFilterParams?: ConversationExportFilterParams, options?: any): AxiosPromise<RunConversationExportResultModel> {
            return localVarFp.runConversationExportByFilter(conversationExportFilterParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConversationExportApi - object-oriented interface
 * @export
 * @class ConversationExportApi
 * @extends {BaseAPI}
 */
export class ConversationExportApi extends BaseAPI {
    /**
     * 
     * @summary Получить файл экспорта истории диалога.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public conversationExport(conversationId: string, options?: any) {
        return ConversationExportApiFp(this.configuration).conversationExport(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Проверить статус экспорта истории диалога.
     * @param {string} requestId ИД запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public conversationExportStatus(requestId: string, options?: any) {
        return ConversationExportApiFp(this.configuration).conversationExportStatus(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить экспорт истории диалога.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public runConversationExport(conversationId: string, options?: any) {
        return ConversationExportApiFp(this.configuration).runConversationExport(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запустить экспорт истории диалогов, выбранных по фильтру.
     * @param {ConversationExportFilterParams} [conversationExportFilterParams] Параметры фильтрации.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationExportApi
     */
    public runConversationExportByFilter(conversationExportFilterParams?: ConversationExportFilterParams, options?: any) {
        return ConversationExportApiFp(this.configuration).runConversationExportByFilter(conversationExportFilterParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectLineApi - axios parameter creator
 * @export
 */
export const DirectLineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlConversation: async (agentStageId: string, userId?: string, userName?: string, eTag?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('createDlConversation', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('User.Id', userId as any);
            }
    
            if (userName !== undefined) { 
                localVarFormParams.append('User.Name', userName as any);
            }
    
            if (eTag !== undefined) { 
                localVarFormParams.append('ETag', eTag as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingDlConversation: async (agentStageId: string, userId?: string, userName?: string, eTag?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('createTestingDlConversation', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('User.Id', userId as any);
            }
    
            if (userName !== undefined) { 
                localVarFormParams.append('User.Name', userName as any);
            }
    
            if (eTag !== undefined) { 
                localVarFormParams.append('ETag', eTag as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlToken: async (agentStageId: string, tokenParameters?: TokenParameters, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('generateDlToken', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/tokens/generate`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestingDlToken: async (agentStageId: string, tokenParameters?: TokenParameters, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('generateTestingDlToken', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/tokens/generate`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlActivities: async (agentStageId: string, conversationId: string, watermark?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getDlActivities', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlActivities', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlConversation: async (agentStageId: string, conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getDlConversation', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlActivities: async (agentStageId: string, conversationId: string, watermark?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getTestingDlActivities', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getTestingDlActivities', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (watermark !== undefined) {
                localVarQueryParameter['Watermark'] = watermark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlConversation: async (agentStageId: string, conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getTestingDlConversation', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getTestingDlConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlToken: async (agentStageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('refreshDlToken', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/tokens/refresh`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTestingDlToken: async (agentStageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('refreshTestingDlToken', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/tokens/refresh`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveDlBotActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveDlBotActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/v3/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivityWithReply: async (agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveDlBotActivityWithReply', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveDlBotActivityWithReply', 'conversationId', conversationId)
            // verify required parameter 'replyToId' is not null or undefined
            assertParamExists('saveDlBotActivityWithReply', 'replyToId', replyToId)
            const localVarPath = `/api/v1/directline/{agentStageId}/v3/conversations/{conversationId}/activities/{replyToId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"replyToId"}}`, encodeURIComponent(String(replyToId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveTestingDlBotActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveTestingDlBotActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/v3/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivityWithReply: async (agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('saveTestingDlBotActivityWithReply', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('saveTestingDlBotActivityWithReply', 'conversationId', conversationId)
            // verify required parameter 'replyToId' is not null or undefined
            assertParamExists('saveTestingDlBotActivityWithReply', 'replyToId', replyToId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/v3/conversations/{conversationId}/activities/{replyToId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"replyToId"}}`, encodeURIComponent(String(replyToId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDlUserActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('sendDlUserActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendDlUserActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestingDlUserActivity: async (agentStageId: string, conversationId: string, activity?: Activity, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('sendTestingDlUserActivity', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('sendTestingDlUserActivity', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}/activities`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDlFile: async (agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('uploadDlFile', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('uploadDlFile', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/conversations/{conversationId}/upload`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (activity !== undefined) { 
                localVarFormParams.append('activity', activity as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTestingDlFile: async (agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('uploadTestingDlFile', 'agentStageId', agentStageId)
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('uploadTestingDlFile', 'conversationId', conversationId)
            const localVarPath = `/api/v1/directline/{agentStageId}/testing/conversations/{conversationId}/upload`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)))
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
            if (activity !== undefined) { 
                localVarFormParams.append('activity', activity as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineApi - functional programming interface
 * @export
 */
export const DirectLineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDlConversation(agentStageId, userId, userName, eTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingDlConversation(agentStageId, userId, userName, eTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDlToken(agentStageId: string, tokenParameters?: TokenParameters, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDlToken(agentStageId, tokenParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTestingDlToken(agentStageId: string, tokenParameters?: TokenParameters, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTestingDlToken(agentStageId, tokenParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlActivities(agentStageId, conversationId, watermark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlConversation(agentStageId: string, conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlConversation(agentStageId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingDlActivities(agentStageId, conversationId, watermark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingDlConversation(agentStageId: string, conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingDlConversation(agentStageId, conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshDlToken(agentStageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshDlToken(agentStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTestingDlToken(agentStageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTestingDlToken(agentStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDlBotActivity(agentStageId, conversationId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTestingDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTestingDlBotActivity(agentStageId, conversationId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTestingDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTestingDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendDlUserActivity(agentStageId, conversationId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTestingDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendTestingDlUserActivity(agentStageId, conversationId, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDlFile(agentStageId, conversationId, userId, file, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTestingDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTestingDlFile(agentStageId, conversationId, userId, file, activity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectLineApi - factory interface
 * @export
 */
export const DirectLineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создать беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} [userId] 
         * @param {string} [userName] 
         * @param {string} [eTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createTestingDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlToken(agentStageId: string, tokenParameters?: TokenParameters, options?: any): AxiosPromise<Conversation> {
            return localVarFp.generateDlToken(agentStageId, tokenParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTestingDlToken(agentStageId: string, tokenParameters?: TokenParameters, options?: any): AxiosPromise<Conversation> {
            return localVarFp.generateTestingDlToken(agentStageId, tokenParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlConversation(agentStageId: string, conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getDlConversation(agentStageId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [watermark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getTestingDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingDlConversation(agentStageId: string, conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getTestingDlConversation(agentStageId, conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlToken(agentStageId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshDlToken(agentStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTestingDlToken(agentStageId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshTestingDlToken(agentStageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveTestingDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сохранить действие в беседу (для ответов от бота).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} replyToId ИД действия, на который пришел ответ.
         * @param {Activity} [activity] Запрос на сохранение действия.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTestingDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.saveTestingDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.sendDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить действие в беседу (для запросов от пользователя).
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {Activity} [activity] Запрос на отправку действия в беседу.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTestingDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.sendTestingDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.uploadDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправить файл в беседу.
         * @param {string} agentStageId ИД стадии бота.
         * @param {string} conversationId ИД беседы.
         * @param {string} [userId] ИД пользователя.
         * @param {Array<any>} [file] 
         * @param {any} [activity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTestingDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options?: any): AxiosPromise<ResourceResponse> {
            return localVarFp.uploadTestingDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineApi - object-oriented interface
 * @export
 * @class DirectLineApi
 * @extends {BaseAPI}
 */
export class DirectLineApi extends BaseAPI {
    /**
     * 
     * @summary Создать беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} [userId] 
     * @param {string} [userName] 
     * @param {string} [eTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public createDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any) {
        return DirectLineApiFp(this.configuration).createDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создать беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} [userId] 
     * @param {string} [userName] 
     * @param {string} [eTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public createTestingDlConversation(agentStageId: string, userId?: string, userName?: string, eTag?: string, options?: any) {
        return DirectLineApiFp(this.configuration).createTestingDlConversation(agentStageId, userId, userName, eTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сгенерировать токен.
     * @param {string} agentStageId ИД стадии бота.
     * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public generateDlToken(agentStageId: string, tokenParameters?: TokenParameters, options?: any) {
        return DirectLineApiFp(this.configuration).generateDlToken(agentStageId, tokenParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сгенерировать токен.
     * @param {string} agentStageId ИД стадии бота.
     * @param {TokenParameters} [tokenParameters] Запрос на генерацию токена.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public generateTestingDlToken(agentStageId: string, tokenParameters?: TokenParameters, options?: any) {
        return DirectLineApiFp(this.configuration).generateTestingDlToken(agentStageId, tokenParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any) {
        return DirectLineApiFp(this.configuration).getDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getDlConversation(agentStageId: string, conversationId: string, options?: any) {
        return DirectLineApiFp(this.configuration).getDlConversation(agentStageId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [watermark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getTestingDlActivities(agentStageId: string, conversationId: string, watermark?: string, options?: any) {
        return DirectLineApiFp(this.configuration).getTestingDlActivities(agentStageId, conversationId, watermark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public getTestingDlConversation(agentStageId: string, conversationId: string, options?: any) {
        return DirectLineApiFp(this.configuration).getTestingDlConversation(agentStageId, conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} agentStageId ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public refreshDlToken(agentStageId: string, options?: any) {
        return DirectLineApiFp(this.configuration).refreshDlToken(agentStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} agentStageId ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public refreshTestingDlToken(agentStageId: string, options?: any) {
        return DirectLineApiFp(this.configuration).refreshTestingDlToken(agentStageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any) {
        return DirectLineApiFp(this.configuration).saveDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} replyToId ИД действия, на который пришел ответ.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any) {
        return DirectLineApiFp(this.configuration).saveDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveTestingDlBotActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any) {
        return DirectLineApiFp(this.configuration).saveTestingDlBotActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сохранить действие в беседу (для ответов от бота).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} replyToId ИД действия, на который пришел ответ.
     * @param {Activity} [activity] Запрос на сохранение действия.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public saveTestingDlBotActivityWithReply(agentStageId: string, conversationId: string, replyToId: string, activity?: Activity, options?: any) {
        return DirectLineApiFp(this.configuration).saveTestingDlBotActivityWithReply(agentStageId, conversationId, replyToId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в беседу (для запросов от пользователя).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на отправку действия в беседу.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public sendDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any) {
        return DirectLineApiFp(this.configuration).sendDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить действие в беседу (для запросов от пользователя).
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {Activity} [activity] Запрос на отправку действия в беседу.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public sendTestingDlUserActivity(agentStageId: string, conversationId: string, activity?: Activity, options?: any) {
        return DirectLineApiFp(this.configuration).sendTestingDlUserActivity(agentStageId, conversationId, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить файл в беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [userId] ИД пользователя.
     * @param {Array<any>} [file] 
     * @param {any} [activity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public uploadDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options?: any) {
        return DirectLineApiFp(this.configuration).uploadDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправить файл в беседу.
     * @param {string} agentStageId ИД стадии бота.
     * @param {string} conversationId ИД беседы.
     * @param {string} [userId] ИД пользователя.
     * @param {Array<any>} [file] 
     * @param {any} [activity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineApi
     */
    public uploadTestingDlFile(agentStageId: string, conversationId: string, userId?: string, file?: Array<any>, activity?: any, options?: any) {
        return DirectLineApiFp(this.configuration).uploadTestingDlFile(agentStageId, conversationId, userId, file, activity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectLineTranscriptApi - axios parameter creator
 * @export
 */
export const DirectLineTranscriptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlExportConversation: async (conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('createDlExportConversation', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/conversations`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlExportToken: async (conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('generateDlExportToken', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/tokens/generate`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportActivities: async (conversationId: string, unusedConversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlExportActivities', 'conversationId', conversationId)
            // verify required parameter 'unusedConversationId' is not null or undefined
            assertParamExists('getDlExportActivities', 'unusedConversationId', unusedConversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/conversations/{unusedConversationId}/activities`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"unusedConversationId"}}`, encodeURIComponent(String(unusedConversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportConversation: async (conversationId: string, unusedConversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlExportConversation', 'conversationId', conversationId)
            // verify required parameter 'unusedConversationId' is not null or undefined
            assertParamExists('getDlExportConversation', 'unusedConversationId', unusedConversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/conversations/{unusedConversationId}`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)))
                .replace(`{${"unusedConversationId"}}`, encodeURIComponent(String(unusedConversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить ИД пользователя беседы.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportUserId: async (conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('getDlExportUserId', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/user-id`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlExportToken: async (conversationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversationId' is not null or undefined
            assertParamExists('refreshDlExportToken', 'conversationId', conversationId)
            const localVarPath = `/api/v1/runtime/dl-export/{conversationId}/tokens/refresh`
                .replace(`{${"conversationId"}}`, encodeURIComponent(String(conversationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectLineTranscriptApi - functional programming interface
 * @export
 */
export const DirectLineTranscriptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectLineTranscriptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDlExportConversation(conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDlExportConversation(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateDlExportToken(conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateDlExportToken(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlExportActivities(conversationId: string, unusedConversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlExportActivities(conversationId, unusedConversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlExportConversation(conversationId: string, unusedConversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlExportConversation(conversationId, unusedConversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить ИД пользователя беседы.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDlExportUserId(conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDlExportUserId(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshDlExportToken(conversationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Conversation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshDlExportToken(conversationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectLineTranscriptApi - factory interface
 * @export
 */
export const DirectLineTranscriptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectLineTranscriptApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать беседу.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDlExportConversation(conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.createDlExportConversation(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Сгенерировать токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDlExportToken(conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.generateDlExportToken(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить действия беседы.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportActivities(conversationId: string, unusedConversationId: string, options?: any): AxiosPromise<ActivitySet> {
            return localVarFp.getDlExportActivities(conversationId, unusedConversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сведения о беседе.
         * @param {string} conversationId ИД беседы.
         * @param {string} unusedConversationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportConversation(conversationId: string, unusedConversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.getDlExportConversation(conversationId, unusedConversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить ИД пользователя беседы.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDlExportUserId(conversationId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDlExportUserId(conversationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить токен.
         * @param {string} conversationId ИД беседы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshDlExportToken(conversationId: string, options?: any): AxiosPromise<Conversation> {
            return localVarFp.refreshDlExportToken(conversationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectLineTranscriptApi - object-oriented interface
 * @export
 * @class DirectLineTranscriptApi
 * @extends {BaseAPI}
 */
export class DirectLineTranscriptApi extends BaseAPI {
    /**
     * 
     * @summary Создать беседу.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public createDlExportConversation(conversationId: string, options?: any) {
        return DirectLineTranscriptApiFp(this.configuration).createDlExportConversation(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Сгенерировать токен.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public generateDlExportToken(conversationId: string, options?: any) {
        return DirectLineTranscriptApiFp(this.configuration).generateDlExportToken(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить действия беседы.
     * @param {string} conversationId ИД беседы.
     * @param {string} unusedConversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public getDlExportActivities(conversationId: string, unusedConversationId: string, options?: any) {
        return DirectLineTranscriptApiFp(this.configuration).getDlExportActivities(conversationId, unusedConversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сведения о беседе.
     * @param {string} conversationId ИД беседы.
     * @param {string} unusedConversationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public getDlExportConversation(conversationId: string, unusedConversationId: string, options?: any) {
        return DirectLineTranscriptApiFp(this.configuration).getDlExportConversation(conversationId, unusedConversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить ИД пользователя беседы.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public getDlExportUserId(conversationId: string, options?: any) {
        return DirectLineTranscriptApiFp(this.configuration).getDlExportUserId(conversationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить токен.
     * @param {string} conversationId ИД беседы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectLineTranscriptApi
     */
    public refreshDlExportToken(conversationId: string, options?: any) {
        return DirectLineTranscriptApiFp(this.configuration).refreshDlExportToken(conversationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Elma365AppApi - axios parameter creator
 * @export
 */
export const Elma365AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить приложение ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} appNamespace Код раздела.
         * @param {string} appCode Код.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365App: async (baseUrl: string, xToken: string, appNamespace: string, appCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365App', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365App', 'xToken', xToken)
            // verify required parameter 'appNamespace' is not null or undefined
            assertParamExists('getElma365App', 'appNamespace', appNamespace)
            // verify required parameter 'appCode' is not null or undefined
            assertParamExists('getElma365App', 'appCode', appCode)
            const localVarPath = `/api/v1/clients/elma365/app/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }

            if (appNamespace !== undefined) {
                localVarQueryParameter['AppNamespace'] = appNamespace;
            }

            if (appCode !== undefined) {
                localVarQueryParameter['AppCode'] = appCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск приложений ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} [searchText] Строка поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchElma365Apps: async (baseUrl: string, xToken: string, searchText?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('searchElma365Apps', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('searchElma365Apps', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/app/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Elma365AppApi - functional programming interface
 * @export
 */
export const Elma365AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Elma365AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить приложение ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} appNamespace Код раздела.
         * @param {string} appCode Код.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365App(baseUrl: string, xToken: string, appNamespace: string, appCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Elma365App>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365App(baseUrl, xToken, appNamespace, appCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск приложений ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} [searchText] Строка поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchElma365Apps(baseUrl: string, xToken: string, searchText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Elma365App>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchElma365Apps(baseUrl, xToken, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Elma365AppApi - factory interface
 * @export
 */
export const Elma365AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Elma365AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить приложение ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} appNamespace Код раздела.
         * @param {string} appCode Код.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365App(baseUrl: string, xToken: string, appNamespace: string, appCode: string, options?: any): AxiosPromise<Elma365App> {
            return localVarFp.getElma365App(baseUrl, xToken, appNamespace, appCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск приложений ELMA365.
         * @param {string} baseUrl Адрес компании в ELMA365.
         * @param {string} xToken X-Token пользователя.
         * @param {string} [searchText] Строка поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchElma365Apps(baseUrl: string, xToken: string, searchText?: string, options?: any): AxiosPromise<Array<Elma365App>> {
            return localVarFp.searchElma365Apps(baseUrl, xToken, searchText, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Elma365AppApi - object-oriented interface
 * @export
 * @class Elma365AppApi
 * @extends {BaseAPI}
 */
export class Elma365AppApi extends BaseAPI {
    /**
     * 
     * @summary Получить приложение ELMA365.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {string} appNamespace Код раздела.
     * @param {string} appCode Код.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365AppApi
     */
    public getElma365App(baseUrl: string, xToken: string, appNamespace: string, appCode: string, options?: any) {
        return Elma365AppApiFp(this.configuration).getElma365App(baseUrl, xToken, appNamespace, appCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск приложений ELMA365.
     * @param {string} baseUrl Адрес компании в ELMA365.
     * @param {string} xToken X-Token пользователя.
     * @param {string} [searchText] Строка поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365AppApi
     */
    public searchElma365Apps(baseUrl: string, xToken: string, searchText?: string, options?: any) {
        return Elma365AppApiFp(this.configuration).searchElma365Apps(baseUrl, xToken, searchText, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Elma365BpmTemplateApi - axios parameter creator
 * @export
 */
export const Elma365BpmTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список шаблонов бизнес-процессов ELMA365.
         * @param {string} baseUrl Адрес компании в elma365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateList: async (baseUrl: string, xToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUrl' is not null or undefined
            assertParamExists('getElma365BpmTemplateList', 'baseUrl', baseUrl)
            // verify required parameter 'xToken' is not null or undefined
            assertParamExists('getElma365BpmTemplateList', 'xToken', xToken)
            const localVarPath = `/api/v1/clients/elma365/bpm/template/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (baseUrl !== undefined) {
                localVarQueryParameter['BaseUrl'] = baseUrl;
            }

            if (xToken !== undefined) {
                localVarQueryParameter['XToken'] = xToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Elma365BpmTemplateApi - functional programming interface
 * @export
 */
export const Elma365BpmTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Elma365BpmTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов бизнес-процессов ELMA365.
         * @param {string} baseUrl Адрес компании в elma365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getElma365BpmTemplateList(baseUrl: string, xToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Elma365BpmTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getElma365BpmTemplateList(baseUrl, xToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Elma365BpmTemplateApi - factory interface
 * @export
 */
export const Elma365BpmTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Elma365BpmTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список шаблонов бизнес-процессов ELMA365.
         * @param {string} baseUrl Адрес компании в elma365.
         * @param {string} xToken X-Token пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElma365BpmTemplateList(baseUrl: string, xToken: string, options?: any): AxiosPromise<Array<Elma365BpmTemplate>> {
            return localVarFp.getElma365BpmTemplateList(baseUrl, xToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Elma365BpmTemplateApi - object-oriented interface
 * @export
 * @class Elma365BpmTemplateApi
 * @extends {BaseAPI}
 */
export class Elma365BpmTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Получить список шаблонов бизнес-процессов ELMA365.
     * @param {string} baseUrl Адрес компании в elma365.
     * @param {string} xToken X-Token пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Elma365BpmTemplateApi
     */
    public getElma365BpmTemplateList(baseUrl: string, xToken: string, options?: any) {
        return Elma365BpmTemplateApiFp(this.configuration).getElma365BpmTemplateList(baseUrl, xToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEvent', 'id', id)
            const localVarPath = `/api/v1/general/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents: async (type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: string, options?: any): AxiosPromise<EventModel> {
            return localVarFp.getEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<EventModelPaginationResponse> {
            return localVarFp.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @summary Получить событие.
     * @param {string} id ИД события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvent(id: string, options?: any) {
        return EventApiFp(this.configuration).getEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск событий.
     * @param {string} [type] Тип события.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return EventApiFp(this.configuration).searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalEventTriggerApi - axios parameter creator
 * @export
 */
export const ExternalEventTriggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Запустить внешнее событие.
         * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchExternalEvent: async (dispatchExternalEventRequest?: DispatchExternalEventRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/external/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dispatchExternalEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список доступных триггеров внешнего события.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotExternalEventTriggers: async (agentStageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentStageId' is not null or undefined
            assertParamExists('getBotExternalEventTriggers', 'agentStageId', agentStageId)
            const localVarPath = `/api/v1/runtime/simple/external/events/{agentStageId}`
                .replace(`{${"agentStageId"}}`, encodeURIComponent(String(agentStageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalEventTriggerApi - functional programming interface
 * @export
 */
export const ExternalEventTriggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalEventTriggerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Запустить внешнее событие.
         * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dispatchExternalEvent(dispatchExternalEventRequest?: DispatchExternalEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dispatchExternalEvent(dispatchExternalEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список доступных триггеров внешнего события.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotExternalEventTriggers(agentStageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BotExternalEventTrigger>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotExternalEventTriggers(agentStageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalEventTriggerApi - factory interface
 * @export
 */
export const ExternalEventTriggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalEventTriggerApiFp(configuration)
    return {
        /**
         * 
         * @summary Запустить внешнее событие.
         * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dispatchExternalEvent(dispatchExternalEventRequest?: DispatchExternalEventRequest, options?: any): AxiosPromise<object> {
            return localVarFp.dispatchExternalEvent(dispatchExternalEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список доступных триггеров внешнего события.
         * @param {string} agentStageId ИД стадии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotExternalEventTriggers(agentStageId: string, options?: any): AxiosPromise<Array<BotExternalEventTrigger>> {
            return localVarFp.getBotExternalEventTriggers(agentStageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalEventTriggerApi - object-oriented interface
 * @export
 * @class ExternalEventTriggerApi
 * @extends {BaseAPI}
 */
export class ExternalEventTriggerApi extends BaseAPI {
    /**
     * 
     * @summary Запустить внешнее событие.
     * @param {DispatchExternalEventRequest} [dispatchExternalEventRequest] Параметры запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalEventTriggerApi
     */
    public dispatchExternalEvent(dispatchExternalEventRequest?: DispatchExternalEventRequest, options?: any) {
        return ExternalEventTriggerApiFp(this.configuration).dispatchExternalEvent(dispatchExternalEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список доступных триггеров внешнего события.
     * @param {string} agentStageId ИД стадии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalEventTriggerApi
     */
    public getBotExternalEventTriggers(agentStageId: string, options?: any) {
        return ExternalEventTriggerApiFp(this.configuration).getBotExternalEventTriggers(agentStageId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeatureManagementApi - axios parameter creator
 * @export
 */
export const FeatureManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить список доступного функционала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/feature-management`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureManagementApi - functional programming interface
 * @export
 */
export const FeatureManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить список доступного функционала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureFlags(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureFlags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeatureManagementApi - factory interface
 * @export
 */
export const FeatureManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить список доступного функционала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureFlags(options?: any): AxiosPromise<Array<FeatureFlag>> {
            return localVarFp.getFeatureFlags(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureManagementApi - object-oriented interface
 * @export
 * @class FeatureManagementApi
 * @extends {BaseAPI}
 */
export class FeatureManagementApi extends BaseAPI {
    /**
     * 
     * @summary Получить список доступного функционала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureManagementApi
     */
    public getFeatureFlags(options?: any) {
        return FeatureManagementApiFp(this.configuration).getFeatureFlags(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileStorageApi - axios parameter creator
 * @export
 */
export const FileStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFile: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getAnalyticsReportFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnalyticsReportFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/analytics-report/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFileHeaders: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getAnalyticsReportFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnalyticsReportFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/analytics-report/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFile: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotStaticFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotStaticFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/static/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFileHeaders: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotStaticFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotStaticFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/static/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFile: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotTemporaryFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotTemporaryFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/temp/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFileHeaders: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getBotTemporaryFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBotTemporaryFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/temp/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFile: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getConversationExportFile', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversationExportFile', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/conversation-export/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFileHeaders: async (tenantId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getConversationExportFileHeaders', 'tenantId', tenantId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConversationExportFileHeaders', 'id', id)
            const localVarPath = `/api/v1/clients/file-storage/conversation-export/{tenantId}/{id}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить статический файл.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotStaticFile: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/file-storage/static`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить временный файл.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotTemporaryFile: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/file-storage/temp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStorageApi - functional programming interface
 * @export
 */
export const FileStorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileStorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsReportFile(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsReportFile(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsReportFileHeaders(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsReportFileHeaders(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotStaticFile(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotStaticFile(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotStaticFileHeaders(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotStaticFileHeaders(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTemporaryFile(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTemporaryFile(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBotTemporaryFileHeaders(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBotTemporaryFileHeaders(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationExportFile(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationExportFile(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConversationExportFileHeaders(tenantId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConversationExportFileHeaders(tenantId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить статический файл.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBotStaticFile(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBotStaticFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить временный файл.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBotTemporaryFile(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBotTemporaryFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileStorageApi - factory interface
 * @export
 */
export const FileStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileStorageApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFile(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAnalyticsReportFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл отчета аналитики.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsReportFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getAnalyticsReportFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFile(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBotStaticFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить статический файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotStaticFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBotStaticFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFile(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBotTemporaryFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить временный файл.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBotTemporaryFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getBotTemporaryFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFile(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getConversationExportFile(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить файл экспорта диалога.
         * @param {string} tenantId ИД тенанта.
         * @param {string} id ИД файла.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConversationExportFileHeaders(tenantId: string, id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getConversationExportFileHeaders(tenantId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить статический файл.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotStaticFile(file?: any, options?: any): AxiosPromise<FileUploadingResponse> {
            return localVarFp.uploadBotStaticFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить временный файл.
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBotTemporaryFile(file?: any, options?: any): AxiosPromise<FileUploadingResponse> {
            return localVarFp.uploadBotTemporaryFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileStorageApi - object-oriented interface
 * @export
 * @class FileStorageApi
 * @extends {BaseAPI}
 */
export class FileStorageApi extends BaseAPI {
    /**
     * 
     * @summary Получить файл отчета аналитики.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getAnalyticsReportFile(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getAnalyticsReportFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл отчета аналитики.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getAnalyticsReportFileHeaders(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getAnalyticsReportFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить статический файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotStaticFile(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getBotStaticFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить статический файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotStaticFileHeaders(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getBotStaticFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить временный файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotTemporaryFile(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getBotTemporaryFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить временный файл.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getBotTemporaryFileHeaders(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getBotTemporaryFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл экспорта диалога.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getConversationExportFile(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getConversationExportFile(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить файл экспорта диалога.
     * @param {string} tenantId ИД тенанта.
     * @param {string} id ИД файла.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public getConversationExportFileHeaders(tenantId: string, id: string, options?: any) {
        return FileStorageApiFp(this.configuration).getConversationExportFileHeaders(tenantId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить статический файл.
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public uploadBotStaticFile(file?: any, options?: any) {
        return FileStorageApiFp(this.configuration).uploadBotStaticFile(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить временный файл.
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public uploadBotTemporaryFile(file?: any, options?: any) {
        return FileStorageApiFp(this.configuration).uploadBotTemporaryFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать группу сотрудников.
         * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (groupCreationRequest?: GroupCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список групп сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск групп сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGroups: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/groups/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (id: string, groupUpdatingRequest?: GroupUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать группу сотрудников.
         * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(groupCreationRequest?: GroupCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(groupCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить список групп сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск групп сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(id: string, groupUpdatingRequest?: GroupUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(id, groupUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать группу сотрудников.
         * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(groupCreationRequest?: GroupCreationRequest, options?: any): AxiosPromise<GroupCreationResponse> {
            return localVarFp.createGroup(groupCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(id: string, options?: any): AxiosPromise<GroupModel> {
            return localVarFp.getGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список групп сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<GroupModelPaginationResponse> {
            return localVarFp.listGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск групп сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any): AxiosPromise<GroupModelPaginationResponse> {
            return localVarFp.searchGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить группу сотрудников.
         * @param {string} id ИД группы.
         * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(id: string, groupUpdatingRequest?: GroupUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroup(id, groupUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @summary Создать группу сотрудников.
     * @param {GroupCreationRequest} [groupCreationRequest] Запрос на создание группы сотрудников.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public createGroup(groupCreationRequest?: GroupCreationRequest, options?: any) {
        return GroupApiFp(this.configuration).createGroup(groupCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить группу сотрудников.
     * @param {string} id ИД группы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public deleteGroup(id: string, options?: any) {
        return GroupApiFp(this.configuration).deleteGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить группу сотрудников.
     * @param {string} id ИД группы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public getGroup(id: string, options?: any) {
        return GroupApiFp(this.configuration).getGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список групп сотрудников
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public listGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return GroupApiFp(this.configuration).listGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск групп сотрудников.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {string} [search] Параметр поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public searchGroups(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any) {
        return GroupApiFp(this.configuration).searchGroups(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить группу сотрудников.
     * @param {string} id ИД группы.
     * @param {GroupUpdatingRequest} [groupUpdatingRequest] Модель группы.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public updateGroup(id: string, groupUpdatingRequest?: GroupUpdatingRequest, options?: any) {
        return GroupApiFp(this.configuration).updateGroup(id, groupUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupEditionApi - axios parameter creator
 * @export
 */
export const GroupEditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить редакцию группы интентов.
         * @param {string} id ИД редакции группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEdition: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentGroupEdition', 'id', id)
            const localVarPath = `/api/v1/intents/group-editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск редакций групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEditions: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/group-editions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupEditionApi - functional programming interface
 * @export
 */
export const GroupEditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupEditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию группы интентов.
         * @param {string} id ИД редакции группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentGroupEdition(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentGroupEdition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск редакций групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentGroupEditions(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEditionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentGroupEditions(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupEditionApi - factory interface
 * @export
 */
export const GroupEditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupEditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию группы интентов.
         * @param {string} id ИД редакции группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEdition(id: string, options?: any): AxiosPromise<GroupEditionModel> {
            return localVarFp.getIntentGroupEdition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск редакций групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEditions(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<GroupEditionModelPaginationResponse> {
            return localVarFp.searchIntentGroupEditions(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupEditionApi - object-oriented interface
 * @export
 * @class GroupEditionApi
 * @extends {BaseAPI}
 */
export class GroupEditionApi extends BaseAPI {
    /**
     * 
     * @summary Получить редакцию группы интентов.
     * @param {string} id ИД редакции группы интентов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEditionApi
     */
    public getIntentGroupEdition(id: string, options?: any) {
        return GroupEditionApiFp(this.configuration).getIntentGroupEdition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск редакций групп интентов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEditionApi
     */
    public searchIntentGroupEditions(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return GroupEditionApiFp(this.configuration).searchIntentGroupEditions(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupEntryApi - axios parameter creator
 * @export
 */
export const GroupEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить запись группы интентов.
         * @param {string} id ИД записи группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEntry: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentGroupEntry', 'id', id)
            const localVarPath = `/api/v1/intents/group-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск записей групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEntries: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/group-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupEntryApi - functional programming interface
 * @export
 */
export const GroupEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить запись группы интентов.
         * @param {string} id ИД записи группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentGroupEntry(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentGroupEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск записей групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentGroupEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupEntryModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentGroupEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupEntryApi - factory interface
 * @export
 */
export const GroupEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupEntryApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить запись группы интентов.
         * @param {string} id ИД записи группы интентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentGroupEntry(id: string, options?: any): AxiosPromise<GroupEntryModel> {
            return localVarFp.getIntentGroupEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск записей групп интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentGroupEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<GroupEntryModelPaginationResponse> {
            return localVarFp.searchIntentGroupEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupEntryApi - object-oriented interface
 * @export
 * @class GroupEntryApi
 * @extends {BaseAPI}
 */
export class GroupEntryApi extends BaseAPI {
    /**
     * 
     * @summary Получить запись группы интентов.
     * @param {string} id ИД записи группы интентов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEntryApi
     */
    public getIntentGroupEntry(id: string, options?: any) {
        return GroupEntryApiFp(this.configuration).getIntentGroupEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск записей групп интентов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupEntryApi
     */
    public searchIntentGroupEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return GroupEntryApiFp(this.configuration).searchIntentGroupEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentityApi - axios parameter creator
 * @export
 */
export const IdentityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackGet: async (code?: string, state?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/security/identity/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackPost: async (code?: string, state?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/security/identity/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyGet: async (securityKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityKey' is not null or undefined
            assertParamExists('apiV1SecurityIdentityRedirectSecurityKeyGet', 'securityKey', securityKey)
            const localVarPath = `/api/v1/security/identity/redirect/{securityKey}`
                .replace(`{${"securityKey"}}`, encodeURIComponent(String(securityKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyPost: async (securityKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityKey' is not null or undefined
            assertParamExists('apiV1SecurityIdentityRedirectSecurityKeyPost', 'securityKey', securityKey)
            const localVarPath = `/api/v1/security/identity/redirect/{securityKey}`
                .replace(`{${"securityKey"}}`, encodeURIComponent(String(securityKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityApi - functional programming interface
 * @export
 */
export const IdentityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityCallbackGet(code?: string, state?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityCallbackGet(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityCallbackPost(code?: string, state?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityCallbackPost(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentityApi - factory interface
 * @export
 */
export const IdentityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentityApiFp(configuration)
    return {
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackGet(code?: string, state?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityCallbackGet(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать обратный вызов от поставщика удостоверений.
         * @param {string} [code] Код авторизации.
         * @param {string} [state] Состояние запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityCallbackPost(code?: string, state?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityCallbackPost(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обработать перенаправление на поставщика удостоверений.
         * @param {string} securityKey Ключ безопасности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentityApi - object-oriented interface
 * @export
 * @class IdentityApi
 * @extends {BaseAPI}
 */
export class IdentityApi extends BaseAPI {
    /**
     * 
     * @summary Обработать обратный вызов от поставщика удостоверений.
     * @param {string} [code] Код авторизации.
     * @param {string} [state] Состояние запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityCallbackGet(code?: string, state?: string, options?: any) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityCallbackGet(code, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать обратный вызов от поставщика удостоверений.
     * @param {string} [code] Код авторизации.
     * @param {string} [state] Состояние запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityCallbackPost(code?: string, state?: string, options?: any) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityCallbackPost(code, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать перенаправление на поставщика удостоверений.
     * @param {string} securityKey Ключ безопасности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey: string, options?: any) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityRedirectSecurityKeyGet(securityKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обработать перенаправление на поставщика удостоверений.
     * @param {string} securityKey Ключ безопасности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityApi
     */
    public apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey: string, options?: any) {
        return IdentityApiFp(this.configuration).apiV1SecurityIdentityRedirectSecurityKeyPost(securityKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntentApi - axios parameter creator
 * @export
 */
export const IntentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить интент.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntent', 'id', id)
            const localVarPath = `/api/v1/intents/intents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск интентов.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents: async (utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (utterance !== undefined) {
                localVarQueryParameter['Utterance'] = utterance;
            }

            if (groupCode !== undefined) {
                localVarQueryParameter['GroupCode'] = groupCode;
            }

            if (intentCode !== undefined) {
                localVarQueryParameter['IntentCode'] = intentCode;
            }

            if (semVer !== undefined) {
                localVarQueryParameter['SemVer'] = semVer;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentApi - functional programming interface
 * @export
 */
export const IntentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить интент.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntent(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleIntentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск интентов.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntents(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListIntentModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntents(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntentApi - factory interface
 * @export
 */
export const IntentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntentApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить интент.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntent(id: string, options?: any): AxiosPromise<SingleIntentModel> {
            return localVarFp.getIntent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск интентов.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntents(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListIntentModelPaginationResponse> {
            return localVarFp.searchIntents(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntentApi - object-oriented interface
 * @export
 * @class IntentApi
 * @extends {BaseAPI}
 */
export class IntentApi extends BaseAPI {
    /**
     * 
     * @summary Получить интент.
     * @param {string} id ИД записи интента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentApi
     */
    public getIntent(id: string, options?: any) {
        return IntentApiFp(this.configuration).getIntent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск интентов.
     * @param {string} [utterance] 
     * @param {string} [groupCode] 
     * @param {string} [intentCode] 
     * @param {string} [semVer] 
     * @param {IntentEditionStatus} [status] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentApi
     */
    public searchIntents(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return IntentApiFp(this.configuration).searchIntents(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntentEditionApi - axios parameter creator
 * @export
 */
export const IntentEditionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить редакцию интента.
         * @param {string} id ИД редакции интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEdition: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentEdition', 'id', id)
            const localVarPath = `/api/v1/intents/intent-editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск редакций интента.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEditions: async (utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/intent-editions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (utterance !== undefined) {
                localVarQueryParameter['Utterance'] = utterance;
            }

            if (groupCode !== undefined) {
                localVarQueryParameter['GroupCode'] = groupCode;
            }

            if (intentCode !== undefined) {
                localVarQueryParameter['IntentCode'] = intentCode;
            }

            if (semVer !== undefined) {
                localVarQueryParameter['SemVer'] = semVer;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentEditionApi - functional programming interface
 * @export
 */
export const IntentEditionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntentEditionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию интента.
         * @param {string} id ИД редакции интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentEdition(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEditionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentEdition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск редакций интента.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentEditions(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEditionModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentEditions(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntentEditionApi - factory interface
 * @export
 */
export const IntentEditionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntentEditionApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить редакцию интента.
         * @param {string} id ИД редакции интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEdition(id: string, options?: any): AxiosPromise<IntentEditionModel> {
            return localVarFp.getIntentEdition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск редакций интента.
         * @param {string} [utterance] 
         * @param {string} [groupCode] 
         * @param {string} [intentCode] 
         * @param {string} [semVer] 
         * @param {IntentEditionStatus} [status] 
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEditions(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<IntentEditionModelPaginationResponse> {
            return localVarFp.searchIntentEditions(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntentEditionApi - object-oriented interface
 * @export
 * @class IntentEditionApi
 * @extends {BaseAPI}
 */
export class IntentEditionApi extends BaseAPI {
    /**
     * 
     * @summary Получить редакцию интента.
     * @param {string} id ИД редакции интента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEditionApi
     */
    public getIntentEdition(id: string, options?: any) {
        return IntentEditionApiFp(this.configuration).getIntentEdition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск редакций интента.
     * @param {string} [utterance] 
     * @param {string} [groupCode] 
     * @param {string} [intentCode] 
     * @param {string} [semVer] 
     * @param {IntentEditionStatus} [status] 
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEditionApi
     */
    public searchIntentEditions(utterance?: string, groupCode?: string, intentCode?: string, semVer?: string, status?: IntentEditionStatus, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return IntentEditionApiFp(this.configuration).searchIntentEditions(utterance, groupCode, intentCode, semVer, status, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntentEntryApi - axios parameter creator
 * @export
 */
export const IntentEntryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить запись интента.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEntry: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIntentEntry', 'id', id)
            const localVarPath = `/api/v1/intents/intent-entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск записей интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEntries: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/intents/intent-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (filtration !== undefined) {
                localVarQueryParameter['filtration'] = filtration;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentEntryApi - functional programming interface
 * @export
 */
export const IntentEntryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntentEntryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить запись интента.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentEntry(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEntryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск записей интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchIntentEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntentEntryModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchIntentEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntentEntryApi - factory interface
 * @export
 */
export const IntentEntryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntentEntryApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить запись интента.
         * @param {string} id ИД записи интента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentEntry(id: string, options?: any): AxiosPromise<IntentEntryModel> {
            return localVarFp.getIntentEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск записей интентов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchIntentEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<IntentEntryModelPaginationResponse> {
            return localVarFp.searchIntentEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntentEntryApi - object-oriented interface
 * @export
 * @class IntentEntryApi
 * @extends {BaseAPI}
 */
export class IntentEntryApi extends BaseAPI {
    /**
     * 
     * @summary Получить запись интента.
     * @param {string} id ИД записи интента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEntryApi
     */
    public getIntentEntry(id: string, options?: any) {
        return IntentEntryApiFp(this.configuration).getIntentEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск записей интентов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentEntryApi
     */
    public searchIntentEntries(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return IntentEntryApiFp(this.configuration).searchIntentEntries(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создать сотрудника.
         * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson: async (personCreationRequest?: PersonCreationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удалить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerson', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузить список сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPersons: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/persons/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersons: async (pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/persons/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Обновить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {PersonUpdatingRequest} [personUpdatingRequest] Модель сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (id: string, personUpdatingRequest?: PersonUpdatingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson', 'id', id)
            const localVarPath = `/api/v1/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создать сотрудника.
         * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson(personCreationRequest?: PersonCreationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(personCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удалить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузить список сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPersons(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPersons(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPersons(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersons(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Обновить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {PersonUpdatingRequest} [personUpdatingRequest] Модель сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(id: string, personUpdatingRequest?: PersonUpdatingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(id, personUpdatingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * 
         * @summary Создать сотрудника.
         * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson(personCreationRequest?: PersonCreationRequest, options?: any): AxiosPromise<PersonCreationResponse> {
            return localVarFp.createPerson(personCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удалить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(id: string, options?: any): AxiosPromise<PersonModel> {
            return localVarFp.getPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузить список сотрудников
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPersons(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<PersonModelPaginationResponse> {
            return localVarFp.listPersons(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск сотрудников.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {string} [search] Параметр поиска.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersons(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any): AxiosPromise<PersonModelPaginationResponse> {
            return localVarFp.searchPersons(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Обновить сотрудника.
         * @param {string} id ИД сотрудника.
         * @param {PersonUpdatingRequest} [personUpdatingRequest] Модель сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(id: string, personUpdatingRequest?: PersonUpdatingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePerson(id, personUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * 
     * @summary Создать сотрудника.
     * @param {PersonCreationRequest} [personCreationRequest] Запрос на создание сотрудника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public createPerson(personCreationRequest?: PersonCreationRequest, options?: any) {
        return PersonApiFp(this.configuration).createPerson(personCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удалить сотрудника.
     * @param {string} id ИД сотрудника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public deletePerson(id: string, options?: any) {
        return PersonApiFp(this.configuration).deletePerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить сотрудника.
     * @param {string} id ИД сотрудника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public getPerson(id: string, options?: any) {
        return PersonApiFp(this.configuration).getPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузить список сотрудников
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public listPersons(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return PersonApiFp(this.configuration).listPersons(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск сотрудников.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {string} [search] Параметр поиска.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public searchPersons(pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, search?: string, options?: any) {
        return PersonApiFp(this.configuration).searchPersons(pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Обновить сотрудника.
     * @param {string} id ИД сотрудника.
     * @param {PersonUpdatingRequest} [personUpdatingRequest] Модель сотрудника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public updatePerson(id: string, personUpdatingRequest?: PersonUpdatingRequest, options?: any) {
        return PersonApiFp(this.configuration).updatePerson(id, personUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonAccountApi - axios parameter creator
 * @export
 */
export const PersonAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить аккаунт сотрудника.
         * @param {string} id ИД аккаунта сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonAccount: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPersonAccount', 'id', id)
            const localVarPath = `/api/v1/accounts/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов сотрудников.
         * @param {string} [channelId] ИД канала.
         * @param {string} [personId] ИД сотрудника.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersonAccounts: async (channelId?: string, personId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/accounts/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (channelId !== undefined) {
                localVarQueryParameter['ChannelId'] = channelId;
            }

            if (personId !== undefined) {
                localVarQueryParameter['PersonId'] = personId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonAccountApi - functional programming interface
 * @export
 */
export const PersonAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить аккаунт сотрудника.
         * @param {string} id ИД аккаунта сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonAccount(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonAccount(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов сотрудников.
         * @param {string} [channelId] ИД канала.
         * @param {string} [personId] ИД сотрудника.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPersonAccounts(channelId?: string, personId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonAccountModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPersonAccounts(channelId, personId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonAccountApi - factory interface
 * @export
 */
export const PersonAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить аккаунт сотрудника.
         * @param {string} id ИД аккаунта сотрудника.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonAccount(id: string, options?: any): AxiosPromise<PersonAccountModel> {
            return localVarFp.getPersonAccount(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск аккаунтов сотрудников.
         * @param {string} [channelId] ИД канала.
         * @param {string} [personId] ИД сотрудника.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPersonAccounts(channelId?: string, personId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<PersonAccountModelPaginationResponse> {
            return localVarFp.searchPersonAccounts(channelId, personId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonAccountApi - object-oriented interface
 * @export
 * @class PersonAccountApi
 * @extends {BaseAPI}
 */
export class PersonAccountApi extends BaseAPI {
    /**
     * 
     * @summary Получить аккаунт сотрудника.
     * @param {string} id ИД аккаунта сотрудника.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccountApi
     */
    public getPersonAccount(id: string, options?: any) {
        return PersonAccountApiFp(this.configuration).getPersonAccount(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск аккаунтов сотрудников.
     * @param {string} [channelId] ИД канала.
     * @param {string} [personId] ИД сотрудника.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAccountApi
     */
    public searchPersonAccounts(channelId?: string, personId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return PersonAccountApiFp(this.configuration).searchPersonAccounts(channelId, personId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuickBPMApi - axios parameter creator
 * @export
 */
export const QuickBPMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1QuickbpmPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/quickbpm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuickBPMApi - functional programming interface
 * @export
 */
export const QuickBPMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuickBPMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1QuickbpmPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1QuickbpmPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuickBPMApi - factory interface
 * @export
 */
export const QuickBPMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuickBPMApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1QuickbpmPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1QuickbpmPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuickBPMApi - object-oriented interface
 * @export
 * @class QuickBPMApi
 * @extends {BaseAPI}
 */
export class QuickBPMApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickBPMApi
     */
    public apiV1QuickbpmPost(options?: any) {
        return QuickBPMApiFp(this.configuration).apiV1QuickbpmPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScenarioTemplateApi - axios parameter creator
 * @export
 */
export const ScenarioTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить шаблон пустого сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmptyScenarioTemplate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/templates/scenario/empty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить шаблон сценария.
         * @param {string} id ИД шаблона сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTemplate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getScenarioTemplate', 'id', id)
            const localVarPath = `/api/v1/runtime/simple/templates/scenario/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск шаблонов сценариев.
         * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
         * @param {string} [searchText] Текст для поиска шаблонов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchScenarioTemplates: async (botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/simple/templates/scenario`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (botTemplateId !== undefined) {
                localVarQueryParameter['BotTemplateId'] = botTemplateId;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScenarioTemplateApi - functional programming interface
 * @export
 */
export const ScenarioTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScenarioTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить шаблон пустого сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmptyScenarioTemplate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleScenarioTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmptyScenarioTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить шаблон сценария.
         * @param {string} id ИД шаблона сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScenarioTemplate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SingleScenarioTemplateModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScenarioTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск шаблонов сценариев.
         * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
         * @param {string} [searchText] Текст для поиска шаблонов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchScenarioTemplates(botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListScenarioTemplateModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchScenarioTemplates(botTemplateId, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScenarioTemplateApi - factory interface
 * @export
 */
export const ScenarioTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScenarioTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить шаблон пустого сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmptyScenarioTemplate(options?: any): AxiosPromise<SingleScenarioTemplateModel> {
            return localVarFp.getEmptyScenarioTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить шаблон сценария.
         * @param {string} id ИД шаблона сценария.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScenarioTemplate(id: string, options?: any): AxiosPromise<SingleScenarioTemplateModel> {
            return localVarFp.getScenarioTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск шаблонов сценариев.
         * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
         * @param {string} [searchText] Текст для поиска шаблонов.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchScenarioTemplates(botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<ListScenarioTemplateModelPaginationResponse> {
            return localVarFp.searchScenarioTemplates(botTemplateId, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScenarioTemplateApi - object-oriented interface
 * @export
 * @class ScenarioTemplateApi
 * @extends {BaseAPI}
 */
export class ScenarioTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Получить шаблон пустого сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioTemplateApi
     */
    public getEmptyScenarioTemplate(options?: any) {
        return ScenarioTemplateApiFp(this.configuration).getEmptyScenarioTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить шаблон сценария.
     * @param {string} id ИД шаблона сценария.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioTemplateApi
     */
    public getScenarioTemplate(id: string, options?: any) {
        return ScenarioTemplateApiFp(this.configuration).getScenarioTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск шаблонов сценариев.
     * @param {string} [botTemplateId] Идентификатор шаблона бота, к которому относится шаблон сценария.
     * @param {string} [searchText] Текст для поиска шаблонов.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenarioTemplateApi
     */
    public searchScenarioTemplates(botTemplateId?: string, searchText?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return ScenarioTemplateApiFp(this.configuration).searchScenarioTemplates(botTemplateId, searchText, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenant: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/tenants/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTenant(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTenant(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenant(options?: any): AxiosPromise<TenantModel> {
            return localVarFp.getCurrentTenant(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getCurrentTenant(options?: any) {
        return TenantApiFp(this.configuration).getCurrentTenant(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestInstanceApi - axios parameter creator
 * @export
 */
export const TestInstanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить score для фраз в интенте.
         * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentScores: async (testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances/intent-scores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testInstanceIntentScoresRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить состояние тестового бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getstate: async (agentId?: string, botVersionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances/get-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['AgentId'] = agentId;
            }

            if (botVersionId !== undefined) {
                localVarQueryParameter['BotVersionId'] = botVersionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Запуск тестового бота.
         * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run: async (testInstanceRunningRequest?: TestInstanceRunningRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testInstanceRunningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выполнить поиск тестовых ботов.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentTestInstances: async (agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/runtime/testinstances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            // authentication OAuth20 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth20", ["elma.assistant.resources.identity.context", "elma.assistant.scopes.api.admin", "email", "offline_access", "openid", "phone", "profile"], configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['AgentId'] = agentId;
            }

            if (botVersionId !== undefined) {
                localVarQueryParameter['BotVersionId'] = botVersionId;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestInstanceApi - functional programming interface
 * @export
 */
export const TestInstanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestInstanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить score для фраз в интенте.
         * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntentScores(testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestInstanceIntentScoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntentScores(testInstanceIntentScoresRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить состояние тестового бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getstate(agentId?: string, botVersionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getstate(agentId, botVersionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Запуск тестового бота.
         * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async run(testInstanceRunningRequest?: TestInstanceRunningRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.run(testInstanceRunningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выполнить поиск тестовых ботов.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgentTestInstances(agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentTestInstanceResponsePaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgentTestInstances(agentId, botVersionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestInstanceApi - factory interface
 * @export
 */
export const TestInstanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestInstanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить score для фраз в интенте.
         * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntentScores(testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options?: any): AxiosPromise<TestInstanceIntentScoresResponse> {
            return localVarFp.getIntentScores(testInstanceIntentScoresRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить состояние тестового бота.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getstate(agentId?: string, botVersionId?: string, options?: any): AxiosPromise<TestInstanceResponse> {
            return localVarFp.getstate(agentId, botVersionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Запуск тестового бота.
         * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        run(testInstanceRunningRequest?: TestInstanceRunningRequest, options?: any): AxiosPromise<TestInstanceResponse> {
            return localVarFp.run(testInstanceRunningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выполнить поиск тестовых ботов.
         * @param {string} [agentId] ИД агента.
         * @param {string} [botVersionId] ИД версии бота.
         * @param {number} [pageIndex] 
         * @param {number} [pageSize] 
         * @param {boolean} [withTotal] 
         * @param {number} [fromItemInclusive] 
         * @param {number} [toItemExclusive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgentTestInstances(agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<AgentTestInstanceResponsePaginationResponse> {
            return localVarFp.searchAgentTestInstances(agentId, botVersionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestInstanceApi - object-oriented interface
 * @export
 * @class TestInstanceApi
 * @extends {BaseAPI}
 */
export class TestInstanceApi extends BaseAPI {
    /**
     * 
     * @summary Получить score для фраз в интенте.
     * @param {TestInstanceIntentScoresRequest} [testInstanceIntentScoresRequest] Запрос на получение score для фраз.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public getIntentScores(testInstanceIntentScoresRequest?: TestInstanceIntentScoresRequest, options?: any) {
        return TestInstanceApiFp(this.configuration).getIntentScores(testInstanceIntentScoresRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить состояние тестового бота.
     * @param {string} [agentId] ИД агента.
     * @param {string} [botVersionId] ИД версии бота.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public getstate(agentId?: string, botVersionId?: string, options?: any) {
        return TestInstanceApiFp(this.configuration).getstate(agentId, botVersionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Запуск тестового бота.
     * @param {TestInstanceRunningRequest} [testInstanceRunningRequest] Запрос на запуск.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public run(testInstanceRunningRequest?: TestInstanceRunningRequest, options?: any) {
        return TestInstanceApiFp(this.configuration).run(testInstanceRunningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выполнить поиск тестовых ботов.
     * @param {string} [agentId] ИД агента.
     * @param {string} [botVersionId] ИД версии бота.
     * @param {number} [pageIndex] 
     * @param {number} [pageSize] 
     * @param {boolean} [withTotal] 
     * @param {number} [fromItemInclusive] 
     * @param {number} [toItemExclusive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestInstanceApi
     */
    public searchAgentTestInstances(agentId?: string, botVersionId?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any) {
        return TestInstanceApiFp(this.configuration).searchAgentTestInstances(agentId, botVersionId, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}


