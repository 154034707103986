import React from 'react';
import { Alert, Button, Dropdown, Menu, PageHeader, Typography } from 'antd';
import { useHistory } from 'react-router';
import { PlayCircleOutlined } from '@ant-design/icons';

import { AgentStageAccountModel, AgentStageModel, TrainStatus } from '../../../../api';
import ConversationWidget from '../../../components/ConversationWidget';
import ChannelList from '../../../components/ChannelList';

import WebChatDrawer from './WebChatDrawer';

const { Title, Paragraph } = Typography;

interface AgentStagePaneProps {
  agentStage: AgentStageModel;
  channels: AgentStageAccountModel[];
  botId: string;
  onRetrainRun: () => void;
}

const AgentStagePane: React.FC<AgentStagePaneProps> = ({ agentStage, botId, channels, onRetrainRun = () => {} }) => {
  const { push } = useHistory();

  const onEditStage = () => {
    push(`/agents/stages/${agentStage.id}/edit`);
  };

  const onAddViberChannel = () => {
    push(`/agents/${agentStage.id}/addViber`);
  };

  const onAddTelegramChannel = () => {
    push(`/agents/${agentStage.id}/addTelegram`);
  };

  const onAddELMA365Channel = () => {
    push(`/agents/${agentStage.id}/addELMA365`);
  };

  const onEmulatorTestSelect = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(agentStage.emulatorUrl, '_self');
  };

  const headerExtraButtons = [
    <Button key="editStage" type="default" onClick={onEditStage}>
      Изменить параметры
    </Button>,
    <Button key="addViber" type="primary" onClick={onAddViberChannel}>
      + Добавить канал Viber
    </Button>,
    <Button key="addTelegram" type="primary" onClick={onAddTelegramChannel}>
      + Добавить канал Telegram
    </Button>,
    <Button key="addELMA365" type="primary" onClick={onAddELMA365Channel}>
      + Добавить линию ELMA365
    </Button>,
  ];

  if (agentStage.trainResult?.status === TrainStatus.Pending) {
    const trainedCount = agentStage.trainResult?.generatedData?.filter((item) => item.omegaGenerated).length || 0;
    const count = agentStage.trainResult?.generatedData?.length || 1;
    const percent = Math.round((trainedCount / count) * 100);

    headerExtraButtons.push(
      <Button key="publication" loading type="primary">
        Идет публикация...({percent} %)
      </Button>
    );
  } else {
    const menu = (
      <Menu>
        <Menu.Item
          key="emulator"
          disabled={agentStage.trainResult?.status === TrainStatus.Error}
          onClick={onEmulatorTestSelect}
        >
          в эмуляторе
        </Menu.Item>
        <WebChatDrawer agentStage={agentStage} />
      </Menu>
    );
    headerExtraButtons.push(
      <Dropdown key="test" overlay={menu}>
        <Button icon={<PlayCircleOutlined />} type="primary">
          Тестировать
        </Button>
      </Dropdown>
    );
  }

  const onRetrainBtnClick = () => {
    onRetrainRun();
  };

  return (
    <div>
      {agentStage.trainResult?.status === TrainStatus.Error && (
        <Alert
          showIcon
          description={
            <React.Fragment>
              <Paragraph>{agentStage.trainResult?.errorMessage}</Paragraph>
              <Button type="link" onClick={onRetrainBtnClick}>
                Опубликовать заново
              </Button>
            </React.Fragment>
          }
          message="Ошибка публикации"
          type={'error'}
        />
      )}
      <PageHeader extra={headerExtraButtons} title="" />
      <ConversationWidget agentStageId={agentStage.id} caption="Все беседы бота" />
      <br />
      <Title level={4}>Каналы</Title>
      <div>
        <ChannelList agentStage={agentStage} botId={botId} channels={channels} />
      </div>
    </div>
  );
};

export default AgentStagePane;
