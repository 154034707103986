import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { activityApi } from '../apis';
import { downloadNamedFile } from '../utils/fileUtil';

export interface IConversationTranscriptLinkProps {
  conversationId: string;
}

const ConversationTranscriptLink: React.FC<IConversationTranscriptLinkProps> = ({ conversationId }) => {
  const onBtnClick = async () => {
    const response = await activityApi.exportActivities(
      undefined,
      undefined,
      undefined,
      conversationId,
      undefined,
      undefined,
      undefined,
      { responseType: 'blob' }
    );
    downloadNamedFile(response);
  };

  return (
    <Button icon={<DownloadOutlined />} type="link" onClick={onBtnClick}>
      Скачать транскрипт
    </Button>
  );
};

export default ConversationTranscriptLink;
