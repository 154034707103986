import { Divider, List, Popover, Radio } from 'antd';
import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';

import './index.less';

import {
  BotStageMergeResponse,
  BotStageType,
  MergeBotStageStatus,
  ScenarioEditionReferenceModel,
  SingleBotModel,
} from '../../../../../../api';
import SbScenarioCard from '../../../../components/SbScenarioCard';
import SbAddScenarioCard from '../../../../components/SbScenarioCard/SbAddScenarioCard';
import { botStageApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import ScenarioAddModal, { SCENARIO_ADD_MODAL_WINDOW_WIDTH } from '../../../../components/SbScenarioAddModal';
import SbModal from '../../../../components/common/SbModal';
import { ScenarioSortDirection } from '../../../../types';
import { currentBotStageTypeSelector } from '../../../../recoil';
import SbButton from '../../../../components/common/SbButton';
import SbIcon from '../../../../components/common/SbIcon';
import SbTypography from '../../../../components/common/SbTypography';
import SbInput from '../../../../components/common/SbInput';
import { getIgnoreCaseStringComparer } from '../../../../../utils/stringUtil';

interface IScenarioListBlockProps {
  bot: SingleBotModel;
  onDataChanged: () => Promise<void>;
}

const ScenarioListBlock: React.FC<IScenarioListBlockProps> = ({ bot, onDataChanged }) => {
  const history = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [currentBotStageType, setCurrentBotStageType] = useRecoilState(currentBotStageTypeSelector(bot.entry.id));

  const [addScenarioModalVisible, setAddScenarioModalVisible] = useState(false);
  const [draftCreatedModalVisible, setDraftCreatedModalVisible] = useState(false);
  const [confirmMergeModalVisible, setConfirmMergeModalVisible] = useState(false);
  const [mergeResultModalVisible, setMergeResultModalVisible] = useState(false);
  const [deleteResultModalVisible, setDeleteResultModalVisible] = useState(false);

  const [mergeDescription, setMergeDescription] = useState('');
  const [botDraftMergeResult, setBotDraftMergeResult] = useState<BotStageMergeResponse>();
  const [sortMode, setSortMode] = useState(ScenarioSortDirection.ModifiedOnDescending);

  const [botDraftCreating, setBotDraftCreating] = useState(false);
  const [botDraftMerging, setBotDraftMerging] = useState(false);
  const [botDraftDeleting, setBotDraftDeleting] = useState(false);

  const isDraft = currentBotStageType === BotStageType.Draft;
  const botStage = isDraft && bot.draftStage ? bot.draftStage : bot.originStage;

  const scenarios = botStage.currentEdition.scenarios;
  const noScenarios = !scenarios.length;

  const buttonsDisabled = botDraftCreating || botDraftMerging || botDraftDeleting;

  const mergeResultModal = {
    title:
      botDraftMergeResult?.status === MergeBotStageStatus.Merged
        ? 'Основная версия бота обновлена'
        : botDraftMergeResult?.status === MergeBotStageStatus.NothingToMerge
        ? 'Черновик не отличается от основной версии'
        : botDraftMergeResult?.status === MergeBotStageStatus.HasChanges
        ? 'Основная версия бота была изменена'
        : '',
    description:
      botDraftMergeResult?.status === MergeBotStageStatus.Merged
        ? 'Теперь настройки черновика записаны вместо основной версии, а черновик пуст. Все изменения доступны в истории версий вашего бота.'
        : botDraftMergeResult?.status === MergeBotStageStatus.NothingToMerge
        ? 'Черновик не отличается от основной версии бота. Можете продолжить редактировать черновик или удалить его.'
        : botDraftMergeResult?.status === MergeBotStageStatus.HasChanges
        ? 'Основная версия бота была изменена после создания текущего черновика. Можете объединить черновик, в таком случае предыдущие изменения будут перезаписаны.'
        : '',
  };

  const getComparer = () => (a: ScenarioEditionReferenceModel, b: ScenarioEditionReferenceModel) => {
    switch (sortMode) {
      case ScenarioSortDirection.NameAscending:
        return getIgnoreCaseStringComparer<ScenarioEditionReferenceModel>((s) => s.name)(a, b);
      case ScenarioSortDirection.ModifiedOnDescending:
        return getIgnoreCaseStringComparer<ScenarioEditionReferenceModel>((s) => s.modifiedOn)(b, a);
      default:
        return 0;
    }
  };

  const mergeBotDraft = async (force = false) => {
    if (!bot?.draftStage || !mergeDescription) return;

    setBotDraftMerging(true);
    try {
      const response = await botStageApi.mergeBotStage(bot.originStage.id, {
        force,
        sourceBotStageId: bot.draftStage.id,
        description: mergeDescription,
      });

      if (response.data.status === MergeBotStageStatus.Merged) {
        setMergeDescription('');
        setCurrentBotStageType(BotStageType.Origin);
        await onDataChanged();
      }

      setBotDraftMergeResult(response.data);
      setConfirmMergeModalVisible(false);
      setMergeResultModalVisible(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при объединении черновика с основной версией',
        error: e,
      });
    }
    setBotDraftMerging(false);
  };

  const onSwitchToOriginBotStage = () => setCurrentBotStageType(BotStageType.Origin);

  const onSwitchToDraftBotStage = () => {
    setDraftCreatedModalVisible(false);
    setCurrentBotStageType(BotStageType.Draft);
  };

  const onCreateBotDraftButtonClick = async () => {
    if (!bot) return;

    setBotDraftCreating(true);
    try {
      await botStageApi.createDraftBotStage(bot.originStage.id);
      await onDataChanged();
      setDraftCreatedModalVisible(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании черновика бота',
        error: e,
      });
    }
    setBotDraftCreating(false);
  };

  const onMergeBotDraftButtonClick = () => setConfirmMergeModalVisible(true);

  const onCancelDraftCreatedModal = () => setDraftCreatedModalVisible(false);

  const onCancelConfirmMergeModal = () => setConfirmMergeModalVisible(false);

  const onCloseMergeResultModal = () => setMergeResultModalVisible(false);

  const onConfirmMergeButtonClick = async () => await mergeBotDraft();

  const onForceMergeBotDraftButtonClick = async () => await mergeBotDraft(true);

  const onDeleteBotDraftButtonClick = async () => {
    if (!bot?.draftStage) return;

    setBotDraftDeleting(true);
    try {
      await botStageApi.deleteBotStage(bot.draftStage.id);
      setMergeResultModalVisible(false);
      setDeleteResultModalVisible(true);
      await onDataChanged();
      setCurrentBotStageType(BotStageType.Origin);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении черновика',
        error: e,
      });
    }
    setBotDraftDeleting(false);
  };

  const onCancelDeleteResultModal = () => setDeleteResultModalVisible(false);

  const onAddScenario = () => setAddScenarioModalVisible(true);

  const onScenarioCreate = async (scenarioTemplateContent: string, scenarioName: string) => {
    try {
      const structure = JSON.parse(scenarioTemplateContent);
      structure.name = scenarioName;

      const response = await botStageApi.createScenario(botStage.id, {
        scenarioName: structure.name,
        scenarioStructure: structure,
      });

      const scenariosCount = response.data.currentEdition.scenarios.length;
      const scenario = response.data.currentEdition.scenarios[scenariosCount - 1];

      history.push(`/simple-bots/${bot.entry.id}/scenario/${scenario.scenarioStructureId}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении сценария',
        error: e,
      });
    }
  };

  const onSortModeChanged = (e: RadioChangeEvent) => setSortMode(e.target.value);

  const renderHeader = () => {
    if (noScenarios) {
      return <h2>Нет сценариев</h2>;
    }

    const draftButtonPopoverContent = (
      <div>
        <p>
          Чтобы безопасно вносить изменения в бота, не прерывая его работу, вы можете создать временный черновик и
          работать в нем.
        </p>
        <p>После вы можете заменить им основной бот или удалить черновик.</p>
      </div>
    );

    return (
      <>
        <h2>Сценарии</h2>
        <div className="sb-scenario-list-block__header__buttons">
          {isDraft ? (
            <>
              <SbButton
                disabled={buttonsDisabled}
                sbSize="medium"
                sbType="tertiary"
                onClick={onMergeBotDraftButtonClick}
              >
                <SbIcon iconName="add-one" size={20} />
                Объединить с основной версией
              </SbButton>
              <SbButton disabled={buttonsDisabled} sbSize="medium" sbType="tertiary" onClick={onSwitchToOriginBotStage}>
                <SbIcon iconName="redo" size={20} />К основной версии
              </SbButton>
            </>
          ) : bot?.draftStage ? (
            <SbButton disabled={buttonsDisabled} sbSize="medium" sbType="tertiary" onClick={onSwitchToDraftBotStage}>
              <SbIcon iconName="redo" size={20} />К черновику бота
            </SbButton>
          ) : (
            <Popover align={{ offset: [-5, 0] }} content={draftButtonPopoverContent} placement="rightTop">
              <div>
                <SbButton
                  disabled={buttonsDisabled}
                  sbSize="big"
                  sbType="tertiary"
                  onClick={onCreateBotDraftButtonClick}
                >
                  {botDraftCreating ? (
                    <SbIcon spin iconName="loading-four" size={20} />
                  ) : (
                    <SbIcon iconName="edit" size={20} />
                  )}
                  <span>Создать черновик</span>
                </SbButton>
              </div>
            </Popover>
          )}
        </div>
        <div className="sb-scenario-list-block__header__sort-panel">
          <span className="sb-scenario-list-block__header__sort-panel__text">Сортировать</span>
          <Radio.Group value={sortMode} onChange={onSortModeChanged}>
            <Radio.Button value={ScenarioSortDirection.ModifiedOnDescending}>по дате изменения</Radio.Button>
            <Divider type="vertical" />
            <Radio.Button value={ScenarioSortDirection.NameAscending}>по названию</Radio.Button>
          </Radio.Group>
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (noScenarios) {
      return (
        <>
          <div className="sb-bot-card__content__block__description">
            Добавьте сценарий за считанные минуты с помощью визуального
            <br /> редактора без привлечения специлистов и начните обрабатывать
            <br /> огромное количество запросов пользователей одновременно
          </div>
          <SbButton icon={<SbIcon iconName="plus" />} sbType="icon-secondary" onClick={onAddScenario}>
            Добавить сценарий
          </SbButton>
        </>
      );
    }

    return (
      <List<ScenarioEditionReferenceModel>
        className="sb-scenario-list-block__content__list"
        dataSource={[{} as ScenarioEditionReferenceModel, ...scenarios.sort(getComparer())]}
        grid={{ gutter: 20, xxl: 5, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }}
        pagination={false}
        renderItem={(item, index) => {
          const addScenarioCard = (
            <List.Item key="add-scenario-card">
              <SbAddScenarioCard onCardClick={onAddScenario} />
            </List.Item>
          );

          if (index === 0) {
            return addScenarioCard;
          }

          return (
            <List.Item key={item.scenarioStructureId}>
              <SbScenarioCard
                botEntryId={bot.entry.id}
                botStageId={botStage.id}
                scenario={item}
                onDataChanged={onDataChanged}
              />
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <div className="sb-scenario-list-block">
      <div className="sb-scenario-list-block__header sb-bot-card__content__block__inner-wrapper__header">
        {renderHeader()}
      </div>
      <div className="sb-scenario-list-block__content">{renderContent()}</div>
      {/* todo: обертку SbModal переместить внутрь компонента ScenarioAddModal */}
      <SbModal
        destroyOnClose
        className="sb-add-scenario-modal"
        footer={[]}
        maskClosable={false}
        sbSize="large"
        visible={addScenarioModalVisible}
        width={SCENARIO_ADD_MODAL_WINDOW_WIDTH}
        onCancel={() => setAddScenarioModalVisible(false)}
      >
        <ScenarioAddModal originBotTemplateCode={bot.entry.botTemplateCode} onScenarioCreate={onScenarioCreate} />
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCancelDraftCreatedModal}>
            Закрыть
          </SbButton>,
          <SbButton key="go-to-draft" sbSize="medium" sbType="secondary" onClick={onSwitchToDraftBotStage}>
            Перейти в черновик бота
          </SbButton>,
        ]}
        sbSize="small"
        title="Черновик создан"
        visible={draftCreatedModalVisible}
        width={496}
        onCancel={onCancelDraftCreatedModal}
        onOk={onSwitchToDraftBotStage}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">
            Теперь можно экспериментировать в этой версии бота. Оригинальная версия продолжит работать, если подключена.
          </p>
        </SbTypography>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCancelConfirmMergeModal}>
            Закрыть
          </SbButton>,
          <SbButton
            key="merge"
            disabled={botDraftMerging}
            sbSize="medium"
            sbType="icon-secondary"
            onClick={onConfirmMergeButtonClick}
          >
            {botDraftMerging && <SbIcon spin iconName="loading-four" size={16} />}
            Да, объединить черновик с основной версией
          </SbButton>,
        ]}
        sbSize="small"
        title="Объединить черновик с основной версией?"
        visible={confirmMergeModalVisible}
        width={580}
        onCancel={onCancelConfirmMergeModal}
        onOk={onConfirmMergeButtonClick}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">
            Вы можете позже вернуться к текущей основной версии, восстановив ее в истории версий.
          </p>
          <SbInput
            isValid={!!mergeDescription}
            placeholder="Опишите изменения, сделанные в черновике"
            sbSize="big"
            value={mergeDescription}
            onChange={setMergeDescription}
          />
        </SbTypography>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCloseMergeResultModal}>
            Закрыть
          </SbButton>,
          botDraftMergeResult?.status === MergeBotStageStatus.NothingToMerge ? (
            <SbButton
              key="delete"
              disabled={botDraftDeleting}
              sbSize="medium"
              sbType="icon-secondary"
              onClick={onDeleteBotDraftButtonClick}
            >
              {botDraftDeleting && <SbIcon spin iconName="loading-four" size={16} />}
              Удалить черновик
            </SbButton>
          ) : null,
          botDraftMergeResult?.status === MergeBotStageStatus.HasChanges ? (
            <SbButton
              key="force-merge"
              disabled={botDraftMerging}
              sbSize="medium"
              sbType="icon-secondary"
              onClick={onForceMergeBotDraftButtonClick}
            >
              {botDraftMerging && <SbIcon spin iconName="loading-four" size={16} />}
              Да, перезаписать изменения
            </SbButton>
          ) : null,
        ]}
        sbSize="small"
        title={mergeResultModal.title}
        visible={mergeResultModalVisible}
        width={580}
        onCancel={onCloseMergeResultModal}
        onOk={onCloseMergeResultModal}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">{mergeResultModal.description}</p>
        </SbTypography>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCancelDeleteResultModal}>
            Закрыть
          </SbButton>,
        ]}
        sbSize="small"
        title="Черновик удален"
        visible={deleteResultModalVisible}
        width={496}
        onCancel={onCancelDeleteResultModal}
        onOk={onCancelDeleteResultModal}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">
            Черновик бота был удален. Вы можете продолжать редактировать основную версию или заново создать черновик.
          </p>
        </SbTypography>
      </SbModal>
    </div>
  );
};

export default ScenarioListBlock;
