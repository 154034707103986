import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSetRecoilState } from 'recoil';
import { Popover, Select, Skeleton, Table } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Moment } from 'moment';
import { useDebounce } from 'usehooks-ts';

import SbDatePicker from '../../../components/common/SbDatePicker';
import SbSelect from '../../../components/common/SbSelect';
import SbButton from '../../../components/common/SbButton';
import SbScroll from '../../../components/common/SbScroll';
import SbInput from '../../../components/common/SbInput';
import SbIcon from '../../../components/common/SbIcon';
import { ConversationModel, ConversationSortDirection, ConversationStatus } from '../../../../../api';
import { ChannelNames, ConversationStatusNames } from '../../../utils/dialogs';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { AlertTypes } from '../../../../constants';
import { conversationApi } from '../../../../apis';

import DialogListItem from './DialogListItem';

const DIALOG_ITEMS_SCROLL_ID = 'sb-dialog-items-scroll';
const DATE_LOCAL_FORMAT = 'DD.MM.YYYY';
const DATE_FORMAT = 'YYYY-MM-DD';
const SEARCH_DELAY = 200; //ms

interface IDialogFilter {
  channel?: string;
  status?: ConversationStatus;
  startDateRange?: [Moment, Moment];
  lastMessageDateRange?: [Moment, Moment];
}

interface IDialogListProps {
  agentStageId?: string;
  onSelect: (conversation?: ConversationModel) => void;
}

const DialogList: React.FC<IDialogListProps> = ({ agentStageId, onSelect }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [conversationsPageIndex, setConversationsPageIndex] = useState(0);
  const [selectedConversation, setSelectedConversation] = useState<ConversationModel>();
  const [conversations, setConversations] = useState([] as ConversationModel[]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterPopoverVisible, setFilterPopoverVisible] = useState(false);
  const [filter, setFilter] = useState<IDialogFilter>();
  const [searchText, setSearchText] = useState('');

  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';

  const loadDialogs = async (loadMore: boolean, filter?: IDialogFilter) => {
    if (loading || !agentStageId) return;

    setLoading(true);
    try {
      const sorting = ConversationSortDirection.NUMBER_4;
      const conversationsResponse = await conversationApi.searchConversations(
        filter?.channel,
        agentStageId,
        undefined,
        undefined,
        filter?.status,
        filter?.startDateRange?.[0].format(DATE_FORMAT),
        filter?.startDateRange?.[1].format(DATE_FORMAT),
        filter?.lastMessageDateRange?.[0].format(DATE_FORMAT),
        filter?.lastMessageDateRange?.[1].format(DATE_FORMAT),
        deferredSearchText,
        sorting,
        loadMore ? conversationsPageIndex : 0
      );

      if (loadMore) {
        setConversations([...conversations, ...(conversationsResponse.data.items ?? [])]);
      } else {
        setConversations(conversationsResponse.data.items ?? []);
      }
      setConversationsPageIndex((conversationsResponse.data.pageIndex ?? conversationsPageIndex) + 1);
      setHasMore(conversationsResponse.data.hasMore ?? false);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке диалогов',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDialogs(false, filter).finally();
  };
  useEffect(loadData, [agentStageId, deferredSearchText]);

  const dialogItem = [
    {
      render: (item: ConversationModel) => <DialogListItem item={item} />,
    },
  ];

  const onDialogSelect = (conversation: ConversationModel) => {
    const newSelectedConversations = [selectedConversation].includes(conversation) ? [] : [conversation];
    const newConversation = newSelectedConversations.pop();
    setSelectedConversation(newConversation);
    onSelect(newConversation);
  };

  const onSearchChange = async (value: string) => {
    setSearchText(value);
  };

  const onFilterPopoverVisibleChange = (visible: boolean) => {
    setFilterPopoverVisible(visible);
  };

  const onApplyFilter = async () => {
    await loadDialogs(false, filter);
    setFilterPopoverVisible(false);
  };

  const onResetFilter = async () => {
    setFilter(undefined);
    await loadDialogs(false, undefined);
  };

  const onFilterChannelChange = (value: SelectValue) =>
    setFilter({
      ...filter,
      channel: value as string,
    });

  const onFilterStatusChange = (value: SelectValue) =>
    setFilter({
      ...filter,
      status: value as ConversationStatus,
    });

  const onFilterStartDateChange = (values: [Moment | null, Moment | null] | null) =>
    setFilter({
      ...filter,
      startDateRange: values as [Moment, Moment],
    });

  const onFilterLastMessageDateChange = (values: [Moment | null, Moment | null] | null) =>
    setFilter({
      ...filter,
      lastMessageDateRange: values as [Moment, Moment],
    });

  const filterMenuContent = (
    <div>
      <h3>Дата начала</h3>
      <SbDatePicker.Range
        format={DATE_LOCAL_FORMAT}
        picker="date"
        value={filter?.startDateRange}
        onChange={onFilterStartDateChange}
      />
      <h3>Дата последнего сообщения</h3>
      <SbDatePicker.Range
        format={DATE_LOCAL_FORMAT}
        picker="date"
        value={filter?.lastMessageDateRange}
        onChange={onFilterLastMessageDateChange}
      />
      <h3>Канал</h3>
      <SbSelect sbType="light" value={filter?.channel} onChange={onFilterChannelChange}>
        {ChannelNames.map((c) => (
          <Select.Option key={c.value} value={c.value}>
            {c.label}
          </Select.Option>
        ))}
      </SbSelect>
      <h3>Статус</h3>
      <SbSelect sbType="light" value={filter?.status} onChange={onFilterStatusChange}>
        {ConversationStatusNames.map((s) => (
          <Select.Option key={s.value} value={s.value}>
            {s.label}
          </Select.Option>
        ))}
      </SbSelect>
      <div className="sb-dialogs-card__content__list-container__filter-menu__buttons">
        <SbButton sbSize="medium" onClick={onApplyFilter}>
          Показать
        </SbButton>
        <SbButton sbSize="medium" sbType="secondary" onClick={onResetFilter}>
          Сбросить фильтр
        </SbButton>
      </div>
    </div>
  );

  const renderFilterSuffix = () => {
    return (
      <Popover
        destroyTooltipOnHide
        align={{ offset: [8, 8] }}
        content={filterMenuContent}
        overlayClassName="sb-dialogs-card__content__list-container__filter-menu"
        placement="bottomRight"
        trigger={['click']}
        visible={filterPopoverVisible}
        onVisibleChange={onFilterPopoverVisibleChange}
      >
        <SbIcon className={filter ? 'badge' : ''} iconName="filter" />
      </Popover>
    );
  };

  return (
    <>
      <div className="sb-dialogs-card__content__list-container__filter">
        <SbInput
          customSuffix={renderFilterSuffix()}
          placeholder="Поиск"
          sbSize="small"
          value={searchText}
          onChange={onSearchChange}
        />
      </div>
      <div className="sb-dialogs-card__content__list-container__list">
        <SbScroll id={DIALOG_ITEMS_SCROLL_ID}>
          <InfiniteScroll
            dataLength={conversations.length}
            hasMore={hasMore}
            loader={<Skeleton active avatar />}
            next={() => loadDialogs(true, filter)}
            scrollableTarget={DIALOG_ITEMS_SCROLL_ID}
          >
            <Table
              columns={dialogItem}
              dataSource={conversations}
              locale={{
                emptyText: loading && <Skeleton active avatar />,
              }}
              pagination={false}
              rowKey="id"
              rowSelection={{ selectedRowKeys: [selectedConversation?.id ?? ''] }}
              showHeader={false}
              onRow={(item) => {
                return {
                  onClick: () => onDialogSelect(item),
                };
              }}
            />
          </InfiniteScroll>
        </SbScroll>
      </div>
    </>
  );
};

export default DialogList;
