import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountModel } from '../../../api';
import { agentStageAccountApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

import AgentAccountListItem from './AgentAccountListItem';

export interface IAgentAccountListProps {
  agentStageId: string;
  visible: boolean;
  onAccountsChange: (accounts: AgentStageAccountModel[]) => void;
}

const AgentAccountList: React.FC<IAgentAccountListProps> = ({
  agentStageId,
  visible = false,
  onAccountsChange = () => {
    /*empty*/
  },
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([] as AgentStageAccountModel[]);

  const loadAgentStageAccountsAsync = async () => {
    setLoading(true);
    try {
      const response = await agentStageAccountApi.searchAgentStageAccounts(undefined, agentStageId, 0, 100);
      setAccounts(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка каналов бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadAgentStageAccounts = () => {
    if (visible) {
      loadAgentStageAccountsAsync();
    }
  };
  useEffect(loadAgentStageAccounts, [visible]);

  if (!visible) return null;
  return (
    <List
      dataSource={accounts}
      loading={loading}
      renderItem={(item: AgentStageAccountModel) => (
        <AgentAccountListItem account={item} accounts={accounts} onAccountsChange={onAccountsChange} />
      )}
      size="small"
    />
  );
};

export default AgentAccountList;
