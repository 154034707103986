import React, { Fragment, useEffect, useState } from 'react';
import { Button, PageHeader, Spin, Tabs, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { RobotOutlined } from '@ant-design/icons';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import {
  AgentModel,
  AgentStageAccountModel,
  AgentStageModel,
  PublishStage,
  SetupStatus,
  TrainStatus,
} from '../../../../api';
import {
  AlertTypes,
  BOT_PUBLISH_STATUS_UPDATED,
  Channels,
  StageTypes,
  UNKNOWN_ID,
  UNKNOWN_URL,
  UNKNOWN_VALUE,
} from '../../../constants';
import { agentApi, agentStageAccountApi, botVersionApi } from '../../../apis';
import BotVersions from '../../../components/BotVersions';
import { hubConnections } from '../../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import { getEditUrlWithBackUrl } from '../../../utils/urlUtil';

import AgentStagePane from './AgentStagePane';
import AgentTestInstancesPane from './AgentTestInstancesPane';

const { Paragraph } = Typography;
const { TabPane } = Tabs;

const AgentCard: React.FC = () => {
  const { push } = useHistory();
  const { id, stageType = StageTypes.PRODUCTION } = useParams<{ id: string; stageType: StageTypes }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const [agent, setAgent] = useState<AgentModel | undefined>(undefined);
  const [prodChannels, setProdChannels] = useState<AgentStageAccountModel[]>([]);
  const [stagingChannels, setStagingChannels] = useState<AgentStageAccountModel[]>([]);

  const createDirectLineAccount = (agentStage: AgentStageModel): AgentStageAccountModel => {
    return {
      id: UNKNOWN_ID,
      agentStageId: agentStage.id,
      channelId: Channels.DIRECTLINE,
      authToken: UNKNOWN_VALUE,
      botUri: UNKNOWN_URL,
      externalName: UNKNOWN_VALUE,
      redirectUrl: agentStage.webChatUrl,
      callbackUrl: UNKNOWN_URL,
      setupResult: { status: SetupStatus.NUMBER_1 },
    };
  };

  const loadDataAsync = async () => {
    try {
      const response = await agentApi.getAgent(id);
      setAgent(response.data);

      const { productionAgent, stagingAgent } = response.data;

      if (productionAgent && productionAgent.id) {
        const prodChannelsResponse = await agentStageAccountApi.searchAgentStageAccounts(
          undefined,
          productionAgent.id,
          0,
          100
        );
        const channels: AgentStageAccountModel[] = [
          createDirectLineAccount(productionAgent),
          ...(prodChannelsResponse.data.items || []),
        ];
        setProdChannels(channels);
      }

      if (stagingAgent && stagingAgent.id) {
        const stagingChannelsResponse = await agentStageAccountApi.searchAgentStageAccounts(
          undefined,
          stagingAgent.id,
          0,
          100
        );
        const channels: AgentStageAccountModel[] = [
          createDirectLineAccount(stagingAgent),
          ...(stagingChannelsResponse.data.items || []),
        ];
        setStagingChannels(channels);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке бота',
        error: e,
      });
    }
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [id]);

  const loadAgentDataAsync = async () => {
    try {
      const response = await agentApi.getAgent(id);

      if (agent?.stagingAgent?.trainResult?.status === TrainStatus.Pending) {
        if (response.data.stagingAgent?.trainResult?.status === TrainStatus.Success) {
          addAlert({
            type: AlertTypes.SUCCESS,
            message: 'Публикация тестовой версии бота прошла успешно',
            description: '',
          });
        } else if (response.data.stagingAgent?.trainResult?.status === TrainStatus.Error) {
          addAlert({
            type: AlertTypes.ERROR,
            message: 'Публикация тестовой версии бота завершилась с ошибкой',
            description: response.data.stagingAgent?.trainResult?.errorMessage || '',
          });
        }
      }

      if (agent?.productionAgent?.trainResult?.status === TrainStatus.Pending) {
        if (response.data.productionAgent?.trainResult?.status === TrainStatus.Success) {
          addAlert({
            type: AlertTypes.SUCCESS,
            message: 'Публикация релизной версии бота прошла успешно',
            description: '',
          });
        } else if (response.data.productionAgent?.trainResult?.status === TrainStatus.Error) {
          addAlert({
            type: AlertTypes.ERROR,
            message: 'Публикация релизной версии бота завершилась с ошибкой',
            description: response.data.stagingAgent?.trainResult?.errorMessage || '',
          });
        }
      }

      setAgent(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке бота',
        error: e,
      });
    }
  };

  const botPublishEventHandler = (args: { agentStageId: string }) => {
    if (agent?.productionAgent?.id === args?.agentStageId || agent?.stagingAgent?.id === args?.agentStageId) {
      loadAgentDataAsync();
    }
  };
  const subscribe = () => {
    if (!agent || !conn) return;

    conn.on(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);

    return () => {
      conn.off(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
    };
  };
  useEffect(subscribe, [conn, agent]);

  const onPublishRun = () => {
    loadAgentDataAsync();
  };

  const onRetrainRun = (publishStage: PublishStage) => async () => {
    try {
      await agentApi.retrain({
        agentId: agent?.id,
        publishStage,
      });

      loadAgentDataAsync();

      addAlert({
        type: AlertTypes.INFO,
        message: 'Повторная публикация успешно запущена',
        description: '',
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Запуск повторной публикации завершился с ошибкой',
        error: e,
      });
    }
  };

  if (!agent) {
    return <Spin />;
  }

  const onEdit = () => {
    push(`/agents/${agent.id}/edit`);
  };

  const onEditBot = async () => {
    const response = await botVersionApi.getBotEditUrl(id);
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(getEditUrlWithBackUrl(response.data), '_self');
  };

  const onTabChanged = (key: string) => {
    push(`/agents/${agent.id}/${key}`);
  };

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="edit" onClick={onEdit}>
            Редактировать
          </Button>,
          <Button key="edit-bot" type="primary" onClick={onEditBot}>
            Редактировать сценарий
          </Button>,
          <BotVersions key="bot-versions" agent={agent} onPublishRun={onPublishRun} />,
        ]}
        title={
          <Fragment>
            <RobotOutlined /> {agent.name}
          </Fragment>
        }
      />
      <Paragraph>{agent.description || 'Без описания'}</Paragraph>
      <Tabs activeKey={stageType} defaultActiveKey={StageTypes.PRODUCTION} onChange={onTabChanged}>
        <TabPane key={StageTypes.PRODUCTION} tab="Релиз">
          <AgentStagePane
            agentStage={agent.productionAgent}
            botId={agent.id}
            channels={prodChannels}
            onRetrainRun={onRetrainRun(PublishStage.NUMBER_2)}
          />
        </TabPane>
        <TabPane key={StageTypes.STAGING} tab="Тест">
          <AgentStagePane
            agentStage={agent.stagingAgent}
            botId={agent.id}
            channels={stagingChannels}
            onRetrainRun={onRetrainRun(PublishStage.NUMBER_1)}
          />
        </TabPane>
        <TabPane key="test-instances" tab="Тестовые боты">
          <AgentTestInstancesPane agentId={agent.id} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AgentCard;
