import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShareAltOutlined,
  TeamOutlined,
  UserOutlined,
  RobotOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { createElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { MenuInfo } from 'rc-menu/lib/interface';

import { AboutModel } from '../../api';
import { applicationApi } from '../apis';
import { AlertTypes } from '../constants';
import UserInfo from '../components/UserInfo';
import { Logo, LogoElmaBot } from '../assets';
import { alertsSelectorAdd } from '../recoil/alerts';

const { Header, Content, Sider } = Layout;

interface IMenuItem {
  path: string;
  icon: React.ReactNode;
  content: string;
}

const BaseLayout: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['']);
  const [appInfo, setAppInfo] = useState({ name: '', description: '', version: '' } as AboutModel);

  const menuItems: IMenuItem[] = [
    {
      path: '/agents',
      icon: <RobotOutlined />,
      content: 'Боты',
    },
    {
      path: '/simple-bots',
      icon: <RobotOutlined />,
      content: 'Простые боты',
    },
    {
      path: '/campaigns',
      icon: <ShareAltOutlined />,
      content: 'Рассылки',
    },
    {
      path: '/persons',
      icon: <UserOutlined />,
      content: 'Работники',
    },
    {
      path: '/groups',
      icon: <TeamOutlined />,
      content: 'Группы',
    },
    {
      path: '/knowledge-bases',
      icon: <CommentOutlined />,
      content: 'Базы знаний',
    },
  ];

  const onMenuClick = (info: MenuInfo) => {
    const { key } = info;
    setSelectedKeys([key]);
    history.push(key);
  };

  const initSelectedKeys = () => {
    const { pathname } = window.location;

    setSelectedKeys(menuItems.filter((item) => pathname.indexOf(item.path) === 0).map((item) => item.path));
  };
  useEffect(initSelectedKeys, []);

  const loadAppInfoAsync = async () => {
    try {
      const response = await applicationApi.getAboutInfo();
      setAppInfo(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных о приложении',
        error: e,
      });
    }
  };
  const loadAppInfo = () => {
    loadAppInfoAsync();
  };
  useEffect(loadAppInfo, []);

  const onLocationChanged = () => {
    setSelectedKeys(menuItems.filter((item) => location.pathname.indexOf(item.path) === 0).map((item) => item.path));
  };

  useEffect(onLocationChanged, [location]);

  return (
    <Layout className="base-layout">
      <Sider collapsible className="base-layout-sider" collapsed={collapsed} theme="dark" trigger={null}>
        <div className="base-layout-logo-container">
          {collapsed ? (
            <Logo className="base-layout-logo-elma-bot" />
          ) : (
            <LogoElmaBot className="base-layout-logo-elma-bot" />
          )}
        </div>
        <Menu mode="inline" selectedKeys={selectedKeys} theme="dark" onClick={onMenuClick}>
          {menuItems.map((menuItem) => (
            <Menu.Item key={menuItem.path} icon={menuItem.icon}>
              {menuItem.content}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="base-layout-main">
        <Header className="base-layout-header">
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
          <code className="app-info">Version: {appInfo.version}</code>
          <UserInfo />
        </Header>
        <Layout className="base-layout-scrollable">
          <Layout className="base-layout-scrollable-content">
            <Content className="base-layout-content">{children}</Content>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
