import { useLocation } from 'react-router-dom';

import { KnowledgeDataType } from '../../kb-api';

export const getEditUrlWithBackUrl = (editUrl: string): string => {
  const backUrl = new URL(editUrl);
  backUrl.searchParams.append('backUrl', window.location.href);
  return backUrl.href;
};

export const useQuery = (): URLSearchParams => new URLSearchParams(useLocation().search);

export const getGoogleDocType = (
  url: string
): KnowledgeDataType.GoogleDocUrl | KnowledgeDataType.GoogleSpreadSheetUrl | undefined => {
  if (url.includes('document')) return KnowledgeDataType.GoogleDocUrl;
  if (url.includes('spreadsheets')) return KnowledgeDataType.GoogleSpreadSheetUrl;
  return undefined;
};
