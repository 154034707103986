import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Radio, RadioChangeEvent, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import moment, { Moment } from 'moment';
import { useAsync } from 'react-async-hook';

import './index.less';

import { AgentModel, SingleBotModel } from '../../../../../api';
import { AlertTypes, ANALYTICS_REPORT_EXPORT_FINISHED } from '../../../../constants';
import { agentApi, analyticsReportsApi, botApi } from '../../../../apis';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbConversationsAnalytics from '../../../components/analytics/SbConversationsAnalytics';
import SbIntentsAnalytics from '../../../components/analytics/SbIntentsAnalytics';
import SbEscalationsAnalytics from '../../../components/analytics/SbEscalationsAnalytics';
import SbScenarioStartsAnalytics from '../../../components/analytics/SbScenarioStartsAnalytics';
import SbSuccessfullServiceEventsAnalytics from '../../../components/analytics/SbSuccessfullServiceEventsAnalytics';
import SbMenuItemSelectionsAnalytics from '../../../components/analytics/SbMenuItemSelectionsAnalytics';
import SbSpin from '../../../components/common/SbSpin';
import SbDatePicker from '../../../components/common/SbDatePicker';
import SbTabs from '../../../components/common/SbTabs';
import SbTabPane from '../../../components/common/SbTabPane';
import SbScroll from '../../../components/common/SbScroll';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';
import SbPrompterModeEventsAnalytics from '../../../components/analytics/SbPrompterModeEventsAnalytics';
import SbConversationsClosedAnalytics from '../../../components/analytics/SbConversationsClosedAnalytics';
import SbCustomEventsAnalytics from '../../../components/analytics/SbCustomEventsAnalytics';
import SbModal from '../../../components/common/SbModal';
import SbTypography from '../../../components/common/SbTypography';
import SbPanel from '../../../components/common/SbPanel';
import { hubConnections } from '../../../../utils/socketsUtil';
import { ANALYTICS_DATE_FORMATS } from '../../../const';

const DATE_FORMAT = 'DD.MM.YYYY';
enum TabPaneKeys {
  COMMON = 'common',
  ESCALATIONS = 'escalations',
  INTENTS = 'intents',
  SCENARIOS = 'scenarios',
  SUCCESSFULL_SERVICE = 'successfull-service',
  MENU_ITEM_SELECTIONS = 'menu-item-selections',
  PROMPTER_MODE = 'prompter-mode',
  CONVERSATIONS_CLOSED = 'conversations-closed',
  CUSTOM_EVENTS = 'custom-events',
}

interface IAnalyticsReport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
}

const reportDefaultValue: IAnalyticsReport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
};

const AnalyticsCard: React.FC = () => {
  const { push } = useHistory();
  const { id, tabPaneKey } = useParams<{ id: string; tabPaneKey?: string }>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const [currentTabPane, setCurrentTabPane] = useState(tabPaneKey || TabPaneKeys.COMMON);
  const [loading, setLoading] = useState(false);
  const [bot, setBot] = useState<SingleBotModel>();
  const [agent, setAgent] = useState<AgentModel>();
  const [filter, setFilter] = useState<AnalyticsDateFilter>({
    fromDate: moment().add(-6, 'd'),
    toDate: moment(),
  });
  const [viewMode, setViewMode] = useState(AnalyticsViewModes.CHART);
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [report, setReport] = useState(reportDefaultValue);

  const agentStageId = agent?.productionAgent.id;

  const closeExportReportModal = () => {
    setReportModalVisible(false);
    setReport(reportDefaultValue);
  };

  const runExportReportAsync = async () => {
    if (!agentStageId) {
      return;
    }

    setReport({
      ...reportDefaultValue,
      preparing: true,
    });
    setReportModalVisible(true);

    try {
      const response = await analyticsReportsApi.runExportAnalyticsReport(
        agentStageId,
        filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse),
        filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse)
      );

      setReport({
        ...reportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске экспорта данных аналитики',
        error: e,
      });
      closeExportReportModal();
    }
  };

  const loadBotDataAsync = async () => {
    setLoading(true);
    try {
      const botResponse = await botApi.getBot(id);
      const bot = botResponse.data;
      const agentResponse = await agentApi.getAgent(bot.entry.agentId);

      setBot(bot);
      setAgent(agentResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadBotData = () => {
    loadBotDataAsync().finally();
  };
  useEffect(loadBotData, [id]);

  const analyticsReportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (report.requestId !== args?.requestId || !report.preparing) {
      return;
    }

    setReport({
      ...report,
      preparing: false,
      errorMessage: args?.errorMessage || '',
    });

    const fileUrl = args?.fileUrl || '';
    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl);
    }
  };
  const subscribeToAnalyticsReportEvents = () => {
    if (!report.requestId || !conn) return;

    conn.on(ANALYTICS_REPORT_EXPORT_FINISHED, analyticsReportEventHandler);

    return () => {
      conn.off(ANALYTICS_REPORT_EXPORT_FINISHED, analyticsReportEventHandler);
    };
  };
  useEffect(subscribeToAnalyticsReportEvents, [conn, report.requestId]);

  const onTabPaneKeyChange = () => {
    setIsAnimationActive(false);
    setCurrentTabPane(tabPaneKey as TabPaneKeys);
  };
  useEffect(onTabPaneKeyChange, [tabPaneKey]);

  const onBackButtonClick = () => push(`/simple-bots/${id}`);

  const onFromDateChange = (value: Moment | null) => {
    if (value && value <= filter.toDate) {
      setIsAnimationActive(true);
      setFilter({
        toDate: filter.toDate,
        fromDate: value,
      });
    }
  };
  const onToDateChange = (value: Moment | null) => {
    if (value && value >= filter.fromDate) {
      setIsAnimationActive(true);
      setFilter({
        toDate: value,
        fromDate: filter.fromDate,
      });
    }
  };

  const onTabChange = (activeKey: string) => {
    setIsAnimationActive(false);
    setCurrentTabPane(activeKey as TabPaneKeys);
    push(`/simple-bots/${id}/analytics/${activeKey}`);
  };
  const onViewModeChange = ({ target: { value } }: RadioChangeEvent) => setViewMode(value as AnalyticsViewModes);

  const onRefreshButtonClick = () => {
    setIsAnimationActive(true);
    setFilter({ ...filter });
  };

  const onReportDownloadButtonClick = () => {
    runExportReportAsync().finally();
  };

  const onExportModalClose = () => closeExportReportModal();

  const renderTabBarExtraContent = () => (
    <div className="sb-analytics-card__content__filters">
      <Radio.Group
        className="sb-analytics-card__content__filters__view-mode"
        options={[
          { label: <SbIcon iconName="insert-table" size={20} />, value: AnalyticsViewModes.TABLE },
          { label: <SbIcon iconName="chart-histogram-two" size={20} />, value: AnalyticsViewModes.CHART },
        ]}
        optionType="button"
        value={viewMode}
        onChange={onViewModeChange}
      />
      <SbDatePicker format={DATE_FORMAT} picker="date" value={filter.fromDate} onChange={onFromDateChange} />
      <span>&nbsp;-&nbsp;</span>
      <SbDatePicker format={DATE_FORMAT} picker="date" value={filter.toDate} onChange={onToDateChange} />
      <SbButton className="sb-analytics-card__content__filters__action" onClick={onRefreshButtonClick}>
        <SbIcon iconName="redo" />
      </SbButton>
      <SbButton className="sb-analytics-card__content__filters__action" onClick={onReportDownloadButtonClick}>
        <SbIcon iconName="download" />
      </SbButton>
    </div>
  );

  return (
    <div className="sb-analytics-card">
      <div className="sb-analytics-card__header">
        <div className="sb-analytics-card__header__left">
          <SbButton icon={<SbIcon iconName="left" />} sbType="icon-secondary-32" onClick={onBackButtonClick} />
          <div className="sb-analytics-card__header__left__title">
            <Typography.Title ellipsis level={3}>
              Аналитика
            </Typography.Title>
            <Typography.Title ellipsis className="sb-analytics-card__header__left__title__subtitle" level={4}>
              {bot?.entry.name}
            </Typography.Title>
          </div>
        </div>
      </div>
      <div className="sb-analytics-card__content">
        {loading ? (
          <SbSpin />
        ) : (
          agentStageId && (
            <SbTabs
              destroyInactiveTabPane
              activeKey={currentTabPane}
              tabBarExtraContent={renderTabBarExtraContent()}
              onChange={onTabChange}
            >
              <SbTabPane key={TabPaneKeys.COMMON} tab="Общая">
                <SbScroll>
                  <SbConversationsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.ESCALATIONS} tab="Эскалация">
                <SbScroll>
                  <SbEscalationsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.INTENTS} tab="Распознавание">
                <SbScroll>
                  <SbIntentsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.SCENARIOS} tab="Сценарии">
                <SbScroll>
                  <SbScenarioStartsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.SUCCESSFULL_SERVICE} tab="Успешное обслуживание">
                <SbScroll>
                  <SbSuccessfullServiceEventsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.MENU_ITEM_SELECTIONS} tab="Кнопки меню">
                <SbScroll>
                  <SbMenuItemSelectionsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.PROMPTER_MODE} tab="Суфлер">
                <SbScroll>
                  <SbPrompterModeEventsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.CONVERSATIONS_CLOSED} tab="Закрытые беседы">
                <SbScroll>
                  <SbConversationsClosedAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
              <SbTabPane key={TabPaneKeys.CUSTOM_EVENTS} tab="Пользовательские события">
                <SbScroll>
                  <SbCustomEventsAnalytics
                    agentStageId={agentStageId}
                    filter={filter}
                    isAnimationActive={isAnimationActive}
                    viewMode={viewMode}
                  />
                </SbScroll>
              </SbTabPane>
            </SbTabs>
          )
        )}
      </div>
      <SbModal
        className="sb-analytics-card__export-modal"
        footer={[
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onExportModalClose}>
            Закрыть
          </SbButton>,
        ]}
        sbSize="small"
        title="Экспорт отчета по аналитике"
        visible={reportModalVisible}
        width={600}
        onCancel={onExportModalClose}
      >
        <SbTypography>
          {report.preparing ? (
            <SbTypography>
              <h4>Пожалуйста, подождите, идет подготовка отчета...</h4>
            </SbTypography>
          ) : report.errorMessage ? (
            <SbPanel sbType="help">
              <SbTypography>
                <p>{report.errorMessage}</p>
              </SbTypography>
            </SbPanel>
          ) : (
            <SbTypography>
              <h4>Файл отчета успешно сформирован!</h4>
            </SbTypography>
          )}
        </SbTypography>
      </SbModal>
    </div>
  );
};

export default AnalyticsCard;
