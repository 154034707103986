import React, { useEffect, useState } from 'react';
import { List, Tabs } from 'antd';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountModel, AgentModel, PersonModel } from '../../../api';
import { agentStageAccountApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

import AgentAccountListItem from './AgentAccountListItem';

const { TabPane } = Tabs;

export interface IAgentAccountListProps {
  person: PersonModel;
  agent: AgentModel;
  visible: boolean;
}

const AgentAccountList: React.FC<IAgentAccountListProps> = ({ person, agent, visible = false }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [prodAgentAccounts, setProdAgentAccounts] = useState([] as AgentStageAccountModel[]);
  const [stagingAgentAccounts, setStagingAgentAccounts] = useState([] as AgentStageAccountModel[]);

  const loadAgentStageAccountsAsync = async () => {
    setLoading(true);
    try {
      const prodResponse = await agentStageAccountApi.searchAgentStageAccounts(
        undefined,
        agent.productionAgent.id,
        0,
        100
      );
      setProdAgentAccounts(prodResponse.data.items || []);

      const stagingResponse = await agentStageAccountApi.searchAgentStageAccounts(
        undefined,
        agent.stagingAgent.id,
        0,
        100
      );
      setStagingAgentAccounts(stagingResponse.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка каналов бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadAgentStageAccounts = () => {
    if (visible) {
      loadAgentStageAccountsAsync();
    }
  };
  useEffect(loadAgentStageAccounts, [visible]);

  if (!visible) return null;
  return (
    <Tabs defaultActiveKey="production">
      <TabPane key="production" tab="Релиз">
        <List
          dataSource={prodAgentAccounts}
          loading={loading}
          renderItem={(item: AgentStageAccountModel) => (
            <AgentAccountListItem agentStageAccount={item} person={person} />
          )}
          size="small"
        />
      </TabPane>
      <TabPane key="staging" tab="Тест">
        <List
          dataSource={stagingAgentAccounts}
          loading={loading}
          renderItem={(item: AgentStageAccountModel) => (
            <AgentAccountListItem agentStageAccount={item} person={person} />
          )}
          size="small"
        />
      </TabPane>
    </Tabs>
  );
};

export default AgentAccountList;
