import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';

import SbIcon from '../common/SbIcon';
import { getProfile, logoutUser, SIMPLE_BOT_DEFAULT_PATH } from '../../../utils/oidcUtil';

import './index.less';

const onLogoutClick = async () => {
  await logoutUser({ state: SIMPLE_BOT_DEFAULT_PATH });
};

const onSupportClick = (url?: string) => () => {
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  url && window.open(url, '_blank');
};

const acronymize = (text: string) => {
  return text
    .split(/\s/)
    .map((word) => {
      return word.length ? word.charAt(0) : '';
    })
    .join('');
};

const SbUserInfo: React.FC = () => {
  const profile = getProfile();
  const originalHost = profile.originalHost;
  const supportUrl = originalHost ? `https://${originalHost}/messages/support` : undefined;

  const userMenu = (
    <Menu>
      <Menu.Item icon={<SbIcon iconName="logout" />} onClick={onLogoutClick}>
        Выйти
      </Menu.Item>
      {supportUrl && (
        <Menu.Item icon={<SbIcon iconName="lifebuoy" />} onClick={onSupportClick(supportUrl)}>
          Техническая поддержка
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={userMenu} overlayClassName="sb-user-profile-menu" trigger={['click']}>
      <div className="sb-user-profile">
        <Avatar size="large">{acronymize(profile.userName)}</Avatar>
        <SbIcon iconName="down" />
      </div>
    </Dropdown>
  );
};

export default SbUserInfo;
