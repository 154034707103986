import { ResultSelectionPriorityType } from '../../api';

export const tagColorPalette = ['#F5F5F5', '#D9F7BE', '#FFF1B8', '#FFD8BF', '#E6F7FF', '#EFDBFF'];

export const ANIMATION_TIMEOUT = 1000; // 1 c

export const NEW_LINE_DIVIDER = '\n';

export const SCRIPT_EXECUTION_TIMEOUT_DEFAULT = 60; // 60 с

const botAvatarColor = '#096DD9';
const botAvatarSvg = `<?xml version="1.0" encoding="UTF-8"?><svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" fill="black" fill-opacity="0.01"/><rect x="9" y="18" width="30" height="24" rx="2" fill="none" stroke="${botAvatarColor}" stroke-width="4"/><circle cx="17" cy="26" r="2" fill="${botAvatarColor}"/><circle cx="31" cy="26" r="2" fill="${botAvatarColor}"/><path d="M20 32C18.8954 32 18 32.8954 18 34C18 35.1046 18.8954 36 20 36V32ZM28 36C29.1046 36 30 35.1046 30 34C30 32.8954 29.1046 32 28 32V36ZM20 36H28V32H20V36Z" fill="${botAvatarColor}"/><path d="M24 10V18" stroke="${botAvatarColor}" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 26V34" stroke="${botAvatarColor}" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M44 26V34" stroke="${botAvatarColor}" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><circle cx="24" cy="8" r="2" stroke="${botAvatarColor}" stroke-width="4"/></svg>`;
const encodedBotAvatarSvg = btoa(botAvatarSvg);

export const simpleBotWebChatStyleOptions = {
  primaryFont: "'SF Pro Display'",
  bubbleBackground: '#F5F5F5',
  bubbleBorderWidth: 0,
  bubbleBorderRadius: 20,
  bubbleFromUserBackground: '#1890FF',
  bubbleFromUserTextColor: '#FFFFFF',
  bubbleFromUserBorderWidth: 0,
  bubbleFromUserBorderRadius: 20,
  hideUploadButton: false,
  avatarSize: 48,
  botAvatarInitials: '',
  botAvatarImage: `data:image/svg+xml;base64,${encodedBotAvatarSvg}`,
  botAvatarBackgroundColor: '#FAFAFA',
  sendBoxButtonColor: '#FFFFFF',
  sendBoxButtonColorOnDisabled: '#CCCCCC',
  sendBoxButtonColorOnFocus: '#FFFFFF',
  sendBoxButtonColorOnHover: '#FFFFFF',
  sendBoxBorderTop: 'solid 1px #E8E8E8',
  sendBoxBorderBottom: 'solid 1px #E8E8E8',
  sendBoxBorderLeft: 'solid 1px #E8E8E8',
  sendBoxBorderRight: 'solid 1px #E8E8E8',
};

export const ANSWER_SELECTION_PRIORITY_TYPES = [
  { value: ResultSelectionPriorityType.Score, label: 'По Score' },
  { value: ResultSelectionPriorityType.SigmaAnswer, label: 'Приоритет Базы знаний' },
  { value: ResultSelectionPriorityType.OmegaIntent, label: 'Приоритет распознавания интентов' },
];

export const DIALOGS_COUNT_TAG_COLOR = '#E6F7FF';
export const INTERRUPTING_TAG_COLOR = '#E6F7FF';
export const SUBSTITUTE_TAG_COLOR = '#EFDBFF';
export const CHILD_DATA_ENTRIES_TAG_COLOR = '#E8E8E8';
export const QUESTIONS_TAG_COLOR_DEFAULT = '#D9F7BE';
export const QUESTIONS_TAG_COLOR_WARNING = '#FA541C32';
export const VARIABLE_TAG_COLOR_DEFAULT = '#E8E8E8';
export const VARIABLE_TAG_COLOR_CAUTION = '#FFDEDE';

export const SUBSTITUTE_DESCRIPTION =
  'Этот тип сценария может выполняться независимо от того, выполняется в данный момент другой сценарий или нет. В случае замещения текущий сценарий прекратит выполняться.';

export const INTERRUPTING_DESCRIPTION =
  'Этот тип сценария может выполняться независимо от того, выполняется в данный момент другой сценарий или нет. Текущий сценарий продолжит выполняться после выполнения данного сценария.';

export const FROM_SCRATCH_BOT_TEMPLATE_CODE = 'fromScratch';

export const ELMA365_HELP_URL = 'https://elma365.com/ru/help/elma-bot.html';

export const DEFAULT_INTENT_THRESHOLD = 0.6;

export const LOCALE_NAMES = {
  default: 'ru-ru',
  russian: 'ru-ru',
  english: 'en-us',
};

export const ANALYTICS_DATE_FORMATS = {
  parse: 'YYYY-MM-DD',
  view: 'DD.MM.YYYY',
  viewCompact: 'DD.MM',
};

export const NAME_FIELD_NAME = 'name';
