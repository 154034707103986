import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider, Input, Popover, Typography } from 'antd';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { Key } from 'ts-key-enum';

import './index.less';

import {
  BotStageModel,
  BotStageType,
  DefaultScenarioSchema,
  SingleBotModel,
  UpdateScenarioOperationType,
} from '../../../../../api';
import { AlertTypes } from '../../../../constants';
import { botApi, botStageApi, groupEntryApi, intentEntryApi } from '../../../../apis';
import ScenarioEditor from '../../../../components/ScenarioEditor';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import {
  botSelector,
  currentScenarioStructureSelector,
  dispatcherState,
  scenarioStructureStackSelectorCanRedo,
  scenarioStructureStackSelectorCanUndo,
  systemIntentGroupsSelector,
  systemIntentsSelector,
} from '../../../../recoil/scenarioStructure';
import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import { currentBotStageTypeSelector } from '../../../recoil';
import SbModal from '../../../components/common/SbModal';
import SbTypography from '../../../components/common/SbTypography';
import { lruLocalStorage } from '../../../../utils/localStorageUtil';

import SaveButton from './SaveButton';
import TestButton from './TestButton';

const SYSTEM_INTENTS_COUNT = 1000;
const SYSTEM_INTENT_GROUPS_COUNT = 1000;

const ScenarioCard: React.FC = () => {
  const { push } = useHistory();
  const { id, scenarioStructureId } = useParams<{ scenarioStructureId: string; id: string }>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const setSystemIntents = useSetRecoilState(systemIntentsSelector);
  const setSystemIntentGroups = useSetRecoilState(systemIntentGroupsSelector);
  const setCurrentScenarioStructure = useSetRecoilState(currentScenarioStructureSelector);
  const resetCurrentScenarioStructure = useResetRecoilState(currentScenarioStructureSelector);
  const { resetSupportingData, undo, redo } = useRecoilValue(dispatcherState);
  const canUndo = useRecoilValue(scenarioStructureStackSelectorCanUndo);
  const canRedo = useRecoilValue(scenarioStructureStackSelectorCanRedo);
  const [currentBotStageType, setCurrentBotStageType] = useRecoilState(currentBotStageTypeSelector(id));

  const [draftCreationModalVisible, setDraftCreationModalVisible] = useState(false);
  const [draftCreatedModalVisible, setDraftCreatedModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [botDraftCreating, setBotDraftCreating] = useState(false);
  const [bot, setBot] = useRecoilState(botSelector);

  const isDraft = currentBotStageType === BotStageType.Draft;
  const botStage = isDraft ? bot?.draftStage : bot?.originStage;
  const scenario = botStage?.currentEdition.scenarios.find((s) => s.scenarioStructureId === scenarioStructureId);
  const [titleIsEditing, setTitleIsEditing] = useState(false);
  const [title, setTitle] = useState('');

  const loadSystemIntentDataAsync = async () => {
    setLoading(true);

    try {
      const response = await intentEntryApi.searchIntentEntries({}, 0, SYSTEM_INTENTS_COUNT);
      setSystemIntents(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке системных интентов',
        error: e,
      });
    }

    try {
      const response = await groupEntryApi.searchIntentGroupEntries({}, 0, SYSTEM_INTENT_GROUPS_COUNT);
      setSystemIntentGroups(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке групп системных интентов',
        error: e,
      });
    }

    setLoading(false);
  };

  const onInit = () => {
    resetSupportingData?.();
    resetCurrentScenarioStructure();
    loadSystemIntentDataAsync().finally();
    return () => {
      resetCurrentScenarioStructure();
    };
  };

  useEffect(onInit, []);

  const onBotStageChange = () => () => {
    botStage && lruLocalStorage.deleteScenarioStructure(botStage.id, scenarioStructureId);
  };
  useEffect(onBotStageChange, [botStage]);

  const loadBotDataAsync = async (checkLastScenarioStructure = true) => {
    setLoading(true);
    try {
      const botResponse = await botApi.getBot(id);
      const bot = botResponse.data;
      setBot(bot);

      const botStage = isDraft && bot.draftStage ? bot.draftStage : bot.originStage;
      const scenarios = botStage.currentEdition.scenarios;
      const scenario = scenarios.find((s) => s.scenarioStructureId === scenarioStructureId);
      if (!scenario) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Сценарий не найден',
          description: scenarioStructureId,
        });
        return;
      }
      setTitle(scenario.name);

      const scenarioResponse = await botStageApi.getScenario(botStage.id, scenario.scenarioStructureId);
      const scenarioStructure = scenarioResponse.data.structure;
      const lastScenarioStructure = lruLocalStorage.getScenarioStructure(botStage.id, scenarioStructureId);
      setCurrentScenarioStructure(scenarioStructure);
      await resetSupportingData?.();

      if (checkLastScenarioStructure && lastScenarioStructure) {
        setTimeout(() => setCurrentScenarioStructure(lastScenarioStructure as DefaultScenarioSchema));
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadBotData = () => {
    loadBotDataAsync().finally();
  };
  useEffect(loadBotData, [id, scenarioStructureId]);

  const onBackButtonClick = () => push(`/simple-bots/${id}`);

  const onUndoButtonClick = async () => await undo();

  const onRedoButtonClick = async () => await redo();

  const onBotStageChanged = (botStage: BotStageModel) => {
    if (!bot) return;

    const newBot = { ...bot };
    if (isDraft) {
      newBot.draftStage = botStage;
    } else {
      newBot.originStage = botStage;
    }
    setBot(newBot);
  };

  const onScenarioReloadNeeded = async () => {
    await loadBotDataAsync(false);
  };

  const onCreateBotDraftButtonClick = () => setDraftCreationModalVisible(true);

  const onConfirmDraftCreation = async () => {
    if (!bot) return;

    setBotDraftCreating(true);
    try {
      const response = await botStageApi.createDraftBotStage(bot.originStage.id);
      setBot({
        ...bot,
        draftStage: response.data,
      } as SingleBotModel);

      setDraftCreationModalVisible(false);
      setDraftCreatedModalVisible(true);
      setCurrentBotStageType(BotStageType.Draft);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании черновика бота',
        error: e,
      });
    }
    setBotDraftCreating(false);
  };

  const onCancelDraftCreationModal = () => setDraftCreationModalVisible(false);

  const onCloseDraftCreatedModal = () => setDraftCreatedModalVisible(false);

  const tryRenameScenario = async () => {
    setTitleIsEditing(false);

    if (!scenario) return;
    if (!botStage) return;

    if (!title) {
      setTitle(scenario.name);
      return;
    }

    if (title === scenario.name) return;

    try {
      await botStageApi.updateScenario(botStage.id, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.Rename,
        name: title,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении названия сценария',
        error: e,
      });
    }
  };

  const onScenarioTitleDoubleClick = () => setTitleIsEditing(true);

  const onScenarioTitleInputBlur = () => tryRenameScenario();

  const onScenarioTitleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setTitle(e.target.value);
  };

  const onScenarioTitleInputFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.select();
  };

  const onScenarioTitleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      tryRenameScenario();
      return;
    }
    if (e.key === Key.Escape) {
      if (!scenario) return;
      setTitle(scenario.name);
      setTitleIsEditing(false);
    }
  };

  const onScenarioTitlePencilClick = () => {
    setTitleIsEditing(true);
  };

  return (
    <div className="sb-scenario-card">
      <div className="sb-scenario-card__header">
        <div className="sb-scenario-card__header__left">
          <SbButton
            icon={<SbIcon iconName="left" />}
            sbType="icon-secondary-32"
            style={{ marginRight: 10 }}
            onClick={onBackButtonClick}
          />
          <div className="sb-scenario-card__header__left__title">
            <Typography.Title ellipsis level={3}>
              <div onDoubleClick={onScenarioTitleDoubleClick}>
                {titleIsEditing ? (
                  <Input
                    autoFocus
                    value={title}
                    onBlur={onScenarioTitleInputBlur}
                    onChange={onScenarioTitleInputChange}
                    onFocus={onScenarioTitleInputFocus}
                    onKeyDown={onScenarioTitleInputKeyDown}
                  />
                ) : (
                  <>
                    <span>{title}</span>
                    <SbIcon
                      className="sb-scenario-card__header__left__title__pencil"
                      iconName="pencil"
                      size={15}
                      onClick={onScenarioTitlePencilClick}
                    />
                  </>
                )}
              </div>
            </Typography.Title>
            <Typography.Title
              ellipsis
              className={!isDraft ? 'sb-scenario-card__header__left__title__subtitle_origin' : undefined}
              level={4}
            >
              {isDraft ? (
                <>
                  Бот-черновик
                  <Popover
                    align={{ offset: [0, -8] }}
                    content={
                      <p>
                        Вы редактируете черновик бота. Изменения в черновике не отобразятся в связанных мессенджерах.
                      </p>
                    }
                    placement="rightTop"
                  >
                    <SbIcon iconName="info" size={16} />
                  </Popover>
                </>
              ) : (
                <>
                  <SbIcon iconName="attention" size={16} />
                  {bot?.draftStage ? 'Действующий бот. ' : 'Вы редактируете действующий бот. '}
                  Все изменения отобразятся в связанных мессенджерах.
                  {!bot?.draftStage && (
                    <SbButton sbSize="medium" sbType="tertiary" onClick={onCreateBotDraftButtonClick}>
                      Создать черновик
                    </SbButton>
                  )}
                </>
              )}
            </Typography.Title>
          </div>
        </div>
        <div className="sb-scenario-card__header__right">
          <SbButton
            disabled={!canUndo}
            icon={<SbIcon iconName="undo" />}
            sbType="icon-primary-48"
            style={{ marginRight: 12 }}
            onClick={onUndoButtonClick}
          />
          <SbButton
            disabled={!canRedo}
            icon={<SbIcon iconName="redo" />}
            sbType="icon-primary-48"
            onClick={onRedoButtonClick}
          />
          <Divider className="sb-scenario-card__header__right__divider" type="vertical" />
          <div className="sb-scenario-card__header__right__test-button-wrapper">
            <TestButton botName={bot?.entry.name} botStage={botStage} scenario={scenario} />
          </div>
          <SaveButton
            botStage={botStage}
            scenario={scenario}
            onBotStageChanged={onBotStageChanged}
            onScenarioReloadNeeded={onScenarioReloadNeeded}
          />
        </div>
      </div>
      <div className="sb-scenario-card__content" style={{ opacity: loading ? 0.2 : 1 }}>
        <ScenarioEditor />
      </div>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCancelDraftCreationModal}>
            Закрыть
          </SbButton>,
          <SbButton key="create" sbSize="medium" sbType="icon-secondary" onClick={onConfirmDraftCreation}>
            {botDraftCreating && <SbIcon spin iconName="loading-four" size={16} />}
            Да, создать черновик бота
          </SbButton>,
        ]}
        sbSize="small"
        title="Создать черновик бота"
        visible={draftCreationModalVisible}
        width={496}
        onCancel={onCancelDraftCreationModal}
        onOk={onConfirmDraftCreation}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">Вы действительно хотите создать черновик бота?</p>
        </SbTypography>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCloseDraftCreatedModal}>
            Закрыть
          </SbButton>,
        ]}
        sbSize="small"
        title="Черновик создан"
        visible={draftCreatedModalVisible}
        width={496}
        onCancel={onCloseDraftCreatedModal}
        onOk={onCloseDraftCreatedModal}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">
            Теперь можно экспериментировать в этой версии бота. Оригинальная версия продолжит работать, если подключена.
          </p>
        </SbTypography>
      </SbModal>
    </div>
  );
};

export default ScenarioCard;
