import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Space, Button, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountModel, AgentStageAccountUpdatingRequest } from '../../../../api';
import { AlertTypes, Channels, XTOKEN } from '../../../constants';
import { agentStageAccountApi } from '../../../apis';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Title } = Typography;

const ELMA365ChannelEdit: React.FC = () => {
  const { id, accountId } = useParams<{ id: string; accountId: string }>();
  const { goBack } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [existingAccount, setExistingAccount] = useState<AgentStageAccountModel>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, unknown>>();
  const [error, setError] = useState<string>();

  const loadExistingAccountAsync = async () => {
    const existingAccount = (await agentStageAccountApi.getAgentStageAccount(accountId)).data;
    form.setFieldsValue({
      [XTOKEN]: existingAccount?.properties ? existingAccount.properties[XTOKEN] : 'lalala',
      displayName: existingAccount?.displayName,
    });
    setExistingAccount(existingAccount);
  };

  const loadExistingAccount = () => {
    loadExistingAccountAsync();
  };

  useEffect(loadExistingAccount, [accountId]);

  const onFinish = async () => {
    try {
      setLoading(true);

      const request: AgentStageAccountUpdatingRequest = {
        agentStageId: id,
        displayName: form.getFieldValue('displayName'),
        authToken: existingAccount?.authToken ?? '',
        channelId: Channels.ELMA365,
        properties: { [XTOKEN]: form.getFieldValue('xToken') },
      };

      await agentStageAccountApi.updateAgentStageAccount(accountId, request);

      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Линия сохранена',
        description: 'ELMA365',
      });
      goBack();
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();
      setErrors(errors);
      setError(errorText);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item help={error} validateStatus={error ? 'error' : undefined}>
          <Title level={4}>Редактировать свойства линии ELMA365</Title>
        </Form.Item>
        <Form.Item label="Отображаемое наименование" name="displayName">
          <Input placeholder="Отображаемое наименование линии" />
        </Form.Item>
        <Form.Item
          help={errors?.XToken ? 'Некорректный X-Token' : undefined}
          label="X-Token ELMA365"
          name="xToken"
          rules={[{ required: false, message: 'Введите токен' }]}
          validateStatus={errors?.XToken ? 'error' : undefined}
        >
          <Input placeholder="Токен авторизации ELMA365" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={goBack}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ELMA365ChannelEdit;
