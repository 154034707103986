import React, { ChangeEvent, ReactNode, KeyboardEvent } from 'react';
import { Input } from 'antd';

import './index.less';
import SbIcon from '../SbIcon';

import SbPassword, { ISbPasswordProps } from './SbPassword';

interface ISbInputProps {
  id?: string;
  sbSize?: 'big' | 'small';
  placeholder?: string;
  value?: string;
  required?: boolean;
  isValid?: boolean;
  style?: React.CSSProperties;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  allowClear?: boolean;
  customSuffix?: ReactNode;
  disabled?: boolean;
  maxLength?: number;
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const SbInput: React.FC<ISbInputProps> & { Password: React.FC<ISbPasswordProps> } = ({
  id,
  sbSize = 'big',
  placeholder,
  value,
  required,
  isValid,
  style,
  onBlur,
  onChange = () => {},
  onFocus,
  allowClear = true,
  customSuffix,
  disabled,
  maxLength,
  onPressEnter,
}) => {
  const classes = ['sb-input'];
  if (sbSize) {
    classes.push(`sb-input_size-${sbSize}`);
  }
  if ((value && allowClear) || customSuffix) {
    classes.push('sb-input_with-suffix');
  }
  if (isValid === false) {
    classes.push('sb-input_warning');
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onClearButtonClick = () => {
    onChange('');
  };

  const suffix = customSuffix ? (
    customSuffix
  ) : allowClear ? (
    <button onClick={onClearButtonClick}>
      <SbIcon iconName="handle-x" size={20} />
    </button>
  ) : undefined;

  return (
    <Input
      className={classes.join(' ')}
      disabled={disabled}
      id={id}
      maxLength={maxLength}
      placeholder={placeholder}
      required={required}
      style={style}
      suffix={suffix}
      value={value}
      onBlur={onBlur}
      onChange={onInputChange}
      onFocus={onFocus}
      onPressEnter={onPressEnter}
    />
  );
};

SbInput.Password = SbPassword;

export default SbInput;
