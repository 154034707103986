export const NOTIFICATIONS_HUB_ENDPOINT = '/api/notifications';

export const PERSON_UPDATED = 'personUpdated';

export const CAMPAIGN_JOB_UPDATED = 'campaignJobUpdated';

export const BOT_PUBLISH_STATUS_UPDATED = 'botPublishStatusUpdated';

export const KNOWLEDGE_SOURCE_PARSED = 'knowledgeSourceParsed';

export const INSTANCE_PUBLISHED = 'instancePublished';

export const INSTANCE_STATUS_UPDATED = 'instanceStatusUpdated';

export const ANALYTICS_REPORT_EXPORT_FINISHED = 'analyticsReportExportFinished';
