import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr';

import { NOTIFICATIONS_HUB_ENDPOINT } from '../constants';

import { getProfile } from './oidcUtil';
import { getConfig } from './configUtil';

const createHubConnection = (url: string) => {
  const accessTokenFactory = () => {
    const profile = getProfile();
    return profile.accessToken;
  };
  const options: IHttpConnectionOptions = {
    logger: LogLevel.Error,
    accessTokenFactory,
    transport: HttpTransportType.LongPolling, // todo: убрать как только настроят nginx
  };
  return new HubConnectionBuilder().withUrl(url, options).withAutomaticReconnect().build();
};

class HubConnections {
  private botManagerConnection?: HubConnection;
  private knowledgeBaseConnection?: HubConnection;

  public getBotManagerConnection = async (): Promise<HubConnection> => {
    if (!this.botManagerConnection) {
      const connection = createHubConnection(NOTIFICATIONS_HUB_ENDPOINT);
      await connection.start();
      this.botManagerConnection = connection;
    }
    return this.botManagerConnection;
  };

  public getKnowledgeBaseConnection = async (): Promise<HubConnection> => {
    if (!this.knowledgeBaseConnection) {
      const config = await getConfig();
      const basePath = config?.knowledgeBaseService?.basePath;
      const connection = createHubConnection(`${basePath}${NOTIFICATIONS_HUB_ENDPOINT}`);
      await connection.start();
      this.knowledgeBaseConnection = connection;
    }
    return this.knowledgeBaseConnection;
  };
}

export const hubConnections = new HubConnections();
