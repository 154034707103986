import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Button, List } from 'antd';
import { v4 } from 'uuid';
import Editor from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import { CallbackInterface, useRecoilCallback, useSetRecoilState } from 'recoil';
import { RcFile } from 'antd/es/upload';

import './index.less';

import { IEmoji } from '../../types';
import { EmojiIcon, VariableIcon } from '../../assets';
import { AttachmentSchema, SchemaKind, VariableSchema } from '../../../../../api';
import DropdownMenu from '../common/DropdownMenu';
import { generateId, useEventListener } from '../../utils';
import VariableSelector from '../VariableSelector';
import { addImage, editorStateToMarkdown } from '../../../../simple-bot/utils/markdownEditor';
import SbUpload from '../../../../simple-bot/components/common/SbUpload';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { fileStorageApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import SbTag from '../../../../simple-bot/components/common/SbTag';
import { base64ContentToFormFile } from '../../../../utils/fileUtil';
import { variableUsagesSelector } from '../../../../recoil/scenarioStructure';

import { Emojis, MAIN_CLASS_NAME } from './constants';
import {
  handleEditorBeforeInput,
  handleEditorPastedText,
  handleEditorReturn,
  insertEntity,
  markdownToEditorState,
} from './utils';
import { EntityType, TooltipMode } from './types';
import InlineToolbar from './InlineToolbar';

const TOOLBAR_CLASS_NAME = `${MAIN_CLASS_NAME}__toolbar`;
const TOOLBAR_ACTIONS_CLASS_NAME = `${TOOLBAR_CLASS_NAME}__actions`;
const TOOLBAR_ACTIONS_BUTTON_CLASS_NAME = `${TOOLBAR_ACTIONS_CLASS_NAME}__button`;

const ATTACHMENTS_CLASS_NAME = `${MAIN_CLASS_NAME}__attachments`;
const ATTACHMENT_CONTENT_CLASS_NAME = `${ATTACHMENTS_CLASS_NAME}__content`;
const ATTACHMENT_TAG_COLOR = '#D9F7BE';

interface IBotAnswerEditorProps {
  value: string;
  attachments: AttachmentSchema[];
  variables: VariableSchema[];
  onVariablesChange?: (variables: VariableSchema[]) => void;
  onAnswerChange?: (value: string) => void;
  onAddAnswer?: () => void;
  onDeleteAnswer?: () => void;
  onAttachmentsChange: (attachments: AttachmentSchema[]) => void;
  onFocus?: (e: MouseEvent<HTMLDivElement>) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  useScenarioEditorZoom?: boolean;
  placeholder?: string;
}

const BotAnswerEditor: React.FC<IBotAnswerEditorProps> = ({
  value,
  attachments,
  variables,
  onVariablesChange = () => {},
  onAnswerChange = () => {},
  onAddAnswer = () => {},
  onDeleteAnswer = () => {},
  onAttachmentsChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  readOnly = false,
  useScenarioEditorZoom = true,
  placeholder,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [variablesVisible, setVariablesVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipMode, setTooltipMode] = useState<TooltipMode>();
  const [editorState, setEditorState] = useState(markdownToEditorState(value, variables));
  const [inlineToolbarVisible, setInlineToolbarVisible] = useState(false);

  const { current: editorId } = useRef(v4());
  const toolbarRef = useRef(null);
  const editorRef = useRef<Editor>(null);

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const handleClickOutside = (event: Event) => {
    if (readOnly || variablesVisible || tooltipVisible) return;

    const editor = document.getElementById(editorId);
    if (!editor?.contains(event.target as Node)) {
      editorRef.current?.blur();
      setInlineToolbarVisible(false);
      onBlur();
    }
  };
  useEventListener('mousedown', handleClickOutside);

  const onReadOnlyPropChange = () => {
    if (!readOnly) {
      // NOTE: исправлена автоматическая установка фокуса в редакторе при клике
      setTimeout(() => {
        editorRef.current?.focus();
      }, 50);
    } else {
      setTooltipVisible(false);
    }
  };
  useEffect(onReadOnlyPropChange, [readOnly]);

  const onValuePropChange = () => {
    if (readOnly) {
      setEditorState(markdownToEditorState(value, variables));
    }
  };
  useEffect(onValuePropChange, [value]);

  const onVariablesPropChange = () => setEditorState(markdownToEditorState(value, variables));
  useEffect(onVariablesPropChange, [variables]);

  const onEditorStateChange = () => {
    if (!readOnly) {
      onAnswerChange(editorStateToMarkdown(editorState));
    }
  };
  useEffect(onEditorStateChange, [editorState]);

  const onEditorChange = (newState: EditorState) => {
    const selection = newState.getSelection();
    setInlineToolbarVisible(!selection.isCollapsed());
    setEditorState(newState);
  };

  const onInlineToolbarChange = (newState: EditorState) => {
    setEditorState(newState);
    setInlineToolbarVisible(false);
  };

  const onShowVariablesButtonClick = () => {
    setTooltipMode(TooltipMode.VARIABLES);
    setTooltipVisible(true);
    setInlineToolbarVisible(false);
  };

  const onShowEmojisButtonClick = () => {
    setTooltipMode(TooltipMode.EMOJIS);
    setTooltipVisible(true);
    setInlineToolbarVisible(false);
  };

  const onAddButtonClick = () => {
    onAddAnswer?.();
    setInlineToolbarVisible(false);
  };

  const onDeleteButtonClick = () => {
    onDeleteAnswer?.();
  };

  const onVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    if (Array.isArray(newVariables)) onVariablesChange(newVariables);
    if (selectedVariable) setEditorState(insertEntity(editorState, `{${selectedVariable.id}}`, EntityType.VARIABLE));
  };

  const onVariableSelectorSettingsModalOpen = () => setVariablesVisible(true);

  const onVariableSelectorClose = () => {
    setVariablesVisible(false);
    setTooltipVisible(false);
  };

  const onEmojiSelect = (emoji: IEmoji) => {
    setEditorState(insertEntity(editorState, emoji.value, EntityType.EMOJI));
    setTooltipVisible(false);
  };

  const onAttachmentFileUpload = async (file: RcFile, base64Content: string) => {
    try {
      const formFile = base64ContentToFormFile(file, base64Content);
      const response = await fileStorageApi.uploadBotStaticFile(formFile);
      const newAttachments = [...attachments];
      newAttachments.push({
        id: generateId('ATT'),
        $kind: SchemaKind.Attachment,
        fileId: response.data.id,
        name: file.name,
        contentType: file.type || 'application/octet-stream',
      });
      onAttachmentsChange(newAttachments);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке файла',
        error: e,
      });
    }
  };

  const onAttachmentDelete = (index: number) => () => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    onAttachmentsChange(newAttachments);
  };

  const onImageFileUpload = async (file: RcFile, base64Content: string) => {
    try {
      const formFile = base64ContentToFormFile(file, base64Content);
      const response = await fileStorageApi.uploadBotStaticFile(formFile);
      setEditorState(addImage(editorState, response.data.url, file.name));
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке файла',
        error: e,
      });
    }
  };

  const renderTooltipContent = () => {
    switch (tooltipMode) {
      case TooltipMode.VARIABLES:
        return (
          <VariableSelector
            getVariableUsages={getVariableUsages}
            variables={variables}
            onChange={onVariableSelectorChange}
            onClose={onVariableSelectorClose}
            onSettingsModalOpen={onVariableSelectorSettingsModalOpen}
          />
        );
      case TooltipMode.EMOJIS:
        return (
          <List
            dataSource={Emojis}
            grid={{ column: 6 }}
            renderItem={(item) => (
              <List.Item key={item.id} style={{ cursor: 'pointer' }} onClick={() => onEmojiSelect(item)}>
                {item.value}
              </List.Item>
            )}
            rowKey={(item) => item.id}
            split={false}
            style={{ paddingLeft: 12 }}
          />
        );
      default:
        return null;
    }
  };

  const classes = [MAIN_CLASS_NAME];
  if (readOnly) {
    classes.push(`${MAIN_CLASS_NAME}_read-only`);
  }

  return (
    <div className={classes.join(' ')} id={editorId} role="none" onMouseDown={onFocus}>
      <Editor
        ref={editorRef}
        spellCheck
        editorState={editorState}
        handleBeforeInput={handleEditorBeforeInput(setEditorState)}
        handlePastedText={handleEditorPastedText(setEditorState)}
        handleReturn={handleEditorReturn(setEditorState, onBlur)}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onEditorChange}
      />
      {inlineToolbarVisible && (
        <InlineToolbar
          editorRef={editorRef}
          editorState={editorState}
          useScenarioEditorZoom={useScenarioEditorZoom}
          onChange={onInlineToolbarChange}
        />
      )}
      <div className={ATTACHMENTS_CLASS_NAME}>
        {attachments.map((attachment, index) => (
          <SbTag
            key={attachment.id}
            color={ATTACHMENT_TAG_COLOR}
            sbSize="small"
            text={
              <span className={ATTACHMENT_CONTENT_CLASS_NAME}>
                <SbIcon iconName="paperclip" size={16} />
                <span>{attachment.name}</span>
              </span>
            }
            onDelete={readOnly ? undefined : onAttachmentDelete(index)}
          />
        ))}
      </div>
      <div ref={toolbarRef} className={TOOLBAR_CLASS_NAME}>
        <DropdownMenu
          isVisible={tooltipVisible}
          placement="right"
          size="small"
          triggerElement={toolbarRef.current}
          useScenarioEditorZoom={useScenarioEditorZoom}
          width={235}
          onClose={() => setTooltipVisible(false)}
        >
          {renderTooltipContent()}
        </DropdownMenu>
        <div className={TOOLBAR_ACTIONS_CLASS_NAME}>
          <Button className={TOOLBAR_ACTIONS_BUTTON_CLASS_NAME} type="link" onClick={onShowEmojisButtonClick}>
            <EmojiIcon />
          </Button>
          <Button className={TOOLBAR_ACTIONS_BUTTON_CLASS_NAME} type="link">
            <SbUpload accept=".jpg,.jpeg,.png" onFileUpload={onImageFileUpload}>
              <SbIcon iconName="picture-one" size={16} />
            </SbUpload>
          </Button>
          <Button className={TOOLBAR_ACTIONS_BUTTON_CLASS_NAME} type="link" onClick={onShowVariablesButtonClick}>
            <VariableIcon />
          </Button>
          <Button className={TOOLBAR_ACTIONS_BUTTON_CLASS_NAME} type="link">
            <SbUpload onFileUpload={onAttachmentFileUpload}>
              <SbIcon iconName="paperclip" size={16} />
            </SbUpload>
          </Button>
          <Button className={TOOLBAR_ACTIONS_BUTTON_CLASS_NAME} type="link" onClick={onAddButtonClick}>
            <span>Добавить вариант</span>
          </Button>
          <Button
            className={`${TOOLBAR_ACTIONS_BUTTON_CLASS_NAME} ${TOOLBAR_ACTIONS_BUTTON_CLASS_NAME}_delete`}
            type="link"
            onClick={onDeleteButtonClick}
          >
            <span>Удалить</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BotAnswerEditor;
