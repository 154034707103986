import { Layout } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import './index.less';

import { LogoElmaBotBlack } from '../../../assets';
import SbScroll from '../../components/common/SbScroll';
import SbTabPane from '../../components/common/SbTabPane';
import SbTabs from '../../components/common/SbTabs';
import SbUserInfo from '../../components/SbUserInfo';
import SbBrowserUnsupportedModal from '../../components/SbBrowserUnsupportedModal';
import { webchatModalSelector } from '../../../recoil/modals';
import SbWebChat from '../../components/SbWebChat';
import { ELMA365_HELP_URL } from '../../const';

const { Header, Content } = Layout;

const SimpleBotBaseLayout: React.FC = (props) => {
  const { children } = props;

  const webchatModalState = useRecoilValue(webchatModalSelector);
  const resetWebchatModalState = useResetRecoilState(webchatModalSelector);

  const { push } = useHistory();

  const webchatComponent = webchatModalState && (
    <div className="sb-layout__webchat-container">
      <SbWebChat
        subTitle="Тестовый виджет"
        title={webchatModalState.title}
        webchatUrl={webchatModalState.webchatUrl}
        onClose={() => {
          resetWebchatModalState();
        }}
      />
    </div>
  );

  const onLogoClick = () => {
    push('/simple-bots');
  };

  return (
    <Layout className="sb-layout">
      <Layout className="sb-layout-main">
        <Header className="sb-layout__header">
          <div className="sb-layout__header__container">
            <div className="sb-layout__header__logo" onClick={onLogoClick}>
              <LogoElmaBotBlack className="sb-layout__header__logo__elma-bot" />
            </div>
            <div className="sb-layout__header__menu">
              <SbTabs destroyInactiveTabPane activeKey="bots">
                <SbTabPane key="bots" tab="Чат-боты" />
                <SbTabPane
                  key="help"
                  tab={
                    <a href={ELMA365_HELP_URL} rel="noreferrer" target="_blank">
                      Помощь
                    </a>
                  }
                />
              </SbTabs>
            </div>
            <div className="sb-layout__header__profile">
              <SbUserInfo />
            </div>
          </div>
        </Header>
        <Layout className="sb-layout-scrollable">
          <Layout className="sb-layout-scrollable-content">
            <SbScroll>
              <Content className="sb-layout-content">{children}</Content>
            </SbScroll>
          </Layout>
        </Layout>
      </Layout>
      {webchatComponent}
      <SbBrowserUnsupportedModal />
    </Layout>
  );
};

export default SimpleBotBaseLayout;
