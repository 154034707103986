import React, { memo, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import numberToWordsRu from 'number-to-words-ru';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

import './index.less';

import SbButton from '../../../../simple-bot/components/common/SbButton';
import {
  currentBotStageIdSelector,
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  dispatcherState,
  scenarioStructuresSelector,
  selectedEntitySelector,
  selectedValidationIssueSelector,
} from '../../../../recoil/scenarioStructure';
import SbTooltip from '../../../../simple-bot/components/common/SbTooltip';
import { currentScenarioValidationStatusSelector } from '../../../../simple-bot/recoil';
import { validateScenario, ValidationStatus } from '../../../../simple-bot/utils/validation';
import { IndexedStructure } from '../../../../simple-bot/utils/indexation';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { AlertTypes } from '../../../../constants';

const ScenarioValidation: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const selectedEntity = useRecoilValue(selectedEntitySelector);
  const botStageId = useRecoilValue(currentBotStageIdSelector);
  const currentScenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const scenarioStructures = useRecoilValue(scenarioStructuresSelector);

  const [currentScenarioValidationStatus, setCurrentScenarioValidationStatus] = useRecoilState(
    currentScenarioValidationStatusSelector
  );
  const [currentScenarioValidationResult, setCurrentScenarioValidationResult] = useRecoilState(
    currentScenarioValidationResultSelector
  );
  const [selectedValidationIssue, setSelectedValidationIssue] = useRecoilState(selectedValidationIssueSelector);
  const { focusToElement } = useRecoilValue(dispatcherState);

  const visibleIssueList = currentScenarioValidationResult?.getVisibleIssueList() || [];
  const currentIssueIndex = visibleIssueList.findIndex((issue) => selectedValidationIssue === issue);

  const validateStructureAsync = async () => {
    if (!currentScenarioStructure || !botStageId) {
      return;
    }

    setCurrentScenarioValidationStatus(ValidationStatus.Validating);
    try {
      const indexedStructure = new IndexedStructure(currentScenarioStructure, scenarioStructures);
      indexedStructure.indexEntitySchema(currentScenarioStructure);

      const validationResult = await validateScenario(indexedStructure, currentScenarioStructure, botStageId);
      setCurrentScenarioValidationResult(validationResult);

      if (selectedValidationIssue) {
        const validationIssues = validationResult.getIssues(
          selectedValidationIssue.entity.id,
          selectedValidationIssue.fieldPath,
          selectedValidationIssue.ruleResult.code
        );
        setSelectedValidationIssue(validationIssues[0]);
      } else if (selectedEntity) {
        const entityIssues = validationResult.issueMap.get(selectedEntity.id);
        const validationIssue = entityIssues?.length ? entityIssues[0] : undefined;
        validationIssue && setSelectedValidationIssue(validationIssue);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при выполнении проверки сценария',
        error: e,
      });
    }
    setCurrentScenarioValidationStatus(ValidationStatus.Validated);
  };
  const validateStructure = () => {
    validateStructureAsync().finally();
  };
  useEffect(validateStructure, [currentScenarioStructure]);

  const onButtonClick = async () => {
    if (currentScenarioValidationStatus === ValidationStatus.Validating) {
      return;
    }

    if (!visibleIssueList.length) {
      return;
    }

    let nextIssueIndex = selectedValidationIssue ? currentIssueIndex + 1 : 0;
    if (nextIssueIndex >= visibleIssueList.length) {
      nextIssueIndex = 0;
    }

    const nextSelectedValidationIssue = visibleIssueList[nextIssueIndex];
    setSelectedValidationIssue(nextSelectedValidationIssue);
    await focusToElement(nextSelectedValidationIssue.entity.id, nextSelectedValidationIssue.fieldPath);
  };

  if (!visibleIssueList.length && currentScenarioValidationStatus === ValidationStatus.Validated) {
    return null;
  }

  const getButtonIcon = () => {
    if (currentScenarioValidationStatus === ValidationStatus.Validated) {
      return undefined;
    }

    return <SbIcon spin iconName="loading-four" />;
  };

  const getButtonContent = () => {
    const warningCount = visibleIssueList.length;
    if (!warningCount) {
      return 'Проверка';
    }

    if (selectedValidationIssue) {
      return `Предупреждение ${currentIssueIndex + 1} из ${warningCount}`;
    }

    return numberToWordsRu.convert(warningCount, {
      currency: {
        currencyNameCases: ['предупреждение', 'предупреждения', 'предупреждений'],
        currencyNameDeclensions: {
          nominative: ['предупреждение', 'предупреждения'],
          genitive: ['предупреждения', 'предупреждений'],
          dative: ['предупреждению', 'предупреждениям'],
          accusative: ['предупреждение', 'предупреждения'],
          instrumental: ['предупреждением', 'предупреждениями'],
          prepositional: ['предупреждении', 'предупреждениях'],
        },
        currencyNounGender: {
          integer: 0,
          fractionalPart: 1,
        },
        fractionalPartMinLength: 0,
      },
      showNumberParts: {
        fractional: false,
      },
      convertNumbertToWords: {
        integer: false,
      },
      showCurrency: {
        fractional: false,
      },
    });
  };

  return (
    <SbTooltip
      overlayClassName="sb-tooltip-validation__overlay"
      placement="bottomRight"
      title={
        <ReactMarkdown renderers={{ link: (props) => <Link to={props.href}>{props.children}</Link> }}>
          {selectedValidationIssue?.ruleResult.message ?? ''}
        </ReactMarkdown>
      }
      visible={!!selectedValidationIssue}
    >
      <SbButton
        icon={getButtonIcon()}
        loading={currentScenarioValidationStatus === ValidationStatus.Validating}
        sbType="text-only"
        onClick={onButtonClick}
      >
        {getButtonContent()}
      </SbButton>
    </SbTooltip>
  );
};

export default memo(ScenarioValidation);
