import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flags } from 'react-feature-flags';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Card, Divider, Menu } from 'antd';
import moment from 'moment';
import numberToWordsRu from 'number-to-words-ru';
import { RcFile } from 'antd/lib/upload';

import './index.less';
import SbContextMenu from '../common/SbContextMenu';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import { botEditionApi, botStageApi } from '../../../apis';
import { BotContentFormat, BotStageModel, BotStageType, ListBotModel } from '../../../../api';
import { AlertTypes, ALLOWED_IMPORT_BOT_FILE_TYPES, FeatureFlagNames } from '../../../constants';
import { downloadNamedFile } from '../../../utils/fileUtil';
import { webchatModalSelector } from '../../../recoil/modals';
import SbTabs from '../common/SbTabs';
import SbTabPane from '../common/SbTabPane';
import { currentBotStageTypeSelector } from '../../recoil';
import SbIcon from '../common/SbIcon';
import SbUpload from '../common/SbUpload';
import SbBotVersionsModal from '../SbBotVersionsModal';

const renderScenariosCount = (count: number) =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['сценарий', 'сценария', 'сценариев'],
      currencyNameDeclensions: {
        nominative: ['сценарий', 'сценарии'],
        genitive: ['сценария', 'сценариев'],
        dative: ['сценарию', 'сценариям'],
        accusative: ['сценарий', 'сценарии'],
        instrumental: ['сценарием', 'сценариями'],
        prepositional: ['сценарии', 'сценариях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

const renderModificationDate = (modifiedOn: string) => {
  const dateFormat = 'DD.MM.YYYY в HH:mm';
  return `Изменен ${moment(modifiedOn).format(dateFormat)}`;
};

interface ISbBotListCardProps {
  bot: ListBotModel;
  onBotChanged: (bot: ListBotModel) => void;
}

const SbBotListCard: React.FC<ISbBotListCardProps> = ({ bot, onBotChanged }) => {
  const { push } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const setWebchatModalState = useSetRecoilState(webchatModalSelector);
  const [currentBotStageType, setCurrentBotStageType] = useRecoilState(currentBotStageTypeSelector(bot.entry.id));
  const [botVersionsModalVisible, setBotVersionsModalVisible] = useState(false);

  const [draftDeleting, setDraftDeleting] = useState(false);

  const onOpenBot = () => push(`/simple-bots/${bot.entry.id}`);

  const onTestBot = async () => {
    try {
      const response = await botStageApi.testBotStage(bot.originStage.id);
      setWebchatModalState({ title: bot.entry.name, webchatUrl: response.data.webchatUrl });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске тестирования бота',
        description: e instanceof Error ? e.message : String(e),
      });
    }
  };

  const onShowBotVersions = () => setBotVersionsModalVisible(true);
  const onBotVersionsModalClose = () => setBotVersionsModalVisible(false);

  const onExport = (botStage?: BotStageModel) => async () => {
    if (!botStage) return;

    try {
      const response = await botEditionApi.exportBotEdition(
        botStage.currentEdition.botEditionId,
        BotContentFormat.Json,
        { responseType: 'blob' }
      );
      downloadNamedFile(response);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при экспорте бота',
        error: e,
      });
    }
  };

  const onDeleteDraft = async () => {
    if (!bot.draftStage) return;

    setDraftDeleting(true);
    try {
      await botStageApi.deleteBotStage(bot.draftStage.id);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении черновика бота',
        error: e,
      });
    }
    setCurrentBotStageType(BotStageType.Origin);
    onBotChanged({
      ...bot,
      draftStage: undefined,
    });
    setDraftDeleting(false);
  };

  const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
    if (!botStage) return;

    try {
      await botStageApi.importBot(botStage.id, {
        botFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
      onOpenBot();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при импорте бота',
        error: e,
        description:
          e.response?.data?.title === 'InvalidCast' ? 'Загружаемый файл не является файлом описания бота' : undefined,
      });
    }
  };

  const onTabChange = (activeKey: string) => setCurrentBotStageType(activeKey as BotStageType);

  const onBotVersionsModalDraftStageAdded = (draftStage: BotStageModel) =>
    onBotChanged({
      ...bot,
      draftStage,
    });

  const contextMenuContent =
    currentBotStageType === BotStageType.Draft ? (
      <Menu>
        <Menu.Item key="export" onClick={onExport(bot.draftStage)}>
          Экспортировать черновик
        </Menu.Item>
        <Menu.Item key="import-as-draft">
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className="sb-bot-list-card__import-upload"
            onFileUpload={onImportBotFileUpload(bot.draftStage)}
          >
            Импортировать в черновик
          </SbUpload>
        </Menu.Item>
        <Menu.Item key="delete" onClick={onDeleteDraft}>
          Удалить черновик
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        <Menu.Item key="open" onClick={onOpenBot}>
          Перейти к сценариям
        </Menu.Item>
        <Menu.Item key="test" onClick={onTestBot}>
          Тестировать
        </Menu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="connect">Подключить мессенжер</Menu.Item>
        </Flags>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="rename">Переименовать</Menu.Item>
        </Flags>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="duplicate">Дублировать</Menu.Item>
        </Flags>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="create-draft">Создать черновик</Menu.Item>
        </Flags>
        <Menu.Item key="history" onClick={onShowBotVersions}>
          История версий
        </Menu.Item>
        <Menu.Item key="export" onClick={onExport(bot.originStage)}>
          Экспортировать
        </Menu.Item>
        <Menu.Item key="import-as-origin">
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            className="sb-bot-list-card__import-upload"
            onFileUpload={onImportBotFileUpload(bot.originStage)}
          >
            Импортировать
          </SbUpload>
        </Menu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
          <Menu.Item key="delete">Удалить</Menu.Item>
        </Flags>
      </Menu>
    );

  const renderStageContent = (botStage: BotStageModel) => (
    <div className="sb-bot-list-card__content">
      <div className="sb-bot-list-card__scenario-count">
        {renderScenariosCount(botStage.currentEdition.scenarios.length)}
      </div>
      <Divider className="sb-bot-list-card__divider" type="vertical" />
      <div className="sb-bot-list-card__scenario-date">{renderModificationDate(botStage.modifiedOn)}</div>
    </div>
  );

  return (
    <>
      <Card hoverable className="sb-bot-list-card" onClick={onOpenBot}>
        {draftDeleting ? (
          <SbIcon spin iconName="loading-four" />
        ) : (
          <>
            <div className="sb-bot-list-card__container">
              <div className="sb-bot-list-card__top">
                <h3 className="sb-bot-list-card__top__title">
                  <span className="sb-bot-list-card__top__title-text">{bot.entry.name}</span>
                </h3>
                <div className="sb-bot-list-card__top__icon" role="none" onClick={(e) => e.stopPropagation()}>
                  <SbContextMenu menuContent={contextMenuContent} />
                </div>
              </div>
              {bot.draftStage ? (
                <SbTabs activeKey={currentBotStageType} onChange={onTabChange}>
                  <SbTabPane key={BotStageType.Origin} tab="Основной">
                    {renderStageContent(bot.originStage)}
                  </SbTabPane>
                  <SbTabPane key={BotStageType.Draft} tab="Черновик">
                    {renderStageContent(bot.draftStage)}
                  </SbTabPane>
                </SbTabs>
              ) : (
                renderStageContent(bot.originStage)
              )}
            </div>
            <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
              <div className="sb-bot-list-card__state sb-bot-list-card__state_on">Подключен</div>
            </Flags>
          </>
        )}
      </Card>
      <SbBotVersionsModal
        botId={bot.entry.id}
        visible={botVersionsModalVisible}
        onClose={onBotVersionsModalClose}
        onDraftStageAdded={onBotVersionsModalDraftStageAdded}
      />
    </>
  );
};

export default SbBotListCard;
