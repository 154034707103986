import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { SelectValue } from 'antd/lib/select';
import { AlignType } from 'rc-table/lib/interface';

import './index.less';

import { ValidatorSchema, VariableOwner, VariableSchema, VariableState, VariableType } from '../../../../../api';
import {
  DateTimeBinaryConditionOperators,
  DateTimeUnaryConditionOperators,
  LengthBinaryConditionOperators,
  NumberBinaryConditionOperators,
  ScenarioActionNames,
  VariableScopes,
  VariableTypes,
} from '../../constants';
import SbModal from '../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../simple-bot/components/common/SbButton';
import SbSelect from '../../../../simple-bot/components/common/SbSelect';
import SbTable from '../../../../simple-bot/components/common/SbTable';
import { includesIgnoreCase } from '../../../../utils/stringUtil';
import SbSearch from '../../../../simple-bot/components/common/SbSearch';
import VariableEditor from '../VariableEditor';
import {
  instanceOfCurrentDateTimeComparisonValidator,
  instanceOfDateTimeTypeValidator,
  instanceOfDateTimeValueComparisonValidator,
  instanceOfLengthComparisonValidator,
  instanceOfNumberValueComparisonValidator,
} from '../../utils';
import { IScenarioEntity } from '../../types';

const MODAL_WIDTH = 924;
const ALL_VARIABLE_TYPE = 'All';

const getValidatorText = (validator: ValidatorSchema) => {
  if (instanceOfLengthComparisonValidator(validator)) {
    return `Значение ${LengthBinaryConditionOperators.find(
      (o) => o.value === validator.operator
    )?.label.toLowerCase()} ${validator.length}`;
  }
  if (instanceOfNumberValueComparisonValidator(validator)) {
    return `Значение ${NumberBinaryConditionOperators.find(
      (o) => o.value === validator.operator
    )?.label.toLowerCase()} ${validator.value}`;
  }
  if (instanceOfDateTimeValueComparisonValidator(validator)) {
    return `Значение ${DateTimeBinaryConditionOperators.find(
      (o) => o.value === validator.operator
    )?.label.toLowerCase()} ${validator.value}`;
  }
  if (instanceOfDateTimeTypeValidator(validator)) {
    return `Значение: ${DateTimeUnaryConditionOperators.find(
      (o) => o.value === validator.expectedType
    )?.label.toLowerCase()}`;
  }
  if (instanceOfCurrentDateTimeComparisonValidator(validator)) {
    return `Значение ${DateTimeBinaryConditionOperators.find(
      (o) => o.value === validator.operator
    )?.label.toLowerCase()} сегодня`;
  }
  throw new Error(`Неизвестный тип валидатора '${validator.$kind}'`);
};

const variableColumns = [
  {
    title: 'Имя переменной',
    dataIndex: 'name',
    key: 'name',
    width: '220px',
  },
  {
    title: 'Системное название',
    dataIndex: 'code',
    key: 'code',
    width: '220px',
  },
  {
    title: 'Тип',
    render: (record: VariableSchema) => VariableTypes.find((t) => t.value === record.type)?.label,
    key: 'type',
    width: '80px',
  },
  {
    title: 'Область',
    render: (record: VariableSchema) => VariableScopes.find((t) => t.value === record.scope)?.label,
    key: 'scope',
    width: '100px',
  },
  {
    title: 'Ограничение',
    render: (record: VariableSchema) =>
      record.validators?.length ? record.validators.map((v) => getValidatorText(v)).join(', ') : '-',
    key: 'validators',
    width: 'auto',
    align: 'right' as AlignType,
  },
];

const getFilteredVariables = (variables: VariableSchema[], filter?: IVariableFilter) => {
  if (!filter) return variables;

  let result = variables.filter((v) => v.state === filter?.state);
  if (filter.type !== ALL_VARIABLE_TYPE) {
    result = result.filter((v) => v.type === filter.type);
  }
  if (filter.search) {
    result = result.filter(
      (v) => includesIgnoreCase(v.name, filter.search) || includesIgnoreCase(v.code, filter.search)
    );
  }
  return result;
};

interface IVariableFilter {
  search: string;
  type: string;
  state: VariableState;
}

interface IVariablesSettingsProps {
  visible: boolean;
  variables: VariableSchema[];
  onChange?: (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => void;
  onClose?: () => void;
  getVariableUsages: (variable: VariableSchema) => Promise<IScenarioEntity[]>;
}

const VariablesSettings: React.FC<IVariablesSettingsProps> = ({
  visible,
  variables,
  onChange = () => {},
  onClose = () => {},
  getVariableUsages,
}) => {
  const [editorVisible, setEditorVisible] = useState(false);
  const [variablesFilter, setVariablesFilter] = useState<IVariableFilter>({
    search: '',
    type: ALL_VARIABLE_TYPE,
    state: VariableState.Exists,
  });
  const [currentVariable, setCurrentVariable] = useState<VariableSchema>();
  const [currentVariables, setCurrentVariables] = useState(cloneDeep(variables));
  const [selectedVariableId, setSelectedVariableId] = useState<string>();
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
  const [isDeleteUnavailableModalVisible, setIsDeleteUnavailableModalVisible] = useState(false);
  const [actionsWithVariables, setActionsWithVariables] = useState<IScenarioEntity[]>();

  const filteredCurrentVariables = getFilteredVariables(currentVariables, variablesFilter);

  const selectedVariable = filteredCurrentVariables.find((v) => v.id === selectedVariableId);

  const onCreateButtonClick = () => {
    setCurrentVariable(undefined);
    setEditorVisible(true);
  };

  const onTypeSelectChange = (value: SelectValue) =>
    setVariablesFilter({
      ...variablesFilter,
      type: value as VariableType,
    });

  const onSearch = (value: string) =>
    setVariablesFilter({
      ...variablesFilter,
      search: value,
    });

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) =>
    setVariablesFilter({
      ...variablesFilter,
      search: event.target.value,
    });

  const onSelectVariableKeys = (rowKeys: string[]) => setSelectedVariableId(rowKeys.pop());

  const onEditorChange = (variable: VariableSchema) => {
    if (currentVariable) {
      setCurrentVariables(currentVariables.map((v) => (v.id === variable.id ? variable : v)));
    } else {
      setCurrentVariables([...currentVariables, variable]);
      setSelectedVariableId(variable.id);
    }
  };

  const onEditorClose = () => setEditorVisible(false);

  const onSave = () => {
    onChange(currentVariables, selectedVariable);
    onClose();
  };

  const onEditButtonClick = () => {
    if (!selectedVariable) return;

    setCurrentVariable(selectedVariable);
    setEditorVisible(true);
  };

  const onDeleteButtonClick = async () => {
    if (!selectedVariable) return;

    const variableUsages = await getVariableUsages(selectedVariable);

    setActionsWithVariables(variableUsages);
    if (variableUsages?.length) {
      setIsDeleteUnavailableModalVisible(true);
    } else {
      setIsDeleteConfirmationModalVisible(true);
    }
  };

  const onConfirmDeletion = () => {
    if (!selectedVariable) return;

    const variables = [...currentVariables];
    const index = variables.indexOf(selectedVariable);
    if (index !== -1) {
      variables[index].state = VariableState.Deleted;
      setCurrentVariables(variables);
    }

    onChange(variables, undefined);
    setIsDeleteConfirmationModalVisible(false);
  };

  const onCancel = () => onClose();
  const onDeleteConfirmationModalClose = () => setIsDeleteConfirmationModalVisible(false);
  const onDeleteUnavailableModalClose = () => setIsDeleteUnavailableModalVisible(false);

  const onVariablesPropChange = () => {
    setCurrentVariables(cloneDeep(variables));
  };

  useEffect(onVariablesPropChange, [variables]);

  const variableOwner = selectedVariable?.owner || VariableOwner.User;
  const canEditVariable = selectedVariable && variableOwner !== VariableOwner.System;

  const entityColumns = [
    {
      render: (_: string, entity: IScenarioEntity) => {
        return (
          <div className="sb-action-with-var-table-row">
            <div className="sb-action-with-var-table-row__cell">
              <p>
                <b>{entity.scenarioName}</b>
              </p>
              <p>{ScenarioActionNames[entity.item.$kind]}</p>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <SbModal
      footer={[
        <SbButton key="save" sbSize="medium" sbType="primary" onClick={onSave}>
          Сохранить
        </SbButton>,
        <SbButton key="edit" disabled={!canEditVariable} sbSize="medium" sbType="secondary" onClick={onEditButtonClick}>
          Настроить
        </SbButton>,
        <SbButton
          key="delete"
          danger
          disabled={!canEditVariable}
          sbSize="medium"
          sbType="secondary"
          onClick={onDeleteButtonClick}
        >
          Удалить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancel}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title={'Выберите переменную'}
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onCancel}
      onOk={onSave}
    >
      <Row className="sb-variable-settings sb-modal__row-stretch" gutter={[0, 20]} wrap={false}>
        <Col className="sb-modal__col-main" span={24}>
          <Row gutter={[12, 24]} wrap={false}>
            <Col flex="1 1 0">
              <SbButton sbSize="medium" sbType="secondary" onClick={onCreateButtonClick}>
                Создать новую переменную
              </SbButton>
            </Col>
            <Col flex="auto" />
            <Col flex="157px">
              <SbSelect sbSize="small" sbType="light" value={variablesFilter.type} onChange={onTypeSelectChange}>
                {[{ value: ALL_VARIABLE_TYPE, label: 'Все' }, ...VariableTypes].map((t) => (
                  <Select.Option key={t.value} value={t.value}>
                    {t.label}
                  </Select.Option>
                ))}
              </SbSelect>
            </Col>
            <Col flex="292px">
              <SbSearch
                placeholder="Поиск"
                sbSize="small"
                value={variablesFilter.search}
                onChange={onSearchValueChange}
                onSearch={onSearch}
              />
            </Col>
          </Row>
          <Row className="sb-modal__row-stretch" gutter={[0, 0]} wrap={false}>
            <Col span={24}>
              <SbTable
                columns={variableColumns}
                dataSource={filteredCurrentVariables}
                rowKey="id"
                selectedRowKeys={selectedVariableId ? [selectedVariableId] : []}
                tableLayout="fixed"
                onSelectRowKeys={onSelectVariableKeys}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <VariableEditor
        variable={currentVariable}
        variables={currentVariables}
        visible={editorVisible}
        onChange={onEditorChange}
        onClose={onEditorClose}
      />
      <SbModal
        footer={[
          <SbButton key="ok" sbSize="medium" sbType="secondary" onClick={onDeleteUnavailableModalClose}>
            Назад
          </SbButton>,
        ]}
        sbSize="small"
        title="Удаление невозможно"
        visible={isDeleteUnavailableModalVisible}
        onCancel={onDeleteUnavailableModalClose}
      >
        <p>Невозможно удалить выбранную переменную, так как она используется в следующих элементах сценария:</p>
        <SbTable columns={entityColumns} dataSource={actionsWithVariables} header={false} />
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="delete" sbSize="medium" sbType="primary" onClick={onConfirmDeletion}>
            Удалить
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onDeleteConfirmationModalClose}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Подтвердите удаление переменной"
        visible={isDeleteConfirmationModalVisible}
        onCancel={onDeleteConfirmationModalClose}
        onOk={onConfirmDeletion}
      >
        <div>
          Вы действительно хотите удалить переменную <b>{selectedVariable?.name}</b>?
        </div>
      </SbModal>
    </SbModal>
  );
};

export default VariablesSettings;
