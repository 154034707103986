import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { useSetRecoilState } from 'recoil';
import './index.less';

import { ListScenarioTemplateModel, ListBotTemplateModel } from '../../../../../../api';
import { scenarioTemplateApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import SbSelectScenarioCard from '../../../../components/SbScenarioCard/SbSelectScenarioCard';
import SbScroll from '../../../../components/common/SbScroll';
import SbTypography from '../../../../components/common/SbTypography';
import SbInput from '../../../../components/common/SbInput';
import SbButton from '../../../../components/common/SbButton';

interface IScenariosSelectionStepProps {
  selectedBotTemplate: ListBotTemplateModel;
  selectedScenariosIds: string[];
  botName: string;
  onSelectedScenariosIdsChange: (newIds: string[]) => void;
  onBotNameChange: (value: string) => void;
}

const PAGE_SIZE = 1000;

const ScenariosSelectionStep: React.FC<IScenariosSelectionStepProps> = ({
  selectedBotTemplate,
  selectedScenariosIds,
  botName,
  onSelectedScenariosIdsChange,
  onBotNameChange,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(true);
  const [scenarios, setScenarios] = useState<ListScenarioTemplateModel[]>([]);

  const loadScenariosAsync = async () => {
    setLoading(true);
    try {
      const selectedBotTemplateScenariosResponse = await scenarioTemplateApi.searchScenarioTemplates(
        selectedBotTemplate.id,
        undefined,
        0,
        PAGE_SIZE
      );
      setScenarios(selectedBotTemplateScenariosResponse.data.items ?? []);
      selectedBotTemplateScenariosResponse.data.items &&
        onSelectedScenariosIdsChange(
          selectedBotTemplateScenariosResponse.data.items.filter((s) => s.isSelectedByDefault).map((s) => s.id)
        );
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке сценариев',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadScenarios = () => {
    // noinspection JSIgnoredPromiseFromCall
    loadScenariosAsync();
  };
  useEffect(loadScenarios, [selectedBotTemplate]);

  const onScenarioClick = (scenario: ListScenarioTemplateModel) => {
    if (selectedScenariosIds.includes(scenario.id)) {
      onSelectedScenariosIdsChange(selectedScenariosIds.filter((id) => id !== scenario.id));
    } else {
      onSelectedScenariosIdsChange([...selectedScenariosIds, scenario.id]);
    }
  };

  const onSelectAllButtonClick = () => {
    onSelectedScenariosIdsChange(scenarios.map((s) => s.id));
  };

  const onDeselectAllButtonClick = () => {
    onSelectedScenariosIdsChange([]);
  };

  return (
    <div className="sb-create-bot-wizard__content__scenarios-selection-step">
      <SbTypography>
        <h2>Назовите чат-бот</h2>
        <SbInput allowClear={false} sbSize="small" value={botName} onChange={onBotNameChange} />
        <div className="sb-create-bot-wizard__content__scenarios-selection-step__scenarios-header">
          <h2>
            Выберите сценарии{' '}
            {!loading && (
              <span className="sb-create-bot-wizard__content__scenarios-selection-step__scenario-count">
                ({selectedScenariosIds.length} из {scenarios.length})
              </span>
            )}
          </h2>
          {scenarios.length !== selectedScenariosIds.length && (
            <SbButton sbSize="medium" sbType="tertiary" onClick={onSelectAllButtonClick}>
              Выбрать все
            </SbButton>
          )}
          {scenarios.length === selectedScenariosIds.length && (
            <SbButton sbSize="medium" sbType="tertiary" onClick={onDeselectAllButtonClick}>
              Очистить выбор
            </SbButton>
          )}
        </div>
      </SbTypography>
      <SbScroll>
        <List<ListScenarioTemplateModel>
          className="sb-create-bot-wizard__content__list"
          dataSource={scenarios}
          grid={{ gutter: 20, xxl: 5, xl: 4, lg: 3, md: 2, sm: 2, xs: 1 }}
          loading={loading}
          renderItem={(item: ListScenarioTemplateModel) => {
            return (
              <List.Item key={item.code}>
                <SbSelectScenarioCard
                  isSelected={selectedScenariosIds.includes(item.id)}
                  scenario={item}
                  onCardClick={() => {
                    onScenarioClick(item);
                  }}
                />
              </List.Item>
            );
          }}
        />
      </SbScroll>
    </div>
  );
};

export default ScenariosSelectionStep;
