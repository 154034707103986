import React from 'react';
import ReactDOM from 'react-dom';
import { FlagsProvider } from 'react-feature-flags';
import { ConfigProvider, Empty } from 'antd';
import { RecoilRoot } from 'recoil';
import ruRu from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import moment from 'moment';

import './index.less';

import { processAuth } from './utils/oidcUtil';
import { includesIgnoreCase } from './utils/stringUtil';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Alerts from './components/Alerts';
import { initKnowledgeBaseServiceApi } from './apis';
import { getFeatureFlags } from './utils/featureFlagsUtil';

moment.locale(window.navigator.language);

(async () => {
  if (!(await processAuth())) {
    return;
  }

  await initKnowledgeBaseServiceApi();

  const customizeRenderEmpty = () => <Empty description="Нет данных" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  const locale = includesIgnoreCase(navigator.language, 'ru') ? ruRu : undefined;

  const featureFlags = await getFeatureFlags();

  ReactDOM.render(
    <FlagsProvider value={featureFlags}>
      <RecoilRoot>
        <ConfigProvider locale={locale} renderEmpty={customizeRenderEmpty}>
          <Alerts />
          <App />
        </ConfigProvider>
      </RecoilRoot>
    </FlagsProvider>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
})();
