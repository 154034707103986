import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import './index.less';

import SbModal from '../../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../../simple-bot/components/common/SbButton';
import { currentScenarioStructureSelector } from '../../../../../../recoil/scenarioStructure';
import SbScriptEditor from '../../../../../../simple-bot/components/common/SbScriptEditor';

const MODAL_WIDTH = 916;

interface IScriptEditorProps {
  visible: boolean;
  readOnly?: boolean;
  content: string;
  onChange?: (content: string) => void;
  onClose: () => void;
}

const ScriptEditor: React.FC<IScriptEditorProps> = ({ visible, readOnly, content, onChange, onClose }) => {
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const [currentContent, setCurrentContent] = useState(content);

  useEffect(() => {
    setCurrentContent(content);
  }, [visible, content]);

  const onModalOk = () => {
    if (onChange) {
      onChange(currentContent);
    }
    onClose();
  };

  const onModalCancel = () => onClose();

  const onSaveButtonClick = () => {
    if (onChange) {
      onChange(currentContent);
    }
    onClose();
  };

  const onCancelButtonClick = () => onClose();

  const onCurrentContentEdit = (value?: string) => setCurrentContent(value || '');

  return (
    <SbModal
      destroyOnClose
      footer={
        !readOnly && [
          <SbButton key="save" disabled={!currentContent} sbSize="medium" onClick={onSaveButtonClick}>
            Сохранить
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
            Отмена
          </SbButton>,
        ]
      }
      keyboard={false}
      maskClosable={false}
      sbSize="small"
      title={readOnly ? `Просмотр скрипта` : `Редактирование скрипта`}
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
      onOk={onModalOk}
    >
      <div className="script-editor">
        <SbScriptEditor
          height="90vh"
          readOnly={readOnly}
          schema={scenarioStructure}
          value={currentContent}
          onChange={onCurrentContentEdit}
        />
      </div>
    </SbModal>
  );
};

export default ScriptEditor;
