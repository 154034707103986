import React, { useEffect, useState } from 'react';
import { Button, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SortOrder, SorterResult } from 'antd/lib/table/interface';
import { EyeOutlined, RobotOutlined, UserOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import {
  ActivityDirection,
  ConversationClosingReason,
  ConversationClosingStatus,
  ConversationModel,
  ConversationSortDirection,
  ConversationStatus,
} from '../../api';
import { alertsSelectorAdd } from '../recoil/alerts';
import { conversationApi } from '../apis';
import { AlertTypes } from '../constants';

import FormattedDateTime from './FormattedDateTime';

export interface IConversationListProps {
  channelId?: string;
  agentStageId?: string;
  agentStageAccountId?: string;
  personId?: string;
}

const startedOnDirections: { [id: number]: string | null } = {
  [ConversationSortDirection.NUMBER_1]: 'ascend',
  [ConversationSortDirection.NUMBER_2]: 'descend',
};

const finishedOnDirections: { [id: number]: string | null } = {
  [ConversationSortDirection.NUMBER_3]: 'ascend',
  [ConversationSortDirection.NUMBER_4]: 'descend',
};

const sortingMap: { [id: string]: ConversationSortDirection } = {
  'startedOn.ascend': ConversationSortDirection.NUMBER_1,
  'startedOn.descend': ConversationSortDirection.NUMBER_2,
  'finishedOn.ascend': ConversationSortDirection.NUMBER_3,
  'finishedOn.descend': ConversationSortDirection.NUMBER_4,
};

const conversationStatusNames = {
  [ConversationStatus.Active]: 'Открыта',
  [ConversationStatus.Closed]: 'Закрыта',
  [ConversationStatus.Operator]: 'Оператор',
};

const conversationClosingStatusNames = {
  [ConversationClosingStatus.NotInitiated]: '',
  [ConversationClosingStatus.Initiated]: '1 - в очереди',
  [ConversationClosingStatus.ClosingMessageSent]: '2 - сообщение отправлено',
  [ConversationClosingStatus.DialogResetEventSent]: '3 - диалоги сброшены',
  [ConversationClosingStatus.SessionClosed]: '4 - сессия закрыта',
};

const conversationClosingReasonNames = {
  [ConversationClosingReason.TimedOut]: 'По таймауту',
  [ConversationClosingReason.ClosedByScenarioAction]: 'Сценарий',
  [ConversationClosingReason.ClosedByOperator]: 'Оператор',
  [ConversationClosingReason.ConversationRestarted]: 'Рестарт',
};

const PAGE_SIZE = 5;

const ConversationList: React.FC<IConversationListProps> = ({
  channelId,
  agentStageId,
  agentStageAccountId,
  personId,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([] as ConversationModel[]);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: 0,
  });

  const [sorting, setSorting] = useState<ConversationSortDirection>(ConversationSortDirection.NUMBER_2);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await conversationApi.searchConversations(
        channelId || undefined,
        agentStageId || undefined,
        agentStageAccountId || undefined,
        personId || undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        sorting,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setConversations(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка бесед',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [sorting, pagination.current, pagination.pageSize]);

  const onTableChange = (
    tablePagination: TablePaginationConfig,
    _: unknown,
    tableSorter: SorterResult<ConversationModel & { key: number }> | SorterResult<ConversationModel & { key: number }>[]
  ) => {
    setPagination(tablePagination);

    const sorter =
      Array.isArray(tableSorter) && tableSorter.length
        ? tableSorter[0]
        : (tableSorter as SorterResult<ConversationModel & { key: number }>);

    setSorting(
      sorter.field && sorter.column ? sortingMap[`${sorter.field}.${sorter.order}`] : ConversationSortDirection.NUMBER_2
    );
  };

  const columns = [];

  columns.push({
    title: 'Идентификатор',
    dataIndex: 'externalId',
    ellipsis: true,
  });

  columns.push({
    title: 'Начало',
    dataIndex: 'startedOn',
    sorter: true,
    sortOrder: startedOnDirections[sorting] as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
    showSorterTooltip: false,
    render: (_: unknown, conversation: ConversationModel) => <FormattedDateTime value={conversation.startedOn} />,
  });

  columns.push({
    title: 'Окончание',
    dataIndex: 'finishedOn',
    sorter: true,
    sortOrder: finishedOnDirections[sorting] as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
    showSorterTooltip: false,
    render: (_: unknown, conversation: ConversationModel) => <FormattedDateTime value={conversation.finishedOn} />,
  });

  if (!channelId && !agentStageAccountId) {
    columns.push({
      title: 'Канал',
      width: '80px',
      ellipsis: true,
      dataIndex: 'channelId',
    });
  }

  columns.push({
    title: 'ServiceUrl',
    dataIndex: 'serviceUrl',
  });

  columns.push({
    title: 'Количество сообщений',
    dataIndex: 'messagesCount',
    width: '100px',
  });

  columns.push({
    title: '⇄',
    width: '48px',
    align: 'right' as const,
    render: (_: unknown, conversation: ConversationModel) =>
      conversation.latestMessageDirection !== undefined &&
      conversation.latestMessageText && (
        <div>
          {conversation.latestMessageDirection === ActivityDirection.NUMBER_0 ? <UserOutlined /> : <RobotOutlined />}
        </div>
      ),
  });

  columns.push({
    title: 'Последнее сообщение',
    dataIndex: 'latestMessageText',
    width: '25%',
    ellipsis: true,
  });

  columns.push({
    title: 'Статус',
    dataIndex: 'status',
    showSorterTooltip: false,
    render: (_: unknown, conversation: ConversationModel) =>
      conversationStatusNames[conversation.status ?? ConversationStatus.Active],
  });

  columns.push({
    title: 'Причина',
    dataIndex: 'closingReason',
    showSorterTooltip: false,
    render: (_: unknown, conversation: ConversationModel) =>
      conversation.closingReason && conversationClosingReasonNames[conversation.closingReason],
  });

  columns.push({
    title: 'Статус закрытия',
    dataIndex: 'closingStatus',
    sorter: true,
    sortOrder: finishedOnDirections[sorting] as SortOrder,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
    showSorterTooltip: false,
    render: (_: unknown, conversation: ConversationModel) =>
      conversation.closingStatus && conversationClosingStatusNames[conversation.closingStatus],
  });

  columns.push({
    dataIndex: 'actions',
    width: '48px',
    render: (_: unknown, conversation: ConversationModel) => {
      const onTranscriptViewButtonClick = () => {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window.open(`/conversations/${conversation.id}`, '_blank');
      };
      return (
        <Tooltip placement="top" title="Просмотр транскрипта беседы">
          <Button icon={<EyeOutlined />} type="primary" onClick={onTranscriptViewButtonClick} />
        </Tooltip>
      );
    },
  });

  return (
    <Table
      columns={columns}
      dataSource={conversations.map((item, i) => ({ key: i, ...item }))}
      loading={loading}
      pagination={pagination}
      size="small"
      onChange={onTableChange}
    />
  );
};

export default ConversationList;
