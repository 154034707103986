import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Divider, Menu } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';

import './index.less';

import SbButton from '../../../../components/common/SbButton';
import SbIcon from '../../../../components/common/SbIcon';
import SbSwitch from '../../../../components/common/SbSwitch';
import SbContextMenu from '../../../../components/common/SbContextMenu';
import { KnowledgeBaseEntryType, SingleKnowledgeBaseModel } from '../../../../../../kb-api';
import { botApi, knowledgeBaseKbApi, stageKbApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { BotEditionModel, SingleBotModel } from '../../../../../../api';
import { renderRecordCount } from '../../../../../utils/stringUtil';

import StageSettingsModal from './StageSettingsModal';

interface IKnowledgeBaseBlockProps {
  bot: SingleBotModel;
  botEdition: BotEditionModel;
  botStageId: string;
  knowledgeBase?: SingleKnowledgeBaseModel;
  onDataChanged: () => Promise<void>;
}

const getKnowledgeBaseEnabledValue = (knowledgeBase?: SingleKnowledgeBaseModel) => {
  if (!knowledgeBase) return false;

  const { recognizerSettings } = knowledgeBase.originStage;
  return recognizerSettings ? recognizerSettings.enabled : true;
};

const KnowledgeBaseBlock: React.FC<IKnowledgeBaseBlockProps> = ({
  bot,
  botEdition,
  botStageId,
  knowledgeBase,
  onDataChanged,
}) => {
  const { push } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [toggling, setToggling] = useState(false);
  const [creating, setCreating] = useState(false);
  const [knowledgeBaseEnabled, setKnowledgeBaseEnabled] = useState(getKnowledgeBaseEnabledValue(knowledgeBase));
  const [stageSettingsModalVisible, setStageSettingsModalVisible] = useState(false);

  const onKnowledgeBasePropChange = () => {
    setKnowledgeBaseEnabled(getKnowledgeBaseEnabledValue(knowledgeBase));
  };
  useEffect(onKnowledgeBasePropChange, [knowledgeBase]);

  const onToggleKnowledgeBase = async (checked: boolean, event: MouseEvent) => {
    event.stopPropagation();
    if (!knowledgeBase) return;

    setToggling(true);
    try {
      const enabled = !knowledgeBaseEnabled;
      await stageKbApi.updateStage(knowledgeBase.originStage.id, {
        recognizerSettings: {
          ...knowledgeBase.originStage.recognizerSettings,
          enabled,
        },
      });
      setKnowledgeBaseEnabled(enabled);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при обновлении настроек стадии базы знаний',
        error: e,
      });
    }
    setToggling(false);
  };

  const onCreateKnowledgeBase = async () => {
    setCreating(true);
    try {
      const knowledgeBaseCreationResponse = await knowledgeBaseKbApi.createKnowledgeBase({
        name: `База знаний для бота '${bot.entry.name}'`,
        type: KnowledgeBaseEntryType.Default,
        searchParentsOnlyIfNoContext: false,
      });

      const knowledgeBaseId = knowledgeBaseCreationResponse.data.id;
      await botApi.updateBot(bot.entry.id, { knowledgeBaseId });
      push(`/simple-bots/${bot.entry.id}/knowledge-base`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при создании базы знаний для бота',
        error: e,
      });
    }
    setCreating(false);
  };

  const renderHeader = () => {
    if (!knowledgeBase) {
      return <h2>Сейчас у вас нет базы знаний</h2>;
    }

    const onOpenStageSettingsModal = () => {
      setStageSettingsModalVisible(true);
    };

    const onCloseStageSettingsModal = () => {
      setStageSettingsModalVisible(false);
    };

    const contextMenuContent = (
      <Menu>
        <Menu.Item key="settings" onClick={onOpenStageSettingsModal}>
          Настройки
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        <div className="sb-knowledge-base-block__header_left">
          <h2>База знаний</h2>
          <SbSwitch
            checked={knowledgeBaseEnabled}
            label={knowledgeBaseEnabled ? 'Включена' : 'Выключена'}
            loading={toggling}
            onClick={onToggleKnowledgeBase}
          />
        </div>
        <div className="sb-knowledge-base-block__header_right" onClick={(e) => e.stopPropagation()}>
          <SbContextMenu iconSize={20} menuContent={contextMenuContent} />
          <StageSettingsModal
            botEdition={botEdition}
            botStageId={botStageId}
            knowledgeBaseEnabled={knowledgeBaseEnabled}
            stage={knowledgeBase.originStage}
            visible={stageSettingsModalVisible}
            onCancel={onCloseStageSettingsModal}
            onDataChanged={onDataChanged}
          />
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (!knowledgeBase) {
      return (
        <>
          <div className="sb-bot-card__content__block__inner-wrapper__description">
            Добавьте вопросы и ответы вручную или загрузите файлы, и получите бота, который умеет автоматически давать
            ответы на заданные пользователями вопросы
          </div>
          <SbButton
            disabled={creating}
            icon={creating ? <SbIcon spin iconName="loading-four" /> : <SbIcon iconName="plus" />}
            sbType="icon-secondary"
            onClick={onCreateKnowledgeBase}
          >
            Создать с нуля
          </SbButton>
        </>
      );
    }

    return (
      <div className="sb-knowledge-base-block__content__info">
        <span>{renderRecordCount(knowledgeBase.originDataEntryCount || 0)}</span>
        <Divider type="vertical" />
        <span>Изменен {moment(knowledgeBase.originStage.modifiedOn).format('DD.MM.YYYY в HH:mm')}</span>
      </div>
    );
  };

  return (
    <div className="sb-knowledge-base-block">
      <div className="sb-knowledge-base-block__header sb-bot-card__content__block__inner-wrapper__header">
        {renderHeader()}
      </div>
      <div className="sb-knowledge-base-block__content">{renderContent()}</div>
    </div>
  );
};

export default KnowledgeBaseBlock;
